// convenience functions
import {mat4, mat3, vec4, vec3, ReadonlyMat4, ReadonlyVec4 } from 'gl-matrix'; // ReadonlyVec3

export const X_AXIS = vec3.fromValues(1.0, 0.0, 0.0);
export const Y_AXIS = vec3.fromValues(0.0, 1.0, 0.0);
export const Z_AXIS = vec3.fromValues(0.0, 0.0, 1.0);


export function map (v : vec4, m : mat4) : vec4 {
    let result = vec4.create();
    vec4.transformMat4(result, v, m);
    return result;
}

export function map3(v : vec3, m : mat4) : vec3
{
    let result = vec3.create();
    vec3.transformMat4(result, v, m);
    return result;
}
// export function mapPoint(v : vec3, m : mat4) : vec3 {
//     return toVec3(map(toPoint(v), m));
// }

// export function mapVector(v : vec3, m : mat4) : vec3 {
//     return toVec3(map(toVector(v), m));
// }

export function toVec3 (v : vec4) : vec3 {
    return vec3.fromValues(v[0], v[1], v[2]);
}

// export function toPoint (v : vec3) : vec4 {
//     return vec4.fromValues(v[0], v[1], v[2], 0.0);
// }

// export function toVector (v : vec3) : vec4 {
//     return vec4.fromValues(v[0], v[1], v[2], 1.0);
// }

export function inverse(m : mat4) : mat4 {
    let result = mat4.create();
    mat4.invert(result, m);
    return result;
}

export function toMat3(m : mat4) : mat3 {
    return mat3.fromValues(m[0], m[1], m[2], m[4], m[5], m[6], m[8], m[9], m[10]);
}

export function rotate(m : mat4, rad : number, axis: vec3) : mat4 {
    let result = mat4.create();
    mat4.rotate(result, m, rad, axis);
    return result;
}

export function translate(m: mat4, v: vec3) : mat4 {
    let result = mat4.create();
    mat4.translate(result, m, v);
    return result;
}

export function scale(m:mat4, v:vec3) : mat4 {
    let result = mat4.create();
    mat4.scale(result, m, v);
    return result;
}

export function mul(...m : ReadonlyMat4[]) : mat4 {
    let result = mat4.create();
    for (let i = 0; i < m.length; ++i) {
        mat4.multiply(result, result, m[i]);
    }
    return result;
}

export function add(...v : ReadonlyVec4[]) : vec4 {
    let result = vec4.create();
    for (let i = 0; i < v.length; ++i) {
        vec4.add(result, result, v[i]);
    }
    return result;
}


