import React from 'react';
import { Anchor, AnchorProps } from 'grommet';
import {
  RouterState,
  useRouterStore,
  createRouterState,
  routerStateToUrl,
} from 'mobx-state-router';
import { Routes, isRoute } from 'routes';
import styled, { css } from 'styled-components';
import { normalizeColor } from 'grommet/utils';
import { transparentize, lighten } from 'polished';

type LinkProps = React.PropsWithChildren<{
  to: Routes | string;
  params?: RouterState['params'];
  queryParams?: RouterState['queryParams'];
  label?: string;
  icon?: JSX.Element;
}>;

const AnchorWithIsActive = ({
  isActive,
  ...rest
}: { isActive: boolean } & React.PropsWithChildren<
  AnchorProps & Omit<JSX.IntrinsicElements['a'], 'color'>
>) => <Anchor {...rest} />;

export const StyledAnchor = styled(AnchorWithIsActive)`
  color: ${({ theme }) => lighten(theme.dark ? 0.3 : 0, normalizeColor('brand', theme))};
  word-break: break-word;
  ${(props) =>
    props.isActive &&
    css`
      color: ${({ theme }) => lighten(theme.dark ? 0.3 : 0, normalizeColor('brand', theme))};

      &:hover {
        background-color: ${({ theme }) => transparentize(0.88, normalizeColor('brand', theme))};
      }
    `}
`;

function isModifiedEvent(event: React.MouseEvent<HTMLElement>) {
  return event.metaKey || event.altKey || event.ctrlKey || event.shiftKey;
}

const Link = ({ to, params, queryParams, label, icon, children }: LinkProps) => {
  const routerStore = useRouterStore();
  const { routerState } = routerStore;
  const route = isRoute(to);

  if (route) {
    const toState = createRouterState(to, { params, queryParams });
    const isActive = routerState.routeName === toState.routeName;

    const handleClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
      // Ignore if link is clicked using a modifier key.
      // Note: The click event is only fired for the primary pointer button,
      // i.e. the left mouse button. So no need to check for right-click.
      // https://w3c.github.io/uievents/#event-type-click
      // https://github.com/testing-library/testing-library-docs/issues/469
      if (isModifiedEvent(event)) {
        return undefined;
      }

      // Prevent default action which reloads the app
      event.preventDefault();

      // Change the router state to trigger a refresh
      return routerStore.goToState(toState);
    };

    return (
      <StyledAnchor
        href={routerStateToUrl(routerStore, toState)}
        onClick={handleClick}
        label={label}
        icon={icon}
        isActive={isActive}
      >
        {children}
      </StyledAnchor>
    );
  } else {
    // This is an external link -> cannot be active
    return (
      <StyledAnchor href={to} label={label} icon={icon} isActive={false}>
        {children}
      </StyledAnchor>
    );
  }
};

export default Link;
