import React, { Suspense } from 'react';
import { Spinner, AppChrome, Box } from 'components';

type LazyScreenProps = React.PropsWithChildren<{
  chrome?: boolean;
}>;

const LazyScreen = ({ chrome, children }: LazyScreenProps) => {
  const content = (
    <Suspense
      fallback={
        <Box justify="center" align="center" flex="grow">
          <Spinner />
        </Box>
      }
    >
      {children}
    </Suspense>
  );

  if (chrome) {
    return <AppChrome>
      {content}
      </AppChrome>;
  } else {
    return content;
  }
};

export default LazyScreen;
