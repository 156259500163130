const Papa = require('papaparse');

export type HistogramStep = {
    id: string;
    bin0: number;
    bin1: number;
    count: number;
    value?: number;
    text?: string;
    hidden?: boolean;
}

export type HistogramData = {
    url: string;
    data: HistogramStep[];
};

type RowData = {
    data: string[] | boolean[] | number[];
    errors: any[];
    meta: Object;
};

type Parser = {
    abort: Function;
    aborted: Function;
    parse: Function;
    pause: Function;
    paused: Function;
    resume: Function;
    streamer: {
        _rowCount: number
    };
};

export default class HistogramDataReader {

    data : HistogramStep[];
    busy : boolean;

    constructor() {
        this.data = [];
        this.read = this.read.bind(this);
        this._handleRow = this._handleRow.bind(this);
        this.busy = false;
    }

    read = async (url: string, callback?: Function) => {
        this.busy = true
        this.data = [];
        Papa.parse(url, {
            download: true,
            dynamicTyping: true,
            delimiter: '\t',
            step: this._handleRow,
            complete: () => {
                this.busy = false;
                if (callback != null) callback(this.data)
            }
        })
    }

    _handleRow = (rData: RowData, parser: Parser) => {
        if (rData.data[0] == null || !/\d*\.?\d* - \d*\.?\d*/.test(rData.data[0] as string) || rData.data[1] === 0) return;
        let values = (rData.data[0] as string).split(" - ");
        this.data.push({
            id: rData.data[0] as string,
            bin0: Number(values[0]),
            bin1: Number(values[1]),
            count: rData.data[1] as number
        });
    }

    _split(s: string, sep: string): string[] {
        return s.split(sep).filter((s: string) => s);
    }

}

