import Unit from './Unit';
import Dimension from './Dimension';

class ValueWithUnit<D extends Dimension>{
  public readonly unit: Unit<D>;
  private readonly value: number;

  constructor(unit: Unit<D>, value: number) {
    this.unit = unit;
    this.value = value;
  }

  getValueAsBaseUnit() {
    return this.unit.toBaseUnit(this.value);
  }

  getValueAs(unit: Unit<D>) {
    if (unit === this.unit) {
      return this.value;
    } else {
      return unit.fromBaseUnit(this.getValueAsBaseUnit());
    }
  }

  toUnit(unit: Unit<D>) {
    return new ValueWithUnit<D>(unit, this.getValueAs(unit));
  }
}

export { ValueWithUnit as default }
