import React, { useEffect, useState } from 'react';
import { HistogramStep } from '../../HistogramDataReader';
import { Box } from 'components';
import { HistogramColors } from '../HistogramViewer'
import { useTranslations } from 'hooks';

const data_ui_histogram = require('@data-ui/histogram')
const {
    Histogram,
    BarSeries,
    XAxis,
    withScreenSize,
} = data_ui_histogram;

const opacity = 0.5;

const HistogramTooltip = ({ event, datum, data, color, r, value }: any) => {
    if (datum.value != null) return (
        <Box align="center">
            {datum.text && <div>{datum.text}</div>}
            <strong>{datum.bin0.toFixed(1)} - {datum.bin1.toFixed(1)}</strong>
            <div>{datum.value.toFixed(2)}%</div>
        </Box>
    )
    else return null;
}


const XplHistogram = withScreenSize(({ screenWidth, data, min, max, level, minS, maxS, maxCount, animated, isbusy }: { 
    screenWidth: number, data: HistogramStep[], min: number, max: number, level: number, minS: number, maxS: number, 
    maxCount: number, animated: boolean, isbusy: (busy: boolean) => void }) => {
    const t = useTranslations('pcb_viewer');
    const [low, setLow] = useState<HistogramStep[]>([]);
    const [mid, setMid] = useState<HistogramStep[]>([]);
    const [high, setHigh] = useState<HistogramStep[]>([]);
    const [below, setBelow] = useState<HistogramStep | null>(null);
    const [within, setWithin] = useState<HistogramStep | null>(null);
    const [above, setAbove] = useState<HistogramStep | null>(null);

    const [wasBusy, setWasBusy] = useState<boolean>(false);

    // const minValue = Math.min(otherMin, data[0].bin0, min);
    // const maxValue = Math.max(otherMax, data[data.length - 1].bin1, max);

    let tickValues = [minS, maxS, min, max].filter((v, i, a) => a.indexOf(v) === i);

    function reduce(data: HistogramStep[], text: string): HistogramStep {
        return data.reduce((ac, cur, i) => {
            ac.value! += cur.count;

            if (i === data.length - 1) {
                // we make our "total" bars a bit higher than the maximum value so we can always hover them
                ac.count = maxCount * 1.05;
            }
            return ac;
        }, { id: text, bin0: data[0].bin0, bin1: data[data.length - 1].bin1, count: 0, value: 0, text });
    }

    useEffect(() => {
        isbusy(true);
        let { belowSpec, withinSpec, aboveSpec } = calculateData(data, min, max, minS, maxS, level);
        setLow(belowSpec);
        setBelow(belowSpec.length > 0 ? reduce(belowSpec, t("histogram.below_spec")) : null);
        setMid(withinSpec);
        setWithin(withinSpec.length > 0 ? reduce(withinSpec, t("histogram.within_spec")) : null);
        setHigh(aboveSpec);
        setAbove(aboveSpec.length > 0 ? reduce(aboveSpec, t("histogram.above_spec")) : null);
        setWasBusy(true);
    }, [data, min, max, minS, maxS, level, screenWidth]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (wasBusy) {
            setWasBusy(false);
            isbusy(false)
        }
    }, [wasBusy, isbusy])


    const totalStyle = {
        fillOpacity: opacity / 15,
        animated
    }
    const barStyle = {
        fillOpacity: opacity,
        animated
    }

    return (
        <Box flex direction="column">
            {low.length + mid.length + high.length > 0 &&
                <Histogram
                    width={(screenWidth - 571) / 2}
                    ariaLabel="Simulation Result Bar Chart"
                    height={400}
                    renderTooltip={HistogramTooltip}
                    binType="numeric"
                    margin={{ top: 10, right: 10, left: 10, bottom: 21 }}
                    vertical
                >
                    <BarSeries fillOpacity={0} binnedData={[{ id: "hidden bounding box", bin0: min, bin1: max, count: maxCount }]} />

                    {below != null && <BarSeries fill={HistogramColors.blue} binnedData={[below]} {...totalStyle} />}
                    {within != null && <BarSeries fill={HistogramColors.green} binnedData={[within]} {...totalStyle} />}
                    {above != null && <BarSeries fill={HistogramColors.red} binnedData={[above]} {...totalStyle} />}

                    {low.length > 0 && <BarSeries fill={HistogramColors.blue} binnedData={low} {...barStyle} />}
                    {mid.length > 0 && <BarSeries fill={HistogramColors.green} binnedData={mid} {...barStyle} />}
                    {high.length > 0 && <BarSeries fill={HistogramColors.red} binnedData={high} {...barStyle} />}
                    <XAxis tickValues={tickValues}/>
                </Histogram>}
        </Box>
    )
})
// label="Plating Thickness"

function calculateData(data: HistogramStep[], min: number, max: number, minS: number, maxS: number, level: number) {
    // let stepSize = (max - min) / level;

    let dataMin = data[0].bin0;
    let dataMax = data[data.length - 1].bin1;
    let trueMin = Math.min(dataMin, minS);
    let trueMax = Math.max(dataMax, maxS);
    let range = trueMax - trueMin;

    // level = Math.min(range * 10, level);
    level = range / 0.1;

    // let stepSize = Math.ceil((range / level - Number.EPSILON) * 10.0) / 10.0;
    // let size = stepSize * level;
    let stepSize = 0.1;
    // let size = range;
    // let offset = 0;
    let offsetMin = trueMin;
    // let offset = (size - range) / 2;
    // let offsetMin = Math.floor((trueMin - offset) * 10.0) / 10.0;
    // let offsetMax = Math.ceil((trueMax + offset) * 10.0)/10.0;


    // let bSteps = Math.ceil(minS*(max-min)/(Math.max(dataMax, max) - Math.min(dataMin, min)) * level)

    let belowSpec: HistogramStep[] = []
    let withinSpec: HistogramStep[] = []
    let aboveSpec: HistogramStep[] = []


    let bins = [];

    for (let i = 0; i <= level; ++i) {
        let bin = Math.round((offsetMin + i * stepSize) * 10.0) / 10.0;
        bins.push(bin);
        if (bin < minS && bin + stepSize > minS) bins.push(minS);
        if (bin < maxS && bin + stepSize > maxS) bins.push(maxS);
    }



    for (let i = 0; i < bins.length - 1; ++i) {
        let bin0 = bins[i]
        let bin1 = bins[i + 1];

        let value = 0;
        for (let j = 0; j < data.length; ++j) {
            if (data[j].bin0 >= bin0 && data[j].bin1 <= bin1) {
                value += data[j].count;
            }
        }

        let count = value * stepSize / (bin1 - bin0);

        if (count <= 0) continue;
        if (bin0 < min || bin1 > max) continue;

        let step = { id: `${bin0} - ${bin1}`, bin0, bin1, count, value };

        if (bin0 < minS && bin1 <= minS) belowSpec.push(step);
        // else if (bin0 < min && bin1 > min) b.push(step);
        else if (bin0 >= minS && bin1 <= maxS + Number.EPSILON) {
            withinSpec.push(step);
        }
        // else if (bin0 < max && bin1 > max) d.push(step);
        else if (bin0 >= maxS && bin1 > maxS) aboveSpec.push(step);
    }

    return { belowSpec, withinSpec, aboveSpec };
}

export default XplHistogram;