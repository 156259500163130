import React from 'react';
import { Box, Heading, Spinner, Paragraph, Link } from 'components';
import { useConfirmEmail } from 'hooks/auth';
import { useTranslations } from 'hooks';
import { Routes } from 'routes';
import { Trans } from 'react-i18next';
import { useRouterStore } from 'mobx-state-router';

const ConfirmEmail = () => {
  const {
    routerState: { params },
  } = useRouterStore();
  const { busy, success } = useConfirmEmail(params.token);
  const t = useTranslations('login');

  const title = busy
    ? t('confirm_busy_title')
    : success
    ? t('confirm_success_title')
    : t('confirm_error_title');
  let content: React.ReactNode;
  if (busy) {
    content = <Spinner />;
  } else {
    // Success will redirect to login -> failed
    content = (
      <>
        <Paragraph>{t('confirm_error_hint')}</Paragraph>
        <Paragraph>
          <Trans t={t} i18nKey="confirm_error_login_instead">
            Did you already confirm your email address?{' '}
            <Link to={Routes.Login}>Sign in instead</Link>.
          </Trans>
        </Paragraph>
      </>
    );
  }

  return (
    <>
      <Heading level="3" size="small" margin={{ bottom: 'medium' }}>
        {title}
      </Heading>
      <Box margin={{ top: 'large' }}>{content}</Box>
    </>
  );
};

export default ConfirmEmail;
