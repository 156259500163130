import React, { useState } from 'react';
import styled from 'styled-components';
import { Spinner, Dialog, Heading, Tooltip } from 'components';
import { Button as GrommetButton, Box as GrommetBox, Paragraph } from 'grommet';
import { normalizeColor } from 'grommet/utils';
import { darken, lighten, desaturate, transparentize } from 'polished';

import { AlignSelfType, MarginType } from 'components/types';
import Span from 'components/Text';
import Icon, { IconName } from 'components/Icon';


const StyledParagraph = styled(Paragraph)`
  text-align: justify;
`

type CommonButtonProps = {
  className?: string;
  onClick?: (e: React.MouseEvent) => void;
  primary?: boolean;
  secondary?: boolean;
  disabled?: boolean;
  type?: 'button' | 'reset' | 'submit';
  size?: 'small' | 'medium' | 'large';
  focusIndicator?: boolean;
  alignSelf?: AlignSelfType;
  plain?: boolean;
  margin?: MarginType;
  fontSize?: number;
  confirm?: {
    title: string;
    question: string;
    okLabel?: string;
    cancelLabel?: string;
  };
};

export type ButtonProps = React.PropsWithChildren<
  CommonButtonProps & {
    label?: string | React.ReactNode;
    busy?: boolean;
    icon?: IconName;
    hint?: string;
  }
>;

type ConfirmDialogViewProps = {
  isOpen: boolean;
  onOk?: (...args: any[]) => void;
  onCancel: (...args: any[]) => void;
  okLabel?: string;
  cancelLabel?: string;
  title: string;
  question: string;
};

const ConfirmDialogView = ({
  isOpen,
  onOk,
  onCancel,
  okLabel,
  cancelLabel,
  title,
  question,
}: ConfirmDialogViewProps) => {

  return (
    <Dialog
      isOpen={isOpen}
      onOk={onOk}
      onCancel={onCancel}
      okLabel={okLabel}
      cancelLabel={cancelLabel}
    >
      <GrommetBox width={{ max: "300px" }}>
        <Heading level={4} margin={{ bottom: 'small' }}>
          {title}
        </Heading>
        <StyledParagraph margin={{ bottom: 'xsmall' }}>{question}</StyledParagraph>
      </GrommetBox>
    </Dialog>
  );
};

const StyledButton = styled(GrommetButton).attrs((props: ButtonProps) => ({
  secondary: props.secondary || false,
  fontSize: props.fontSize,
}))`
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  text-align: center;
  # padding: 8px 16px;
  color: ${({ theme, primary, secondary }) =>
    primary
      ? 'white'
      : secondary
        ? normalizeColor('text', theme)
        : theme.dark
          ? lighten(0.2, normalizeColor('brand', theme))
          : darken(0.2, normalizeColor('brand', theme))};

  background: ${({ theme, secondary, primary }) =>
    primary
      ? normalizeColor('brand', theme)
      : secondary
        ? transparentize(0.8, normalizeColor('brand', theme))
        : 'transparent'};

  &:focus {
    border-color: ${({ theme, primary }) =>
    primary
      ? darken(0.07, normalizeColor('brand', theme))
      : lighten(0.1, desaturate(0.4, normalizeColor('brand', theme)))};
  }

  &:active:not(disabled) {
    opacity: 0.7;
  }

  span {
    ${({ fontSize }) =>
    fontSize ? `font-size: ${fontSize}px;` : ''
  }
    color: ${({ theme, primary, secondary }) =>
    primary
      ? 'white'
      : secondary
        ? normalizeColor('text', theme)
        : normalizeColor('brand', theme)};
  }
`;

const ConditionalWrapper = ({ condition, wrapper, children }: { condition: boolean, wrapper: Function, children: React.ReactNode }) => {
  return condition ? wrapper(children) : children;
}

const Button = ({
  className,
  onClick,
  primary,
  disabled,
  busy,
  type,
  label,
  children,
  secondary,
  plain = false,
  size,
  icon,
  alignSelf,
  focusIndicator,
  hint,
  margin,
  fontSize,
}: ButtonProps) => {
  return (

    <ConditionalWrapper condition={hint != null} wrapper={(children: React.ReactNode) =>
      <Tooltip tooltip={hint} placement="top">{children}</Tooltip>
    }>
      <StyledButton
        className={className}
        type={type}
        size={size}
        onClick={onClick}
        plain={plain}
        primary={primary}
        secondary={secondary}
        disabled={disabled}
        alignSelf={alignSelf}
        focusIndicator={focusIndicator}
        margin={margin}
        fontSize={fontSize}
      >
        {busy && <Spinner size={20} pad={{ right: 'small' }} color={primary ? 'white' : undefined} />}
        {!busy && icon && (
          <GrommetBox pad={{ right: label != null ? 'small' : 'none' }}>
            <Icon size="20px" color={primary ? 'white' : 'brand'} name={icon} />
          </GrommetBox>
        )}
        <Span>{label}</Span>
        {children}
      </StyledButton>
    </ConditionalWrapper>
  );
};

const ConfirmableButton = ({ confirm, onClick, ...rest }: ButtonProps) => {
  const [open, setOpen] = useState(false);

  const confirmableOnClick = confirm != null ? () => setOpen(true) : onClick;
  const onClickAfterOk = (e: React.MouseEvent) => {
    setOpen(false);
    if (onClick != null) {
      onClick(e);
    }
  };
  return (
    <>
      {confirm != null && (
        <ConfirmDialogView
          isOpen={open}
          onOk={onClickAfterOk}
          onCancel={() => setOpen(false)}
          {...confirm}
        />
      )}
      <Button onClick={confirmableOnClick} {...rest} />
    </>
  );
};

export type IconButtonProps = CommonButtonProps & {
  icon: IconName;
};

export const IconButton = ({ icon, ...rest }: IconButtonProps) => (
  <ConfirmableButton {...rest}>
    <Icon name={icon} />
  </ConfirmableButton>
);

export default ConfirmableButton;
