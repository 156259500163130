import React from 'react';
import { Box, FormikTextField, FormikSelectField, SelectOption, FormikPhoneInputField } from 'components';
import { useTranslations } from 'hooks';
import { isValidPhoneNumber } from 'react-phone-number-input'

import * as yup from 'yup';

export type ProfileFieldsFields = {
  firstName: string;
  lastName: string;
  company: string;
  country: string;
  role: string;
  phone_number: string;
};

export type ProfileFieldsFeedback = Partial<
  { [K in keyof ProfileFieldsFields]: string } & { general?: string }
>;
export type ProfileFieldsHints = Partial<{ [K in keyof ProfileFieldsFields]: string }>;


// TODO: Find a way to use the ${min} interpolation of yup iso hardcoding 'password_too_short'
const ProfileFieldsSchemaProto = {
  firstName: yup.string().required('missing_field'),
  lastName: yup.string().required('missing_field'),
  company: yup.string().required('missing_field'),
  country: yup.string().required('missing_field'),
  role: yup.string().required('missing_field'),
  // yup automatically makes an empty string into undefined
  // for some reason this causes yup to throw an error when testing required, but only for phone number ??
  // workaround: default('')
  // nvm, isValidPhoneNumber does not like undefined as parameter
  // the error thrown however did show 'missing_field' ?
  phone_number: yup.string().test('test-valid-phone-number', 'invalid_phone_number', (v) => v === undefined || isValidPhoneNumber(v))
};

export { ProfileFieldsSchemaProto };

export type ProfileFieldsProps = {
  errors?: ProfileFieldsFeedback;
  hints?: ProfileFieldsHints;
  countryOptions: string[];
  roleOptions: string[];
  busy?: boolean;
};

const ProfileFields = ({
  errors,
  hints,
  countryOptions,
  roleOptions,
  busy,
}: ProfileFieldsProps) => {
  const t = useTranslations('login');
  const tr = useTranslations('roles');
  const tc = useTranslations('country_names', 'countries');

  const countrySelectOptions: SelectOption<string>[] = countryOptions.map((s) => ({
    label: tc(s),
    value: s,
  }));

  const roleSelectOptions: SelectOption<string>[] = roleOptions.map((s) => ({
    label: tr(s),
    value: s,
  }));

  return (
    <>
      <Box direction="row" fill="horizontal" gap="medium">
        <Box flex={true}>
          <FormikTextField
            name="firstName"
            label={t('first_name_label')}
            placeholder={t('first_name_placeholder')}
            error={errors?.firstName}
            hint={hints?.firstName}
            disabled={busy}
            required
          />
        </Box>
        <Box flex={true}>
          <FormikTextField
            name="lastName"
            label={t('last_name_label')}
            placeholder={t('last_name_placeholder')}
            error={errors?.lastName}
            hint={hints?.lastName}
            disabled={busy}
            required
          />
        </Box>
      </Box>
      <Box direction="row" fill="horizontal" gap="medium">
        <Box flex={true}>
          <FormikSelectField
            name="country"
            label={t('country_label')}
            options={countrySelectOptions}
            disabled={busy}
            placeholder={t('country_placeholder')}
            error={errors?.country}
            hint={hints?.country}
            required
          />
        </Box>
        <Box flex={true}>
          <FormikPhoneInputField
            label={t('phone_number_label')}
            name="phone_number"
            placeholder={t('phone_number_placeholder')}
            error={errors?.phone_number}
            hint={hints?.phone_number}
            disabled={busy}
            required
          />
        </Box>
      </Box>
      <Box direction="row" fill="horizontal" gap="medium">
        <Box flex={true}>
          <FormikTextField
            label={t('company_label')}
            name="company"
            placeholder={t('company_placeholder')}
            error={errors?.company}
            hint={hints?.company}
            disabled={busy}
            required
          />

        </Box>
        <Box flex={true}>
          <FormikSelectField
            name="role"
            label={t('role_label')}
            options={roleSelectOptions}
            disabled={busy}
            placeholder={t('role_placeholder')}
            error={errors?.role}
            hint={hints?.role}
            required
          />
        </Box>
      </Box>
    </>
  );
};

export default ProfileFields;
