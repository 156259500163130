import { client, anonymousClient } from './client';
import type { Customer } from '@nimbu/api';

type CustomerCustomFields = {
  company: string | null;
  country: string | null;
  role?: {
    value?: string | null;
  };
  settings?: any;
  phone_number: string | null;
};

export async function validate() {
  try {
    const json = await client.me<CustomerCustomFields>();
    return {
      customer: {
        id: json.id,
        createdAt: json.created_at,
        updatedAt: json.updated_at,
        firstname: json.firstname,
        lastname: json.lastname,
        email: json.email,
        company: json.company || undefined,
        country: json.country || undefined,
        settings: json.settings || undefined,
        role: json.role && json.role.value != null ? json.role.value : undefined,
        phone_number: json.phone_number || undefined,
      },
      remember: client.remember,
    };
  } catch (error) {
    return { customer: undefined, remember: false };
  }
}

export async function roles() : Promise<string[] | null> {
  try {
    const { body : { roles } } : any = await client.get('/customers/me?resolve=roles');
    return roles as string[];
  } catch (error) {
    return null;
  }
}

export async function login(email: string, password: string, remember: boolean = false) {
  const success = await client.login(email, password, remember);
  if (success) {
    const customer = await client.me<CustomerCustomFields>();
    return {
      id: customer.id,
      createdAt: customer.created_at,
      updatedAt: customer.updated_at,
      firstname: customer.firstname,
      lastname: customer.lastname,
      email: customer.email,
      company: customer.company || undefined,
      country: customer.country || undefined,
      settings: customer.settings || undefined,
      role: customer.role && customer.role.value != null ? customer.role.value : undefined,
      phone_number: customer.phone_number || undefined,
    };
  } else {
    return undefined;
  }
}

export function logout() {
  return client.logout();
}

export async function register(
  email: string,
  password: string,
  password_confirmation: string,
  firstname: string,
  lastname: string,
  country?: string,
  company?: string,
  role?: string,
  tc_accepted: boolean = false,
  phone_number?: string,
) {
  const { body } = await anonymousClient.post<Customer<CustomerCustomFields>>('/customers', {
    email,
    password,
    password_confirmation,
    firstname,
    lastname,
    country,
    company,
    role,
    tc_accepted,
    phone_number,
  });

  return {
    id: body.id,
    createdAt: body.created_at,
    updatedAt: body.updated_at,
    firstname: body.firstname,
    lastname: body.lastname,
    email: body.email,
    company: body.company || undefined,
    country: body.country || undefined,
    role: body.role ? body.role.value : undefined,
    phone_number: body.phone_number || undefined,
  };
}

export async function confirmEmail(token: string): Promise<string | undefined> {
  try {
    const { body: customer } = await anonymousClient.post<Customer<CustomerCustomFields>>(
      '/customers/confirmations',
      { confirmation_token: token }
    );
    return customer.verified_email ? customer.email : undefined;
  } catch (error) {
    return undefined;
  }
}

export async function requestPasswordReset(email: string) {
  await anonymousClient.post('/customers/password/reset', { email });
}

export async function resetPassword(
  token: string,
  password: string,
  password_confirmation: string
) {
  const { body } = await anonymousClient.put<Customer<CustomerCustomFields>>(
    '/customers/password',
    {
      customer: {
        reset_password_token: token,
        password,
        password_confirmation,
      },
    }
  );
  return body;
}

export async function updateCustomer(
  id: string,
  updates: {
    firstname?: string;
    lastname?: string;
    company?: string;
    country?: string;
    role?: string;
    email?: string;
    phone_number?: string;
    password?: string;
    password_confirmation?: string;
    current_password?: string;
    settings?: any;
  }
) {
  const { body: json } = await client.put<Customer<CustomerCustomFields>>(
    `/customers/${id}`,
    updates
  );
  return {
    id: json.id,
    createdAt: json.created_at,
    updatedAt: json.updated_at,
    firstname: json.firstname,
    lastname: json.lastname,
    email: json.email,
    company: json.company || undefined,
    country: json.country || undefined,
    settings: json.settings || undefined,
    role: json.role ? json.role.value : undefined,
    phone_number: json.phone_number || undefined,
  };
}
