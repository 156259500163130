import { useEffect } from 'react';
import { useStore } from 'store';

export const useGetTitle = () => {
  const store = useStore();

  return store.title;
};

export const useGetPageTitle = () => {
  const store = useStore();

  return store.pageTitle;
};

export function useTitle(title: string) {
  const store = useStore();

  useEffect(() => {
    store.setTitle(title);
  }, [store, title]);
  return store.title;
}

export function usePageTitle(title: string) {
  const store = useStore();

  useEffect(() => {
    store.setPageTitle(title);
  }, [store, title]);
  return store.title;
}
