import { types, Instance, getParentOfType, flow, getParent, getRoot } from 'mobx-state-tree';
import { BalanceState } from './types';
import { BasicSimulation, Simulation } from '../store';
import { ResultsType, SimulationState } from '../types';
import FileSaver from 'file-saver';
import { fillStoreSetting, SettingStore } from 'settings/store';
import { Setting, ValueWithUnit, LengthUnits, ScalarUnits } from 'settings'; // ThicknessUnits
import * as api from 'api/simulation';

const round = (number: number) => {
  return +(Math.round(Number(number + 'e+5')) + 'e-5');
};

export const defaultNoGoZone = new Setting('no_go_zone', new ValueWithUnit(LengthUnits.mmUnit, 2));
export const defaultMinCuFraction = new Setting(
  'min_cu_fraction',
  new ValueWithUnit(ScalarUnits.percentUnit, 70)
);
export const defaultMaxCuFraction = new Setting(
  'max_cu_fraction',
  new ValueWithUnit(ScalarUnits.percentUnit, 70)
);
export const defaultIntensity = new Setting('intensity', new ValueWithUnit(ScalarUnits.noUnit, 2));
export const defaultHashCoverage = new Setting(
  'hatch_coverage',
  new ValueWithUnit(ScalarUnits.percentUnit, 0)
);

const defaultNoGoZoneForStore = fillStoreSetting(defaultNoGoZone);
const defaultMinCuFractionForStore = fillStoreSetting(defaultMinCuFraction);
const defaultMaxCuFractionForStore = fillStoreSetting(defaultMaxCuFraction);
const defaultIntensityForStore = fillStoreSetting(defaultIntensity);
const defaultHashCoverageForStore = fillStoreSetting(defaultHashCoverage);

const BalancerSettings = types
  .model('BalancerSettings', {
    settings: types.optional(SettingStore, {
      settings: {
        no_go_zone: defaultNoGoZoneForStore,
        min_cu_fraction: defaultMinCuFractionForStore,
        max_cu_fraction: defaultMaxCuFractionForStore,
        intensity: defaultIntensityForStore,
        hatch_coverage: defaultHashCoverageForStore,
      },
    }),
  })
  .views((self) => ({
    getSetting: self.settings.getSetting,
    getAllSettings: self.settings.getAllSettings,
    get expertMode(): boolean {
      const simulation = getParentOfType<typeof BasicSimulation>(self, Simulation);
      if (simulation != null) {
        return simulation.expertMode;
      } else {
        return false;
      }
    },
  }))
  .actions((self) => ({
    setExpertMode(value: boolean) {
      const simulation = getParentOfType<typeof BasicSimulation>(self, Simulation);
      if (simulation != null) {
        simulation.setExpertMode(value);
      }
    },
    updateSetting: self.settings.updateSetting,
  }));

export const Balancer = types
  .model('Balancer', {
    showCopperAreas: false,
    settings: types.optional(BalancerSettings, {}),
    settingsUsed: types.optional(BalancerSettings, {}),
    result: types.maybe(types.frozen<ResultsType>()),
    exporting: false,
  })
  .volatile((self) => ({
    frontImage: undefined as string | undefined,
    backImage: undefined as string | undefined,
    xplFile: undefined as string | undefined,
    frontBalancingFile: undefined as string | undefined,
    backBalancingFile: undefined as string | undefined,
    // frontImageWithBA: undefined as string | undefined,
    // backImageWithBA: undefined as string | undefined,
  }))
  .views((self) => ({
    get simulation() {
      return getParent<typeof BasicSimulation>(self);
    },
    get busy() {
      return (
        this.simulation.status === SimulationState.Balancing ||
        this.simulation.status === SimulationState.BalancingQueued ||
        this.simulation.status === SimulationState.BalancingQueuing
      );
    },
  }))
  .views((self) => ({
    get state(): BalanceState {
      if (self.simulation.status === SimulationState.BalancingError) {
        return BalanceState.ERROR;
      } else if (self.busy) {
        return BalanceState.BALANCING;
      } else if (self.exporting || self.simulation.unlockingBalancing) {
        return BalanceState.EXPORTING;
      } else if (self.result != null) {
        return BalanceState.READY;
      } else {
        return BalanceState.NEW;
      }
    },
  }))
  .actions((self) => ({
    balance: flow(function* balance() {
      // set internal state to AnalysisQueuing so we can already show spinner while sending this to the server
      self.simulation.setStatus(SimulationState.BalancingQueuing);

      // const expertMode = true;
      const simulation = getParentOfType<typeof BasicSimulation>(self, Simulation);

      const noGoZone = simulation.settings.getSettingFor('balance', 'no_go_zone');
      const minCuFraction = simulation.settings.getSettingFor('balance', 'min_cu_fraction');
      const maxCuFraction = simulation.settings.getSettingFor('balance', 'max_cu_fraction');
      const intensity = simulation.settings.getSettingFor('balance', 'intensity'); //new Setting('intensity', new ValueWithUnit(ScalarUnits.noUnit, 2));
      const hatchCoverage = simulation.settings.getSettingFor('balance', 'hatch_coverage');

      // const root = getRoot(self) as any;
      const envPowerFromSettings = simulation.settings.getSettingFor('balance', 'environmental_power');//new Setting('environmental_power', new ValueWithUnit(ScalarUnits.noUnit, 0.5));
      const influenceRadiusFromSettings = new Setting('influence_radius', new ValueWithUnit(LengthUnits.mmUnit, 30));
      // const minThicknessFromSettings = simulation.settings.getSettingFor('analyze', 'min_thickness');
      // const maxThicknessFromSettings = simulation.settings.getSettingFor('analyze', 'max_thickness');

      let params: any = {
        // expert: expertMode,
      };

      if (noGoZone != null) {
        params.no_go_zone_board = round(noGoZone.value.getValueAs(LengthUnits.mmUnit));
      }

      if (minCuFraction != null) {
        params.min_cu_fraction = round(
          minCuFraction.value.getValueAs(ScalarUnits.floatingPointUnit)
        );
      }

      if (maxCuFraction != null) {
        params.max_cu_fraction = round(
          maxCuFraction.value.getValueAs(ScalarUnits.floatingPointUnit)
        );
      }

      if (intensity != null) {
        params.intensity = round(intensity.value.getValueAs(ScalarUnits.noUnit));
      }

      if (hatchCoverage != null) {
        params.hatch_coverage = round(hatchCoverage.value.getValueAs(ScalarUnits.floatingPointUnit));
      }

      if (envPowerFromSettings != null) {
        params.environmental_power = round(
          envPowerFromSettings.value.getValueAs(ScalarUnits.noUnit)
        );
      }

      if (influenceRadiusFromSettings != null) {
        params.influence_radius = round(
          influenceRadiusFromSettings.value.getValueAs(LengthUnits.mmUnit)
        );
      }

      // if (minThicknessFromSettings != null) {
      //   params.min_thickness = round(
      //     minThicknessFromSettings.value.getValueAs(ThicknessUnits.umUnit)
      //   );
      // }

      // if (maxThicknessFromSettings != null) {
      //   params.max_thickness = round(
      //     maxThicknessFromSettings.value.getValueAs(ThicknessUnits.umUnit)
      //   );
      // }

      yield api.queueBalancing(self.simulation.id, self.simulation.remoteId, params);
      yield (self as any).ensureBalancingResults();
    }),
    export: flow(function* doExport() {
      if (self.simulation.balancingUnlocked) {
        self.exporting = true;
        const { gbrArchive } = yield api.getBalancingUrls(
          self.simulation.id,
          self.simulation.remoteId
        );

        if (gbrArchive != null) {
          FileSaver.saveAs(gbrArchive, 'balanced-gbrs.zip');
        }

        self.exporting = false;
      }
    }),
    toggleCopperAreas() {
      if (!self.showCopperAreas && self.simulation.balancingLocked) {
        self.simulation.askUnlockCopperAreasConfirmation();
      } else {
        self.showCopperAreas = !self.showCopperAreas;
      }
    },
    startExport: flow(function* startExport() {
      if (self.simulation.balancingLocked) {
        self.simulation.askUnlockExportConfirmation();
      } else {
        yield (self as any).export();
      }
    }),
    reset() {
      // Reset after changes to sources or analyzer
      self.result = undefined;
      self.frontImage = undefined;
      self.backImage = undefined;
      self.xplFile = undefined;
      self.frontBalancingFile = undefined;
      self.backBalancingFile = undefined;
    },
    ensureBalancingResults: flow(function* ensureBalancingResults() {
      if (
        self.simulation.balancingAvailable &&
        self.simulation.status !== SimulationState.Balancing &&
        self.simulation.status !== SimulationState.BalancingQueued &&
        self.simulation.status !== SimulationState.BalancingQueuing &&
        (self.xplFile == null ||
          self.frontBalancingFile == null ||
          self.backBalancingFile == null)
      ) {
        yield (self as any).fetchBalancingUrls();
      } else if (self.simulation.status !== SimulationState.BalancingError) {
        try {
          // Poll the result
          yield (self as any).pollBalancingResults();
        } catch (error) {
          console.log(error);
          self.simulation.setStatus(SimulationState.BalancingError);
        }
      }
    }),
    pollBalancingResults: flow(function* pollBalancingResults() {
      const root = getRoot(self) as any;
      const refreshed: boolean = yield root.refreshSimulation(self.simulation.id);
      if (
        refreshed &&
        self.simulation.status !== SimulationState.Balancing &&
        self.simulation.status !== SimulationState.BalancingQueued
      ) {
        if (self.simulation.status === SimulationState.BalancingReady) {
          yield (self as any).fetchBalancingUrls();
        }
      } else {
        // try again in 5 seconds
        setTimeout((self as any).pollBalancingResults, 5000);
      }
    }),
    fetchBalancingUrls: flow(function* fetchBalancingUrls() {
      // Fetch the urls from the backend
      const { xpl, frontB, backB } = yield api.getBalancingUrls(
        self.simulation.id,
        self.simulation.remoteId
      );
      self.xplFile = xpl;
      self.frontBalancingFile = frontB;
      self.backBalancingFile = backB;
    }),
  }));

export interface IBalancer extends Instance<typeof Balancer> { }
