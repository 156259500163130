import { useTranslation } from 'react-i18next';
import { TFunction, TOptions } from 'i18next';
import { Setting, Dimension, Unit } from 'settings';

export const useTranslations = (scope: string, namespace?: string | string[]) => {
  const { t: i18nt } = useTranslation(namespace);
  const wrapper: TFunction = (
    key: string,
    defaultValue?: string,
    options?: TOptions<{ [key: string]: any }> | string
  ) => {
    return i18nt(`${scope}.${key}`, defaultValue, options);
  };
  return wrapper;
};

export const useParameterTranslations = () => {
  const p = useTranslations('simulation.parameters');

  const labelFor = (name: string) => {
    const unit = p(`${name}.unit`);
    const unitLabel = unit && unit.length > 0 ? ` [${unit}]` : '';
    return `${p(`${name}.label`)}${unitLabel}`;
  };

  const hintFor = (name: string) => {
    return p(`${name}.hint`);
  };

  return {
    labelFor,
    hintFor,
  };
};

export const useSettingTranslations = () => {
  const p = useTranslations('simulation.parameters');
  const u = useTranslations('simulation.units');

  const labelFor = <D extends Dimension>(
    setting: Setting<D>,
    unit?: Unit<D>,
    includeUnit?: boolean
  ) => {
    const actualUnit = unit ? unit : setting.value.unit;
    const unitTranslation = u(`${actualUnit.name}.name`);
    const unitString =
      includeUnit && unitTranslation && unitTranslation.length > 0 ? ` [${unitTranslation}]` : '';
    return `${p(`${setting.name}.label`)} ${unitString}`;
  };

  const hintFor = <D extends Dimension>(setting: Setting<D>) => {
    return p(`${setting.name}.hint`);
  };

  return {
    labelFor,
    hintFor,
  };
};
