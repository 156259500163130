import React from 'react';
import { Box } from 'components';
import styled from 'styled-components';
import { useTranslations, useTitle } from 'hooks';

const StyledBox = styled(Box)`
  height: 100%;
`;

const FourOhFour = () => {
  const t = useTranslations('four_oh_four');

  useTitle(t('page_title'));

  return (
    <StyledBox align="center" justify="center">
      404 - {t('title')}
    </StyledBox>
  );
};

export default FourOhFour;
