import { createRouterState, TransitionHook, RouterState } from 'mobx-state-router';
import { Routes } from './types';
import { IRootStore } from 'store';
import { ISimulation } from 'screens/simulation/store';
import { SimulationState } from 'screens/simulation/types';

const login = createRouterState(Routes.Login);
const home = createRouterState(Routes.Home);

export const checkAuthenticated: TransitionHook = async (from, to, router) => {
  const store = router.options.store as IRootStore;
  if (!store.session.authenticated) {
    store.session.setRedirectAfterLogin(to);
    return login;
  } else {
    return undefined;
  }
};

export const checkAdmin: TransitionHook = async (from, to, router) => {
  const store = router.options.store as IRootStore;
  if (!store.session.authenticated) {
    store.session.setRedirectAfterLogin(to);
    return login;
  } else if (!store.session.isAdmin) {
    return home;
  } else return undefined;
  //return checkAuthenticated(from, store.session.isAdmin ? to : home, router);
}

export const checkAnonymous: TransitionHook = async (from, to, router) => {
  const store = router.options.store as IRootStore;
  if (store.session.authenticated) {
    return home;
  } else {
    return undefined;
  }
};

export const checkCanCreateNew: TransitionHook = async (from, to, router) => {
  const store = router.options.store as IRootStore;
  if (!await store.canCreateNewProject()) {
    return home;
  } else {
    return undefined;
  }
}

export const loadArchive: TransitionHook = async (from, to, router) => {
  const store = router.options.store as IRootStore;
  store.loadSimulations();
};

function routeForSimulation(simulation: ISimulation) {
  switch (simulation.status) {
    case SimulationState.New:
    case SimulationState.Uploading:
    case SimulationState.UploadError:
      return Routes.UploadSources;
    case SimulationState.PreviewsReady: //return simulation.revisingSources ? Routes.UploadSources : Routes.Preview;
    case SimulationState.GeneratingPreviews:
    case SimulationState.CreateContour:
    case SimulationState.PreviewsError:
    case SimulationState.SizeError:
    case SimulationState.SizeEqualityError:
      return Routes.Preview;
    case SimulationState.PreparingAnalysis:
    case SimulationState.AnalysisQueued:
    case SimulationState.Analyzing:
    case SimulationState.AnalysisReady:
    case SimulationState.AnalysisError:
      return Routes.Analyze;
    case SimulationState.PreparingBalancing:
    case SimulationState.BalancingQueued:
    case SimulationState.Balancing:
    case SimulationState.BalancingReady:
    case SimulationState.BalancingError:
    case SimulationState.Finished:
      return Routes.Balance;
    default:
      return Routes.UploadSources;
  }
}

function routerStateForSimulation(simulation: ISimulation, to: RouterState) {
  return createRouterState(routeForSimulation(simulation), {
    params: to.params,
    queryParams: to.queryParams,
  });
}

export const checkSimulationState: TransitionHook = async (from, to, router) => {
  checkSimulation(from, to, router);
  const store = router.options.store as IRootStore;
  // store.setCurrentSimulation(to.params.id);
  // if (store.currentSimulation && store.simulations.get(store.currentSimulation) == null) {
  //   await store.ensureSimulation(to.params.id);
  // }
  const simulation = store.simulations.get(to.params.id);
  if (simulation != null) {
    return routerStateForSimulation(simulation, to);
  } else return undefined;
  // } else {
  //   store.ensureSimulation(to.params.id).then((simulation) => {
  //     router.goToState(routerStateForSimulation(simulation, to));
  //   });
  //   return undefined;
  // }
};

// export const checkSimulationStateB: TransitionHook = async (from, to, router) => {
//   const store = router.options.store as IRootStore;
//   const simulation = store.simulations.get(to.params.id);
//   if (simulation != null) {
//     return routerStateForSimulation(simulation, to);
//   } else {
//     store.ensureSimulation(to.params.id).then((simulation) => {
//       router.goToState(routerStateForSimulation(simulation, to));
//     });
//     return undefined;
//   }
// };

export const checkSimulation: TransitionHook = async (from, to, router) => {
  const store = router.options.store as IRootStore;
  store.setCurrentSimulation(to.params.id);

  if (store.currentSimulation && store.simulations.get(store.currentSimulation) == null) {
    await store.ensureSimulation(to.params.id);
  }
}

export const pipeline: (...hooks: TransitionHook[]) => TransitionHook = (...hooks) => {
  // console.log(hooks);
  const pipelined: TransitionHook = async (from, to, router) => {
    for (const hook of hooks) {
      const result = await hook(from, to, router);
      if (result != null) {
        return result;
      }
    }
    return undefined;
  };
  // console.log(pipelined);
  return pipelined;
};
