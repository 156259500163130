import React, { useState, useEffect } from 'react';
import { NumberInput, NumberInputProps, SplitTimeInput } from 'components';
import { Dimension, ValueWithUnit, Unit, TimeUnits } from 'settings';

export type SettingInputProps<D extends Dimension> = {
  value: ValueWithUnit<D>;
  onChange: (newValue: ValueWithUnit<D>) => void;
  unit: Unit<D>;
  precision?: number;
  disabled?: boolean;
  resetValue?: boolean;
  min?: number;
  max?: number;
};

type ResettableNumberInputProps = {
  resetValue?: boolean;
} & NumberInputProps;

const BufferedNumberInput = ({
  disabled,
  value,
  onChange,
  precision,
  min,
  max,
  resetValue,
}: ResettableNumberInputProps) => {
  const [currentValue, setCurrentValue] = useState(value || 0);
  const [currentStringValue, setCurrentStringValue] = useState(value?.toString() || '');

  useEffect(() => {
    // dont null check value with "if (value)" when value can be 0 | "" | []
    // value isn't updated when externally set to 0
    if (value != null) {
      setCurrentValue(value);
    }
  }, [value, resetValue]);

  const onBlurHandler = () => {
    if (onChange != null) {
      onChange(currentValue, currentStringValue);
    }
  };

  const onChangeHandler = (value: number, stringValue: string) => {
    setCurrentValue(value);
    setCurrentStringValue(stringValue);
  };

  return (
    <NumberInput
      type="float"
      disabled={disabled}
      value={currentValue}
      onChange={onChangeHandler}
      onBlur={onBlurHandler}
      precision={precision}
      min={min}
      max={max}
    />
  );
};

const defaultInput = <D extends Dimension>({
  value,
  onChange,
  unit,
  precision,
  disabled,
  resetValue,
}: SettingInputProps<D>) => {
  const onChangeHandler = (value: number) => {
    onChange(new ValueWithUnit(unit, value));
  };
  return (
    <BufferedNumberInput
      type="float"
      disabled={disabled}
      value={value.getValueAs(unit)}
      resetValue={resetValue}
      onChange={onChangeHandler}
      precision={precision}
    />
  );
};

const mmssInput = <D extends Dimension>({
  value,
  onChange,
  unit,
  disabled,
  resetValue,
}: SettingInputProps<D>) => {
  const onChangeHandler = (value: number) => {
    onChange(new ValueWithUnit(unit, value));
  };
  return (
    <SplitTimeInput
      disabled={disabled}
      timeInSeconds={value.getValueAs(unit)}
      onChange={onChangeHandler}
      resetValue={resetValue}
    />
  );
};

function getInputField<D extends Dimension>(props: SettingInputProps<D>) {
  switch (props.unit) {
    case TimeUnits.mmssUnit as Unit<Dimension>:
      return mmssInput(props);
    default:
      return defaultInput(props);
  }
}

const SettingInput = <D extends Dimension>(props: SettingInputProps<D>) => {
  return getInputField(props);
};

export default SettingInput;
