import React, { useState } from 'react';
import { Modal, Box, CheckBox, Button } from 'components';
import { observer } from 'mobx-react';
import { useSettings } from 'store';
import styled from 'styled-components';


const StyledBox = styled(Box)`
  text-align: justify;
`

const HelpMessage = ({ children }: { children: React.ReactNode }) => {
    const globalSettings = useSettings();
    const [stopShow, setStopShow] = useState(false);
    const [hide, setHide] = useState(false);
    function close() {
        if (stopShow) globalSettings.updateHelp(false);
        else setHide(true);
    }

    return (
        <Modal isOpen={globalSettings.help && !hide} onRequestClose={() => { setHide(true) }} pad="none">
            <Box pad="medium" gap="medium" width="400px">
                <StyledBox gap="small">
                    {children}
                </StyledBox>
                <Box align="center" direction="row" justify="between">
                    <CheckBox
                        label="Stop showing help messages"
                        hint="You can turn this back on in default settings"
                        onChange={setStopShow} checked={stopShow} />
                    <Button onClick={close} label="OK" />
                </Box>
            </Box>
        </Modal>
    )
}

export default observer(HelpMessage);