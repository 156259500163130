import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useTranslations } from 'hooks';

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
const Callback = () => {
  const t = useTranslations('buy');

  useEffect(() => {
    window.top.postMessage('3DS-authentication-complete', '*');
  }, []);
  return <Container>{t('please_wait')}</Container>;
};

export default Callback;
