import React from 'react';
import ReactPDF, { Document, Page, Text, View, StyleSheet, Image, Font } from '@react-pdf/renderer';

// import { observer } from 'mobx-react';

import { useTranslations } from 'hooks';
import { kpiValue } from '../../screens/simulation/hooks';

// import logo from '../../assets/pcb_home.jpg';
// import logo from '../../assets/svgs/elsyca.svg';
import logo from '../../assets/logo/PNG/logo_CuBE.png';
// import { ParamsType } from '../../screens/simulation/types';

// import { useSettings } from 'store';
// import { useSettingTranslations } from 'hooks';

// import { useSimulationParameters } from '../../screens/simulation/hooks';
// // import {
// //     Setting,
// //     Dimension,
// //   } from 'settings'

// import { formatValue } from 'components';

Font.register({ family: 'Roboto', src: 'https://fonts.gstatic.com/s/sourcesanspro/v14/6xKydSBYKcSV-LCoeQqfX1RYOo3i54rAkA.ttf' });

const styles = StyleSheet.create({
    page: {
        flexDirection: 'column',
        padding: '50px',
        fontSize: 12
        // backgroundColor: 'blue'
    },
    headerline: {
        // alignSelf: 'center',
        textAlign: 'right',
        fontSize: 11,
        color: '#347190'
    },
    title: {
        width: '100%',
        fontSize: '20',
        textAlign: 'center'
    },
    subtitle: {
        width: '100%',
        fontSize: '15',
        textAlign: 'center',
        marginBottom: '10px'
    },
    subtitle2: {
        width: '100%',
        fontSize: '10',
        textAlign: 'center',
    },
    title2: {
        fontSize: '15',
        marginTop: '15px',
        marginBottom: '10px',
        textDecoration: 'underline',
        fontWeight: 'bold',
    },
    title3: {
        fontSize: '15',
        marginBottom: '10px',
        textDecoration: 'underline',
        fontWeight: 'bold',
    },
    footerb: {
        display: "flex",
        flex: 1,
        // justifyContent: "center",
    },
    footer: {
        fontSize: 11,
        color: '#347190',
        width: 'auto'
    }
})

export type ReportPdfProps = {
    valid: { analyse: boolean, balance: boolean, balanceAreas: boolean }
    id: string;
    name: string;
    date: string;
    files: { text: string, value: string }[];
    parameters: { text: string, value: string }[];
    kpiparameters: { text: string, value: string }[];
    bparameters?: { text: string, value: string }[];
    images: {
        analyzed: {
            front: string;
            back: string;
        };
        balanced?: {
            front: string;
            back: string;
        };
        balancedWithAreas?: {
            front: string;
            back: string;
        };
        colorPlotBar: string;
    };
    kpi: {
        analyse: kpiValue[] | undefined;
        balance: kpiValue[] | undefined;
    };
    fileNames: {
        front?: string;
        back?: string;
        through?: string;
        vias?: string[];
    };
}



const ReportPdf = (props: ReportPdfProps) => {
    const t = useTranslations("simulation.pdf_export");
    const u = useTranslations("simulation.upload");

    // test long images
    // const longImage = "https://cms-assets.tutsplus.com/uploads/users/107/posts/26488/final_image/41-space-scrolling-background850-2.jpg";

    if (!props.valid.analyse) return null;

    let name = props.name !== '' ? props.name : "untitled"

    return (
        <Document title={t(props.valid.balance ? "balancing_filename" : "analysis_filename", { name: name?.replace(/ /g, '') })}>
            {props.valid.balance &&
                <Page size="A4" style={styles.page}>
                    <Header />
                    <View style={{ flexDirection: 'column' }}>

                        <Text style={styles.title}>{t("balancing_page_title")}</Text>
                        <Text style={styles.subtitle2}>{props.id}</Text>
                        <Text style={styles.subtitle}>{name}</Text>

                        <Text style={styles.title2}>1. {t("bparameters_title")}</Text>
                        <Parameters params={props.bparameters!} style={{ width: "50%" }} />

                        <Text style={styles.title2}>2. {t("balancing_results_title")}</Text>
                        <PCBViewer {...(props.valid.balanceAreas ? props.images.balancedWithAreas! : props.images.balanced!)} cpbar={props.images.colorPlotBar} style={{ marginBottom: '10px' }} />

                        <View style={{ flexDirection: 'row', marginTop: '15px' }}>
                            <View style={{ width: '50%', flexDirection: 'column' }}>
                                <Text style={styles.title3}>3. {t("kpi_title")}</Text>
                                <Parameters params={props.kpiparameters} style={{ width: "60%" }} />
                            </View>
                            <KPIS kpi={props.kpi.balance!} style={{ width: "50%" }} />
                        </View>
                    </View>
                    <Footer date={props.date || ""} pageCount={3} pageNumber={1} />
                </Page>}
            <Page size="A4" style={styles.page}>
                <Header />
                <View style={{ flexDirection: 'column' }}>
                    <Text style={styles.title}>{t("analysis_page_title")}</Text>
                    <Text style={styles.subtitle2}>{props.id}</Text>
                    <Text style={styles.subtitle}>{name}</Text>

                    <Text style={styles.title2}>{props.valid.balance ? 4 : 1}. {t("parameters_title")}</Text>
                    <Parameters params={props.parameters} style={{ width: "50%" }} />

                    <Text style={styles.title2}>{props.valid.balance ? 5 : 2}. {t("analysis_results_title")}</Text>
                    <PCBViewer {...props.images.analyzed} cpbar={props.images.colorPlotBar} style={{ marginBottom: '10px' }} />

                    <View style={{ flexDirection: 'row', marginTop: '15px' }}>
                        <View style={{ width: '50%', flexDirection: 'column' }}>
                            <Text style={styles.title3}>{props.valid.balance ? 6 : 3}. {t("kpi_title")}</Text>
                            <Parameters params={props.kpiparameters} style={{ width: "60%" }} />
                        </View>
                        <KPIS kpi={props.kpi.analyse!} style={{ width: "50%" }} />
                    </View>
                </View>
                <Footer date={props.date || ""} pageCount={props.valid.balance ? 3 : 2} pageNumber={props.valid.balance ? 2 : 1} />
            </Page>
            <Page size="A4" style={styles.page}>
                <Header />
                <Text style={styles.title2}>{props.valid.balance ? 7 : 4}. {u("label")}</Text>
                <FileNames fileNames={props.fileNames}/>
                <Footer date={props.date || ""} pageCount={props.valid.balance ? 3 : 2} pageNumber={props.valid.balance ? 3 : 2} />
            </Page>

        </Document>
    )
}

const Header = () => (
    <View style={{ flexDirection: 'row', height: '50px', justifyContent: 'space-between', marginBottom: '30px' }}>
        <View style={{ width: '50%', flexGrow: 1, paddingRight: "10%" }}>
            <Image src={logo} style={{ width: "auto", height: '100%', objectFit: 'contain' }} />
        </View>
        <View style={{ width: '50%', flexGrow: 1, marginLeft: 'auto', flexDirection: 'column', justifyContent: 'center' }}>
            <Text style={styles.headerline}>Vaartdijk 3/603 • B-3018 Wijgmaal (Leuven)</Text>
            <Text style={styles.headerline}>Phone: +32 16 474960 • Fax: +32 16 474961</Text>
            <Text style={styles.headerline}>E-Mail: info@elsyca.com • Web: www.elsyca.com</Text>
        </View>
    </View>
)

const Footer = ({ pageNumber, pageCount, date }: { pageNumber: number, pageCount: number, date: string }) => {
    const t = useTranslations("simulation.pdf_export");
    return (
        <View style={{ flexDirection: 'column', position: 'absolute', bottom: styles.page.padding, left: styles.page.padding, width: '100%' }}>
            <View style={{ borderBottomColor: '#347190', borderBottomWidth: 1, marginBottom: '5px', paddingBottom: '5px' }} />
            <View style={{ flexDirection: 'row', display: 'flex', width: '100%' }}>
                <Text style={{ ...styles.footer, flex: 1 }}> {t("creation_date", { date })} </Text>
                <Text style={{ ...styles.footer }}>{t("page", { pageNumber, pageCount })}</Text>
                <Text style={{ ...styles.footer, flex: 1, textAlign: "right" }}>www.elsyca.com</Text>
            </View>
        </View>
    );
}

const Parameters = ({ params, style }: { params: { text: string, value: string }[], style?: ReactPDF.Style | ReactPDF.Style[] | undefined }) => {
    return (
        <View style={{ flexDirection: 'column', ...style }}>
            {/* {props.parameters != null && <ParametersTable parameters={parameters} precision={4} />} */}
            {params.map((p) => (
                <View key={p.text} style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                    <Text style={{ flexGrow: 1, width: "75%" }}>{p.text}</Text>
                    <Text style={{ flexGrow: 1, width: "25%", textAlign: 'right' }}>{p.value}</Text>
                </View>
            ))}
        </View>
    )
}
const FileNames = ({ fileNames, style }: {
    fileNames: {
        front?: string;
        back?: string;
        through?: string;
        vias?: string[];
    }, style?: ReactPDF.Style | ReactPDF.Style[] | undefined
}) => {
    const t = useTranslations("simulation.upload");
    return (
        <View style={{ flexDirection: 'column', ...style }}>
            {/* {props.parameters != null && <ParametersTable parameters={parameters} precision={4} />} */}
            {fileNames && fileNames.front &&
                <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                    <Text style={{ flexGrow: 1, width: "25%" }}>{t('front')}</Text>
                    <Text style={{ flexGrow: 1, width: "75%" }}>{fileNames.front}</Text>
                </View>
            }
            {fileNames && fileNames.back &&
                <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                    <Text style={{ flexGrow: 1, width: "25%" }}>{t('back')}</Text>
                    <Text style={{ flexGrow: 1, width: "75%" }}>{fileNames.back}</Text>
                </View>
            }
            {fileNames && fileNames.through &&
                <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                    <Text style={{ flexGrow: 1, width: "25%" }}>{t('through')}</Text>
                    <Text style={{ flexGrow: 1, width: "75%" }}>{fileNames.through}</Text>
                </View>
            }
            {fileNames && fileNames.vias && fileNames.vias.map((v, index) => (
                <View key={index} style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                    <Text style={{ flexGrow: 1, width: "25%" }}>{index === 0 ? t('via') : ''}</Text>
                    <Text style={{ flexGrow: 1, width: "75%" }}>{v}</Text>
                </View>
            ))}
        </View>
    )
}

const PCBViewer = ({ front, back, cpbar, style }: { front: string, back: string, cpbar: string, style?: ReactPDF.Style | ReactPDF.Style[] | undefined }) => {
    const p = useTranslations("pcb_viewer");
    return (
        <View style={{ ...style }}>
            <View style={{ flexDirection: 'row' }}>
                <Text style={{ width: "48%", marginRight: "4%" }} >{p("front")}</Text>
                <Text style={{ width: "48%", textAlign: "right" }} >{p("back")}</Text>
            </View>
            <View style={{ flexDirection: 'row', marginBottom: '20px', marginTop: '5px', maxHeight: '240px' }}>
                <Image src={front} style={{ width: "48%", marginRight: "4%", objectFit: 'contain' }} />
                <Image src={back} style={{ width: "48%", objectFit: 'contain' }} />
            </View>
            <Image src={cpbar} />
        </View>
    )
}

const KPIS = ({ kpi, style }: { kpi: kpiValue[], style?: ReactPDF.Style | ReactPDF.Style[] | undefined }) => {
    const p = useTranslations("pcb_viewer");
    const a = useTranslations("simulation.analyze");
    return (
        <View style={{ flexDirection: 'column', ...style }}>
            <View style={{ flexDirection: 'row', marginBottom: '5px', paddingBottom: '5px', borderBottomWidth: 1, width: '100%' }}>
                <Text style={{ width: '30%' }}>{a("kpis")}</Text>
                <Text style={{ width: '25%', textAlign: 'right' }}>{p("front")}</Text>
                <Text style={{ width: '25%', textAlign: 'right' }}>{p("back")}</Text>
            </View>
            {kpi.map((v) => (
                <View key={v.text} style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                    <Text style={{ width: '30%' }}>{v.text}</Text>
                    <Text style={{ width: '25%', textAlign: 'right', color: (v.key !== 'avg' ? (v.frontOk ? 'green' : 'red') : undefined) }}>{v.front}</Text>
                    <Text style={{ width: '25%', textAlign: 'right', color: (v.key !== 'avg' ? (v.backOk ? 'green' : 'red') : undefined) }}>{v.back}</Text>
                    {v.key !== 'avg' ?
                        <Text style={{
                            width: '20%',
                            textAlign: 'center',
                            alignSelf: 'center',
                            color: (v.frontOk && v.backOk) ? 'green' : 'red',
                            fontFamily: 'Roboto'
                        }}>
                            {(v.frontOk && v.backOk) ? "✓" : "X"}
                        </Text>
                        : <Text style={{ width: '20%' }}></Text>
                    }
                </View>
            ))}
        </View>
    )
}


export default ReportPdf;