import { PanelData, XplRenderData } from "./PanelData"; // BalanceRenderData
// import { vec3 } from 'gl-matrix'
import { DisplayDataUrls } from "../XplPainter";
import Balance from "./Balance";

// const lineByLine = require('n-readlines');

const Papa = require('papaparse');

const SCALAR_VALUE = 98765.429688;

export default class XPLReader {
    // xplFilePath: string | undefined
    balance: Balance;
    urls?: DisplayDataUrls;
    previousUrls?: DisplayDataUrls;
    panelData?: PanelData;
    _currentData?: XplRenderData;
    // stream: any;
    // line: string = '';
    totalCuIndex: number = -1;
    _rowCount: number = -1;
    _counter: number = -1;

    _step: number = -1;
    // -1 = header + cu + # of panels
    //  0 = front panel fields
    //  1 = front panel vertices
    //  2 = front panel indices
    //  3 = back panel fields
    //  4 = back panel vertices
    //  5 = back panel indices

    _minScalar = Number.MAX_VALUE;
    _maxScalar = -Number.MAX_VALUE;

    constructor() {
        this.balance = new Balance();
        this.read = this.read.bind(this);

        this._handleRow = this._handleRow.bind(this);
        this._onVertices = this._onVertices.bind(this);
        this._onTriangles = this._onTriangles.bind(this);
        this._onVertex = this._onVertex.bind(this);
        this._onTriangle = this._onTriangle.bind(this);
    }



    readXpl = (xpl: string | undefined, panelData: PanelData) : Promise<void> => {
        return new Promise<void>((resolve, reject) => {
            if (xpl == null) resolve();
            this._rowCount = -1;
            this._step = -1;
            this._counter = -1;
            this.panelData = panelData;

            Papa.parse(xpl, {
                download: true,
                dynamicTyping: true,
                delimiter: '\t',
                step: this._handleRow,
                complete: () => {
                    if (this.panelData != null) this.panelData.generateTextureCoordinates();
                    resolve();
                }
            })
        })
    };

    readBalancing = (bal: string, rdata: XplRenderData, isFront: boolean) : Promise<void> => {
        return new Promise<void>((resolve, reject) => {
            this._rowCount = -1;
            this._step = -1;
            this._counter = -1;
            this._currentData = rdata;
            this._currentData.balanceRenderData = {};

            Papa.parse(bal, {
                download: true,
                dynamicTyping: true,
                delimiter: '\t',
                step: this._handleBalancingRow,
                complete: () => {
                    this.balance.updateCopperBalancing(this._currentData!, isFront)
                    resolve();
                }
            });
        })
    }

    read = async (urls: DisplayDataUrls, panelData: PanelData, callback?: Function) => {
        let after = () => {
            this.previousUrls = this.urls;
            if (callback != null) callback();
        }

        this.panelData = panelData;
        this.urls = urls;

        this.panelData.front.gerber = urls.front;
        this.panelData.back.gerber = urls.back;

        if (this.previousUrls == null || this.urls.xpl !== this.previousUrls.xpl) {

            this._rowCount = -1;
            this._step = -1;
            this._counter = -1;

            Papa.parse(this.urls.xpl, {
                download: true,
                dynamicTyping: true,
                delimiter: '\t',
                step: this._handleRow,
                complete: () => {
                    if (this.panelData == null) return;
                    this.panelData.generateTextureCoordinates();
                    this._parseBalancing(after);
                }
            })
        } else after();
    }

    _parseBalancing = async (callback?: Function) => {
        if (this.urls == null || this.urls.backB == null || this.urls.frontB == null) {
            if (callback != null) callback();
            return;
        }
        if (this.panelData == null) return;

        if (this.previousUrls == null || this.urls.frontB !== this.previousUrls.frontB || this.urls.backB !== this.previousUrls.backB) {
            this._rowCount = -1;
            this._step = -1;
            this._counter = -1;
            this._currentData = this.panelData.front;
            this._currentData.balanceRenderData = {};

            Papa.parse(this.urls.frontB, {
                download: true,
                dynamicTyping: true,
                delimiter: '\t',
                step: this._handleBalancingRow,
                complete: () => {
                    if (this.urls == null || this.panelData == null) return;

                    this._rowCount = -1;
                    this._step = -1;
                    this._counter = -1;
                    this._currentData = this.panelData.back;
                    this._currentData.balanceRenderData = {};

                    Papa.parse(this.urls.backB, {
                        download: true,
                        dynamicTyping: true,
                        delimiter: '\t',
                        step: this._handleBalancingRow,
                        complete: () => {
                            if (this.panelData == null) return;
                            this.balance.updateCopperBalancing(this.panelData.front, true);
                            this.balance.updateCopperBalancing(this.panelData.back, false);
                            if (callback != null) callback();
                        }
                    })
                }
            })
        } else if (callback != null) callback();
    }

    _handleBalancingRow = (
        row: {
            data: string[] | boolean[] | number[],
            errors: any[],
            meta: Object
        },
        parser: {
            abort: Function,
            aborted: Function,
            parse: Function,
            pause: Function,
            paused: Function,
            resume: Function,
            streamer: {
                _rowCount: number
            }
        }) => {

        if (this.panelData == null || this._currentData == null) { parser.abort(); return; }
        let data = this._currentData.balanceRenderData;
        if (data == null) return;

        ++this._rowCount;


        // columns
        if (this._rowCount === 0) {
            let splittedScalars = this._split(row.data[0] as string, ':');
            data.nbCols = Number(splittedScalars[1]);
            if (isNaN(data.nbCols)) parser.abort();
            return;
        }

        // rows
        if (this._rowCount === 1) {
            let splittedScalars = this._split(row.data[0] as string, ':');
            data.nbRows = Number(splittedScalars[1]);
            if (isNaN(data.nbRows) || data.nbCols == null) { parser.abort(); return; }
            data.cellValues = new Float32Array(data.nbRows * data.nbCols);
            return;
        }

        ++this._counter;

        if (data.cellValues == null || data.nbRows == null || data.nbCols == null) { parser.abort(); return; }
        if (this._counter > data.nbRows - 1) { parser.abort(); return; }

        while (row.data[row.data.length - 1] === null) row.data.pop(); // remove null element(s) (because there is a tab after the last number)
        data.cellValues?.set(row.data as number[], this._counter * data.nbCols);

        if (this._counter >= data.nbRows - 1) { parser.abort(); return; }
    }

    _handleRow = (
        row: {
            data: string[] | boolean[] | number[],
            errors: any[],
            meta: Object
        },
        parser: {
            abort: Function,
            aborted: Function,
            parse: Function,
            pause: Function,
            paused: Function,
            resume: Function,
            streamer: {
                _rowCount: number
            }
        }
    ) => {

        if (this.panelData == null) {
            parser.abort();
            return;
        }

        ++this._rowCount;

        // header
        if (this._rowCount === 0) {
            if ((row.data[0] as string).indexOf("#xplorer file version") < 0) {
                console.error(`Could not read solver result file: ${this.urls?.xpl}`);
                parser.abort();
            }
            return;
        }

        // cu index
        if (this._rowCount === 1) {
            let splittedScalars = this._split(row.data[0] as string, ':');
            for (let i = 0; i < splittedScalars.length; ++i) {
                if (splittedScalars[i].charAt(0) === 'd') {
                    this.totalCuIndex = i;
                }
            }
            if (this.totalCuIndex < 0) {
                parser.abort();
            }
            return;
        }

        // # of panels
        if (this._rowCount === 2) {
            return;
        }


        // panel data

        // if counter is 0: check what step to perform
        if (this._counter <= 0) {
            ++this._step; // next step

            // if extra fields : skip 5 lines (including this one)
            if (this._step === 0 || this._step === 3) this._counter = 4;

            // if start of vertices or indices: read amount of rows
            else if ([1, 2, 4, 5].indexOf(this._step) > -1) {
                this._counter = row.data[0] as number;
                if (this._step === 1) this._onVertices(this.panelData.front);
                else if (this._step === 2) this._onTriangles(this.panelData.front);
                else if (this._step === 4) this._onVertices(this.panelData.back);
                else if (this._step === 5) this._onTriangles(this.panelData.back);
            }

            // end of useable data
            else if (this._step === 6) parser.abort(); // finished

            // error
            else {
                console.error(`invalid step: ${this._step}`);
                parser.abort();
            }

            return;
        }

        // perform action depending on step
        if (this._step === 1) this._onVertex(this.panelData.front, row.data as number[]);
        else if (this._step === 2) {
            this._onTriangle(this.panelData.front, row.data as number[]);
        }
        else if (this._step === 4) this._onVertex(this.panelData.back, row.data as number[]);
        else if (this._step === 5) this._onTriangle(this.panelData.back, row.data as number[]);

        --this._counter;
        return;
    }


    _onVertices(data: XplRenderData) {
        if (this.panelData == null) return;

        // this.panelData.scalarList.push("d[um]");

        // resize arrays
        data.points = new Float32Array(this._counter * 3);
        data.normals = new Float32Array(this._counter * 3);
        data.scalars = new Float32Array(this._counter);

        // init min/max vec
        data.min = Float32Array.of(Number.MAX_VALUE, Number.MAX_VALUE, Number.MAX_VALUE);
        data.max = Float32Array.of(-Number.MAX_VALUE, -Number.MAX_VALUE, -Number.MAX_VALUE);

        // // init min/max scalar
        // this._minScalar = Number.MAX_VALUE;
        // this._maxScalar = -Number.MAX_VALUE;
    }

    _onTriangles(data: XplRenderData) {
        // data.extremes.set(0, [this._minScalar, this._maxScalar]);

        data.triangles = new Uint16Array(this._counter * 3);
    }

    _onVertex(data: XplRenderData, row: number[]) {
        if (data.points == null
            || data.scalars == null
            || data.normals == null
            || data.min == null
            || data.max == null)
            return;

        let n = data.points.length / 3 - this._counter;
        data.scalars[n] = SCALAR_VALUE;
        this._fillVec(data.normals, n, 0.0, 0.0, -1.0);

        if (row.length >= 3) {
            this._fillVec(data.points, n, row[0], row[1], row[2]);
            for (let j = 0; j < 3; j++) {
                if (data.min[j] > data.points[n * 3 + j]) data.min[j] = data.points[n * 3 + j];
                if (data.max[j] < data.points[n * 3 + j]) data.max[j] = data.points[n * 3 + j];
            }
        }

        if (row.length > 3 + this.totalCuIndex) {
            data.scalars[n] = row[3 + this.totalCuIndex];
            // let scalar = row[3 + this.totalCuIndex];
            // data.scalars[n] = scalar;
            // if (Math.abs(scalar - SCALAR_VALUE) >= 0.0001) {
            //     if (scalar < this._minScalar) this._minScalar = scalar;
            //     if (scalar > this._maxScalar) this._maxScalar = scalar;
            // }
        }
    }

    _fillVec(array: Float32Array, n: number, x: number, y: number, z: number) {
        array[n * 3] = x;
        array[n * 3 + 1] = y;
        array[n * 3 + 2] = z;
    }

    _onTriangle(data: XplRenderData, row: number[]) {
        if (data.triangles == null) return;

        let n = data.triangles.length / 3 - this._counter;
        if (row.length >= 3) {
            data.triangles[n * 3] = row[0];
            data.triangles[n * 3 + 2] = row[1]; //Wrong solver triangle orientation
            data.triangles[n * 3 + 1] = row[2]; //Switch again when fixed
        }
    }


    // readOld = (xplFilePath: string, panelData: PanelData) => {
    //     // init props
    //     this.panelData = panelData;
    //     this.xplFilePath = xplFilePath;
    //     this.stream = new lineByLine(this.xplFilePath);
    //     this.line = "";
    //     this.totalCuIndex = -1;

    //     // read header
    //     this._readLine();
    //     if (this.line.indexOf("#xplorer file version") < 0) {
    //         this._close();
    //         console.error(`Could not read solver result file: ${this.xplFilePath}`);
    //         return;
    //     }

    //     // read totalCuIndex
    //     this._readLine();
    //     let splittedScalars = this._split(this.line, ':');
    //     for (let i = 0; i < splittedScalars.length; i++) {
    //         if (splittedScalars[i].charAt(0) === 'd') {
    //             this.totalCuIndex = i;
    //         }
    //     }
    //     if (this.totalCuIndex < 0) {
    //         this._close();
    //         return;
    //     }

    //     this._readLine(); //Nb Of panels

    //     this._readPanel(this.panelData.front);
    //     this._readPanel(this.panelData.back);
    //     // 3rd panel?
    //     this._close();
    // };

    // _readPanel = (data: XPLRenderData) => {

    //     this._readLine(); //panel number
    //     this._readLine(); //type
    //     this._readLine(); //panel name
    //     this._readLine(); //D2
    //     this._readLine(); //used scalars

    //     this._readLine(); //Nb of points;
    //     let nbOfPoints = this._toNumber(this.line);

    //     this.panelData!.scalarList.push("d[um]");
    //     let scalarDIndex = 0;

    //     // scalars
    //     data.scalars.set(scalarDIndex, []);
    //     let scalars = data.scalars.get(scalarDIndex)!;
    //     scalars.length = nbOfPoints;

    //     // resize
    //     data.points.length = nbOfPoints;
    //     data.normals.length = nbOfPoints;

    //     vec3.set(data.min, Number.MAX_VALUE, Number.MAX_VALUE, Number.MAX_VALUE);
    //     vec3.set(data.max, -Number.MAX_VALUE, -Number.MAX_VALUE, -Number.MAX_VALUE);

    //     let minScalar = Number.MAX_VALUE;
    //     let maxScalar = -Number.MAX_VALUE;

    //     let abs = Math.abs;
    //     let splittedLine: string[];

    //     for (let i = 0; i < nbOfPoints; i++) {
    //         // fill values
    //         scalars[i] = SCALAR_VALUE;
    //         data.normals[i] = vec3.fromValues(0.0, 0.0, -1.0);

    //         this._readLine()
    //         splittedLine = this._split(this.line, '\t');

    //         if (splittedLine.length >= 3) {
    //             data.points[i] = vec3.fromValues(
    //                 this._toNumber(splittedLine[0]),
    //                 this._toNumber(splittedLine[1]),
    //                 this._toNumber(splittedLine[2])
    //             );

    //             for (let j = 0; j < 3; j++) {
    //                 if (data.min[j] > data.points[i][j]) data.min[j] = data.points[i][j];
    //                 if (data.max[j] < data.points[i][j]) data.max[j] = data.points[i][j];
    //             }
    //         }

    //         if (splittedLine.length > this.totalCuIndex) {
    //             let scalar = this._toNumber(splittedLine[3 + this.totalCuIndex]);
    //             scalars[i] = scalar;
    //             if (abs(scalar - SCALAR_VALUE) >= 0.0001) {
    //                 if (scalar < minScalar) minScalar = scalar;
    //                 if (scalar > maxScalar) maxScalar = scalar;
    //             }
    //         }
    //     }

    //     data.extremes.set(scalarDIndex, [minScalar, maxScalar]);

    //     this._readLine(); // nb of triangles;
    //     let nbTriangles = this._toNumber(this.line);

    //     // resize
    //     data.triangles.length = nbTriangles * 3;

    //     for (let i = 0; i < nbTriangles; i++) {
    //         this._readLine();
    //         splittedLine = this._split(this.line, '\t');
    //         if (splittedLine.length >= 3) {
    //             data.triangles[i * 3] = this._toNumber(splittedLine[0]);
    //             data.triangles[i * 3 + 2] = this._toNumber(splittedLine[1]); //Wrong solver triangle orientation
    //             data.triangles[i * 3 + 1] = this._toNumber(splittedLine[2]); //Switch again when fixed
    //         }
    //     }
    // };

    // split string and remove empty parts
    _split(s: string, sep: string): string[] {
        return s.split(sep).filter((s: string) => s);
    }

    // // safe number conversion
    // _toNumber(s: string): number {
    //     let n = Number(s)
    //     if (isNaN(n)) {
    //         this._close();
    //         throw new Error(`"${s}" is not a number. Failed to read ${this.xplFilePath}`)
    //     }
    //     return n;
    // }

    // // safe read next line from stream into this.line
    // _readLine() {
    //     if (this.stream == null)
    //         throw new Error(`Xpl reading stream is not initialized. Failed to read ${this.xplFilePath}`)

    //     let s = this.stream.next();
    //     if (s === false) {
    //         this._close();
    //         throw new Error(`End of file reached preemptively. Failed to read ${this.xplFilePath}`)
    //     }
    //     this.line = s;
    // }

    // // close stream
    // _close() {
    //     if (this.stream != null) this.stream.close();
    //     this.stream = null;
    // }
}

        // const stream = fs.createReadStream(this.xplFilePath || '');
        // const rl = readline.createInterface({ input: stream, crlfDelay: Infinity });
        // const it = rl[Symbol.asyncIterator]();