import Dimension, {
  ThicknessDimension,
  LengthDimension,
  AreaDimension,
  CurrentDensityDimension,
  TimeDimension,
  lengthDimensionInstance,
  thicknessDimensionInstance,
  areaDimensionInstance,
  currentDensityInstance,
  timeInstance,
  ScalarDimension,
  scalarInstance,
  NoDimension,
  noDimensionInstance,
} from './Dimension';

class Unit<D extends Dimension> {
  readonly name: string;
  readonly BaseUnitsPerUnit: number;
  readonly dimension: D;
  // We need to store an instance of D, otherwise two Unit instances with a different D have
  // compatible fields, and therefore are considered to be compatible types

  // We need to store an instance, and not the class itself,
  // since the class itself doesn't exist. Only the constructor really exists in JS.
  // To get any useful data out of the constructor we need to construct an instance anyway,
  // so might as well just demand an instance in the first place

  constructor(name: string, BaseUnitsPerUnit: number, dimension: D) {
    this.name = name;
    this.BaseUnitsPerUnit = BaseUnitsPerUnit;
    this.dimension = dimension;
  }

  toBaseUnit(unitValue: number) {
    return unitValue * this.BaseUnitsPerUnit;
  }

  fromBaseUnit(baseUnitValue: number) {
    return baseUnitValue / this.BaseUnitsPerUnit;
  }

  getDimensionName() {
    return this.dimension.getName();
  }
}

export { Unit as default };

// Length Units
const mmUnit = new Unit<LengthDimension>('mm', 1, lengthDimensionInstance);
const inchUnit = new Unit<LengthDimension>('inch', 25.4, lengthDimensionInstance);
const LengthUnits = { mmUnit, inchUnit };
export { LengthUnits };

// Thickness Units
const umUnit = new Unit<ThicknessDimension>('um', 1, thicknessDimensionInstance);
const milUnit = new Unit<ThicknessDimension>('mil', 25.4, thicknessDimensionInstance);
const ThicknessUnits = { umUnit, milUnit };
export { ThicknessUnits };

// Area Units
const mm2Unit = new Unit<AreaDimension>('mm2', 1, areaDimensionInstance);
const inch2Unit = new Unit<AreaDimension>('inch2', 645.16, areaDimensionInstance);
const AreaUnits = { mm2Unit, inch2Unit };
export { AreaUnits };

// Current Density Units
const a_m2Unit = new Unit<CurrentDensityDimension>('A_m2', 1, currentDensityInstance);
const a_ft2Unit = new Unit<CurrentDensityDimension>('A_ft2', 10.764, currentDensityInstance);
const CurrentDensityUnits = { a_m2Unit, a_ft2Unit };
export { CurrentDensityUnits };

// Time Units
const sUnit = new Unit<TimeDimension>('s', 1, timeInstance);
const mmssUnit = new Unit<TimeDimension>('mmss', 1, timeInstance);
const TimeUnits = { sUnit, mmssUnit };
export { TimeUnits };

// Scalar Units
const noUnit = new Unit<NoDimension>('no_unit', 1, noDimensionInstance);
const floatingPointUnit = new Unit<ScalarDimension>('scalar', 1, scalarInstance);
const percentUnit = new Unit<ScalarDimension>('percent', 0.01, scalarInstance);
const ScalarUnits = { noUnit, percentUnit, floatingPointUnit };
export { ScalarUnits };

const AllUnits: Array<Unit<Dimension>> = [
  LengthUnits,
  ThicknessUnits,
  AreaUnits,
  CurrentDensityUnits,
  TimeUnits,
  ScalarUnits,
]
  .map((group) => {
    return Object.values(group);
  })
  .flat();

export const getUnitByName = (name: string, dimType: typeof Dimension) => {
  for (const unit of AllUnits) {
    if (unit.dimension instanceof dimType && unit.name === name) {
      return unit as Unit<Dimension>;
    }
  }
  return undefined;
};

// Is there a better way to do this?
const getUnitsForDimension = <D extends Dimension>(dimension: D): Array<Unit<D>> => {
  let result = {};
  if (dimension instanceof LengthDimension) {
    result = LengthUnits;
  } else if (dimension instanceof ThicknessDimension) {
    result = ThicknessUnits;
  } else if (dimension instanceof AreaDimension) {
    result = AreaUnits;
  } else if (dimension instanceof CurrentDensityDimension) {
    result = CurrentDensityUnits;
  } else if (dimension instanceof TimeDimension) {
    result = TimeUnits;
  } else if (dimension instanceof ScalarDimension) {
    result = ScalarUnits;
  }
  return Object.values(result);
};

export { getUnitsForDimension };
