import React from "react";
import { StyledAnchor, Icon, Box } from 'components';
import { Layer as GrommetLayer } from 'grommet';
import styled from 'styled-components';

const StyledBox = styled(Box)`
    position: fixed;
    right: 0;
    top: 0;
`;

function isModifiedEvent(event: React.MouseEvent<HTMLElement>) {
    return event.metaKey || event.altKey || event.ctrlKey || event.shiftKey;
}

const Layer = ({ label, children }: { label: string, children: React.ReactNode }) => {

    function handleClick(event: React.MouseEvent<HTMLAnchorElement>) {
        // Ignore if link is clicked using a modifier key.
        // Note: The click event is only fired for the primary pointer button,
        // i.e. the left mouse button. So no need to check for right-click.
        // https://w3c.github.io/uievents/#event-type-click
        // https://github.com/testing-library/testing-library-docs/issues/469
        if (isModifiedEvent(event)) {
            return;
        }

        // Prevent default action which reloads the app
        event.preventDefault();

        // Change the router state to trigger a refresh
        setShow(true);
    }


    const [show, setShow] = React.useState<boolean>(false);
    return (
        <React.Fragment>
            <StyledAnchor onClick={handleClick} isActive={show}>{label}</StyledAnchor>
            {show && (
                <GrommetLayer
                    onEsc={() => setShow(false)}
                    onClickOutside={() => setShow(false)}
                    margin="large"
                >
                    <StyledBox background='brand' pad={{vertical: "5px"}} onClick={() => setShow(false)} margin="medium"><Icon size="25px" color='background-front' name='close'/></StyledBox>
                    {children}
                </GrommetLayer>
            )}
        </React.Fragment>
    );
}
export default Layer;