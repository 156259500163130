import {
  Route,
  createRouterState,
  RouterStore,
  HistoryAdapter,
  browserHistory,
  TransitionHook,
} from 'mobx-state-router';
import {
  checkAnonymous,
  checkAuthenticated,
  loadArchive,
  checkSimulationState,
  // checkSimulationStateB,
  checkSimulation,
  checkAdmin,
  checkCanCreateNew,
} from './transition_hooks';
import { IRootStore } from 'store';
import { Routes } from './types';

export const checkAuthenticatedAndStartSimulation: TransitionHook = async (from, to, router) => {
  return checkAuthenticated(from, to, router).then(async (redirect) => {
    if (redirect == null) {
      // we are logged-in
      return checkCanCreateNew(from, to, router).then((redirect => {
        if (redirect == null) {
          const store = router.options.store as IRootStore;
          const sim = store.addSimulation();
          store.setCurrentSimulation(sim.id);

          return createRouterState(Routes.UploadSources, { params: { id: sim.id } });
        } else {
          return redirect;
        }
      }))
    } else {
      return redirect;
    }
  });
};

export const routes: Route[] = [
  {
    name: Routes.Home,
    pattern: '/',
    beforeEnter: checkAuthenticated,
  },
  {
    name: Routes.NewSimulation,
    pattern: '/new',
    beforeEnter: checkAuthenticatedAndStartSimulation,
  },
  {
    name: Routes.Simulation,
    pattern: '/session/:id',
    beforeEnter: checkAuthenticated,
    onEnter: checkSimulationState,
  },
  {
    name: Routes.UploadSources,
    pattern: '/session/:id/sources',
    beforeEnter: checkAuthenticated,
    onEnter: checkSimulationState,
  },
  {
    name: Routes.Preview,
    pattern: '/session/:id/preview',
    beforeEnter: checkAuthenticated,
    onEnter: checkSimulationState,
  },
  {
    name: Routes.Analyze,
    pattern: '/session/:id/analyze',
    beforeEnter: checkAuthenticated,
    onEnter: checkSimulationState,
  },
  {
    name: Routes.Balance,
    pattern: '/session/:id/balance',
    beforeEnter: checkAuthenticated,
    onEnter: checkSimulationState,
  },
  {
    name: Routes.Archive,
    pattern: '/archive',
    beforeEnter: checkAuthenticated,
    onEnter: loadArchive,
  },
  {
    name: Routes.Settings,
    pattern: '/settings',
    beforeEnter: checkAuthenticated,
  },
  {
    name: Routes.SimulationSettings,
    pattern: '/session/:id/settings',
    beforeEnter: checkAuthenticated,
    onEnter: checkSimulation,
  },
  {
    name: Routes.Account,
    pattern: '/account',
    beforeEnter: checkAuthenticated,
  },
  {
    name: Routes.Login,
    pattern: '/login',
    beforeEnter: checkAnonymous,
  },
  {
    name: Routes.Register,
    pattern: '/register',
    beforeEnter: checkAnonymous,
  },
  {
    name: Routes.ForgotPassword,
    pattern: '/forgot_password',
    beforeEnter: checkAnonymous,
  },
  {
    name: Routes.ResetPassword,
    pattern: '/password/reset/:token',
    beforeEnter: checkAnonymous,
  },
  {
    name: Routes.ConfirmEmail,
    pattern: '/account/confirm/:token',
    beforeEnter: checkAnonymous,
  },
  {
    name: Routes.Callback,
    pattern: '/payments/callback',
    // beforeEnter: checkAuthenticated, TODO: check if this can be required within callback iframe
  },
  {
    name: Routes.NotFound,
    pattern: '/notfound',
  },
  {
    name: Routes.Error,
    pattern: '/error',
  },
  {
    name: Routes.Admin,
    pattern: '/admin',
    beforeEnter: checkAdmin,
  }
];

const notFound = createRouterState(Routes.NotFound);

export function initRouter(store: IRootStore) {
  const router = new RouterStore(routes, notFound, { store });

  // Observer history
  const historyAdapter = new HistoryAdapter(router, browserHistory);
  historyAdapter.observeRouterStateChanges();

  return router;
}
