export default `
precision mediump float;

varying vec3 worldPosition;
varying vec3 worldNormal;
varying float scalarF;
varying float containsMagicNumber;
varying vec2 texCoord;

uniform vec3 eyePosition;
uniform sampler2D activeSurfaceTexture;
uniform sampler2D colorTable;

uniform float minScalar;
uniform float maxScalar;
uniform vec4 cutOffMinColor;
uniform vec4 cutOffMaxColor;
uniform bool useCutOff;
uniform bool transparent;
uniform bool useSafeZone;
uniform float minSafeScalar;
uniform float maxSafeScalar;
uniform vec4 safeZoneColor;

void main()
{
    vec3 worldView = normalize(eyePosition - worldPosition);
	vec4 color = vec4(1.0, 1.0, 1.0, 1.0);

	vec4 activeColor = vec4(texture2D(activeSurfaceTexture, texCoord).rgba);

	if((activeColor.r < 0.95 || activeColor.g < 0.95 || activeColor.b < 0.95))
	{
		if(useCutOff == true)
		{
			if(scalarF < minScalar)
			{
				color = vec4(cutOffMinColor.rgb, 1.0);
			}
			else if(scalarF > maxScalar)
			{
				color = vec4(cutOffMaxColor.rgb, 1.0);
			}
			else
			{
				if(useSafeZone && scalarF >= minSafeScalar && scalarF <= maxSafeScalar)
				{
					color = safeZoneColor;
				}
				else
				{
					float realScalarValue = (scalarF - minScalar) / (maxScalar - minScalar);
					if(realScalarValue < 0.001) { realScalarValue = 0.00001; }
					if(realScalarValue > 0.999) { realScalarValue = 0.99999; }
					color = vec4(texture2D(colorTable, vec2(realScalarValue, 0.5)).rgb, 1.0);
				}
			}
		}
		else
		{
			if(useSafeZone && scalarF >= minSafeScalar && scalarF <= maxSafeScalar)
			{
				color = safeZoneColor;
			}
			else
			{
				float realScalarValue = (scalarF - minScalar) / (maxScalar - minScalar);
				if(realScalarValue < 0.001) { 
					realScalarValue = 0.00001; 
				}
				if(realScalarValue > 0.999) {
					realScalarValue = 0.99999; 
				}
				color = vec4(texture2D(colorTable, vec2(realScalarValue, 0.5)).rgb, 1.0);
			}
		}
	}

	if(transparent)
	{
		color.a = 0.7;
	}
	else
	{
		color.a = 1.0;
	}

	gl_FragColor = color;
}
`
