import { mat4, mat3, vec4, vec3 } from 'gl-matrix'
import {mul, add, toVec3, toMat3, map, inverse} from './util';

export default class Camera {
    worldTransform : mat4;
    viewMatrix : mat4;
    projectionMatrix : mat4;
    normalMatrix : mat4;
    
    // uniforms
    eyePosition : vec3;
    modelMatrix : mat4;
    modelNormalMatrix : mat3;
    mvp : mat4;

    // settings
    min : vec3;
    max : vec3;
    flip : boolean;

    constructor(min: vec3, max: vec3) {
        this.worldTransform = mat4.create();
        this.viewMatrix = mat4.create();
        this.projectionMatrix = mat4.create();
        this.eyePosition = vec3.create();
        this.normalMatrix = mat4.create();
        this.modelMatrix = mat4.create();
        this.modelNormalMatrix = mat3.create();
        this.mvp = mat4.create();

        this.min = min;
        this.max = max;

        this.flip = false;

        this.adjust = this.adjust.bind(this);
        this._calc = this._calc.bind(this);
        this._setViewMatrix = this._setViewMatrix.bind(this);
        this._setProjectionMatrix = this._setProjectionMatrix.bind(this);
        this._setNormalMatrix = this._setNormalMatrix.bind(this);
        this._setEyePosition = this._setEyePosition.bind(this);
        this._setModelMatrix = this._setModelMatrix.bind(this);
        this._setModelNormalMatrix = this._setModelNormalMatrix.bind(this);
        this._setMvp = this._setMvp.bind(this);

        this._calc()
    }

    adjust(flip : boolean) {
        if (flip === this.flip) return;
        this.flip = flip;
        this._calc();
    }

    _calc() {
        this._setViewMatrix();
        this._setProjectionMatrix();
        this._setNormalMatrix();

        // uniforms needed
        this._setEyePosition();
        this._setModelMatrix();
        this._setModelNormalMatrix();
        this._setMvp();
    }

    _setViewMatrix() {
        let position = map(vec4.fromValues(0.0, 0.0, 1.0, 1.0), this.worldTransform);
        let viewDirection = map(vec4.fromValues(0.0, 0.0, -1.0, 0.0), this.worldTransform);
        let upVector = map(vec4.fromValues(0.0, 1.0, 0.0, 0.0), this.worldTransform);
        mat4.lookAt(this.viewMatrix, toVec3(position), toVec3(add(position, viewDirection)), toVec3(upVector));
    }

    _setProjectionMatrix() {
        mat4.ortho(this.projectionMatrix, this.min[0], this.max[0], this.min[1], this.max[1], 0.01, 50.0);
        if (this.flip) mat4.scale(this.projectionMatrix, this.projectionMatrix, vec3.fromValues(-1,1,1));
    }

    _setEyePosition() {
        mat4.getTranslation(this.eyePosition, inverse(this.viewMatrix));
    }

    _setNormalMatrix() {
        mat4.transpose(this.normalMatrix, inverse(this.viewMatrix));
    }

    _setModelMatrix() {
        this.modelMatrix = this.worldTransform;
    }

    _setModelNormalMatrix() {
        let m = mat4.create();
        mat4.transpose(m, inverse(this.modelMatrix));
        this.modelNormalMatrix = toMat3(m);
    }

    _setMvp() {
        this.mvp = mul(this.projectionMatrix, this.viewMatrix, this.modelMatrix);
    }
}

