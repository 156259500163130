import { Routes } from 'routes';
import { RouterState, useRouterStore, createRouterState } from 'mobx-state-router';

function useNavigate() {
  const routerStore = useRouterStore();

  return (
    to: Routes,
    options?: {
      params?: RouterState['params'];
      queryParams?: RouterState['queryParams'];
    }
  ) => {
    const toState = createRouterState(to, options);
    return routerStore.goToState(toState);
  };
}

export default useNavigate;
