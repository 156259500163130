import React from 'react';
import LazyScreen from 'components/LazyScreen';

const Screen = React.lazy(() => import('./Settings'));

const Settings = () => {
  return (
  <LazyScreen chrome>
    <Screen />
  </LazyScreen>
)};

export default Settings;

