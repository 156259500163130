import React from 'react';
import { LoginForm, Heading, Paragraph, Box, Link } from 'components';
import {
  useTitle,
  useTranslations,
  useLogin,
  useEmailConfirmed,
  usePasswordResetEmail,
} from 'hooks';
import { Routes } from 'routes';
import { useAsyncFn } from 'react-use';
import { LoginFormProps, LoginFormValues } from 'components/LoginForm';
import { Trans } from 'react-i18next';

const Login = () => {
  const t = useTranslations('login');

  useTitle(t('page_title'));

  const confirmed = useEmailConfirmed();
  const reset = usePasswordResetEmail();
  const { login, ...loginData } = useLogin(
    true,
    confirmed != null ? confirmed : reset != null ? reset : ''
  );

  const [loginState, onLogin] = useAsyncFn(
    async (values?: LoginFormValues) => {
      if (values != null) {
        await login(values);
      }
    },
    [login]
  );

  const loginProps: LoginFormProps = {
    ...loginData,
    busy: loginState.loading,
    forgotPasswordLink: Routes.ForgotPassword,
    registerLink: Routes.Register,
    onLogin: onLogin,
  };

  return (
    <Box gap="xlarge">
      <Box gap="medium">
        <Box>
          <Heading level="3" size="small">{t('teaser.title')}</Heading>
          <Paragraph size="xsmall" color="text-xweak"><Trans i18nKey="login.title_asterisk">More info on our <Link to="https://www.elsyca.com/elsyca-solutions/elsyca-cube#pricing_table">pricing page</Link></Trans></Paragraph>
        </Box>

        <Box>
          <Paragraph color="text-weak">{t('teaser.paragraph1')}</Paragraph>
          <Paragraph color="text-weak">{t('teaser.paragraph2')}</Paragraph>
          <br />
          <Paragraph color="text-weak">{t('teaser.paragraph3')}</Paragraph>
        </Box>
      </Box>

      <Box>
        <Heading level="3" size="small" margin={{ bottom: 'medium' }}>
          {t('sidebar_call_to_action')}
        </Heading>
        {(confirmed != null || reset != null) && (
          <Box margin={{ top: 'small', bottom: 'small' }}>
            <Paragraph color="status-ok">
              {confirmed != null ? t('confirm_success') : ''}
              {reset != null ? t('reset_password_success') : ''}
            </Paragraph>
          </Box>
        )}
        <LoginForm {...loginProps} />
      </Box>
    </Box>
  );
};

export default Login;
