import { PanelData } from "./PanelData";
import XplReader from "./XplReader";

import XplRenderer from "./XplRenderer";
// import { vec3 } from 'gl-matrix'


export type DisplaySettings = {
    level: number;
    b_flipBack: boolean;

    min_thickness: number;
    max_thickness: number;

    b_cutoff: boolean;

    b_safezone: boolean;
    min_safezone: number;
    max_safezone: number;

    // showCopperAreas?: boolean;
}

export type DisplayDataUrls = {
    xpl: string,
    xplB?: string,
    front: string,
    back: string,
    frontB?: string,
    backB?: string
}


// const COLOR_LEVEL = 16;

export default class XplPainter {
    panelData: PanelData;
    panelDataB: PanelData;
    xplReader: XplReader;
    urls?: DisplayDataUrls;
    lastUrls: DisplayDataUrls;
    reload: boolean;
    reloadData: {
        urls: DisplayDataUrls, update: {
            front: Function,
            back: Function,
            frontB: Function,
            backB: Function,
            frontBA: Function,
            backBA: Function,
        }
    } | null;

    // analyzed
    frontRenderer?: XplRenderer;
    backRenderer?: XplRenderer;

    // balanced
    frontRendererB?: XplRenderer;
    backRendererB?: XplRenderer;

    // balanced + areas
    // frontRendererBA?: XplRenderer;
    // backRendererBA?: XplRenderer;

    settings?: { [key: string]: number };
    // frontImg: HTMLImageElement;
    // backImg: HTMLImageElement;

    loading: boolean = false;
    isbusy: (busy: boolean) => void;

    constructor(isbusy : (busy: boolean) => void) { // frontImgId: string, backImgId: string
        this.panelData = new PanelData();
        this.panelDataB = new PanelData();
        this.xplReader = new XplReader();
        this.isbusy = isbusy;

        this.reload = false;
        this.reloadData = null;
        this.lastUrls = {xpl: "", front: "", back: ""};

        // let frontImg = document.getElementById(frontImgId);
        // if (frontImg == null) throw new Error(`Unable to find display img ${frontImgId} [XplPainter]`);
        // this.frontImg = frontImg as HTMLImageElement;

        // let backImg = document.getElementById(backImgId);
        // if (backImg == null) throw new Error(`Unable to find display img ${backImgId} [XplPainter]`)
        // this.backImg = backImg as HTMLImageElement;

        this.init = this.init.bind(this);
        this.render = this.render.bind(this);
        this.onEndBusy = this.onEndBusy.bind(this);
    }

    onEndBusy() {
        this.isbusy(this.loading || this.reload || 
            (this.frontRenderer ? this.frontRenderer.busy : false) || 
            (this.backRenderer ? this.backRenderer.busy : false) || 
            (this.frontRendererB ? this.frontRendererB.busy : false) || 
            (this.backRendererB ? this.backRendererB.busy : false)
        )
    }

    async init(urls: DisplayDataUrls,
        update: {
            front: Function,
            back: Function,
            frontB: Function,
            backB: Function,
            frontBA: Function,
            backBA: Function,
        }
    ) {
        if (this.loading) {
            this.reload = true;
            this.reloadData = { urls, update };
            return;
        }
        this.loading = true;

        // read data
        if (this.frontRenderer == null) this.frontRenderer = new XplRenderer(update.front, false, this.onEndBusy);
        if (this.backRenderer == null) this.backRenderer = new XplRenderer(update.back, true, this.onEndBusy);
        if (this.frontRendererB == null) this.frontRendererB = new XplRenderer(update.frontB, false, this.onEndBusy, update.frontBA);
        if (this.backRendererB == null) this.backRendererB = new XplRenderer(update.backB, true, this.onEndBusy, update.backBA);
        // if (this.frontRendererBA == null) this.frontRendererBA = new XplRenderer(update.frontBA, false);
        // if (this.backRendererBA == null) this.backRendererBA = new XplRenderer(update.backBA, true);

        if (!this.reload && urls.xpl !== this.lastUrls.xpl) {
            await this.xplReader.readXpl(urls.xpl, this.panelData);
            this.lastUrls.xpl = urls.xpl;
            this.panelData.generateTextureCoordinates();
            this.panelData.front.gerber = urls.front;
            this.panelData.back.gerber = urls.back;
            this.frontRenderer.init(this.panelData.front);
            this.backRenderer.init(this.panelData.back);
        }

        if (!this.reload && urls.xplB != null && urls.xplB !== this.lastUrls.xplB) {
            await this.xplReader.readXpl(urls.xplB, this.panelDataB);
            this.lastUrls.xplB = urls.xplB;
            this.panelDataB.generateTextureCoordinates();
            this.panelDataB.front.gerber = urls.front;
            this.panelDataB.back.gerber = urls.back;
            this.frontRendererB.init(this.panelDataB.front);
            this.backRendererB.init(this.panelDataB.back);
        }

        if (!this.reload && urls.frontB != null && urls.frontB !== this.lastUrls.frontB) {
            await this.xplReader.readBalancing(urls.frontB, this.panelDataB.front, true);
            this.lastUrls.frontB = urls.frontB;
            this.frontRendererB.initB();
        }

        if (!this.reload && urls.backB != null && urls.backB !== this.lastUrls.backB) {
            await this.xplReader.readBalancing(urls.backB, this.panelDataB.back, false);
            this.lastUrls.backB = urls.backB;
            this.backRendererB.initB();
        }

        this.loading = false;
        if (this.reload && this.reloadData != null) {
            this.reload = false;
            this.init(this.reloadData.urls, this.reloadData.update);
        } else this.render(this.settings);

        // read possible balancing


        // // this.panelData.front.gerber = urls.front;
        // // this.panelData.back.gerber = urls.back;
        // let _onDataLoaded = (() => {
        //     // this.panelData.generateTextureCoordinates();
        //     // console.dir(this.panelData);

        //     if (this.frontRenderer == null) this.frontRenderer = new XplRenderer(update.front, false);
        //     this.frontRenderer.init(this.panelData.front);

        //     if (this.backRenderer == null) this.backRenderer = new XplRenderer(update.back, true);
        //     this.backRenderer.init(this.panelData.back);

        //     this.loading = false;
        //     this.render(this.settings);
        // });
        // this.loading = true;
        // this.xplReader.read(urls, this.panelData, _onDataLoaded);
    }


    render(settings?: { [key: string]: number },) {
        if (settings != null) this.settings = settings;
        if (this.loading) return;
        if (this.settings == null) return;

        if (this.frontRenderer != null) this.frontRenderer.draw(this.settings);
        if (this.backRenderer != null) this.backRenderer.draw(this.settings);
        if (this.frontRendererB != null) this.frontRendererB.draw(this.settings);
        if (this.backRendererB != null) this.backRendererB.draw(this.settings);
        this.onEndBusy();
        // if (this.frontRendererBA != null) this.frontRendererBA.draw(this.settings, true);
        // if (this.backRendererBA != null) this.backRendererBA.draw(this.settings, true);
    }

    invalidate() {
        if (this.frontRenderer != null) this.frontRenderer.update = null;
        if (this.backRenderer != null) this.backRenderer.update = null;
        if (this.frontRendererB != null) this.frontRendererB.update = null;
        if (this.backRendererB != null) this.backRendererB.update = null;
        // if (this.frontRendererBA != null) this.frontRendererBA.update = null;
        // if (this.backRendererBA != null) this.backRendererBA.update = null;
    }
}