import React from 'react';
import LazyScreen from 'components/LazyScreen';

const Screen = React.lazy(() => import('./Account'));

const Account = () => (
  <LazyScreen chrome>
    <Screen />
  </LazyScreen>
);

export default Account;
