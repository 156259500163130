import React from 'react';
import { useNavigate } from 'utils';
import { Main, Navigation, TopNav, Footer, Span, BuyButton } from 'components';
import { Box, Grid } from 'grommet';
import { observer } from 'mobx-react';
import { useSession, useCredits } from 'store';
import { useTranslations } from 'hooks';
import { Routes } from 'routes';
import styled from 'styled-components';

// import styled from 'styled-components';

//  min-height: -webkit-calc(100% - 51px);
// min-height:    -moz-calc(100% - 51px);
// min-height:         calc(100% - 51px);
// const HeightBox = styled(Box)`

// `;

const StyledBox = styled(Box)`
  border-right-width: 1px;
  border-bottom-width: 1px;
`;

type AppChromeProps = React.PropsWithChildren<{}>;

const AppChrome = ({ children }: AppChromeProps) => {
  const session = useSession();
  const navigate = useNavigate();
  const t = useTranslations('navigation.topbar');
  const creditsAvailable = useCredits();

  // We shouldn't get here without a user, but if we do, userName will be
  // 'undefined undefined'
  const userName = `${session.user?.firstname} ${session.user?.lastname}`;
  const menuItems = [
    { label: t('my_account'), onClick: () => navigate(Routes.Account) },
    { label: t('default_settings'), onClick: () => navigate(Routes.Settings) },
    {
      label: t('logout'),
      onClick: () => {
        session.logout().then(() => navigate(Routes.Login));
      },
    },
  ];

  return (
    <Grid
      fill
      rows={['auto', 'flex']}
      columns={['auto', 'flex']}
      gap="none"
      areas={[
        { name: 'corner', start: [0,0], end: [0, 0] },
        { name: 'sidebar', start: [0, 1], end: [1, 1] },
        { name: 'topnav', start: [0, 0], end: [1, 0] },
        { name: 'main', start: [1, 1], end: [1, 1] },
      ]}
    >
      <StyledBox gridArea='corner' background="background-front"/>

      <Box gridArea="topnav">
        <TopNav userName={userName} menuItems={menuItems}>
          {session.user &&
            <Box flex="shrink" direction="row" gap="small" align="center">
              <BuyButton />
              {creditsAvailable != null && (
                <Span size="small">
                  {t('remaining', { count: Math.round(creditsAvailable)})}
                </Span>
              )}
            </Box>}
        </TopNav>
      </Box>
      <Box gridArea="main">
        <Main>
          <Box flex fill direction="column" justify="between">
            <Box flex="grow">
              {children}
            </Box >
            <Box>
              <Footer />
            </Box>
          </Box>
        </Main>
      </Box>
      <Box gridArea="sidebar">
        <Navigation />
      </Box>
    </Grid>
  );
};

export default observer(AppChrome);
