import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
// TODO: we might want to add this in the future if translations become huge
// import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

// Languages
export const languages = [
  { code: 'en', name: 'English' },
  // { code: 'de', name: 'Deutsch' },
];

function loadLanguages(languageCodes: string[]) {
  const result = Object.fromEntries(
    languageCodes.map((languageCode) => {
      const langData = require(`./${languageCode}.json`);
      return [languageCode, extendWithCountries(langData, languageCode)];
    })
  );
  return result;
}

function extendWithCountries(language: { [key: string]: any }, languageCode: string) {
  const countries = require(`i18n-iso-countries/langs/${languageCode}.json`);
  language['countries'] = {}; // Create namespace for the countries
  language['countries']['country_names'] = countries['countries'];
  return language;
}

i18n
  // TODO: we might want to add this in the future if translations become huge:
  // load translation using http -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
  // learn more: https://github.com/i18next/i18next-http-backend
  // .use(Backend)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    fallbackLng: 'en',
    debug: process.env.NODE_ENV === 'development',
    ns: ['app', 'inputs', 'controls', 'countries'],
    defaultNS: 'app',
    resources: loadLanguages(languages.map((l) => l.code)),

    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    detection: {
      order: ['cookie', 'navigator'],
      caches: ['cookie'],
    },
  });

export default i18n;
