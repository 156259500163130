abstract class Dimension {
  public static readonly dimensionName: string;
  abstract getName: () => string;
}

class LengthDimension extends Dimension {
  static dimensionName: string = 'length';
  // Dummy value to ensure types are really different and certainly incompatible with each other
  public readonly lengthUnique: boolean = true;
  getName = () => LengthDimension.dimensionName;
}

class ThicknessDimension extends Dimension {
  static dimensionName: string = 'thickness';
  public readonly thicknessUnique: boolean = true;
  getName = () => ThicknessDimension.dimensionName;
}

class AreaDimension extends Dimension {
  static dimensionName: string = 'area';
  public readonly areaUnique: boolean = true;
  getName = () => AreaDimension.dimensionName;
}

class CurrentDensityDimension extends Dimension {
  static dimensionName: string = 'current_density';
  public readonly currentDensityUnique: boolean = true;
  getName = () => CurrentDensityDimension.dimensionName;
}

class TimeDimension extends Dimension {
  static dimensionName: string = 'time';
  public readonly timeUnique: boolean = true;
  getName = () => TimeDimension.dimensionName;
}

class ScalarDimension extends Dimension {
  static dimensionName: string = 'scalar';
  public readonly scalarUnique: boolean = true;
  getName = () => ScalarDimension.dimensionName;
}

class NoDimension extends Dimension {
  static dimensionName: string = '';
  public readonly scalarUnique: boolean = true;
  getName = () => NoDimension.dimensionName;
}

export { Dimension as default };

export {
  LengthDimension,
  ThicknessDimension,
  AreaDimension,
  CurrentDensityDimension,
  TimeDimension,
  ScalarDimension,
  NoDimension,
};

const AllDimensions: Array<typeof Dimension> = [
  LengthDimension,
  ThicknessDimension,
  AreaDimension,
  CurrentDensityDimension,
  TimeDimension,
  ScalarDimension,
  NoDimension,
];

export const getDimensionByName = (name: string) => {
  for (const dimension of AllDimensions) {
    if (dimension.dimensionName === name) {
      return dimension;
    }
  }
  return undefined;
};

const lengthDimensionInstance = new LengthDimension();
const thicknessDimensionInstance = new ThicknessDimension();
const areaDimensionInstance = new AreaDimension();
const currentDensityInstance = new CurrentDensityDimension();
const timeInstance = new TimeDimension();
const scalarInstance = new ScalarDimension();
const noDimensionInstance = new NoDimension();

export {
  lengthDimensionInstance,
  thicknessDimensionInstance,
  areaDimensionInstance,
  currentDensityInstance,
  timeInstance,
  scalarInstance,
  noDimensionInstance,
};
