import React, { useEffect, useRef, useCallback } from 'react'; // useState, 

import { PCBViewer, Spinner, Box } from 'components';
// import { Box } from 'components';

import XplPainter, { DisplayDataUrls } from '../../webGL/XplPainter'; // DisplaySettings

import { observer } from 'mobx-react';

import { useSimulation } from '../../screens/simulation/hooks'


// import styled from 'styled-components';

// const painter : XplPainter;
// const canvasIdFront = 'glCanvasFront';
// const imageIdFront = 'imageIdFront';
// const canvasIdBack = 'glCanvasBack';
// const xplfilepath = '../../assets/XplToPng/test.xpl';


// const Wrapper = styled.div`
//   position: relative;
//   width: fit-content;
//   height: fit-content;
// `;

type XplViewerProps = {
    id: string;
    urls: DisplayDataUrls;
    balanced?: boolean;
    areas?: boolean;
    settings: { [key: string]: number };
    isbusy: (busy: boolean) => void;
};

// type XplUrls = {
//     xpl: string;
//     front: string;
//     back: string;
//     frontB?: string;
//     backB?: string;
// }

// function useDeepCompareEffect(callback, dependencies) {
//     useEffect(
//         callback,
//         dependencies.map(useDeepCompareEffect(callback, dependencies.map(useDeepCompareMemorize)))
//     )
// }


const XplViewer = (props: XplViewerProps) => {
    const simulation = useSimulation(props.id);
    // const [front, setFront] = useState(props.urls.front);
    // const [back, setBack] = useState(props.urls.back);

    const front = useCallback((front : string) => {
        simulation.images.analyzed.setFront(front);
    }, []); // eslint-disable-line react-hooks/exhaustive-deps
    const back = useCallback((back : string) => {
        simulation.images.analyzed.setBack(back);
    }, []); // eslint-disable-line react-hooks/exhaustive-deps
    const frontB = useCallback((front : string) => {
        simulation.images.balanced.setFront(front);
    }, []); // eslint-disable-line react-hooks/exhaustive-deps
    const backB = useCallback((back : string) => {
        simulation.images.balanced.setBack(back);
    }, []); // eslint-disable-line react-hooks/exhaustive-deps
    const frontBA = useCallback((front : string) => {
        simulation.images.balancedWithAreas.setFront(front);
    }, []); // eslint-disable-line react-hooks/exhaustive-deps
    const backBA = useCallback((back : string) => {
        simulation.images.balancedWithAreas.setBack(back);
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const painter = useRef<XplPainter | null>(null);

    // var src = "";
    // var source = { value : "https://cdn.pixabay.com/photo/2015/04/23/22/00/tree-736885_960_720.jpg" }

    useEffect(() => {
        painter.current = new XplPainter(props.isbusy);
        return () => {
            painter.current?.invalidate();
            painter.current = null;
        }
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    // useEffect(() => {
    //     setFront("");
    //     setBack("");
    // }, [props.urls.front, props.urls.back, setFront, setBack])

    useEffect(() => {
        (async function wait() {
            await painter.current?.init(props.urls, { front, back, frontB, backB, frontBA, backBA });
        })();
    }, [props.urls.front, props.urls.back, props.urls.xpl, props.urls.xplB, props.urls.frontB, props.urls.backB]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        painter.current?.render(props.settings);
    }, Object.values(props.settings)) // eslint-disable-line react-hooks/exhaustive-deps

    const imagePair = (!props.balanced || props.urls.xplB == null) ? simulation.images.analyzed : (!props.areas ? simulation.images.balanced : simulation.images.balancedWithAreas)
    if (imagePair.front && imagePair.back) return (
        <PCBViewer frontSrc={imagePair.front} backSrc={imagePair.back} allowUnlink={true} reverseIncoming={props.settings.b_flip_back !== 0} />
    )
    else return (<Box width="100%" height={{min:"400px"}} flex align="center" justify="center"><Spinner/></Box>);
}
// margin={{vertical:"200px"}}

export default observer(XplViewer);