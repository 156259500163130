import React, { useState } from 'react';
import { FlexBox as Box, ImageViewer, Text, Button, Spinner } from 'components';
import { useTranslations } from 'hooks';
import type { Transform } from 'components/ImageViewer';
import styled from 'styled-components';
import { normalizeColor } from 'grommet/utils';



type PCBViewerProps = {
  frontSrc?: string;
  backSrc?: string;
  frontOverlay?: string;
  backOverlay?: string;
  frontControls?: string | JSX.Element | React.ReactNode;
  backControls?: string | JSX.Element | React.ReactNode;
  allowUnlink?: boolean;
  flipBack?: boolean;
  reverseIncoming?: boolean;
  front_contour?: {
    left: number,
    right: number,
    top: number,
    bottom: number,
  };
  back_contour?: {
    left: number,
    right: number,
    top: number,
    bottom: number,
  };
};

const StyledLockWrapper = styled(Box)`
  position: relative;
  # margin-top: 8px;
  # margin-bottom: 24px;

  &:after {
    content: '';
    position: absolute;
    top: 30px;
    bottom: 24px;
    left: 50%;
    transform: translate(-0.5px, 0);
    border-right: 1px dotted ${({ theme }) => normalizeColor('brand', theme)};
  }
`;

const StyledButton = styled(Button)`
  transform: rotate(45deg);
  width: 30px;
  height: 30px;
`;

const LockToggle = ({
  lockTransform,
  toggleLock,
}: {
  lockTransform: boolean;
  toggleLock: (lock: boolean) => void;
}) => {
  return (
    <StyledLockWrapper
      direction="column"
      width={{ min: '10px', max: '10px' }}
      align="center"
      justify="end"
    >
      <StyledButton
        focusIndicator={false}
        icon={lockTransform ? 'linked-regular' : 'unlinked-regular'}
        plain={true}
        onClick={() => toggleLock(!lockTransform)}
      ></StyledButton>
    </StyledLockWrapper>
  );
};

const PCBViewer = ({
  frontSrc,
  backSrc,
  frontOverlay,
  backOverlay,
  frontControls,
  backControls,
  allowUnlink = false,
  flipBack,
  reverseIncoming,
  front_contour,
  back_contour,
}: PCBViewerProps) => {


  const t = useTranslations('pcb_viewer');
  const [frontTransform, setFrontTransform] = useState({ scale: 1, translation: { x: 0, y: 0 } });
  const [backTransform, setBackTransform] = useState({ scale: 1, translation: { x: 0, y: 0 } });
  const [lockTransform, setLockTransform] = useState(!allowUnlink);

  
  function reverseX(transform: Transform, width: number) {
    return {
      scale: transform.scale, 
      translation: {
        x: -(transform.scale - 1) * (width) - transform.translation.x,
        y: transform.translation.y
      }
    }
  }

  const handleFrontChange = (transform: Transform, width: number) => {
    setFrontTransform(transform);
    if (lockTransform) {
      setBackTransform((reverseIncoming || flipBack) ? reverseX(transform, width) : transform);
    }
  };

  const handleBackChange = (transform: Transform, width: number) => {
    setBackTransform(transform);
    if (lockTransform) {
      setFrontTransform((reverseIncoming || flipBack) ? reverseX(transform, width) : transform);
    }
  };

  const toggleLock = (lock: boolean) => {
    if (!lock) {
      setLockTransform(false);
    } else {
      setBackTransform(frontTransform);
      setLockTransform(true);
    }
  };

  if (frontControls == null) {
    frontControls = t('front');
  }

  if (backControls == null) {
    backControls = t('back');
  }

  return (
    <Box width="100%" height="100%" direction="column" gap="none" pad="medium" align="center">
      <Box width="100%" height="100%" direction="row" gap="none">
        <Box height="100%" direction="column">
          <Text as="label" size="medium" margin={{ bottom: 'small', horizontal: 'small' }} alignSelf="start">
            {frontControls}
          </Text>
          {frontSrc ? (
            <ImageViewer
              src={frontSrc}
              alt={t('front')}
              overlay={frontOverlay}
              transform={frontTransform}
              onChange={handleFrontChange}
              contour={front_contour}
            />
          ) : (
            <Box align="center" justify="center" height={{min: "344px"}}>
              {/* <Span>{t('placeholder', { what: t('front') })}</Span> */}
              <Spinner/>
            </Box>
          )}
        </Box>
        {allowUnlink && (<LockToggle lockTransform={lockTransform} toggleLock={toggleLock} />)}
        <Box height="100%" direction="column">
          <Text as="label" size="medium" margin={{ bottom: 'small', horizontal: 'small' }} alignSelf="end">
            {backControls}
          </Text>
          {backSrc ? (
            <ImageViewer
              src={backSrc}
              alt={t('back')}
              overlay={backOverlay}
              transform={backTransform}
              onChange={handleBackChange}
              flipX={flipBack}
              contour={back_contour}
            />
          ) : (
            <Box align="center" justify="center" height={{min: "344px"}}>
              {/* <Span>{t('placeholder', { what: t('back') })}</Span> */}
              <Spinner/>
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default PCBViewer;
