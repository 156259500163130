import React from 'react';
import { Box, Button, Heading } from 'components';
import AppChrome from 'components/AppChrome';
import { useTranslations, useTitle, usePageTitle } from 'hooks';
// import { useStartSimulation } from 'screens/simulation/hooks';
import { useNavigate } from 'utils';
import { Routes } from 'routes';
import { Paragraph, Text } from 'components';
// import banner from '../../assets/pcb_home.jpg';
import styled from 'styled-components';
import { useWindowSize } from 'hooks';
import { useStore } from 'store';
import { useToasts } from 'react-toast-notifications';


const StyledParagraph = styled(Paragraph)`
text-align: justify;
`
const HomeView = () => {
  const t = useTranslations('home');
  const n = useTranslations('navigation');
  useTitle(t('page_title'));
  usePageTitle(t('title'));
  const store = useStore();
  const { addToast } = useToasts();

  const [loading, setLoading] = React.useState(true);
  const [canCreate, setCanCreate] = React.useState(true);
  React.useEffect(() => {
    const getCanCreate = async () => {
      setLoading(true);
      setCanCreate(await store.canCreateNewProject());
      setLoading(false);
    }
    getCanCreate();
  }, [store.simulations.size, store]);

  const navigate = useNavigate();
  const onClick = () => {
    if (loading) return;
    if (canCreate) navigate(Routes.NewSimulation);
    else addToast(n('cant_create_new'), { appearance: 'warning' });
  };



  const mainBox = React.useRef<HTMLDivElement>(null);
  // const w = mainBox.current ? mainBox.current.clientWidth : 1600;

  const { windowWidth } = useWindowSize();
  const [w, setW] = React.useState<number>(1500);
  React.useLayoutEffect(() => {
    if (mainBox.current != null) {
      let cw = mainBox.current.clientWidth - 120;
      if (cw > 1000) setW(Math.min(cw / 2, 750));
      else setW(Math.min(cw, 750));
    }
  }, [windowWidth]);
  // const SmallVid = ({ url, topic, info }: { url: string, topic?: string, info?: string }) => {
  //   const ref = React.useRef<HTMLDivElement>(null);
  //   const [sw, setSw] = React.useState<number>(480);
  //   React.useLayoutEffect(() => {
  //     console.log("clientWidth changed: " + ref.current?.clientWidth)
  //     if (ref.current != null) {
  //       setSw(Math.min(ref.current.clientWidth, 480));
  //     }
  //   }, [windowWidth]);

  //   return (
  //     <Box direction="row" flex basis="1/3" pad="small" width={{ min: "245px" }} ref={ref}>
  //       <Box flex>
  //         <Box>
  //           {/* <iframe width={sw} height={sw * 0.5625} src={url} allow="autoplay; fullscreen; picture-in-picture" title="test1" /> */}
  //           <iframe width={480} height={300} src={url} allow="autoplay; fullscreen; picture-in-picture" title="test1" />
  //         </Box>
  //         {/* <Box flex align="start" justify="start" gap="small" pad="medium">
  //         <Heading level={4} textAlign="center">{topic}</Heading>
  //         <StyledParagraph>
  //           {info}
  //         </StyledParagraph>
  //       </Box> */}
  //       </Box>
  //     </Box>
  //   );
  // }

  // width={{ max: "1000px" }}
  return (
    <AppChrome>
      <Box align="center" justify="center" flex="grow" height="auto" ref={mainBox}>
        {/* <Box pad={{ horizontal: "20px", top: "20px" }}>
          <img src={banner} alt="Banner" style={{ width: "100%", height: "100%", objectFit: "cover", maxHeight: "400px" }} />
        </Box> */}
        <Box direction="column" flex="grow" align="center" justify="center" margin="60px" gap="xlarge" width={{ max: "1500" }} height="auto" wrap >
          <Box direction="row" align="center" justify="center" wrap flex>
            <Box direction="column" align="start" justify="start" pad={{
              right: ((mainBox.current?.clientWidth || 0) - 120) > 1000 ? "50px" : "small",
              left: "small",
              bottom: ((mainBox.current?.clientWidth || 0) - 120) < 1000 ? "50px" : "small",
              top: "small"
            }} gap="medium" width={`${w}px`}>
              <Heading level={2} margin={{ bottom: "medium" }}>How to get started?</Heading>
              <StyledParagraph size="large"> {t('info_1')} </StyledParagraph>
              <StyledParagraph size="large"> {t('info_2')} </StyledParagraph>
              <ul style={{ listStyleType: "circle", listStylePosition: "outside", paddingLeft: "3%", alignSelf: "left", width: "100%" }}>
                <Text as="li" size='large' margin={{ bottom: "small" }}>{t('bullet_1')}</Text>
                <Text as="li" size='large'>{t('bullet_2')}</Text>
              </ul>
              <Box width="100%" align="start" margin={{ top: "medium" }} direction="row" gap="medium">
                <Button size="large" onClick={onClick}>
                  {t('start_simulation')}
                </Button>
              </Box>
            </Box>
            <Box width={`${w}px`} align="start" justify="center">

              {/* <Box width="100%"> */}
              <iframe src="https://player.vimeo.com/video/533516826" width={w} height={w * 0.5625} allow="autoplay; fullscreen; picture-in-picture" title="test0" />
              {/* </Box> */}
            </Box>
          </Box>

          <Box align="start" justify="center" width="100%">
            <Heading level={2} margin={{ bottom: "medium" }}>Choose a topic to learn more:</Heading>

            <Box direction="row" wrap width={{ max: "1500px" }} flex>
              {/* <SmallVid url="https://player.vimeo.com/video/533519043" />
              <SmallVid url="https://player.vimeo.com/video/533519121" />
              <SmallVid url="https://player.vimeo.com/video/533519211" />
              <SmallVid url="https://player.vimeo.com/video/533519332" /> */}
              {/* fullscreen werkt niet wanneer je child element gebruikt ???*/}
              <Box pad="small"><iframe width={480} height={270} src="https://player.vimeo.com/video/533519043" allow="autoplay; fullscreen; picture-in-picture" title="test1" /></Box>
              <Box pad="small"><iframe width={480} height={270} src="https://player.vimeo.com/video/533519121" allow="autoplay; fullscreen; picture-in-picture" title="test1" /></Box>
              <Box pad="small"><iframe width={480} height={270} src="https://player.vimeo.com/video/533519211" allow="autoplay; fullscreen; picture-in-picture" title="test1" /></Box>
              <Box pad="small"><iframe width={480} height={270} src="https://player.vimeo.com/video/533519332" allow="autoplay; fullscreen; picture-in-picture" title="test1" /></Box>
              <Box pad="small"><iframe width={480} height={270} src="https://player.vimeo.com/video/533519424" allow="autoplay; fullscreen; picture-in-picture" title="test1" /></Box>
            </Box>
          </Box>
          <Box width="100%" wrap>
            <Box width={{ max: "600px" }} flex justify="between" direction="row" align="center">
              <Box direction="column" flex width={{ min: "200px" }} margin={{ bottom: "10px" }}>
                <Heading level={4}>Didn't find what you were looking for?</Heading>
                <Heading level={4}>How can we help?</Heading>
              </Box>
              <Button size="large" onClick={() => { window.open('mailto:info@elsyca.com?subject=Elsyca%20CuBE%20info%20request') }}>
                {t('ask')}
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
    </AppChrome>
  );
};



const Home = HomeView;

export default Home;
