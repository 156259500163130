import React from 'react';
import { Box, Button, Paragraph, FormikTextField,  } from 'components'; // passwordReqs
import { useTranslations } from 'hooks';
import { Formik, Form } from 'formik';
import * as yup from 'yup';

export type PasswordResetFormValues = { password: string; password_repeat: string };

export type PasswordResetFormFeedback = { general?: string } & {
  [K in keyof PasswordResetFormValues]?: string;
};

export type PasswordResetFormProps = {
  errors?: PasswordResetFormFeedback;
  initialValues: PasswordResetFormValues;
  onResetPassword: (values: PasswordResetFormValues) => void;
  busy?: boolean;
};

// import doesnt work TODO
const passwordLength = 8;
const passwordReqs = {
  length: passwordLength,
  regex: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*([^\w\s]|[_]))(?=.{8,})/ //eslint-disable-line
}

const schema = yup.object({
  password: yup.string().required('missing_field').min(passwordReqs?.length, 'password_too_short').matches(passwordReqs?.regex, 'weak_password'),
  password_repeat: yup.string().required('missing_field').oneOf([yup.ref('password')], 'passwords_dont_match'),
});

const PasswordResetForm = ({
  errors,
  initialValues,
  onResetPassword,
  busy,
}: PasswordResetFormProps) => {
  const t = useTranslations('login');

  return (
    <Box wrap direction="column" margin="none">
      {errors != null && errors['general'] != null && errors['general'].length > 0 && (
        <Paragraph color="status-critical">{errors.general}</Paragraph>
      )}
      <Formik
        initialValues={initialValues}
        onSubmit={onResetPassword}
        validationSchema={schema}
        validateOnBlur={true}
        validateOnChange={false}
      >
        <Form>
          <FormikTextField
            type="password"
            name="password"
            label={t('new_password_label')}
            error={errors?.password}
            required
            disabled={busy}
            placeholder={t('password_placeholder')}
          />
          <FormikTextField
            type="password"
            name="password_repeat"
            label={t('repeat_password_label')}
            disabled={busy}
            placeholder={t('password_placeholder')}
            required
          />

          <Box margin={{ top: 'medium' }}>
            <Button
              label={busy ? '' : t('update_password')}
              type="submit"
              primary
              busy={busy}
              disabled={busy}
              alignSelf="stretch"
            />
          </Box>
        </Form>
      </Formik>
    </Box>
  );
};

export default PasswordResetForm;
