import React from 'react';
import { Paragraph, Box, Heading, Footer, LanguageSwitcher, Link } from 'components';
import { useTranslations } from 'hooks';
import styled from 'styled-components';
import { darken, transparentize } from 'polished';
import { normalizeColor } from 'grommet/utils';
import { Background as backgroundImage, CuBELogoLight } from 'assets';

const GradientBox = styled(Box)`
  background: ${({ theme }) => normalizeColor('brand', theme)};
  background: linear-gradient(
      126deg,
      ${({ theme }) => transparentize(0.2, darken(0.2, normalizeColor('brand', theme)))} 0%,
      ${({ theme }) => normalizeColor('brand', theme)} 100%
    ),
    url(${backgroundImage});
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
`;

const SidebarWrapperBox = styled(Box)`
  display: flex;
  overflow-y: auto;
  position: relative;
`;

const SidebarCenteredBox = styled(Box)`
  flex-grow: 2;
  z-index: 1;

  & > div {
    margin-top: auto;
    margin-bottom: auto;
  }
`;

const LightHeading = styled(Heading)`
  font-size: 55px;
  line-height: 1.1;
  color: white;
`;

const LightParagraph = styled(Paragraph)`
  color: rgba(255, 255, 255, 0.8);
  font-size: 20px;
  line-height: 1.5;
`;

const LogoWrapper = styled(Box)`
  margin-top: 50px;
  display: flex;
  align-items: center;
`;

const LanguageSwitcherWrapper = styled(Box)`
  margin-bottom: 40px;
  display: flex;
  align-items: center;
`;

const AuthScreen = ({ children }: React.PropsWithChildren<{}>) => {
  const t = useTranslations('login');

  return (
    <Box direction="row" fill>
      <SidebarWrapperBox
        basis="25%"
        width={{ min: '400px', max: '600px' }}
        background="background-front"
        direction="column"
      >
        <LogoWrapper>
          <Link to="https://www.elsycacube.com">
            <CuBELogoLight width="300px" height="70px" />
          </Link>
        </LogoWrapper>

        <SidebarCenteredBox pad="medium">
          <div>{children}</div>
        </SidebarCenteredBox>

        <LanguageSwitcherWrapper>
          <LanguageSwitcher />
        </LanguageSwitcherWrapper>
      </SidebarWrapperBox>
      <GradientBox basis="auto" flex={{ grow: 1, shrink: 1 }} direction="column">
        <Box justify="center" flex="grow" direction="column" pad={{ left: 'large' }}>
          <LightHeading level="1">{t('welcome_message')}</LightHeading>
          <LightParagraph size="large" margin={{ top: 'medium' }}>
            {t('tagline')}
          </LightParagraph>
        </Box>
        <Box alignSelf="end" fill="horizontal">
          <Footer inverted={true} languageSwitcher={false} />
        </Box>
      </GradientBox>
    </Box>
  );
};

export default AuthScreen;
