import { client } from './client';
import { requestCustomerToken, callSync } from './backend';

export type CreditsData = {
  id: string;
  balance: number;
};

export type Customer = {
  id: string;
  name: string;
  email: string;
  phone?: string;
  address: {
    city: string;
    country: string;
    line1: string;
    line2: string;
    postal_code: string;
    state?: string;
  };
  metadata: {
    companyName?: string;
    tax_id?: string;
  };
  tax_id_data?: {
    type: string;
    value: string;
  };
}

export type Payment = {
  name: string;
  address: {
    city: string;
    country: string;
    line1: string;
    line2: string;
    postal_code: string;
    state?: string;
  };
  company: string;
  tax_id: string;
  amount: number;
  currency: string;
  date: string;
  nb_credits: number;
}

export async function get() {
  const { body } = await client.get<Array<CreditsData>>('/channels/credits/entries');
  return body;
}

export async function createPaymentIntent(customerId: string, amount: number, paymentData: any): Promise<string> {
  const token = await requestCustomerToken();
  if (token != null) {
    const response = await callSync(
      token,
      '/credits/payment',
      'POST',
      JSON.stringify({ amount: amount, ...paymentData }),
      'application/json'
    );

    if (response.ok) {
      const { secret } = await response.json();
      return secret;
    } else {
      throw new Error(`Couldn't get Stripe client secret from backend`);
    }
  } else {
    throw new Error(`Couldn't get token for the backend`);
  }
  // await client.delete(`/channels/sessions/entries/${id}`);
}

export async function updateCustomer(customer: Customer): Promise<any> {
  const token = await requestCustomerToken();
  if (token != null) {
    const response = await callSync(
      token,
      '/credits/customer',
      'POST',
      JSON.stringify(customer),
      'application/json'
    );

    if (response.ok) {
      const result = await response.json();
      return result;
    } else {
      throw new Error(`Couldn't update Stripe customer`);
    }
  } else {
    throw new Error(`Couldn't get token for the backend`);
  }
}

export async function customer() : Promise<Customer> {
  const token = await requestCustomerToken();
  if (token != null) {
    const response = await callSync(token, '/credits/customer');
    if (response.ok) {
      const customer : Customer = await response.json();
      return customer;
    } else {
      throw new Error(`Couldn't retrieve Stripe customer`);
    }
  } else {
    throw new Error(`Couldn't get token for the backend`);
  }
}

export async function paymentList(page : number): Promise<Payment[] | null> {
  const token = await requestCustomerToken();
  if (token != null) {
    const response = await callSync(token, `/credits/payments?page=${page}`);
    if (response.status === 401) throw new Error('Unauthorized');
    return await response.json() as Payment[] | null;
  }
  return null;
}
