import { Client } from '@nimbu/api';

let CLIENT_ID = process.env.NIMBU_CLIENT_ID!;
let TOKEN = process.env.NIMBU_TOKEN!;

export const client = new Client(CLIENT_ID);
export const anonymousClient = new Client(CLIENT_ID, TOKEN, { name: 'anonymous' });

if (process.env.NODE_ENV === 'development') {
  (window as any).client = client;
  (window as any).anonymousClient = anonymousClient;
}

export default client;
