import React from 'react';
import styled from 'styled-components';
import { normalizeColor } from 'grommet/utils';
import { ColorType } from 'components/types';

import '../../assets/fontello/css/pcbbalance.css';

// generated with:
// cat ./src/assets/fontello/config.json | jq '.glyphs[].css' | sort

export const iconNames = [
  'angle-down-solid',
  'angle-left-solid',
  'angle-right-solid',
  'angle-up-solid',
  'archive-light',
  'arrow-circle-down-regular',
  'badge-check-light',
  'badge-check-regular',
  'badge-check-solid',
  'caret-down-solid',
  'caret-left-solid',
  'caret-right-solid',
  'caret-up-solid',
  'cart-plus-regular',
  'check-circle-light',
  'check-circle-regular',
  'check-circle-solid',
  'check-regular',
  'check-small',
  'check',
  'chevron-circle-down-regular',
  'close-small',
  'close',
  'cloud-download-alt-regular',
  'cloud-upload-alt-regular',
  'copy-light',
  'download-light',
  'duplicate',
  'ellipsis-h-light',
  'ellipsis-h-regular',
  'ellipsis-h-solid',
  'ellipsis-v-light',
  'ellipsis-v-regular',
  'ellipsis-v-solid',
  'file-archive-light',
  'file-download-regular',
  'file-import-regular',
  'flask-solid',
  'folder-light',
  'folder',
  'info-circle-light',
  'info-circle-regular',
  'info-circle-solid',
  'linked-light',
  'linked-regular',
  'linked-solid',
  'mail',
  'minus-circle-light',
  'minus-circle-regular',
  'minus-circle-solid',
  'minus-hexagon-light',
  'minus-hexagon-regular',
  'minus-hexagon-solid',
  'new-folder',
  'open',
  'plus-light',
  'plus-solid',
  'question-circle-light',
  'question-circle-regular',
  'question-circle-solid',
  'save',
  'search-light',
  'search-minus-light',
  'search-minus-regular',
  'search-minus-solid',
  'search-plus-light',
  'search-plus-regular',
  'search-plus-solid',
  'search-regular',
  'search-solid',
  'settings',
  'shredder-light',
  'shredder-regular',
  'shredder-solid',
  'sign-out-solid',
  'sliders-h-regular',
  'sliders-v-regular',
  'sliders-v-square-regular',
  'times-circle-light',
  'times-circle-regular',
  'times-circle-solid',
  'times-light',
  'times-regular',
  'times-solid',
  'times-square-regular',
  'trash-alt-light',
  'trash-alt-regular',
  'trash-alt-solid',
  'twitter',
  'unlinked-light',
  'unlinked-regular',
  'unlinked-solid',
  'user-circle-light',
  'user-circle-regular',
  'user-circle-solid',
  'user-light',
  'user-regular',
  'user-solid',
  'view',
  'web',
  'wrench-outline',
  'undo',
  'lock-open-alt',
  'home-outline'
] as const;

export type IconName = typeof iconNames[number];

export type IconProps = {
  name: IconName;
  size?: string;
  color?: ColorType;
  opacity?: number;
};


const StyledIcon = styled.i.attrs(({ size, color, opacity }: { size: number; color: ColorType; opacity: number; }) => ({
  size: size || '15px',
  color: color || 'text',
}))`
  :hover {
    opacity: 1;
  }
  :active {
    color: #FFF;
  }
  opacity: ${({opacity = 1}) => opacity};
  font-size: ${({ size = '15px', theme }) =>
    theme.text != null && theme.text[size] != null ? theme.text[size].size : size};
  color: ${({ color, theme }) => normalizeColor(color || 'text', theme)};
`;

const Icon = ({ name, size, color, opacity }: IconProps) => {
  return <StyledIcon className={`icon-${name}`} size={size} color={color} opacity={opacity} />;
};

Icon.Styles = StyledIcon;

export default Icon;
