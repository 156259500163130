import React from 'react';
import { Box, FormikTextField, Paragraph, Button,  } from 'components'; // passwordReqs
import { useTranslations } from 'hooks';
import { Formik, Form } from 'formik';

import * as yup from 'yup';

export type SecurityFormFields = {
  email: string;
  password: string;
  password_repeat: string;
  password_current: string;
};

export type SecurityFormFeedback = Partial<
  { [K in keyof SecurityFormFields]: string } & { general?: string }
>;
export type SecurityFormHints = Partial<{ [K in keyof SecurityFormFields]: string }>;

// import doesnt work TODO
const passwordLength = 8;
const passwordReqs = {
  length: passwordLength,
  regex: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*([^\w\s]|[_]))(?=.{8,})/ //eslint-disable-line
}

// TODO: Find a way to use the ${min} interpolation of yup iso hardcoding 'password_too_short'
const schema = yup.object({
  email: yup.string().required('missing_field').email('invalid_email'),
  password_current: yup.string().required('missing_field').min(passwordReqs?.length, 'password_too_short'),
  password: yup.string().required('missing_field').min(passwordReqs?.length, 'password_too_short').matches(passwordReqs?.regex, 'weak_password'),
  password_repeat: yup.string().required('missing_field').oneOf([yup.ref('password')], 'passwords_dont_match'),
});

export type SecurityFormProps = {
  errors?: SecurityFormFeedback;
  hints?: SecurityFormHints;
  busy?: boolean;
  onSubmit: (values: SecurityFormFields) => void;
  initialValues: SecurityFormFields;
};

const SecurityForm = ({ errors, hints, busy, onSubmit, initialValues }: SecurityFormProps) => {
  const t = useTranslations('account');

  return (
    <>
      {errors?.general != null && errors.general.length > 0 && (
        <Paragraph color="status-critical">{errors.general}</Paragraph>
      )}
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={schema}
        validateOnBlur={true}
        validateOnChange={false}
      >
        <Form>
          <Box direction="row" fill="horizontal" gap="medium">
            <Box flex>
              <FormikTextField
                type="email"
                name="email"
                label={t('email_label')}
                placeholder={t('email_placeholder')}
                disabled={busy}
                error={errors?.email}
                hint={hints?.email}
                required
              />
            </Box>
            <Box flex>
              <FormikTextField
                type="password"
                name="password_current"
                label={t('password_current_label')}
                placeholder={t('password_placeholder')}
                error={errors?.password_current}
                hint={hints?.password_current}
                disabled={busy}
                required
              />
            </Box>
          </Box>
          <Box direction="row" fill="horizontal" gap="medium">
            <Box flex>
              <FormikTextField
                type="password"
                name="password"
                label={t('password_label')}
                placeholder={t('password_placeholder')}
                error={errors?.password}
                hint={hints?.password}
                disabled={busy}
              />
            </Box>
            <Box flex>
              <FormikTextField
                type="password"
                name="password_repeat"
                label={t('password_repeat_label')}
                placeholder={t('password_placeholder')}
                error={errors?.password_repeat}
                hint={hints?.password_repeat}
                disabled={busy}
              />
            </Box>
          </Box>
          <Box margin={{ top: 'medium' }}>
            <Button
              type="submit"
              label={busy ? '' : t('update_security_info')}
              alignSelf="end"
              busy={busy}
              disabled={busy}
              primary
            />
          </Box>
        </Form>
      </Formik>
    </>
  );
};

export default SecurityForm;
