import { types } from 'mobx-state-tree';
import { DateTime } from 'luxon';

export const ObjectBase = types
  .model('ObjectBase', {
    id: types.identifier,
    updatedAt: types.string,
    createdAt: types.string,
  })
  .views((self) => ({
    get updated() {
      return DateTime.fromISO(self.updatedAt);
    },
    get created() {
      return DateTime.fromISO(self.createdAt);
    },
  }));
