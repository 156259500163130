import React from 'react';
import styled from 'styled-components';

import {
    Box,
    Span,
} from 'components';


const UpperCaseText = styled(Span)`
  text-transform: uppercase;
  width: auto;
`;

export const RightBox = styled(Box)`
  margin-left: auto;
`;

const StyledBox = styled(Box)`
  overflow: visible;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
`

export type FieldSetHeaderProps = {
    title: string,
    children?: React.ReactNode | undefined,
    // setting: JSX.Element | null
};

const FieldSetHeader = ({
    title,
    children
    // setting,
}: FieldSetHeaderProps) => {
    return (
        <StyledBox height="52px">
            <UpperCaseText>{title}</UpperCaseText>
            <RightBox>{children}</RightBox>
        </StyledBox>
    )
}

export default FieldSetHeader;