import React from 'react';
import { Box } from 'grommet';
import { normalizeColor } from 'grommet/utils';
import styled from 'styled-components';
import { MarginType, PadType } from 'components/types';

const SpinningCircle = ({ size = 28 }: { size?: number }) => (
  <svg version="1.1" viewBox="0 0 32 32" width={`${size}px`} height={`${size}px`}>
    <path
      opacity=".25"
      d="M16 0 A16 16 0 0 0 16 32 A16 16 0 0 0 16 0 M16 4 A12 12 0 0 1 16 28 A12 12 0 0 1 16 4"
    />
    <path d="M16 0 A16 16 0 0 1 32 16 L28 16 A12 12 0 0 0 16 4z">
      <animateTransform
        attributeName="transform"
        type="rotate"
        from="0 16 16"
        to="360 16 16"
        dur="0.8s"
        repeatCount="indefinite"
      />
    </path>
  </svg>
);

const StyledWrapper = styled(Box)`
  svg {
    fill: ${({ theme, color }) => normalizeColor(color || 'brand', theme)};
  }
`;

type SpinnerProps = {
  color?: string;
  margin?: MarginType;
  pad?: PadType;
  size?: number;
};

const Spinner = ({ size, color, margin, pad }: SpinnerProps) => {
  return (
    <StyledWrapper align="center" justify="center" margin={margin} pad={pad} color={color}>
      <SpinningCircle size={size} />
    </StyledWrapper>
  );
};

export default Spinner;
