import React from 'react';
import { Anchor, AnchorProps } from 'grommet';
import { Routes, isRoute } from 'routes';
import { RouterState, useRouterStore, RouterLink, RouterLinkProps } from 'mobx-state-router';
import styled, { css } from 'styled-components';
import { normalizeColor } from 'grommet/utils';
import { transparentize, darken, lighten } from 'polished';

type RoutedButtonProps = React.PropsWithChildren<{
  onClick?: (e: React.MouseEvent) => void;
  to?: Routes | string;
  params?: RouterState['params'];
  queryParams?: RouterState['queryParams'];
  primary?: boolean;
  disabled?: boolean;
  active?: boolean;
}>;

const AnchorWithIsActive = ({
  isActive,
  ...rest
}: { isActive: boolean } & React.PropsWithChildren<
  AnchorProps & Omit<JSX.IntrinsicElements['a'], 'color'>
>) => <Anchor {...rest} />;

const StyledAnchor = styled(AnchorWithIsActive)`  
    font-weight: 400;
    span {
      max-width: 100%;
      word-break: break-word;
      text-align: center;
      hyphens: auto;
      padding: 0 2px;
    }

    &:hover {
      background-color: ${({ theme }) => transparentize(0.95, normalizeColor('brand', theme))};
      text-decoration: none;
    }

      ${(props) =>
    props.isActive &&
    css`
      background-color: ${({ theme }) => transparentize(0.88, normalizeColor('brand', theme))};
      color: ${({ theme }) =>
        darken(
          theme.dark ? 0 : 0.3,
          lighten(theme.dark ? 0.3 : 0, normalizeColor('brand', theme))
        )};

      &:hover {
        background-color: ${({ theme }) => transparentize(0.88, normalizeColor('brand', theme))};
        text-decoration: none;
      }

      i,
      span {
        color: ${({ theme }) =>
        lighten(
          theme.dark ? 0.5 : 0,
          darken(theme.dark ? 0 : 0.2, normalizeColor('brand', theme))
        )};
      }
    `}
`;

const RouterLinkWithIsActive = ({ active, ...rest }: { active: boolean } & RouterLinkProps) => (
  <RouterLink {...rest} />
);

const StyledRouterLink = styled(RouterLinkWithIsActive)`
  text-decoration: none;

  span {
    max-width: 100%;
    word-break: break-word;
    text-align: center;
    hyphens: auto;
    padding: 0 2px;
  }

  &:hover {
    background-color: ${({ theme }) => transparentize(0.95, normalizeColor('brand', theme))};
  }

  ${(props) =>
    props.active &&
    css`
      background-color: ${({ theme }) => transparentize(0.88, normalizeColor('brand', theme))};
      color: ${({ theme }) =>
        darken(
          theme.dark ? 0 : 0.3,
          lighten(theme.dark ? 0.3 : 0, normalizeColor('brand', theme))
        )};

      &:hover {
        background-color: ${({ theme }) => transparentize(0.88, normalizeColor('brand', theme))};
      }

      i,
      span {
        color: ${({ theme }) =>
        lighten(
          theme.dark ? 0.5 : 0,
          darken(theme.dark ? 0 : 0.2, normalizeColor('brand', theme))
        )};
      }
    `}
`;

const RoutedButton = ({ to, params, queryParams, active, ...rest }: RoutedButtonProps) => {
  const routerStore = useRouterStore();
  const { routerState } = routerStore;
  const route = isRoute(to);
  const isActive = active || (route && routerState.routeName === to) ||
    (route && to === Routes.Simulation as string && ([Routes.UploadSources, Routes.Analyze, Routes.Balance, Routes.Preview] as string[]).includes(routerState.routeName));

  if (to != null && route) {
    return (
      <StyledRouterLink
        routeName={to}
        params={params}
        queryParams={queryParams}
        active={!!isActive}
        {...rest}
      />
    );
  } else {
    return <StyledAnchor isActive={!!isActive} href={to} {...rest}/>;
  }
};

export default RoutedButton;
