import React from 'react';
import RSelect, { ValueType } from 'react-select';
import styled from 'styled-components';
import { normalizeColor } from 'grommet/utils';
import { desaturate, transparentize, darken, lighten } from 'polished';
import { PlaceHolderType } from 'components/types';

const StyledRSelect = styled(RSelect)`
  flex: 1 1 0;
  height:100%;
  .rs__control {
    min-height: 30px;
    font-family: ${(props) => props.theme.global.font.family};
    font-size: ${(props) => props.theme.global.font.size};
    background-color: ${(props) => normalizeColor('white', props.theme)};
    color: ${(props) => normalizeColor('text', props.theme)};
    border-color: ${(props) => normalizeColor('border', props.theme)};
    font-weight: 500;
    background-color: ${(props) =>
      normalizeColor(props.theme.global.input.background, props.theme)}}
  }

  .rs__indicators {
    height: 28px;
  }

  .rs__value-container {
    height: 24px;

    > div {
      margin: 0px;
      padding: 0px;
    }
  }

  .rs__input {
    height: 20px;

    > input {
      font: inherit;
    }
  }

  .rs__single-value {
    #top: 60%;
    color: ${({ theme }) => normalizeColor('text', theme)};
  }

  .rs__menu {
    background-color: ${({ theme }) => normalizeColor(theme.global.input.background, theme)}}
  }

  .rs__control--is-focused,
  .rs__control--is-focused:hover {
    border-radius: 4px;
    border-color: ${({ theme }) =>
      `${lighten(0.1, desaturate(0.4, normalizeColor('brand', theme)))}`};
    outline-color: ${({ theme }) => normalizeColor('focus', theme)};
    box-shadow: 0 0 2px 2px ${({ theme }) => normalizeColor('focus', theme)};
  }

  .rs__option.rs__option--is-focused {
    background-color: ${({ theme }) => transparentize(0.8, normalizeColor('brand', theme))};
  }

  .rs__option.rs__option--is-selected,
  .rs__option.rs__option--is-selected.rs__option--is-focused {
    background-color: ${({ theme }) => darken(0.1, normalizeColor('brand', theme))};
  }

  .rs__multi-value {
    background-color: ${({ theme }) =>
      lighten(theme.dark ? 0.1 : 0, normalizeColor('background-contrast', theme))};
    color: ${({ theme }) => normalizeColor('text', theme)};
  }

  .rs__multi-value__label, .rs__input {
    color: ${({ theme }) => normalizeColor('text', theme)};
  }
`;

export type SelectOptionValue = string | number;

export type SelectOption<T extends SelectOptionValue> = {
  label: string;
  value: T;
};

type CommonSelectProps<T extends SelectOptionValue> = {
  disabled?: boolean;
  clearable?: boolean;
  searchable?: boolean;
  placeholder?: PlaceHolderType;
  name?: string;
  options: SelectOption<T>[];
  onBlur?: (e: React.FocusEvent<any>) => void;
};

type SingleSelectProps<T extends SelectOptionValue> = CommonSelectProps<T> & {
  multiple?: false;
  value?: SelectOption<T>;
  onChange?: (value?: SelectOption<T>) => void;
};

type MultiSelectProps<T extends SelectOptionValue> = CommonSelectProps<T> & {
  multiple: true;
  value?: SelectOption<T>[];
  onChange?: (value?: SelectOption<T>[]) => void;
};

function Select<T extends SelectOptionValue>(props: SingleSelectProps<T>): JSX.Element;
function Select<T extends SelectOptionValue>(props: MultiSelectProps<T>): JSX.Element;
function Select<T extends SelectOptionValue>({
  disabled = false,
  clearable = true,
  searchable = true,
  placeholder,
  value,
  options,
  multiple,
  onChange,
  ...rest
}: SingleSelectProps<T> | MultiSelectProps<T>) {
  return (
    <StyledRSelect
      value={value || null}
      onChange={(value: ValueType<SelectOption<T>>) => {
        if (onChange != null) {
          // I don't know what TS is doing here. onChange above is:
          // (value?: SelectOption<T>) => void | (value?: SelectOption<T>[]) => void | undefined
          // The if turns it into:
          // (value: (SelectOption<T> & SelectOption<T>[]) | undefined) => void
          // Which makes no sense
          //
          // Also: the typings of react-select make no distinction between the types
          // of the onChange in single- and multi-select cases, which makes it
          // cumbersome.
          //
          // -> let's resort to as any
          onChange((value as any) || undefined);
        }
      }}
      isDisabled={disabled}
      isClearable={clearable}
      isSearchable={searchable}
      options={options}
      isMulti={multiple}
      placeholder={placeholder}
      classNamePrefix="rs"
      {...rest}
      styles={{
        control: (base : any) => ({
          ...base,
          height: '100%'
        })
      }}
    
    ></StyledRSelect>
  );
}

export default Select;
