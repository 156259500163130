import { client } from './client';

let OPENFAAS_URL: string;
let OPENFAAS_FUNCTION: string;

if (process.env.ELSYCA_ENV === 'development') {
  OPENFAAS_URL = '';
  OPENFAAS_FUNCTION = 'pcbbalance';
} else if (process.env.ELSYCA_ENV === 'staging') {
  OPENFAAS_URL = 'https://fn.pcbbalance.elsyca.com';
  OPENFAAS_FUNCTION = 'pcbbalance-staging';
} else {
  OPENFAAS_URL = 'https://fn.app.elsycacube.com';
  OPENFAAS_FUNCTION = 'cube-production';
}

export async function requestToken(uuid: string, id?: string) {
  try {
    const response = await client.post<{ result: string }>('/functions/get_token', { uuid, id });
    return response?.body?.result;
  } catch (err) {
    console.log(err);
    return undefined;
  }
}

export async function requestCustomerToken() {
  try {
    const response = await client.post<{ result: string }>('/functions/get_customer_token', {});
    return response?.body?.result;
  } catch (err) {
    return undefined;
  }
}

function call(
  token: string,
  path: string,
  method = 'GET',
  body?: RequestInit['body'],
  contentType?: string
) {
  const headers: RequestInit['headers'] = {
    Authorization: token,
  };
  if (contentType != null) {
    headers['Content-Type'] = contentType;
  }
  return fetch(`${OPENFAAS_URL}${path}`, {
    method,
    headers,
    body,
  });
}

export function callAsync(
  token: string,
  path: string,
  body?: RequestInit['body'],
  contentType?: string
) {
  // POST is required for async calls of functions in OpenFaaS
  return call(token, `/async-function/${OPENFAAS_FUNCTION}${path}`, 'POST', body, contentType);
}

export function callSync(
  token: string,
  path: string,
  method = 'GET',
  body?: RequestInit['body'],
  contentType?: string
) {
  return call(token, `/function/${OPENFAAS_FUNCTION}${path}`, method, body, contentType);
}
