import React from 'react';
import { Heading as GrommetHeading } from 'grommet';
import {
  HeadingLevel,
  ColorType,
  AlignSelfType,
  MarginType,
  TextAlignType,
} from 'components/types';

type HeadingProps = React.PropsWithChildren<{
  alignSelf?: AlignSelfType;
  color?: ColorType;
  level?: HeadingLevel;
  margin?: MarginType;
  responsive?: boolean;
  size?: 'small' | 'medium' | 'large' | 'xlarge' | string;
  textAlign?: TextAlignType;
  truncate?: boolean;
}>;

const Heading = (props: HeadingProps) => {
  return <GrommetHeading {...props} />;
};

export default Heading;
