import { XplRenderData, BalanceRenderData } from './PanelData'
import { mat4, vec3 } from 'gl-matrix'
import { scale, rotate, translate, mul, Z_AXIS, map3 } from './util' // Y_AXIS

const PANELDEPTH = 0.1;

export type coordinateValue = {
    x: number,
    y: number,
    val: number,
}

export default class Balance {
    panelWidth: number = 0;
    panelHeight: number = 0;
    rdata?: BalanceRenderData;

    startX: number = 0;
    startY: number = 0;
    endX: number = 0;
    endY: number = 0;
    val: number = 0;
    nbSuccessive: number = 0;

    meshSize: number = 0;
    meshSizeX: number = 0;
    meshSizeY: number = 0;
    points: number[] = [];
    triangles: number[] = [];

    depth: number = 0;
    isFront: boolean = true;

    updateCopperBalancing(data: XplRenderData, isFront: boolean) {
        if (data.max == null
            || data.min == null
            || data.balanceRenderData == null) return;

        this.rdata = data.balanceRenderData;

        if (this.rdata.nbCols == null
            || this.rdata.nbRows == null
            || this.rdata.cellValues == null) return;

        this.isFront = isFront;

        this.panelWidth = data.max[0] - data.min[0];
        this.panelHeight = data.max[1] - data.min[1];

        this.triangles = [];
        this.points = [];
        this.rdata.objects = [];
        this.nbSuccessive = 0;

        this.meshSizeX = 1.0 * this.panelWidth / this.rdata.nbCols;
        this.meshSizeY = 1.0 * this.panelHeight / this.rdata.nbRows;
        this.meshSize = (this.meshSizeX + this.meshSizeY) / 2.0;

        this.depth = isFront ? PANELDEPTH : -PANELDEPTH;

        let coordinateValues: coordinateValue[] = [];
        for (let row = 0; row < this.rdata.nbRows; ++row) {
            for (let col = 0; col < this.rdata.nbCols; ++col) {
                let c: coordinateValue = { x: 0, y: 0, val: 0 }
                c.val = this.rdata.cellValues[row * this.rdata.nbCols + col]
                if (c.val > 0.0) {
                    c.x = 1.0 * (isFront ? col : (this.rdata.nbCols - col - 1)) * this.meshSizeX + this.meshSizeX / 2.0;
                    c.y = 1.0 * (this.rdata.nbRows - row - 1) * this.meshSizeY + this.meshSizeY / 2.0; // why isfront is not -1? (isFront ? 1 : 0)
                    coordinateValues.push(c);
                }
            }
        }

        if (coordinateValues.length > 0) {
            this.points = [];
            this.triangles = [];

            this.startX = coordinateValues[0].x;
            this.startY = coordinateValues[0].y;
            this.endX = coordinateValues[0].x;
            this.endY = coordinateValues[0].y;
            this.val = coordinateValues[0].val;

            this.nbSuccessive = 1.0;

            // for (let i = 1; i < coordinateValues.length; ++i) {
            //     let c = coordinateValues[i];
            //     if (Math.abs(c.y - this.endY) < DELTA
            //         && Math.abs(Math.abs(c.x - this.endX) - this.meshSize) < DELTA
            //         && Math.abs(1.0 - c.val) < Number.EPSILON) {
            //         this.endX = c.x;
            //         this.nbSuccessive += 1.0;
            //     } else {
            //         Math.abs(1.0 - this.val) < Number.EPSILON
            //             ? this._addRect()
            //             : this._addCross();

            //         // New starting coordinate
            //         this.startX = c.x;
            //         this.startY = c.y;
            //         this.endX = c.x;
            //         this.endY = c.y;
            //         this.val = c.val;
            //         this.nbSuccessive = 1.0;
            //     }
            // }

            // Math.abs(1.0 - this.val) < Number.EPSILON
            //     ? this._addRect()
            //     : this._addCross();

            for (let i = 0; i < coordinateValues.length; ++i) {
                this._addCircle(coordinateValues[i]);
            }

            this._pushObject();

            // rdata.points = new Float32Array(this.points);
            // rdata.normals = new Float32Array(rdata.points.length);
            // let normal = [0.0, 0.0, isFront ? 1.0 : -1.0];
            // for (let i = 0; i < rdata.points.length / 3; ++i) {
            //     rdata.normals.set(normal, i * 3);
            // }
            // rdata.triangles = new Uint16Array(this.triangles);
        }
    }

    _pushObject() {
        // create normals array
        let normals = new Float32Array(this.points.length);
        let normal = [0.0, 0.0, this.isFront ? 1.0 : -1.0];
        for (let i = 0; i < this.points.length / 3; ++i) {
            normals.set(normal, i * 3);
        }

        // push object
        this.rdata?.objects?.push({
            points: new Float32Array(this.points),
            normals,
            triangles: new Uint16Array(this.triangles)
        })

        // reset local arrays
        this.points = [];
        this.triangles = [];
    }

    _addRect() {
        let halfX = (this.startX + this.endX) / 2.0;
        let matrixScale = scale(mat4.create(), vec3.fromValues(this.nbSuccessive, 1.0, 1.0));
        // let matrixRotate = rotate(mat4.create(), Math.PI / 2, Y_AXIS); // * (this.isFront ? 1.0 : -1.0)
        let matrixTranslate = translate(mat4.create(), vec3.fromValues(halfX - this.panelWidth / 2.0, this.startY - this.panelHeight / 2.0, this.depth * 1.1));
        this._addRectData(mul(matrixTranslate, matrixScale));
    }

    _addCircle(c: coordinateValue) {
        let matrixTranslate = translate(mat4.create(), vec3.fromValues(c.x - this.panelWidth / 2.0, c.y - this.panelHeight / 2.0, this.depth * 1.1));
        this._addCircleData(c, matrixTranslate);
    }

    _addCross() {
        let scaleFactor = (1.0 - Math.sqrt(1.0 - this.val)) / 2.0;
        let scaleX = Math.sqrt(2.0) * scaleFactor;
        let scaleY = Math.sqrt(2.0) * (1.0 - scaleFactor);

        // SQUARE
        let matrixScale = scale(mat4.create(), vec3.fromValues(scaleY, scaleX, 1.0));
        // let matrixRotate2 = rotate(mat4.create(), Math.PI / 2 * (this.isFront ? 1.0 : -1.0), vec3.fromValues(1.0, 0.0, 0.0));
        let matrixTransl = translate(mat4.create(), vec3.fromValues(this.startX - this.panelWidth / 2.0, this.startY - this.panelHeight / 2.0, 0.0));//this.depth * 1.1));

        // - x
        let matrixRotateX = rotate(mat4.create(), Math.PI / 4, Z_AXIS);
        this._addRectData(mul(matrixTransl, matrixRotateX, matrixScale));

        // // - y
        let matrixRotateY = rotate(mat4.create(), -Math.PI / 4, Z_AXIS);
        this._addRectData(mul(matrixTransl, matrixRotateY, matrixScale));

        // TRIANGLES
        let matrixScaleTri = scale(mat4.create(), vec3.fromValues(scaleFactor, scaleFactor, 1.0));
        // let matrixRotate2Tri = mat4.create();//this.isFront ? mat4.create() : rotate(mat4.create(), Math.PI, Z_AXIS);
        let matrixTranslTriLT = translate(mat4.create(), vec3.fromValues(
            this.startX - this.panelWidth / 2.0 - this.meshSize / 2.0,
            this.startY - this.panelHeight / 2.0 + this.meshSize / 2.0,
            0.0
        ));
        let matrixTranslTriRT = translate(mat4.create(), vec3.fromValues(
            this.startX - this.panelWidth / 2.0 + this.meshSize / 2.0,
            this.startY - this.panelHeight / 2.0 + this.meshSize / 2.0,
            0.0
        ));
        let matrixTranslTriLB = translate(mat4.create(), vec3.fromValues(
            this.startX - this.panelWidth / 2.0 - this.meshSize / 2.0,
            this.startY - this.panelHeight / 2.0 - this.meshSize / 2.0,
            0.0
        ));
        let matrixTranslTriRB = translate(mat4.create(), vec3.fromValues(
            this.startX - this.panelWidth / 2.0 + this.meshSize / 2.0,
            this.startY - this.panelHeight / 2.0 - this.meshSize / 2.0,
            0.0
        ));

        // - tri lt
        let matrixRotateTriLT = rotate(mat4.create(), 0, Z_AXIS);
        this._addTriangleData(mul(matrixTranslTriLT, matrixRotateTriLT, matrixScaleTri)); //mul(matrixTranslTriL, matrixRotateTriLT, matrixScaleTri));

        // - tri rt
        let matrixRotateTriRT = rotate(mat4.create(), -Math.PI / 2, Z_AXIS);
        this._addTriangleData(mul(matrixTranslTriRT, matrixRotateTriRT, matrixScaleTri));

        // - tri lb
        let matrixRotateTriLB = rotate(mat4.create(), Math.PI / 2, Z_AXIS);
        this._addTriangleData(mul(matrixTranslTriLB, matrixRotateTriLB, matrixScaleTri));

        // - tri rb
        let matrixRotateTriRB = rotate(mat4.create(), Math.PI, Z_AXIS);
        this._addTriangleData(mul(matrixTranslTriRB, matrixRotateTriRB, matrixScaleTri));
    }
    done = false;
    _addCircleData(c: coordinateValue, m: mat4) {
        const RESOLUTION = 16;
        
        const radius = this.meshSize * Math.sqrt(Math.min(c.val, 0.7)/Math.PI);
        
        // split object into multiple parts if the point index would go above uint16 limit
        // point index (3 coos per point: length/3)
        // + # points to add (= resolution)
        // - 1 (0-based indexing)
        if ((this.points.length / 3 + RESOLUTION -1) > 65535) {
            // console.log('multi object');
            this._pushObject();
        }

        // add points
        for (let i = 0; i < RESOLUTION; ++i) {
            // angle in radians: 2PI = 360d, divided into {RESOLUTION} equal parts
            const angle = 2*Math.PI*i/RESOLUTION;
            const x = Math.cos(angle) * radius;
            const y = Math.sin(angle) * radius;
            
            // add a triangle starting from point 0 to this point and the next adjacent point
            // dont do this for the first point, cause the first point == point 0
            // dont do this for the last point cause the next adjacent point == point 0
            if (i > 0 && i < RESOLUTION-1) this._addTriangle(-i, 0, 1);
            this._addPoint(map3(vec3.fromValues(x, y, 0), m))
        }
        this.done = true;
    }

    _addRectData(m: mat4) {
        // let leftTop = map3(vec3.fromValues(-this.meshSize / 2.0, 0.0, -this.meshSize / 2.0), m);
        // let leftBottom = map3(vec3.fromValues(-this.meshSize / 2.0, 0.0, this.meshSize / 2.0), m);
        // let rightTop = map3(vec3.fromValues(this.meshSize / 2.0, 0.0, -this.meshSize / 2.0), m);
        // let rightBottom = map3(vec3.fromValues(this.meshSize / 2.0, 0.0, this.meshSize / 2.0), m);
        let size = this.meshSize / 2.0;
        let leftTop = map3(vec3.fromValues(-size, -size, 0.0), m);
        let leftBottom = map3(vec3.fromValues(-size, size, 0.0), m);
        let rightTop = map3(vec3.fromValues(size, -size, 0.0), m);
        let rightBottom = map3(vec3.fromValues(size, size, 0.0), m);


        if ((this.points.length / 3 + 4 - 1) > 65535) this._pushObject();

        this._addTriangle(0, 1, 2);
        this._addTriangle(0, 2, 3);
        this._addPoints(leftTop, leftBottom, rightBottom, rightTop);
    }

    _addTriangleData(m: mat4) {
        let size = this.meshSize;
        let top = map3(vec3.fromValues(0.0, -size, 0.0), m);
        let left = map3(vec3.fromValues(size, 0.0, 0.0), m);
        let right = map3(vec3.fromValues(0.0, 0.0, 0.0), m);

        if ((this.points.length / 3 + 3 - 1) > 65535) this._pushObject();

        this._addTriangle(0, 1, 2);
        this._addPoints(top, left, right);

    }

    _addPoint(v: vec3) {
        for (let i = 0; i < 3; ++i) { this.points.push(v[i]); }
    }

    _addPoints(...v: vec3[]) {
        for (let i = 0; i < v.length; ++i) {
            this._addPoint(v[i]);
        }
    }

    _addTriangle(a: number, b: number, c: number) {
        this.triangles.push(this.points.length / 3 + a)
        this.triangles.push(this.points.length / 3 + b)
        this.triangles.push(this.points.length / 3 + c)
    }
}