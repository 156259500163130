import { Setting, Dimension, ValueWithUnit } from 'settings';

// TODO: what is the difference between BalancingReady and Finished?
// Order of these keys is important
export enum SimulationState {
  New = 'new',
  Uploading = 'uploading',
  UploadError = 'upload_error',
  GeneratingPreviews = 'generating_previews',
  CreateContour = 'create_contour',
  PreviewsError = 'previews_error',
  SizeError = 'size_error',
  SizeEqualityError = 'size_equality_error',
  PreviewsReady = 'previews_ready',
  PreparingAnalysis = 'preparing_analysis',
  AnalysisQueuing = 'analysis_queuing', // only a state in the front-end => used to avoid UI glitches in  intermediary state between unlocking and starting the analysis
  AnalysisQueued = 'analysis_queued',
  Analyzing = 'analyzing',
  AnalysisError = 'analysis_error',
  AnalysisReady = 'analysis_ready',
  PreparingBalancing = 'preparing_balancing',
  BalancingQueuing = 'balancing_queuing',
  BalancingQueued = 'balancing_queued',
  Balancing = 'balancing',
  BalancingError = 'balancing_error',
  BalancingReady = 'balancing_ready',
  Finished = 'finished',
}

export const SimulationStateSequence = Object.values(SimulationState);

export enum LockState {
  LOCKED = 'locked',
  NEEDS_CONFIRMATION = 'needs_confirmation',
  OUT_OF_RUNS= 'out_of_runs',
  PAYING = 'paying',
  UNLOCKED = 'unlocked',
  ERROR = 'error',
}

export enum UnlockIntents {
  COPPER_AREAS = 'copper_areas',
  EXPORT = 'export',
}

type KPI = 'avg' | 'min' | 'max' | 'std' | 'frac' | 'cpk';

export type ResultsType = {
  [K in KPI]: SingleResult<Dimension>;
} & {
  timeUpdate?: ValueWithUnit<Dimension>;
};

type SingleResult<D extends Dimension> = {
  front: ValueWithUnit<D>;
  back: ValueWithUnit<D>;
};

export type ParamsType = {
  [K: string]: Setting<Dimension>;
};
