import { client } from './client';
import { LockState, ResultsType, SimulationState } from 'screens/simulation/types';
import { ViaSide } from 'screens/simulation/upload_source_files/store';
import { requestToken, callSync, callAsync } from './backend';
import {
  Setting,
  ValueWithUnit,
  CurrentDensityUnits,
  TimeUnits,
  ThicknessUnits,
  ScalarUnits,
  LengthUnits,
} from 'settings';
import { fillStoreSetting } from 'settings/store';

const SLUG = 'public_simulation_data';


export type SimulationPublicData = {
  id: string;
  session: string;
  name: string;
  settings: any;
}

export type SimulationSessionData = {
  id: string;
  uuid: string;
  status: {
    value: SimulationState;
  };
  nb_simulations?: number;
  nb_balancings?: number;
  simulation_unlocked: boolean;
  balancing_unlocked: boolean;
  last_activity_at: string;
  aws_data: any;
  simulation_data: any;
  archived: boolean;
  history: {
    data: string[];
  };
  read_errors: {
    front?: string[];
    back?: string[];
  };
};

export function kpiToResults(kpis: any): ResultsType {
  let results: ResultsType = {
    avg: {
      front: new ValueWithUnit(ThicknessUnits.umUnit, kpis.front.avg),
      back: new ValueWithUnit(ThicknessUnits.umUnit, kpis.back.avg),
    },
    min: {
      front: new ValueWithUnit(ThicknessUnits.umUnit, kpis.front.min),
      back: new ValueWithUnit(ThicknessUnits.umUnit, kpis.back.min),
    },
    max: {
      front: new ValueWithUnit(ThicknessUnits.umUnit, kpis.front.max),
      back: new ValueWithUnit(ThicknessUnits.umUnit, kpis.back.max),
    },
    std: {
      front: new ValueWithUnit(ThicknessUnits.umUnit, kpis.front.std),
      back: new ValueWithUnit(ThicknessUnits.umUnit, kpis.back.std),
    },
    frac: {
      front: new ValueWithUnit(ScalarUnits.floatingPointUnit, kpis.front.frac), // api returns value between 0 en 1,
      back: new ValueWithUnit(ScalarUnits.floatingPointUnit, kpis.back.frac),
    },
    cpk: {
      front: new ValueWithUnit(ScalarUnits.noUnit, kpis.front.cpk),
      back: new ValueWithUnit(ScalarUnits.noUnit, kpis.back.cpk),
    }
  };

  if (kpis.timeUpdate != null) {
    results.timeUpdate = new ValueWithUnit(TimeUnits.sUnit, kpis.timeUpdate);
  }

  return results;
}

function assignSettingFromApi(settings: any, name: string, value: number, unit: any) {
  settings[name] = fillStoreSetting(new Setting(name, new ValueWithUnit(unit, value)));
}

// dit is een draak van een functie geworden... needs refactoring 🤔
export function convertFromApi(value: SimulationSessionData) {
  let data: any = {
    id: value.uuid,
    remoteId: value.id,
    _lastModified: value.last_activity_at,
    numSimulations: value.nb_simulations || 0,
    numBalancings: value.nb_balancings || 0,
    simulationLock: value.simulation_unlocked ? LockState.UNLOCKED : LockState.LOCKED,
    balancingLock: value.balancing_unlocked ? LockState.UNLOCKED : LockState.LOCKED,
    status: value.status.value,
    archived: value.archived,
    history: value.history && value.history.data ? value.history.data : [],
    read_errors: value.read_errors || {},
  };

  if (value.simulation_data != null) {
    if (value.simulation_data.front_bbox) {
      data.front_bbox = {};
      ['minX', 'maxX', 'minY', 'maxY'].forEach((n) => {
        data.front_bbox[`_${n}`] = fillStoreSetting(new Setting(n, new ValueWithUnit(LengthUnits.mmUnit, value.simulation_data.front_bbox[n])))
      })
    }
    if (value.simulation_data.back_bbox) {
      data.back_bbox = {};
      ['minX', 'maxX', 'minY', 'maxY'].forEach((n) => {
        data.back_bbox[`_${n}`] = fillStoreSetting(new Setting(n, new ValueWithUnit(LengthUnits.mmUnit, value.simulation_data.back_bbox[n])))
      })
    }
    if (value.simulation_data.contour) {
      data.contour = {};
      ['minX', 'maxX', 'minY', 'maxY'].forEach((n) => {
        data.contour[`_${n}`] = fillStoreSetting(new Setting(n, new ValueWithUnit(LengthUnits.mmUnit, value.simulation_data.back_bbox[n])))
      })
    }
    if (value.simulation_data.fileNames) {
      data.sources = { fileNames: value.simulation_data.fileNames };
    }
    if (value.simulation_data.expert) {
      data.expertMode = true;
    }

    if (value.simulation_data.analysis != null) {
      data.analyzer = {};

      if (value.simulation_data.analysis.params != null) {
        let params = value.simulation_data.analysis.params;


        data.analyzer.settings = {};




        // TODO adjust;

        if (params.method != null) {
          data.analyzer.settings.preferProcessTime = params.method === 'time';
        }

        const {
          plating_time,
          target_thickness,
          j_front,
          j_back,
          min_thickness,
          max_thickness,
          mesh,
        } = params;

        if (
          plating_time != null ||
          target_thickness != null ||
          j_front != null ||
          j_back != null ||
          min_thickness != null ||
          max_thickness != null ||
          mesh != null
        ) {
          let settings: any = {};

          if (target_thickness != null) {
            assignSettingFromApi(
              settings,
              'target_thickness',
              target_thickness,
              ThicknessUnits.umUnit
            );
          }

          if (min_thickness != null) {
            assignSettingFromApi(settings, 'min', min_thickness, ThicknessUnits.umUnit);
          }

          if (max_thickness != null) {
            assignSettingFromApi(settings, 'max', max_thickness, ThicknessUnits.umUnit);
          }

          if (plating_time != null) {
            assignSettingFromApi(settings, 'process_time', plating_time, TimeUnits.sUnit);
          }

          if (j_front != null) {
            assignSettingFromApi(
              settings,
              'current_density_front',
              j_front,
              CurrentDensityUnits.a_m2Unit
            );
          }

          if (j_back != null) {
            assignSettingFromApi(
              settings,
              'current_density_back',
              j_back,
              CurrentDensityUnits.a_m2Unit
            );
          }

          if (mesh != null) {
            assignSettingFromApi(
              settings,
              'mesh_resolution',
              mesh,
              LengthUnits.mmUnit
            )
          }

          // no idea why there are so many nested settings
          data.analyzer.settings.settings = {
            settings,
          };
          data.analyzer.settingsUsed = data.analyzer.settings;
        }
      }
      if (value.simulation_data.analysis.kpis != null) {
        data.analyzer.result = kpiToResults(value.simulation_data.analysis.kpis);
      }
    }

    if (value.simulation_data.balancing != null) {
      data.balancer = {};

      if (value.simulation_data.balancing.params != null) {
        let params = value.simulation_data.balancing.params;
        data.balancer.settings = {};

        const {
          no_go_zone_board,
          min_cu_fraction,
          max_cu_fraction,
          intensity,
          environmental_power,
          hatch_coverage,
        } = params;

        if (
          no_go_zone_board != null ||
          min_cu_fraction != null ||
          max_cu_fraction != null ||
          intensity != null ||
          environmental_power != null ||
          hatch_coverage != null
        ) {
          let settings: any = {};

          if (no_go_zone_board != null) {
            assignSettingFromApi(settings, 'no_go_zone', no_go_zone_board, LengthUnits.mmUnit);
          }

          if (min_cu_fraction != null) {
            
            assignSettingFromApi(
              settings,
              'min_cu_fraction',
              Math.max(Math.min(0.60, min_cu_fraction), 0.10), // make sure value is between 60 and 10
              ScalarUnits.floatingPointUnit
            );
          }

          if (max_cu_fraction != null) {
            assignSettingFromApi(
              settings,
              'max_cu_fraction',
              Math.max(Math.min(0.60, max_cu_fraction), 0.10), // make sure value is between 60 and 10
              ScalarUnits.floatingPointUnit
            );
          }

          if (intensity != null) {
            assignSettingFromApi(settings, 'intensity', intensity, ScalarUnits.noUnit);
          }

          if (environmental_power != null) {
            assignSettingFromApi(settings, 'environmental_power', environmental_power, ScalarUnits.noUnit);
          }

          if (hatch_coverage != null) {
            assignSettingFromApi(
              settings,
              'hatch_coverage',
              hatch_coverage,
              ScalarUnits.floatingPointUnit
            );
          }

          // no idea why there are so many nested settings
          data.balancer.settings.settings = {
            settings,
          };
          
          data.balancer.settingsUsed = data.balancer.settings;
        }
      }
      if (value.simulation_data.balancing.kpis != null) {
        data.balancer.result = kpiToResults(value.simulation_data.balancing.kpis);
      }
    }
  }
  return data;
}

export async function list(): Promise<Array<SimulationSessionData>> {
  let result: Array<SimulationSessionData> = [];
  let hasNext = true;
  let page = 1;
  while (hasNext) {
    const { body, headers } = await client.get<Array<SimulationSessionData>>(
      `/channels/sessions/entries?page=${page++}`
    );
    result.push(...body);
    const link = headers.get('Link');
    hasNext = link != null && /rel="next"/i.test(link);
  }
  return result;
}

export async function remove(uuid: string, id: string): Promise<void> {
  const token = await requestToken(uuid, id);
  if (token != null) {
    await callSync(token, '/cleanup', 'POST');
  } else {
    throw new Error(`Couldn't get token for the backend`);
  }
  // await client.delete(`/channels/sessions/entries/${id}`);
}

export async function get(id: string): Promise<SimulationSessionData> {
  const { body } = await client.get<SimulationSessionData>(`/channels/sessions/entries/${id}`);
  return body;
}

export async function find(uuid: string): Promise<SimulationSessionData | undefined> {
  const query = new URLSearchParams({
    uuid,
    limit: '1',
  });
  const { body } = await client.get<Array<SimulationSessionData>>(
    `/channels/sessions/entries?${query.toString()}`
  );
  if (body.length > 0) {
    return body[0];
  } else {
    return undefined;
  }
}

export async function getOrFind(uuid: string, id?: string) {
  if (id != null) {
    return get(id);
  } else {
    return find(uuid);
  }
}

export type Via = { id: string; depth: number; side: ViaSide; file: File };

const post = (file: File, data: any) : Promise<Response> => {
  let form = new FormData();
  Object.keys(data.fields).forEach(key => form.append(key, data.fields[key]));
  form.append('file', file);
  return fetch(data.url, { method: 'POST', body: form });
}

export async function upload(
  uuid: string,
  front: File,
  back: File,
  pdata: any,
  contour?: File,
  through?: File,
  vias?: Via[],
  id?: string,
) {
  const token = await requestToken(uuid, id);
  if (token != null) {
    let dataForUpload: any = {};
    if (contour != null) {
      dataForUpload.contour = 'contour.gbr';
    }
    if (through != null) {
      dataForUpload.through = 'through.gbr';
    }
    if (vias != null) {
      dataForUpload.vias = vias.map((v) => ({ side: v.side, depth: v.depth, id: v.id }));
    }

    let fileNames: any = {};
    if (front != null) fileNames.front = front.name;
    if (back != null) fileNames.back = back.name;
    if (contour != null) fileNames.contour = contour.name;
    if (through != null) fileNames.through = through.name;
    if (vias != null) { fileNames.vias = vias.map((v) => v.file.name); }
    dataForUpload.fileNames = fileNames;
    dataForUpload.pdata = pdata;

    const response = await callSync(
      token,
      '/upload',
      'POST',
      JSON.stringify(dataForUpload),
      'application/json'
    );
    if (response.ok) {
      const json = await response.json();
      const uploads = [
        post(front, json.front),
        post(back, json.back),
      ];
      if (contour != null && json.contour != null) {
        uploads.push(post(contour, json.contour));
      }
      if (through != null && json.through != null) {
        uploads.push(post(through, json.through));
      }
      if (vias != null) {
        for (const via of vias) {
          if (json.vias != null && json.vias[via.id] != null) {
            uploads.push(
              post(via.file, json.vias[via.id])
            );
          }
        }
      }
      await Promise.all(uploads);
    } else {
      throw new Error('Failed to get S3 upload urls');
    }
  } else {
    throw new Error(`Couldn't get token for the backend`);
  }
}

export async function uploadContour(
  uuid: string,
  contour: { minX: number, maxX: number, minY: number, maxY: number },
  id?: string,
) {
  const token = await requestToken(uuid, id);
  if (token != null) {
    const response = await callSync(
      token,
      '/upload/contour',
      'POST',
      JSON.stringify(contour),
      'application/json'
    );
    if (response.ok) {
      return;
    } else {
      throw new Error('Failed to upload created contour');
    }
  } else {
    throw new Error(`Couldn't get token for the backend`);
  }
}

export async function getPreviewUrls(uuid: string, id?: string) {
  const token = await requestToken(uuid, id);
  if (token != null) {
    const response = await callSync(token, '/preview/urls');
    if (response.ok) {
      return await response.json();
    } else {
      throw new Error(`Couldn't get preview urls from backend`);
    }
  } else {
    throw new Error(`Couldn't get token for the backend`);
  }
}

export async function startPreviewGeneration(depth: number, uuid: string, id?: string) {
  const token = await requestToken(uuid, id);
  if (token != null) {
    const response = await callAsync(token, '/preview/generate', JSON.stringify({ depth }), 'application/json');
    if (response.ok) {
      return true;
    } else {
      throw new Error(`Couldn't start preview generation`);
    }
  } else {
    throw new Error(`Couldn't get token for the backend`);
  }
}

export async function acknowledgePreviews(uuid: string, id?: string) {
  const token = await requestToken(uuid, id);
  if (token != null) {
    const response = await callSync(token, '/analyze/prepare', 'POST');
    if (response.ok) {
      return true;
    } else {
      throw new Error(`Couldn't acknowledge previews`);
    }
  } else {
    throw new Error(`Couldn't get token for the backend`);
  }
}

export async function unlockAnalysis(uuid: string, id?: string) {
  const token = await requestToken(uuid, id);
  if (token != null) {
    const response = await callSync(token, '/analyze/unlock', 'POST', '{}', 'application/json');
    if (response.ok) {
      const json = await response.json();
      return json.success;
    } else {
      throw new Error(`Couldn't unlock analysis`);
    }
  } else {
    throw new Error(`Couldn't get token for the backend`);
  }
}

export async function queueAnalysis(
  uuid: string,
  id?: string,
  parameters?: {
    expert?: boolean;
    depth?: number;
    mesh?: number;
    min_thickness?: number;
    max_thickness?: number;
    plating_time?: number;
    j_front?: number;
    j_back?: number;
    target_thickness?: number;
    method?: 'time' | 'thickness';
  }
) {
  const token = await requestToken(uuid, id);
  if (token != null) {
    const response = await callSync(
      token,
      '/analyze/enqueue',
      'POST',
      JSON.stringify(parameters || {}),
      'application/json'
    );
    if (response.ok) {
      return true;
    } else {
      throw new Error(`Couldn't start analysis`);
    }
  } else {
    throw new Error(`Couldn't get token for the backend`);
  }
}

export async function getAnalysisUrls(uuid: string, id?: string) {
  const token = await requestToken(uuid, id);
  if (token != null) {
    const response = await callSync(token, '/analyze/urls');
    if (response.ok) {
      return await response.json();
    } else {
      throw new Error(`Couldn't get analysis urls from backend`);
    }
  } else {
    throw new Error(`Couldn't get token for the backend`);
  }
}

export async function prepareBalancing(uuid: string, id?: string) {
  const token = await requestToken(uuid, id);
  if (token != null) {
    const response = await callSync(token, '/balance/prepare', 'POST');
    if (response.ok) {
      return true;
    } else {
      throw new Error(`Couldn't store simulation state`);
    }
  } else {
    throw new Error(`Couldn't get token for the backend`);
  }
}

export async function queueBalancing(
  uuid: string,
  id?: string,
  parameters?: {
    // expert?: boolean;
    no_go_zone_board?: number;
    // min_thickness?: number;
    // max_thickness?: number;
    target_thickness?: number;
    min_cu_fraction?: number;
    max_cu_fraction?: number;
    intensity?: number;
    hatch_coverage?: number;
    influence_radius?: number;
    environmental_power?: number;
  }
) {
  if (parameters != null) parameters.max_cu_fraction = parameters.min_cu_fraction; // hardcoded edit to disable max fraq
  const token = await requestToken(uuid, id);
  if (token != null) {
    const response = await callSync(
      token,
      '/balance/enqueue',
      'POST',
      JSON.stringify(parameters || {}),
      'application/json'
    );
    if (response.ok) {
      return true;
    } else {
      throw new Error(`Couldn't start balancer`);
    }
  } else {
    throw new Error(`Couldn't get token for the backend`);
  }
}

export async function getBalancingUrls(uuid: string, id?: string) {
  const token = await requestToken(uuid, id);
  if (token != null) {
    const response = await callSync(token, '/balance/urls');
    if (response.ok) {
      return await response.json();
    } else {
      throw new Error(`Couldn't get balance urls from backend`);
    }
  } else {
    throw new Error(`Couldn't get token for the backend`);
  }
}

export async function unlockBalancing(uuid: string, id?: string) {
  const token = await requestToken(uuid, id);
  if (token != null) {
    const response = await callSync(token, '/balance/unlock', 'POST', '{}', 'application/json');
    if (response.ok) {
      const json = await response.json();
      return json.success;
    } else {
      throw new Error(`Couldn't unlock balancing`);
    }
  } else {
    throw new Error(`Couldn't get token for the backend`);
  }
}

export async function removeSimulationData(session: string, id?: string): Promise<void> {
  if (id == null) {
    // try to retreive nimbu id
    const query = new URLSearchParams({
      session,
      limit: '1',
    });
    const { body } = await client.get<Array<SimulationPublicData>>(
      `/channels/${SLUG}/entries?${query.toString()}`
    );

    if (body.length > 0) {
      id = body[0].id;
    } else {
      return; // there was no simulation data for this simulation
    }
  }

  client.delete(`/channels/${SLUG}/entries/${id}`);
}

export async function publicList(): Promise<Array<SimulationPublicData>> {
  let result: Array<SimulationPublicData> = [];
  let hasNext = true;
  let page = 1;
  while (hasNext) {
    const { body, headers } = await client.get<Array<SimulationPublicData>>(
      `/channels/${SLUG}/entries?page=${page++}`
    );
    result.push(...body);
    const link = headers.get('Link');
    hasNext = link != null && /rel="next"/i.test(link);
  }
  return result;
}

export async function getSimulationData(session: string, id?: string) {
  if (id == null) {
    // try to retreive nimbu id
    const query = new URLSearchParams({
      session,
      limit: '1',
    });
    const { body } = await client.get<Array<SimulationPublicData>>(
      `/channels/${SLUG}/entries?${query.toString()}`
    );

    if (body.length > 0) {
      return body[0];
    } else {
      return undefined
    }
  }
  else {
    return await client.get<SimulationPublicData>(`/channels/${SLUG}/entries/${id}`);
  }
}

export async function setSimulationName(name: string, session: string, id?: string) {
  return await setPublicSimulationData({ name }, session, id);
}

export async function setSimulationSettings(settings: any, session: string, id?: string) {
  return await setPublicSimulationData({ settings }, session, id);
}

export async function setPublicSimulationData(data: any, session: string, id?: string) {
  if (id == null) {

    // try to retreive nimbu id
    const query = new URLSearchParams({
      session,
      limit: '1',
    });
    const { body } = await client.get<Array<SimulationPublicData>>(
      `/channels/${SLUG}/entries?${query.toString()}`
    );

    if (body.length > 0) {
      // data exists, retreived nimbu id
      id = body[0].id;
    } else {
      // create data
      const response = await client.post(
        `/channels/${SLUG}/entries`,
        {
          session,
          ...data
        }
      );
      return response.body;
    }
  }

  // use nimbu id to update data
  const response = await client.put(
    `/channels/${SLUG}/entries/${id}`, data
  );

  return response.body;
}


// export async function createSimulationSettings(project_settings: any, id: string, ) {

//   const response = await client.post(
//     `/channels/${SLUG}/entries`,
//     {
//       settings: project_settings
//     }
//   );
//   return response.body;
// }

// export async function updateSimulationSettings(project_settings: any, id: string, ) {
//   const response = await client.put(
//     `/channels/${SLUG}/entries/${id}`,
//     {
//       settings: project_settings
//     }
//   );
//   return response.body;
// }

