import { Dimension, ValueWithUnit, Unit, TimeUnits } from 'settings';
import { Span } from 'components';
import React from 'react';
var TimeFormat = require('hh-mm-ss');

export type SettingTextProps<D extends Dimension> = {
  value: ValueWithUnit<D>;
  unit: Unit<D>;
  precision?: number;
  showUnit?: boolean;
};

function toMaxPrecision(value: number, precision?: number) {
  if (precision == null) {
    return value.toString();
  }

  const fixedPrecision = value.toFixed(precision);
  return fixedPrecision;
  // Remove trailing zeros
  // return parseFloat(fixedPrecision).toString();
}

function secondToTimeString(time: number) {
  return TimeFormat.fromS(time, 'hh:mm:ss');
}

const formatDefault = <D extends Dimension>({
  value,
  unit,
  precision,
  showUnit,
}: SettingTextProps<D>) => {
  return `${toMaxPrecision(value.getValueAs(unit), precision)}${showUnit ? ` ${unit.name}` : ''}`;
};

const formatmmss = <D extends Dimension>({
  value,
  unit,
  precision,
  showUnit,
}: SettingTextProps<D>) => {
  const numSeconds = value.getValueAsBaseUnit();
  return secondToTimeString(numSeconds);
};

export function formatValue<D extends Dimension>(props: SettingTextProps<D>) {
  switch (props.unit) {
    case TimeUnits.mmssUnit as Unit<Dimension>:
      return formatmmss(props);
    default:
      return formatDefault(props);
  }
}

const SettingText = <D extends Dimension>(props: SettingTextProps<D>) => {
  return <Span color="inherit">{formatValue(props)}</Span>;
};

export default SettingText;
