import React, { useEffect, useState, useRef } from 'react';
import HistogramDataReader, { HistogramStep } from "../../HistogramDataReader";
import { useTranslations } from 'hooks';
import styled from 'styled-components';
import { Text } from 'grommet';

import { Histogram, Box, Spinner } from 'components';
import { BoxProps } from 'components/Box/Box';


export const HistogramColors = {
  red: "#FF0000",
  green: "#AABBAA",
  blue: "#0000FF"
}

const StyledBox = styled(Box)`
  position: relative;
`
// const StyledTextLeft = styled(Text)`
//   position: absolute;
//   left: 0;
//   top: 100%;
//   text-align: center;
//   transform: translate(-50%, 0);
// `;

// const StyledTextRight = styled(Text)`
//   position: absolute;
//   right: 0;
//   top: 100%;
//   text-align: center;
//   transform: translate(50%, 0);
// `;

const ColorBox = ({ color, children, ...rest }: { color: string, children: React.ReactNode } & BoxProps) => {
  return (
    <StyledBox background={{ color: color, opacity: 0.7 }} {...rest}>
      {children}
    </StyledBox>
  )
}

const HistogramViewer = ({ front, back, min, max, minSpec, maxSpec, level, isbusy, animate }: { 
  front: string, back: string, min: number, max: number, minSpec: number, maxSpec: number, 
  level: number, isbusy: (busy: boolean) => void, animate: boolean 
}) => {
  const t = useTranslations('pcb_viewer');

  // round to 0.1
  const [rmin, setMin] = useState(Math.round(min * 10.0) / 10.0);
  const [rmax, setMax] = useState(Math.round(max * 10.0) / 10.0);
  const [rminS, setMinS] = useState(Math.round(minSpec * 10.0) / 10.0); // rounded minimum Spec
  const [rmaxS, setMaxS] = useState(Math.round(maxSpec * 10.0) / 10.0); // rounded maximum Spec
  useEffect(() => {
    setMin(Math.round(min * 10.0) / 10.0)
  }, [min]);
  useEffect(() => {
    setMax(Math.round(max * 10.0) / 10.0)
  }, [max]);
  useEffect(() => {
    setMinS(Math.round(minSpec * 10.0) / 10.0)
  }, [minSpec]);
  useEffect(() => {
    setMaxS(Math.round(maxSpec * 10.0) / 10.0)
  }, [maxSpec]);

  const [frontData, setFrontData] = useState<HistogramStep[]>([]);
  const [backData, setBackData] = useState<HistogramStep[]>([]);

  const [frontPerc, setFrontPerc] = useState({ below: 0, within: 0, above: 0 });
  const [backPerc, setBackPerc] = useState({ below: 0, within: 0, above: 0 });

  const [maxCount, setMaxCount] = useState(0);

  const fReader = useRef(new HistogramDataReader());
  const bReader = useRef(new HistogramDataReader());

  const [frontBusy, setFrontBusy] = useState(false);
  const [backBusy, setBackBusy] = useState(false);
  const [wasFrontBusy, setWasFrontBusy] = useState(false);
  const [wasBackBusy, setWasBackBusy] = useState(false);

  const calc = (d: HistogramStep[], isFront: boolean) => {
    let mc = maxCount;
    let perc = { below: 0, within: 0, above: 0 };
    for (let i = 0; i < d.length; ++i) {
      if (d[i].bin0 < rminS && d[i].bin1 <= rminS) perc.below += d[i].count;
      if (d[i].bin0 >= rminS && d[i].bin1 <= rmaxS) perc.within += d[i].count;
      if (d[i].bin0 >= rmaxS && d[i].bin1 > rmaxS) perc.above += d[i].count;
      if (d[i].count > mc) mc = d[i].count;
    }
    isFront ? setFrontPerc(perc) : setBackPerc(perc);
    setMaxCount(mc);
  }

  useEffect(() => {
    isbusy(true);
    fReader.current.read(front, (d: HistogramStep[]) => {
      setFrontData(d);

      // wasbusy(fReader.current.busy || bReader.current.busy || frontData.length < 1 || backData.length < 1);
    })
  }, [front, isbusy])

  useEffect(() => {
    isbusy(true);
    bReader.current.read(back, (d: HistogramStep[]) => {
      setBackData(d)
      // isbusy(fReader.current.busy || bReader.current.busy || frontData.length < 1 || backData.length < 1);
    })
  }, [back, isbusy])

  useEffect(() => {
    calc(frontData, true);
    setWasBackBusy(true);
    // isbusy(fReader.current.busy || bReader.current.busy || frontData.length < 1 || backData.length < 1);
  }, [frontData, min, max]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    // isbusy(true);
    calc(backData, false);
    setWasFrontBusy(true);
    // isbusy(fReader.current.busy || bReader.current.busy || frontData.length < 1 || backData.length < 1);
  }, [backData, min, max]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (wasBackBusy) {
      setWasBackBusy(false);
      if (!wasFrontBusy) isbusy(frontBusy || backBusy)
    }
    if (wasFrontBusy) {
      setWasFrontBusy(false);
      if (!wasBackBusy) isbusy(frontBusy || backBusy)
    }
  }, [wasFrontBusy, wasBackBusy, isbusy]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    isbusy(frontBusy || backBusy);
  }, [frontBusy, backBusy, isbusy]);



  // const mperc = 20;
  // const below = (frontPerc.below + backPerc.below) / 2;
  // const within = (frontPerc.within + backPerc.within) / 2;
  // const above = (frontPerc.above + backPerc.above) / 2;

  // const adjustedPerc = (100 - Math.max(mperc - below, 0) - Math.max(mperc - within, 0) - Math.max(mperc - above, 0)) / 100;
  return (
    <Box direction="column" gap="medium" pad="medium" align="center" flex="grow" width="100%">
      <Box direction="row" gap="20px" flex="grow" width="100%">
        <Box direction="column" flex="grow">
          <Text as="label" size="medium" margin={{ bottom: 'small', horizontal: 'small' }} alignSelf="start">
            {t('front')}
          </Text>
          <HistogramWindow
            data={frontData}
            min={rmin}
            max={rmax}
            minS={rminS}
            maxS={rmaxS}
            {...{ level, maxCount, animate }}
            isbusy={setFrontBusy}
            spec={frontPerc}
          />
        </Box>

        <Box direction="column" flex="grow">
          <Text as="label" size="medium" margin={{ bottom: 'small', horizontal: 'small' }} alignSelf="end">
            {t('back')}
          </Text>
          <HistogramWindow
            data={backData}
            min={rmin}
            max={rmax}
            minS={rminS}
            maxS={rmaxS}
            {...{ level, maxCount, animate }}
            isbusy={setBackBusy}
            spec={backPerc}
          />
        </Box>
      </Box>
    </Box>
  )

}

const HistogramWindow = ({ data, min, max, minS, maxS, level, maxCount, isbusy, animate, spec }: {
  data: HistogramStep[],
  min: number,
  max: number,
  minS: number,
  maxS: number,
  level: number,
  // otherData: HistogramStep[],
  maxCount: number,
  isbusy: (busy: boolean) => void,
  animate: boolean,
  spec: { below: number, within: number, above: number }
}) => {
  // const t = useTranslations('pcb_viewer');
  return (
    <Box border={{ color: "brand" }} round="4px" gap="5px" justify="center" align="center" pad="10px" width="100%">
      {
        data.length > 0 ? (
          <>
            <Box height={{min: "404px"}}>
              <Histogram {...{ data, min, max, minS, maxS, level, animate, isbusy, maxCount }} />
            </Box>
            <SpecBar {...{ spec }} flex width="95%" margin={{ bottom: "5px" }} />
          </>
        ) : (
            <Box align="center" justify="center" height={{ min: "434px" }} width="100%">
              <Spinner />
            </Box>
          )
      }
    </Box>
  )
}

const SpecBar = ({ spec, ...rest }: { spec: { below: number, within: number, above: number } } & BoxProps) => {
  const t = useTranslations('pcb_viewer');
  const minPerc = 20;

  return (
    <Box direction="row" justify="center" gap="1px" height="auto" {...rest}>
      <ColorBox wrap color={HistogramColors.blue} height="auto" align="center" justify="center"
        flex={{ grow: spec.below < minPerc ? 0 : spec.below }}
        basis={spec.below < minPerc ? "20%" : "0"}
        round={{ corner: "left", size: "10px" }} >
        <Text as="p" margin="3px" textAlign="center" size="0.65vw">
          {t("histogram.below_spec") + ` (${spec.below.toFixed(2)}%)`}
        </Text>
      </ColorBox>
      <ColorBox wrap color={HistogramColors.green} height="auto" align="center" justify="center"
        flex={{ grow: spec.within < minPerc ? 0 : spec.within }}
        basis={spec.within < minPerc ? "20%" : "0"}>
        <Text as="p" margin="3px" textAlign="center" size="0.65vw">
          {t("histogram.within_spec") + ` (${spec.within.toFixed(2)}%)`}
        </Text>
      </ColorBox>
      <ColorBox wrap color={HistogramColors.red} height="auto" align="center" justify="center"
        flex={{ grow: spec.above < minPerc ? 0 : spec.above }}
        basis={spec.above < minPerc ? "20%" : "0"}
        round={{ corner: "right", size: "10px" }} >
        <Text as="p" margin="3px" textAlign="center" size="0.65vw">
          {t("histogram.above_spec") + ` (${spec.above.toFixed(2)}%)`}
        </Text>
      </ColorBox>
    </Box>
  )
}

export default HistogramViewer;