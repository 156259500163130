import React from 'react';
import { FormField as GrommetFormField } from 'grommet';
import styled from 'styled-components';
import { MarginType, PlaceHolderType } from 'components/types';
import { Icon, Tooltip } from 'components';
import { normalizeColor } from 'grommet/utils';

const StyledFormField = styled(GrommetFormField).attrs(
  ({ border = true }: { border: boolean }) => ({
    border, 
  })
)`
  border: none;
  overflow: visible;
  border-bottom: 1px solid 
    ${({ border, theme }) => (border ? normalizeColor('border', theme) : 'transparent')};
  padding-bottom: ${({ border }) => (border ? '15px' : '0px')};
  
    & > label {
    margin-left: 0;
    margin-bottom: 10px;

    & + span {
      font-size: 14px;
      margin-top: -5px;
      margin-left: 0;
      margin-bottom: 10px;
    }
  }

  & > div {
    border-bottom: none;
    overflow: visible;
    & + span {
      font-size: 14px;
      margin-left: 0;
      margin-bottom: 0px;
    }
  }
  `;

type FormFieldProps = React.PropsWithChildren<{
  border?: boolean;
  disabled?: boolean;
  error?: string | React.ReactNode;
  help?: string | React.ReactNode;
  hint?: string | React.ReactNode;
  htmlFor?: string;
  info?: string | React.ReactNode;
  label?: string | React.ReactNode;
  margin?: MarginType;
  name?: string;
  options?: string[];
  // Although Placeholder is not a prop within FormField we Omit the HTML placeholder attribute and replaced with following.
  placeholder?: PlaceHolderType;
  required?: boolean;
  component?: any;
  validate?:
    | { regexp?: object; message?: string | React.ReactNode; status?: 'error' | 'info' }
    | ((...args: any[]) => any)
    | (
        | { regexp?: object; message?: string | React.ReactNode; status?: 'error' | 'info' }
        | ((...args: any[]) => any)
      )[];
}>;

const FormField = ({ label, hint, ...props }: FormFieldProps) => {
  if (hint != null) {
    label = (
      <>
        {label}{' '}
        <Tooltip tooltip={hint}>
          <Icon name="question-circle-regular" color="text-xweak" />
        </Tooltip>
      </>
    );
  }
  return <StyledFormField pad={false} label={label} {...props} />;
};

export default FormField;
export {StyledFormField};
