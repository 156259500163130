import { MAX_VIA_FILES } from 'config';
import { types, Instance } from 'mobx-state-tree';
import { v4 as uuid } from 'uuid';

export enum ViaSide {
  Front = 'front',
  Back = 'back',
}

export const Via = types
  .model('Via', {
    id: types.optional(types.identifier, uuid),
    depth: types.optional(types.number, 1),
    side: types.optional(types.enumeration(Object.values(ViaSide)), ViaSide.Front),
  })
  .volatile((self) => ({
    file: undefined as File | undefined,
  }))
  .actions((self) => ({
    setDepth(d: number) {
      self.depth = d;
    },
    setSide(s: ViaSide) {
      self.side = s;
    },
    setFile(f: File) {
      self.file = f;
    },
  }));

export interface IVia extends Instance<typeof Via> {}

export const FileNames = types
.model('FileNames', {
  front: types.maybe(types.string),
  back: types.maybe(types.string),
  through: types.maybe(types.string),
  contour: types.maybe(types.string),
  vias: types.maybe(types.array(types.string)),
});

export const Sources = types
  .model('Sources', {
    hasVias: true,
    vias: types.array(Via),
    fileNames: types.optional(FileNames, {})
  })
  .volatile((self) => ({
    front: undefined as File | undefined,
    back: undefined as File | undefined,
    through: undefined as File | undefined,
    contour: undefined as File | undefined,
  }))
  .actions((self) => ({
    setFront(f: File) {
      self.front = f;
    },
    resetFront() {
      self.front = undefined;
    },
    setBack(f: File) {
      self.back = f;
    },
    resetBack() {
      self.back = undefined;
    },
    setThrough(f: File) {
      self.through = f;
    },
    resetThrough() {
      self.through = undefined;
    },
    setContour(f: File) {
      self.contour = f;
    },
    resetContour() {
      self.contour = undefined;
    },
    toggleVias(v: boolean) {
      if (!v) {
        self.hasVias = false;
      } else {
        if (self.vias.length === 0) {
          // this.addVia();
        }
        self.hasVias = true;
      }
    },
    addVia(file: File) {
      if (self.vias.length >= MAX_VIA_FILES) return false;
      let length = self.vias.push({});
      self.vias[length-1].file = file;
      return true;
    },
    removeVia(id: string) {
      const via = self.vias.find((v) => v.id === id);
      if (via != null) {
        self.vias.remove(via);
      }
    }
  }));

export interface ISources extends Instance<typeof Sources> {}
