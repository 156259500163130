import { useEffect } from 'react';
import { useAsyncFn } from 'react-use';
import { useNavigate } from 'utils';
import { Routes } from 'routes';
import { useStore } from 'store';

import { useSimulation,  } from '../hooks'; // useResetBeforeAnalyzer
import { SimulationState } from '../types';

export function useInputSources(id: string) {
  const simulation = useSimulation(id);
  return simulation.sources;
}

export function useUpload(id: string) {
  const navigate = useNavigate();
  const simulation = useSimulation(id);

  const [state, upload] = useAsyncFn(async () => {
    await simulation.upload();
    navigate(Routes.Preview, { params: { id } });
  }, [id]);

  return {
    busy: state.loading,
    upload,
  };
}

export function useGoBackFromPreview(id: string) {
  // const simulation = useSimulation(id);
  const store = useStore();
  const navigate = useNavigate();
  return () => {
    // simulation.setRevisingSource(true);
    store.deleteSimulation(id);
    navigate(Routes.NewSimulation, { params: { id } });
  };
}

export function useGoToAnalyze(id: string) {
  const navigate = useNavigate();
  // const reset = useResetBeforeAnalyzer(id);
  const simulation = useSimulation(id);

  return () => {
    simulation.toAnalyzer();
    navigate(Routes.Analyze, { params: { id } });
  };
}
export function useGoToBalancing(id: string) {
  const navigate = useNavigate();
  // const reset = useResetBeforeAnalyzer(id);
  const simulation = useSimulation(id);

  if (simulation.simulationLocked) return undefined;
  return () => {
    simulation.toBalancer();
    navigate(Routes.Balance, { params: { id } });
  };
}

export function usePreviewSources(id: string) {
  const simulation = useSimulation(id);

  const error = simulation.status === SimulationState.PreviewsError;
  const sizeError = simulation.status === SimulationState.SizeError;
  const sizeEqualityError = simulation.status === SimulationState.SizeEqualityError;
  const front = simulation.frontPreview;
  const back = simulation.backPreview;

  useEffect(() => {
    if ((simulation.status === SimulationState.PreviewsReady || simulation.status === SimulationState.CreateContour) && (front == null || back == null)) {
      simulation.ensurePreviews();
    }
  }, [front, back, simulation, simulation.status]);

  const generating =
  (simulation.status !== SimulationState.PreviewsReady && simulation.status !== SimulationState.CreateContour) ||
    front == null ||
    back == null;

  const loading = generating && (simulation.status === SimulationState.PreviewsReady || simulation.status === SimulationState.CreateContour);

  return {
    error,
    sizeError,
    sizeEqualityError,
    generating,
    loading,
    front,
    back,
    retry: simulation.ensurePreviews,
  };
}
