import React from 'react';
import { Box as GrommetBox } from 'grommet';
import {
  AlignSelfType,
  AlignContentType,
  AnimationType,
  // BackgroundType,
  BasisType,
  DirectionType,
  ElevationType,
  FillType,
  GapType,
  DimensionType,
  JustifyContentType,
  MarginType,
  OverflowType,
  PadType,
  RoundType,
  BorderType,
} from 'components/types';

import { BackgroundType as GrommetBackgroundType } from 'grommet/utils';

export type BoxProps = React.PropsWithChildren<{
  align?: AlignSelfType | 'baseline';
  alignContent?: AlignContentType;
  alignSelf?: AlignSelfType;
  animation?: AnimationType;
  background?: GrommetBackgroundType;
  basis?: BasisType;
  direction?: DirectionType;
  elevation?: ElevationType;
  fill?: FillType;
  flex?: 'grow' | 'shrink' | boolean | { grow?: number; shrink?: number };
  gap?: GapType;
  height?: DimensionType;
  justify?: JustifyContentType;
  margin?: MarginType;
  overflow?: OverflowType;
  pad?: PadType;
  round?: RoundType;
  width?: DimensionType;
  wrap?: boolean | 'reverse';
  border?: BorderType;
  onClick?: ((...args: any[]) => any);
  focusIndicator?: boolean;
  hoverIndicator?: GrommetBackgroundType | boolean;
  onFocus?: React.HTMLAttributes<HTMLDivElement>['onFocus'];
  onBlur?: React.HTMLAttributes<HTMLDivElement>['onBlur'];
  onPointerEnter?: React.HTMLAttributes<HTMLDivElement>['onPointerEnter'];
  onPointerLeave?: React.HTMLAttributes<HTMLDivElement>['onPointerLeave'];
}>;

export const Box = React.forwardRef<HTMLDivElement, BoxProps>(
  ({ direction = 'column', gap = 'none', flex = false, children, ...rest }: BoxProps, ref) => {
    return (
      <GrommetBox ref={ref} direction={direction} gap={gap} flex={flex} {...rest}>
        {children}
      </GrommetBox>
    );
  }
);

export const FlexBox = React.forwardRef<HTMLDivElement, Omit<BoxProps, 'flex'>>((props, ref) => (
  <Box {...props} ref={ref} flex={true} />
));

export const Panel = ({
  children,
  background = 'background-front',
  align = 'center',
  pad = 'large',
  elevation = 'xsmall',
  ...rest
}: BoxProps) => {
  return (
    <Box
      align={align}
      pad={pad}
      background={background}
      elevation={elevation}
      round="small"
      {...rest}
    >
      {children}
    </Box>
  );
};

export default Box;
