module.exports = {
  BUGSNAG_API_KEY: '2c0ca619d6108b58507acb17cb0a05bc',
  STRIPE_STAGING: 'pk_test_oA13Qr8m9ufZZ0JcHTR0XiVG00h4YE6ruH',
  STRIPE_PRODUCTION: 'pk_live_9b8WQXRvgVzsppsBgSVk3U0L00BnypA5GY',
  NIMBU_CLIENT_ID_STAGING: 'b191354d427b38f93db18c0e59adcca1',
  NIMBU_CLIENT_ID_PRODUCTION: '4a9754aa714a6e60ffec3a2eb74d856a',
  NIMBU_TOKEN_STAGING: '0529d005d52234e1f30b681cb307ac921c0118afaff3751930e55179ca2e1c75',
  NIMBU_TOKEN_PRODUCTION: 'd5e5b9a85e8bff3540894f7d4bc430902a963821f865e27900a7d69583085a4e',
  CDN_ROOT: 'https://app.elsycacube.com/',
  CDN_ROOT_STAGING: 'https://app.staging.elsycacube.com/',
  UNLOCK_SIMULATION: 9,
  UNLOCK_BALANCING: 6,
  UNIT_PRICE: 10.0,
  MAX_LOCKED_SIMULATIONS: 5,
  MAX_GBR_FILE_SIZE_MB: 10,
  MAX_LOCKED_MONTHLY_RUNS: 10,
  MAX_VIA_FILES: 10,
};
