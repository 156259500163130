import React from 'react';
import { TextInput as GrommetTextInput } from 'grommet';
import { normalizeColor } from 'grommet/utils';
import styled from 'styled-components';
import { lighten, desaturate } from 'polished';
import InputMask from 'react-input-mask';

import { PlaceHolderType } from 'components/types';

// forward props from https://github.com/grommet/grommet/tree/master/src/js/components/Box
export type TextInputProps = {
  icon?: JSX.Element;
  name?: string;
  id?: string;
  type?: HTMLInputElement['type'];
  required?: boolean;
  placeholder?: PlaceHolderType;
  disabled?: boolean;
  reverse?: boolean; // allows to put icon at the end
  onChange?: (value: string, e?: React.FormEvent<HTMLInputElement>) => void;
  size?: 'small' | 'medium' | 'large' | 'xlarge' | string;
  value?: string;
  mask?: any;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
};

const StyledTextInput = styled(GrommetTextInput)`
  font-weight: 500;
  padding: 2px 8px 2px 8px;
  background-color: ${({ theme }) => normalizeColor(theme.global.input.background, theme)}}

  &:focus {
    border-color: ${({ theme }) => lighten(0.1, desaturate(0.4, normalizeColor('brand', theme)))};
  }
`;

const StyledInputMask = styled(InputMask)`
  font-weight: 500;
  background-color: ${({ theme }) => normalizeColor(theme.global.input.background, theme)}}

  &:focus {
    border-color: ${({ theme }) => lighten(0.1, desaturate(0.4, normalizeColor('brand', theme)))};
  }
`;

const TextInput = ({ mask, placeholder, type, onChange, onBlur, ...rest }: TextInputProps) => {
  let wrappedOnChange: ((e: React.FormEvent<HTMLInputElement>) => void) | undefined;
  if (onChange != null) {
    wrappedOnChange = (e: React.FormEvent<HTMLInputElement>) => {
      let value = e.currentTarget.value;
      // If numeric, only emit values with valid character, i.e. - 0-9 and . or
      // , in case of float
      if (type === 'integer' && value != null) {
        value = value.replace(/[^-\d]/, '');
      } else if (type === 'float' && value != null) {
        value = value.replace(/[^-\d.,]/g, '');
      }
      onChange(value, e);
    };
  }
  let inputmode: 'numeric' | undefined;
  let inputType = type;
  if (type === 'integer' || type === 'float') {
    // HTML number input behave problematic if you want to implement a controlled input. So we use text.
    // https://sharif.io/better-react-number-input/
    inputType = 'text';
    inputmode = 'numeric';
  }
  if (mask != null) {
    const { value, disabled, ...others } = rest;
    return (
      <StyledInputMask
        mask={mask}
        placeholder={placeholder}
        maskPlaceholder="_"
        onChange={wrappedOnChange}
        value={value}
        disabled={disabled}
      >
        <StyledTextInput
          {...others}
          onBlur={onBlur as any} // For some reason, Grommet expects "any" return type for MaskedInput. Seems like a bug...
        />
      </StyledInputMask>
    );
  } else {
    return (
      <StyledTextInput
        {...rest}
        type={inputType}
        placeholder={placeholder}
        onChange={wrappedOnChange}
        inputMode={inputmode}
        onBlur={onBlur}
      />
    );
  }
};

export default TextInput;
