import { types, Instance, flow, getParentOfType, getParent, getRoot } from 'mobx-state-tree';
import { ResultsType, SimulationState } from '../types';
import { AnalyzeState } from './types';
import { BasicSimulation, Simulation } from '../store';
import { SettingStore, fillStoreSetting} from 'settings/store';
import { Setting, ValueWithUnit, ThicknessUnits, TimeUnits, CurrentDensityUnits } from 'settings';
import * as api from 'api/simulation';

const round = (number: number) => {
  return +(Math.round(Number(number + 'e+5')) + 'e-5');
};

const defaultTargetThickness = fillStoreSetting(
  new Setting('target_thickness', new ValueWithUnit(ThicknessUnits.umUnit, 25))
);

const defaultMinThickness = fillStoreSetting(
  new Setting('min_thickness', new ValueWithUnit(ThicknessUnits.umUnit, 20))
);

const defaultMaxThickness = fillStoreSetting(
  new Setting('max_thickness', new ValueWithUnit(ThicknessUnits.umUnit, 30))
);

const defaultProcessTime = fillStoreSetting(
  new Setting('process_time', new ValueWithUnit(TimeUnits.sUnit, 7225)) // TODO: get default value from global settings
);

const defaultCurrentDensityFront = fillStoreSetting(
  new Setting('current_density_front', new ValueWithUnit(CurrentDensityUnits.a_m2Unit, 100))
);

const defaultCurrentDensityBack = fillStoreSetting(
  new Setting('current_density_back', new ValueWithUnit(CurrentDensityUnits.a_m2Unit, 100))
);

const AnalyzerSettings = types
  .model('AnalyzerSettings', {
    settings: types.optional(SettingStore, {
    settings: {
        target_thickness: defaultTargetThickness,
        min_thickness: defaultMinThickness,
        max_thickness: defaultMaxThickness,
        process_time: defaultProcessTime,
        current_density_front: defaultCurrentDensityFront,
        current_density_back: defaultCurrentDensityBack,
      },
    }),
    preferProcessTime: false,
  })
  .views((self) => ({
    getSetting: self.settings.getSetting,
    getAllSettings: self.settings.getAllSettings,
    get expertMode(): boolean {
      const simulation = getParentOfType<typeof BasicSimulation>(self, Simulation);
      if (simulation != null) {
        return simulation.expertMode;
      } else {
        return false;
      }
    },
  }))
  .actions((self) => ({
    setExpertMode(value: boolean) {
      const simulation = getParentOfType<typeof BasicSimulation>(self, Simulation);
      if (simulation != null) {
        simulation.setExpertMode(value);
        self.preferProcessTime = false;
      }
    },
    updateSetting: self.settings.updateSetting,
    setPreferProcessTime(value: boolean) {
      self.preferProcessTime = value;
    },
  }));

export const Analyzer = types
  .model('Analyzer', {
    settings: types.optional(AnalyzerSettings, {}),
    settingsUsed: types.optional(AnalyzerSettings, {}),
    result: types.maybe(types.frozen<ResultsType>()),
    isPolling: false,
  })
  .volatile((self) => ({
    frontImage: undefined as string | undefined,
    backImage: undefined as string | undefined,
    frontBC: undefined as string | undefined,
    backBC: undefined as string | undefined,
    xplFile: undefined as string | undefined,
  }))
  .views((self) => ({
    get simulation() {
      return getParent<typeof BasicSimulation>(self);
    },
    get busy() {
      return (
        this.simulation.status === SimulationState.Analyzing ||
        this.simulation.status === SimulationState.AnalysisQueued ||
        this.simulation.status === SimulationState.AnalysisQueuing
      );
    },
  }))
  .views((self) => ({
    get locked() {
      return self.simulation != null ? self.simulation.simulationLocked : true;
    },
    get unlocking() {
      return self.simulation != null ? self.simulation.unlockingSimulation : false;
    },
    get state(): AnalyzeState {
      if (self.simulation.status === SimulationState.AnalysisError) {
        return AnalyzeState.ERROR;
      } else if (this.unlocking) {
        return AnalyzeState.UNLOCKING;
      } else if (self.busy) {
        return AnalyzeState.ANALYZING;
      } else if (self.result == null) {
        return AnalyzeState.NEW
      } else if (!this.locked) {
        return AnalyzeState.UNLOCKED;
      } else {
        return AnalyzeState.READY;
      }
    },
  }))
  .actions((self) => ({
    analyze: flow(function* analyze() {
      // set internal state to AnalysisQueuing so we can already show spinner while sending this to the server
      self.simulation.setStatus(SimulationState.AnalysisQueuing);

      const expertMode = true;
      const simulation = getParentOfType<typeof BasicSimulation>(self, Simulation);

      const processTime = new Setting('process_time', new ValueWithUnit(TimeUnits.sUnit, 7225));
      const thickness = simulation.settings.getSettingFor('analyze', 'target_thickness');
      const currentDensityFront = simulation.settings.getSettingFor('analyze', 'current_density_front');
      const currentDensityBack = simulation.settings.getSettingFor('analyze', 'current_density_back');

      // const root = getRoot(self) as any;
      const minThicknessFromSettings = simulation.settings.getSettingFor('kpi', 'min');
      const maxThicknessFromSettings = simulation.settings.getSettingFor('kpi', 'max');

      let params: any = {
        expert: expertMode,
        method: self.settings.preferProcessTime ? 'time' : 'thickness',
      };

      if (minThicknessFromSettings != null) {
        params.min_thickness = round(
          minThicknessFromSettings.value.getValueAs(ThicknessUnits.umUnit)
        );
      }

      if (maxThicknessFromSettings != null) {
        params.max_thickness = round(
          maxThicknessFromSettings.value.getValueAs(ThicknessUnits.umUnit)
        );
      }

      if (thickness != null) {
        params.target_thickness = round(thickness.value.getValueAs(ThicknessUnits.umUnit));
      }

      if (processTime != null) {
        params.plating_time = round(processTime.value.getValueAs(TimeUnits.sUnit));
      }

      if (currentDensityFront != null) {
        params.j_front = round(currentDensityFront.value.getValueAs(CurrentDensityUnits.a_m2Unit));
      }

      if (currentDensityBack != null) {
        params.j_back = round(currentDensityBack.value.getValueAs(CurrentDensityUnits.a_m2Unit));
      }
      
      yield api.queueAnalysis(self.simulation.id, self.simulation.remoteId, params);
      yield (self as any).ensureAnalysisResults();
    }),
    start: flow(function* start() {
      // if (self.locked) {
      //   self.simulation.askUnlockConfirmation();
      // } else {
        yield (self as any).analyze();
      // }
    }),
    reset() {
      // Reset state when sources changed
      self.result = undefined;
      self.frontImage = undefined;
      self.backImage = undefined;
      self.frontBC = undefined;
      self.backBC = undefined;
      self.xplFile = undefined;
    },
    ensureAnalysisResults: flow(function* ensureAnalysisResults() {
      if (
        self.simulation.analysisAvailable &&
        self.simulation.status !== SimulationState.Analyzing &&
        self.simulation.status !== SimulationState.AnalysisQueued &&
        self.simulation.status !== SimulationState.AnalysisQueuing &&
        (self.frontBC == null || self.backBC == null)//(self.frontImage == null || self.backImage == null || self.xplFile == null)
      ) {
        // const root = getRoot(self) as any;
        // const refreshed: boolean = yield root.refreshSimulation(self.simulation.id);
        yield (self as any).fetchAnalysisUrls();
      } else if (self.simulation.status !== SimulationState.AnalysisError) {
        try {
          // Poll the result
          yield (self as any).pollAnalysisResults();
        } catch (error) {
          console.log(error);
          self.simulation.status = SimulationState.AnalysisError;
        }
      }
    }),
    pollAnalysisResults: flow(function* pollAnalysisResults() {
      const root = getRoot(self) as any;
      const refreshed: boolean = yield root.refreshSimulation(self.simulation.id);
      if (
        refreshed &&
        self.simulation.status !== SimulationState.Analyzing &&
        self.simulation.status !== SimulationState.AnalysisQueued
      ) {
        if (self.simulation.status === SimulationState.AnalysisReady) {
          yield (self as any).fetchAnalysisUrls();
        }
      } else {
        // try again in 5 seconds
        setTimeout((self as any).pollAnalysisResults, 5000);
      }
    }),
    fetchAnalysisUrls: flow(function* fetchAnalysisUrls() {
      // Fetch the urls from the backend
      const urls = yield api.getAnalysisUrls(self.simulation.id, self.simulation.remoteId);
      self.frontImage = urls.front;
      self.backImage = urls.back;
      self.frontBC = urls.frontBC;
      self.backBC = urls.backBC;
      self.xplFile = urls.xpl;
    }),
  }));

export interface IAnalyzer extends Instance<typeof Analyzer> {}
