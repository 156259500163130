import React from "react";
import { Box, Heading, Link, Paragraph } from 'components'; // Paragraph, Span
import styled from 'styled-components';

// const StyledParagraph = styled(Paragraph)`
// text-align: justify;
// `

// const StyledParagraphB = styled(Paragraph)`
// text-align: justify;
// `

const StyledList = styled.ol`
text-align: justify;
counter-reset: item;
padding-left: 50px;
& > li {
  position: relative;
  display: block;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-right: 10px;
  margin: 10px 0;
}
& > li:before {
  position: absolute;
  left: -50px;
  content: counters(item, ".") "." " ";
  counter-increment: item;
  font-weight: 600;
}
`

const NestedList = styled(StyledList)`
  margin-top: 20px;
  font-weight: 400;
`

const NestedListB = styled(StyledList)`
  margin-top: 10px;
  & > li {
    margin: 0;
  }
`

const MainList = styled(StyledList)`
  & > li {
    margin-top: 20px;
    font-weight: 600;
  }
  & > li:before {
    line-height: 22px;
    font-size: 18px;
  }
`

const BoxedLi = styled.li`
  border: 1px solid black;
  padding-left: 60px !important;
  margin-left: -60px !important;
  margin-top: 20px !important;
  margin-bottom: 20px !important;
  &:before {
    left: 10px !important;
  }
`
const BoxedDiv = styled.div`
  margin-top: 20px;
  text-align: justify;
  border: 1px solid black;
  padding: 5px 10px;
`

const BoldDiv = styled.div`
  text-align: justify;
  font-weight: 600;
`

const Heading2 = styled(Heading)`
  max-width: 1000px;
  line-height: 22px;
  font-size: 18px;
`

// const style = {
//   h1: { level: 1 as 1, margin: { vertical: "medium" } },
//   h2: { level: 2 as 2, margin: { vertical: "medium" } },
//   h3: { level: 3 as 3, margin: { vertical: "small" } },
//   ul: { style: { listStyleType: "disc" as "disc", listStylePosition: "outside" as "outside", paddingLeft: "30px", alignSelf: "left", marginTop: "10px", marginBottom: "10px" } },
//   p1: {},
//   p2: {},
// }

export const TermsAndConditions = () => {
  // return (
  //   <Box pad="large" overflow="auto" flex gap="small">
  //     <Heading {...style.h1}>Terms and Conditions</Heading>
  //     <li {...style.p1}>Last updated: March 29, 2021</li>
  //     <li {...style.p1}>Please read these terms and conditions carefully before using Our Service.</li>
  //     <li {...style.h2}>Interpretation and Definitions</li>
  //     <Heading {...style.h3}>Interpretation</Heading>
  //     <li {...style.p1}>The words of which the initial letter is capitalized have meanings defined under the following conditions. The following definitions shall have the same meaning regardless of whether they appear in singular or in plural.</li>
  //     <Heading {...style.h3}>Definitions</Heading>
  //     <li {...style.p1}>For the purposes of these Terms and Conditions:</li>
  //     <ul {...style.ul}>
  //       <li><li {...style.p1}><Span weight="bold">Application</Span> means the software program provided by the Company downloaded by You on any electronic device, named app.elsycacube.com</li></li>
  //       <li><li {...style.p1}><Span weight="bold">Application Store</Span> means the digital distribution service operated and developed by Apple Inc. (Apple App Store) or Google Inc. (Google Play Store) in which the Application has been downloaded.</li></li>
  //       <li><li {...style.p1}><Span weight="bold">Affiliate</Span> means an entity that controls, is controlled by or is under common control with a party, where &quot;control&quot; means ownership of 50% or more of the shares, equity interest or other securities entitled to vote for election of directors or other managing authority.</li></li>
  //       <li><li {...style.p1}><Span weight="bold">Account</Span> means a unique account created for You to access our Service or parts of our Service.</li></li>
  //       <li><li {...style.p1}><Span weight="bold">Country</Span> refers to:  Belgium</li></li>
  //       <li><li {...style.p1}><Span weight="bold">Company</Span> (referred to as either &quot;the Company&quot;, &quot;We&quot;, &quot;Us&quot; or &quot;Our&quot; in this Agreement) refers to Elsyca® NV, Vaartdijk 3/603, 3018 Wijgmaal, Belgium.</li></li>
  //       <li><li {...style.p1}><Span weight="bold">Content</Span> refers to content such as text, images, or other information that can be posted, uploaded, linked to or otherwise made available by You, regardless of the form of that content.</li></li>
  //       <li><li {...style.p1}><Span weight="bold">Device</Span> means any device that can access the Service such as a computer, a cellphone or a digital tablet.</li></li>
  //       <li><li {...style.p1}><Span weight="bold">Feedback</Span> means feedback, innovations or suggestions sent by You regarding the attributes, performance or features of our Service.</li></li>
  //       <li><li {...style.p1}><Span weight="bold">In-app Purchase</Span> refers to the purchase of a product, item, service or Subscription made through the Application and subject to these Terms and Conditions and/or the Application Store's own terms and conditions.</li></li>
  //       <li><li {...style.p1}><Span weight="bold">Service</Span> refers to the Application.</li></li>
  //       <li><li {...style.p1}><Span weight="bold">Terms and Conditions</Span> (also referred as &quot;Terms&quot;) mean these Terms and Conditions that form the entire agreement between You and the Company regarding the use of the Service.</li></li>
  //       <li><li {...style.p1}><Span weight="bold">Third-party Social Media Service</Span> means any services or content (including data, information, products or services) provided by a third-party that may be displayed, included or made available by the Service.</li></li>
  //       <li><li {...style.p1}><Span weight="bold">You</Span> means the individual accessing or using the Service, or the company, or other legal entity on behalf of which such individual is accessing or using the Service, as applicable.</li></li>
  //     </ul>
  //     <li {...style.h2}>Acknowledgment</li>
  //     <li {...style.p1}>These are the Terms and Conditions governing the use of this Service and the agreement that operates between You and the Company. These Terms and Conditions set out the rights and obligations of all users regarding the use of the Service.</li>
  //     <li {...style.p1}>Your access to and use of the Service is conditioned on Your acceptance of and compliance with these Terms and Conditions. These Terms and Conditions apply to all visitors, users and others who access or use the Service.</li>
  //     <li {...style.p1}>By accessing or using the Service You agree to be bound by these Terms and Conditions. If You disagree with any part of these Terms and Conditions then You may not access the Service.</li>
  //     <li {...style.p1}>You represent that you are over the age of 18. The Company does not permit those under 18 to use the Service.</li>
  //     <li {...style.p1}>Your access to and use of the Service is also conditioned on Your acceptance of and compliance with the Privacy Policy of the Company. Our Privacy Policy describes Our policies and procedures on the collection, use and disclosure of Your personal information when You use the Application or the Website and tells You about Your privacy rights and how the law protects You. Please read Our Privacy Policy carefully before using Our Service.</li>
  //     <li {...style.h2}>In-app Purchases</li>
  //     <li {...style.p1}>The Application may include In-app Purchases that allow you to buy products, services or Subscriptions.</li>
  //     <li {...style.p1}>More information about how you may be able to manage In-app Purchases using your Device may be set out in the Application Store's own terms and conditions or in your Device's Help settings.</li>
  //     <li {...style.p1}>In-app Purchases can only be consumed within the Application. If you make a In-app Purchase, that In-app Purchase cannot be cancelled after you have initiated its download. In-app Purchases cannot be redeemed for cash or other consideration or otherwise transferred.</li>
  //     <li {...style.p1}>If any In-app Purchase is not successfully downloaded or does not work once it has been successfully downloaded, we will, after becoming aware of the fault or being notified to the fault by You, investigate the reason for the fault. We will act reasonably in deciding whether to provide You with a replacement In-app Purchase or issue You with a patch to repair the fault. In no event will We charge You to replace or repair the In-app Purchase. In the unlikely event that we are unable to replace or repair the relevant In-app Purchase or are unable to do so within a reasonable period of time and without significant inconvenience to You, We will authorize the Application Store to refund You an amount up to the cost of the relevant In-app Purchase. Alternatively, if You wish to request a refund, You may do so by contacting the Application Store directly.</li>
  //     <li {...style.p1}>You acknowledge and agree that all billing and transaction processes are handled by the Application Store from where you downloaded the Application and are governed by that Application Store's own terms and conditions.</li>
  //     <li {...style.p1}>If you have any payment related issues with In-app Purchases, then you need to contact the Application Store directly.</li>
  //     <li {...style.h2}>User Accounts</li>
  //     <li {...style.p1}>When You create an account with Us, You must provide Us information that is accurate, complete, and current at all times. Failure to do so constitutes a breach of the Terms, which may result in immediate termination of Your account on Our Service.</li>
  //     <li {...style.p1}>You are responsible for safeguarding the password that You use to access the Service and for any activities or actions under Your password, whether Your password is with Our Service or a Third-Party Social Media Service.</li>
  //     <li {...style.p1}>You agree not to disclose Your password to any third party. You must notify Us immediately upon becoming aware of any breach of security or unauthorized use of Your account.</li>
  //     <li {...style.p1}>You may not use as a username the name of another person or entity or that is not lawfully available for use, a name or trademark that is subject to any rights of another person or entity other than You without appropriate authorization, or a name that is otherwise offensive, vulgar or obscene.</li>
  //     <li {...style.h2}>Content</li>
  //     <Heading {...style.h3}>Your Right to Post Content</Heading>
  //     <li {...style.p1}>Our Service allows You to post Content. You are responsible for the Content that You post to the Service, including its legality, reliability, and appropriateness.</li>
  //     <li {...style.p1}>By posting Content to the Service, You grant Us the right and license to use, modify, publicly perform, publicly display, reproduce, and distribute such Content on and through the Service. You retain any and all of Your rights to any Content You submit, post or display on or through the Service and You are responsible for protecting those rights. You agree that this license includes the right for Us to make Your Content available to other users of the Service, who may also use Your Content subject to these Terms.</li>
  //     <li {...style.p1}>You represent and warrant that: (i) the Content is Yours (You own it) or You have the right to use it and grant Us the rights and license as provided in these Terms, and (ii) the posting of Your Content on or through the Service does not violate the privacy rights, publicity rights, copyrights, contract rights or any other rights of any person.</li>
  //     <Heading {...style.h3}>Content Restrictions</Heading>
  //     <li {...style.p1}>The Company is not responsible for the content of the Service's users. You expressly understand and agree that You are solely responsible for the Content and for all activity that occurs under your account, whether done so by You or any third person using Your account.</li>
  //     <li {...style.p1}>You may not transmit any Content that is unlawful, offensive, upsetting, intended to disgust, threatening, libelous, defamatory, obscene or otherwise objectionable. Examples of such objectionable Content include, but are not limited to, the following:</li>
  //     <ul {...style.ul}>
  //       <li><li {...style.p1}>Unlawful or promoting unlawful activity.</li></li>
  //       <li><li {...style.p1}>Defamatory, discriminatory, or mean-spirited content, including references or commentary about religion, race, sexual orientation, gender, national/ethnic origin, or other targeted groups.</li></li>
  //       <li><li {...style.p1}>Spam, machine – or randomly – generated, constituting unauthorized or unsolicited advertising, chain letters, any other form of unauthorized solicitation, or any form of lottery or gambling.</li></li>
  //       <li><li {...style.p1}>Containing or installing any viruses, worms, malware, trojan horses, or other content that is designed or intended to disrupt, damage, or limit the functioning of any software, hardware or telecommunications equipment or to damage or obtain unauthorized access to any data or other information of a third person.</li></li>
  //       <li><li {...style.p1}>Infringing on any proprietary rights of any party, including patent, trademark, trade secret, copyright, right of publicity or other rights.</li></li>
  //       <li><li {...style.p1}>Impersonating any person or entity including the Company and its employees or representatives.</li></li>
  //       <li><li {...style.p1}>Violating the privacy of any third person.</li></li>
  //       <li><li {...style.p1}>False information and features.</li></li>
  //     </ul>
  //     <li {...style.p1}>The Company reserves the right, but not the obligation, to, in its sole discretion, determine whether or not any Content is appropriate and complies with this Terms, refuse or remove this Content. The Company further reserves the right to make formatting and edits and change the manner any Content. The Company can also limit or revoke the use of the Service if You post such objectionable Content.<br />As the Company cannot control all content posted by users and/or third parties on the Service, you agree to use the Service at your own risk. You understand that by using the Service You may be exposed to content that You may find offensive, indecent, incorrect or objectionable, and You agree that under no circumstances will the Company be liable in any way for any content, including any errors or omissions in any content, or any loss or damage of any kind incurred as a result of your use of any content.</li>
  //     <Heading {...style.h3}>Content Backups</Heading>
  //     <li {...style.p1}>Although regular backups of Content are performed, the Company do not guarantee there will be no loss or corruption of data.</li>
  //     <li {...style.p1}>Corrupt or invalid backup points may be caused by, without limitation, Content that is corrupted prior to being backed up or that changes during the time a backup is performed.</li>
  //     <li {...style.p1}>The Company will provide support and attempt to troubleshoot any known or discovered issues that may affect the backups of Content. But You acknowledge that the Company has no liability related to the integrity of Content or the failure to successfully restore Content to a usable state.</li>
  //     <li {...style.p1}>You agree to maintain a complete and accurate copy of any Content in a location independent of the Service.</li>
  //     <li {...style.h2}>Copyright Policy</li>
  //     <Heading {...style.h3}>Intellectual Property Infringement</Heading>
  //     <li {...style.p1}>We respect the intellectual property rights of others. It is Our policy to respond to any claim that Content posted on the Service infringes a copyright or other intellectual property infringement of any person.</li>
  //     <li {...style.p1}>If You are a copyright owner, or authorized on behalf of one, and You believe that the copyrighted work has been copied in a way that constitutes copyright infringement that is taking place through the Service, You must submit Your notice in writing to the attention of our copyright agent via email at info@elsyca.com and include in Your notice a detailed description of the alleged infringement.</li>
  //     <li {...style.p1}>You may be held accountable for damages (including costs and attorneys' fees) for misrepresenting that any Content is infringing Your copyright.</li>
  //     <Heading {...style.h3}>DMCA Notice and DMCA Procedure for Copyright Infringement Claims</Heading>
  //     <li {...style.p1}>You may submit a notification pursuant to the Digital Millennium Copyright Act (DMCA) by providing our Copyright Agent with the following information in writing (see 17 U.S.C 512(c)(3) for further detail):</li>
  //     <ul {...style.ul}>
  //       <li><li {...style.p1}>An electronic or physical signature of the person authorized to act on behalf of the owner of the copyright's interest.</li></li>
  //       <li><li {...style.p1}>A description of the copyrighted work that You claim has been infringed, including the URL (i.e., web page address) of the location where the copyrighted work exists or a copy of the copyrighted work.</li></li>
  //       <li><li {...style.p1}>Identification of the URL or other specific location on the Service where the material that You claim is infringing is located.</li></li>
  //       <li><li {...style.p1}>Your address, telephone number, and email address.</li></li>
  //       <li><li {...style.p1}>A statement by You that You have a good faith belief that the disputed use is not authorized by the copyright owner, its agent, or the law.</li></li>
  //       <li><li {...style.p1}>A statement by You, made under penalty of perjury, that the above information in Your notice is accurate and that You are the copyright owner or authorized to act on the copyright owner's behalf.</li></li>
  //     </ul>
  //     <li {...style.p1}>You can contact our copyright agent via email at info@elsyca.com.<br />Upon receipt of a notification, the Company will take whatever action, in its sole discretion, it deems appropriate, including removal of the challenged content from the Service.</li>
  //     <li {...style.h2}>Intellectual Property</li>
  //     <li {...style.p1}>The Service and its original content (excluding Content provided by You or other users), features and functionality are and will remain the exclusive property of the Company and its licensors.</li>
  //     <li {...style.p1}>The Service is protected by copyright, trademark, and other laws of both the Country and foreign countries.</li>
  //     <li {...style.p1}>Our trademarks and trade dress may not be used in connection with any product or service without the prior written consent of the Company.</li>
  //     <li {...style.h2}>Your Feedback to Us</li>
  //     <li {...style.p1}>You assign all rights, title and interest in any Feedback You provide the Company. If for any reason such assignment is ineffective, You agree to grant the Company a non-exclusive, perpetual, irrevocable, royalty free, worldwide right and license to use, reproduce, disclose, sub-license, distribute, modify and exploit such Feedback without restriction.</li>
  //     <li {...style.h2}>Links to Other Websites</li>
  //     <li {...style.p1}>Our Service may contain links to third-party web sites or services that are not owned or controlled by the Company.</li>
  //     <li {...style.p1}>The Company has no control over, and assumes no responsibility for, the content, privacy policies, or practices of any third party web sites or services. You further acknowledge and agree that the Company shall not be responsible or liable, directly or indirectly, for any damage or loss caused or alleged to be caused by or in connection with the use of or reliance on any such content, goods or services available on or through any such web sites or services.</li>
  //     <li {...style.p1}>We strongly advise You to read the terms and conditions and privacy policies of any third-party web sites or services that You visit.</li>
  //     <li {...style.h2}>Termination</li>
  //     <li {...style.p1}>We may terminate or suspend Your Account immediately, without prior notice or liability, for any reason whatsoever, including without limitation if You breach these Terms and Conditions.</li>
  //     <li {...style.p1}>Upon termination, Your right to use the Service will cease immediately. If You wish to terminate Your Account, You may simply discontinue using the Service.</li>
  //     <li {...style.h2}>Limitation of Liability</li>
  //     <li {...style.p1}>Notwithstanding any damages that You might incur, the entire liability of the Company and any of its suppliers under any provision of this Terms and Your exclusive remedy for all of the foregoing shall be limited to the amount actually paid by You through the Service or 100 USD if You haven't purchased anything through the Service.</li>
  //     <li {...style.p1}>To the maximum extent permitted by applicable law, in no event shall the Company or its suppliers be liable for any special, incidental, indirect, or consequential damages whatsoever (including, but not limited to, damages for loss of profits, loss of data or other information, for business interruption, for personal injury, loss of privacy arising out of or in any way related to the use of or inability to use the Service, third-party software and/or third-party hardware used with the Service, or otherwise in connection with any provision of this Terms), even if the Company or any supplier has been advised of the possibility of such damages and even if the remedy fails of its essential purpose.</li>
  //     <li {...style.p1}>Some states do not allow the exclusion of implied warranties or limitation of liability for incidental or consequential damages, which means that some of the above limitations may not apply. In these states, each party's liability will be limited to the greatest extent permitted by law.</li>
  //     <li {...style.h2}>&quot;AS IS&quot; and &quot;AS AVAILABLE&quot; Disclaimer</li>
  //     <li {...style.p1}>The Service is provided to You &quot;AS IS&quot; and &quot;AS AVAILABLE&quot; and with all faults and defects without warranty of any kind. To the maximum extent permitted under applicable law, the Company, on its own behalf and on behalf of its Affiliates and its and their respective licensors and service providers, expressly disclaims all warranties, whether express, implied, statutory or otherwise, with respect to the Service, including all implied warranties of merchantability, fitness for a particular purpose, title and non-infringement, and warranties that may arise out of course of dealing, course of performance, usage or trade practice. Without limitation to the foregoing, the Company provides no warranty or undertaking, and makes no representation of any kind that the Service will meet Your requirements, achieve any intended results, be compatible or work with any other software, applications, systems or services, operate without interruption, meet any performance or reliability standards or be error free or that any errors or defects can or will be corrected.</li>
  //     <li {...style.p1}>Without limiting the foregoing, neither the Company nor any of the company's provider makes any representation or warranty of any kind, express or implied: (i) as to the operation or availability of the Service, or the information, content, and materials or products included thereon; (ii) that the Service will be uninterrupted or error-free; (iii) as to the accuracy, reliability, or currency of any information or content provided through the Service; or (iv) that the Service, its servers, the content, or e-mails sent from or on behalf of the Company are free of viruses, scripts, trojan horses, worms, malware, timebombs or other harmful components.</li>
  //     <li {...style.p1}>Some jurisdictions do not allow the exclusion of certain types of warranties or limitations on applicable statutory rights of a consumer, so some or all of the above exclusions and limitations may not apply to You. But in such a case the exclusions and limitations set forth in this section shall be applied to the greatest extent enforceable under applicable law.</li>
  //     <li {...style.h2}>Governing Law</li>
  //     <li {...style.p1}>The laws of the Country, excluding its conflicts of law rules, shall govern this Terms and Your use of the Service. Your use of the Application may also be subject to other local, state, national, or international laws.</li>
  //     <li {...style.h2}>Disputes Resolution</li>
  //     <li {...style.p1}>If You have any concern or dispute about the Service, You agree to first try to resolve the dispute informally by contacting the Company.</li>
  //     <li {...style.h2}>For European Union (EU) Users</li>
  //     <li {...style.p1}>If You are a European Union consumer, you will benefit from any mandatory provisions of the law of the country in which you are resident in.</li>
  //     <li {...style.h2}>United States Legal Compliance</li>
  //     <li {...style.p1}>You represent and warrant that (i) You are not located in a country that is subject to the United States government embargo, or that has been designated by the United States government as a &quot;terrorist supporting&quot; country, and (ii) You are not listed on any United States government list of prohibited or restricted parties.</li>
  //     <li {...style.h2}>Severability and Waiver</li>
  //     <Heading {...style.h3}>Severability</Heading>
  //     <li {...style.p1}>If any provision of these Terms is held to be unenforceable or invalid, such provision will be changed and interpreted to accomplish the objectives of such provision to the greatest extent possible under applicable law and the remaining provisions will continue in full force and effect.</li>
  //     <Heading {...style.h3}>Waiver</Heading>
  //     <li {...style.p1}>Except as provided herein, the failure to exercise a right or to require performance of an obligation under this Terms shall not effect a party's ability to exercise such right or require such performance at any time thereafter nor shall be the waiver of a breach constitute a waiver of any subsequent breach.</li>
  //     <li {...style.h2}>Translation Interpretation</li>
  //     <li {...style.p1}>These Terms and Conditions may have been translated if We have made them available to You on our Service.<br />You agree that the original English text shall prevail in the case of a dispute.</li>
  //     <li {...style.h2}>Changes to These Terms and Conditions</li>
  //     <li {...style.p1}>We reserve the right, at Our sole discretion, to modify or replace these Terms at any time. If a revision is material We will make reasonable efforts to provide at least 30 days' notice prior to any new terms taking effect. What constitutes a material change will be determined at Our sole discretion.</li>
  //     <li {...style.p1}>By continuing to access or use Our Service after those revisions become effective, You agree to be bound by the revised terms. If You do not agree to the new terms, in whole or in part, please stop using the website and the Service.</li>
  //     <li {...style.h2}>Contact Us</li>
  //     <li {...style.p1}>If you have any questions about these Terms and Conditions, You can contact us:</li>
  //     <ul {...style.ul}>
  //       <li><li {...style.p1}>By visiting this page on our website: <Link to="https://www.elsyca.com/contact" label="www.elsyca.com/contact"/></li></li>
  //     </ul>
  //   </Box>
  // );
  return (
    <Box pad="large" overflow="auto" flex gap="small">
      <Heading>TERMS OF USE ELSYCA CUBE</Heading>
      <MainList>
        <li><Heading2>GENERAL</Heading2>
          <NestedList>
            <li><Paragraph>These Terms of Use (hereinafter, the “Terms”) relating to the use of ELSYCA CUBE SaaS via <Link to="https://app.elsycacube.com/login" label="https://app.elsycacube.com/login" /> (hereinafter, “Application Software”) provided by ELSYCA NV, registered with the Crossroads Bank of Enterprises under number 0460.049.026 and having its registered office at Vaartdijk 3/603, 3018 Wijgmaal (Belgium), or by any of its affiliates (hereinafter, “ELSYCA”) will apply to: (a) all use of the Application Software made by a Customer (i.e. person or company having a valid account); (b) all purchases placed by Customer with ELSYCA in the Application Software (hereinafter, “In-App Purchases”); and (c) any order confirmations issued by ELSYCA.</Paragraph></li>
            <li><Paragraph>ELSYCA is the licensor, Customer is the user and licensee under these Terms (hereinafter, “License”).</Paragraph></li>
            <li><Paragraph>The Terms will replace any other terms and conditions stated orally by Customer or set forth in any document issued by Customer either before or after issuance of any document by ELSYCA setting forth or referring to these Terms. Such any other terms and conditions are hereby explicitly rejected and they will be wholly inapplicable to any performance by ELSYCA, who will not be bound by them.</Paragraph></li>
            <BoxedLi><Paragraph>USE OF THE APPLICATION SOFTWARE IS EXPRESSLY CONDITIONAL ON CUSTOMER’S ACCEPTANCE OF ALL OF THESE TERMS. </Paragraph></BoxedLi>
            <li><Paragraph>Acceptance by Customer may be evidenced by Customer’s, or its representative’s, (a) use of the Application Software, (b) payment which is due to ELSYCA for the use of the Application Software (“Software Credits”), (c) or click on the ‘agree’ field when activating the Application Software.</Paragraph></li>
            <BoxedLi><Paragraph>ANY OFFER, WHETHER FOR FREE OR PAYABLE, IS AN ENGAGEMENT OF ELSYCA TO PROVIDE ACCESS FOR USE TO THE APPLICATION SOFTWARE. IT IS NOT AN ENGAGEMENT TO ACHIEVE ANY RESULT.</Paragraph></BoxedLi>
            <li><Paragraph>Likewise, any delivery date is a mere estimation, and is not binding on ELSYCA. ELSYCA undertakes its best effort to process the Customer’s content within reasonable time. However, the Customer understand that processing time in the Application Software may depend on several factors such as the complexity of the analysis, the quality of the content and the total number of customers using the Software Application at the same time.</Paragraph></li>
            <BoxedLi><Paragraph>THE APPLICATION SOFTWARE ALLOWS THE CUSTOMER TO ANALYSE AND BALANCE THE CUSTOMER’S PCB DESIGN FOR MANUFACTURABILITY. A LICENSE IS PROVIDED “AS IS” WITHOUT OBLIGATION OR WARRANT ON THE SIDE OF ELSYCA. ELSYCA THEREFORE IS NOT LIABLE FOR ANY DAMAGES THAT RESULT FROM USING THE APPLICATION SOFTWARE OR REPORTS OR DRAWINGS GENERATED BY THE APPLICATION SOFTWARE (INCORPORATING SUGGESTIONS ON IMPROVEMENTS OR ADAPTATIONS).</Paragraph></BoxedLi>
            <li><Paragraph>These Terms are also applicable in case ELSYCA provides a temporary or free trial License on the Application Software to Customer. The purpose of a free or trial License is to allow the Customer to evaluate whether the Application Software could be useful. ELSYCA may decide to terminate a free or trial License with immediate effect and without ground or notification.</Paragraph></li>
            <li><Paragraph>ELSYCA reserves the right at any time, and from time to time, with or without cause and without prior notification: (a) to amend the Terms; (b) to change the Application Software including eliminating or discontinuing, temporarily or permanently any functionality or feature of the Application Software without any liability against the Customer; (c) to amend the price or value of Software Credits for In-App Purchases.</Paragraph></li>
          </NestedList>
        </li>
        <li><Heading2>PRICES, INVOICING, PAYMENT AND SUSPENSION</Heading2>
          <NestedList>
            <BoxedLi><Paragraph>THE APPLICATION SOFTWARE IS ACTIVATED AFTER CREATION OF A VALID ACCOUNT AND, DEPENDING ON THE CUSTOMER’S SELECTION OF PRICING (<Link to="https://www.elsycacube.com/pricing" label="https://www.elsycacube.com/pricing" />), UPON RECEIPT OF PAYMENT OF THE SOFTWARE CREDITS.</Paragraph></BoxedLi>
            <li><Paragraph>All prices are exclusive of all applicable taxes, levies or duties, and Customer will be solely responsible for the payment of all such amounts. ELSYCA will add taxes to the Software Credits, where it is required to do so by law, at the moment of invoicing, and Customer will pay them together with the price. All prices are payable in Euro (EUR) unless agreed differently.</Paragraph></li>
            <li><Paragraph>ELSYCA will invoice Customer for the License, as specified in the pricing model (<Link to="https://www.elsycacube.com/pricing" label="https://www.elsycacube.com/pricing" />), as well as for related services. Any Software Credits need to be paid in advance before use of the Application Software or parts thereof.</Paragraph></li>
            <li><Paragraph>All invoices, that will be sent electronically to the e-mail address in the account, are immediately due and payable upon receipt or a due date as mentioned on the invoice.</Paragraph></li>
            <li><Paragraph>If Customer does not pay the Software Credits due, Customer will be automatically, without ELSYCA’s notification being required, in default.  In addition, all amounts not paid by Customer on the due date mentioned in the invoice will bear an interest of eight percent (8%) per year. Furthermore, Customer agrees to pay any costs, fees and expenses incurred by ELSYCA in connection with the collection of the debt together with the applicable interest, with a minimum of one hundred fifty (150) EUR.</Paragraph></li>
            <li><Paragraph>If Customer has not or not timely paid the Software Credits, ELSYCA may automatically suspend the License and use of the Application Software. Customer acknowledges and agrees that ELSYCA is not obliged to continue rendering any services or providing access to the Application Software as long as the related invoice is not paid.</Paragraph></li>
            <BoxedLi><Paragraph>ANY IN-APP PURCHASES OR PURCHASE OF SOFTWARE CREDITS CANNOT BE CANCELLED AFTER THE CUSTOMER HAS INITIATED OR ACTIVATED THE PROCESS. NO OBLIGATION RESTS UPON ELSYCA TO REIMBURSE IN CASH OR OTHERWISE SUCH PURCHASES.</Paragraph></BoxedLi>
            <li><Paragraph>ELSYCA is not responsible for any failures or errors during payment that are due to the Customer, the Customer’s bank, the store where the Application Software may be offered or the payment software from a third party. The Customer will directly contact the bank, store or the payment software provider in case of any payment related issues. </Paragraph></li>
          </NestedList>
        </li>
        <li><Heading2>LICENSE</Heading2>
          <NestedList>
            <li><Paragraph>Subject to Customer’s fulfilment of all obligations under the Terms, Customer will have a non-exclusive, personal, non-transferable, non-sublicensable license to use the Application Software for the purpose as agreed between ELSYCA and Customer.</Paragraph></li>
            <li><Paragraph>Customer agrees not to use the Application Software for any purpose that may be detrimental to ELSYCA’s business activities or commercial interests.</Paragraph></li>
            <li><Paragraph>ELSYCA may use a license key or similar control mechanism to ensure compliance with the use and limitations of the License.</Paragraph></li>
          </NestedList>
        </li>
        <li><Heading2>ACCOUNT</Heading2>
          <NestedList>
            <BoxedLi><Paragraph>TO ACCESS THE APPLICATION SOFTWARE, THE CUSTOMER SHALL HAVE THE NECESSARY HARDWARE AND A FAST INTERNET CONNECTION.</Paragraph></BoxedLi>
            <li><Paragraph>When creating an account, the Customer: (a) shall identify him/herself accurately and honestly; (b) cannot use any nickname, but shall disclose first name and family name; (c) shall be solely responsible for maintaining the confidentiality and security of the account login information such as user name and password, which are personal and cannot be transferred to any third party; (d) will be liable towards ELSYCA for any false account information; (e) shall be fully responsible for all activities that occur under its personal account; (f) agrees to immediately notify ELSYCA of any unauthorized use, or suspected unauthorized use of any account or any other breach of security.</Paragraph></li>
            <li><Paragraph>The Customer represents and warrants not to be located in a country that is subject to any embargo or export/import restrictions. The Customer represents and warrants not to use the Application Software for unlawful or criminal actions.</Paragraph></li>
          </NestedList>
        </li>
        <li><Heading2>CUSTOMER CONTENT</Heading2>
          <NestedList>
            <BoxedLi><Paragraph>THE CUSTOMER IS SOLELY RESPONSIBLE FOR THE CUSTOMER CONTENT WHEN USING THE APPLICATION SOFTWARE. THE CUSTOMER SHALL ENSURE THAT THE CONTENT IS UPLOADED IN A CORRECT FORMAT WITH CLEAR AND DETAILED IMAGES.</Paragraph></BoxedLi>
            <BoxedLi><Paragraph>ELSYCA IS NOT RESPONSIBLE IF THE APPLICATION SOFTWARE CANNOT PROCESS THE CONTENT OR IF THE CUSTOMER HAS UPLOADED IMPROPER, INACCURATE OR WRONGFUL CONTENT. NO OBLIGATION RESTS UPON ELSYCA TO REIMBURSE ANY USED SOFTWARE CREDITS.</Paragraph></BoxedLi>
            <li><Paragraph>The Customer represents and warrants that he is entitled to use the content, business data, PCB design and lay-out, images, specifications, etc. (hereinafter, “Content”) and that uploading the Content does not violate any intellectual property, privacy, contractual rights or obligations.</Paragraph></li>
            <li><Paragraph>By uploading Content in the Application Software the Customer grants ELSYCA the right under the License to: (a) process the Content for providing the service with the Application Software; (b) provide support or additional consultancy services if requested by the Customer.</Paragraph></li>
            <li><Paragraph>ELSYCA does not claim any intellectual property rights in relation to the Content uploaded or provided by the Customer.</Paragraph></li>
            <li><Paragraph>ELSYCA undertakes its best effort to keep the Content confidential when providing the services using the Application Software. ELSYCA may disclose the Content: (a) to its employees, officers, representatives or advisers who need to know such information within the framework of their function or employment in ELSYCA; (b) as it may be required by law, a court or competent jurisdiction or any governmental or regulatory authority; (c) where it is already in the public domain or known by ELSYCA (as shown by its written records) before its disclosure by Customer; or (d) where it has been independently developed by ELSYCA (as shown by its written records) before its disclosure by Customer.</Paragraph></li>
            <li><Paragraph>Content provided by the Customer will be deleted automatically from the Application Software within one (1) week after uploading and when closing the account. The Customer can delete the Content at any time. No obligation rests upon ELSYCA to archive or back-up the Content or any reports generated by the Application Software.  </Paragraph></li>
            <li><Paragraph>ELSYCA is entitled to use data from the use of the Application Software for statistical, internal product and service development, research and scientific purposes, provided that the business data are generic and Content is kept confidential.</Paragraph></li>
          </NestedList>
        </li>
        <li><Heading2>INTELLECTUAL PROPERTY</Heading2>
          <NestedList>
            <li><Paragraph>Any and all intellectual property rights - i.e. any and all (a) rights associated with works of authorship, including copyrights, mask work rights, and moral rights; (b) trademark or service mark rights; (c) trade secret rights, know-how; (d) patents, patent rights, and industrial property rights; (e) layout design rights, design rights, (f) trade and business names, domain names, database rights, rental rights and any other industrial or intellectual proprietary rights or similar right (whether registered or unregistered); (g) all registrations, applications for registration, renewals, extensions, continuations, divisions, improvements or reissues relating to any of these rights and the right to apply for, maintain and enforce any of the preceding items, in each case in any jurisdiction throughout the world - in the Application Software are the sole property of ELSYCA.</Paragraph></li>
            <li><Paragraph>Except as expressly set forth herein, no express or implied license or right of any kind is granted to Customer regarding the Application Software. </Paragraph></li>
            <li><Paragraph>Customer agrees to utilize the Application Software for Customer’s legitimate business purposes only and Customer agrees not to disseminate the Application Software, in any form, to any person or entity.</Paragraph></li>
            <li><Paragraph>Customer will not, and will not encourage any third party to: (i) modify, adapt, alter, translate, or create derivative works of the Application Software; (ii) reverse-engineer, decompile, disassemble, or attempt to derive the source code for the Application Software; (iii) distribute, license, sublicense, lease, rent, loan or otherwise transfer the Application Software or license key to any third party; (iv) remove, alter, obscure in any way the proprietary rights notices of ELSYCA; (v) use the Application Software for the purpose of creating a product or service competitive with the Application Software; (vi) use the Application Software in connection with any unsupported software or hardware.</Paragraph></li>
            <BoxedLi><Paragraph>CUSTOMER MAY NOT REMOVE OR CIRCUMVENT ANY TECHNICAL PROVISIONS THAT WERE INSTALLED TO PROTECT THE APPLICATION SOFTWARE (OR HAVE THESE REMOVED OR CIRCUMVENTED BY OTHERS).</Paragraph></BoxedLi>
            <li><Paragraph>In case Customer uses the Application Software in combination with opensource software it will ensure that ELSYCA is under such opensource license not obliged (i) to divulge or disclose any functionalities or technical aspects of the proprietary Application Software or (ii) to provide a free license on the Application Software.</Paragraph></li>
            <li><Paragraph>Customer will refrain from any action that can in any way be damaging ELSYCA or reputation associated with the trademark ELSYCA. Customer will refrain from registering the trademark ELSYCA or any similar trademark for similar activities anywhere in the world.</Paragraph></li>
          </NestedList>
        </li>
        <li><Heading2>CONFIDENTIALITY</Heading2>
          <NestedList>
            <li><Paragraph>Customer acknowledges that the Application Software incorporates confidential and proprietary information developed or acquired by or licensed to ELSYCA. Customer will take all reasonable precautions necessary to safeguard the confidentiality of the Application Software.</Paragraph></li>
            <li><Paragraph>Customer will not allow the removal or defacement of any confidentiality or proprietary notice placed on the Application Software. The placement of copyright notices on these items will not constitute publication or otherwise impair their confidential nature.</Paragraph></li>
            <li><Paragraph>Customer acknowledges that any unauthorized use or disclosure of the Application Software may cause irreparable damage to ELSYCA. If an unauthorized use or disclosure occurs, Customer will immediately notify ELSYCA and take all reasonable steps which may be available to recover the Application Software and to prevent subsequent unauthorized use or dissemination.</Paragraph></li>
            <li><Paragraph>Customer entitles ELSYCA to use the name of Customer as reference for marketing purposes.  </Paragraph></li>
          </NestedList>
        </li>
        <li><Heading2>DATA PROTECTION</Heading2>
          <NestedList>
            <li><Paragraph>Before the Application Software can be taken into use, Customer will read and accept the contents of the Privacy Statement.</Paragraph></li>
            <BoxedLi><Paragraph>THE APPLICATION SOFTWARE DOES ITSELF NOT STORE PERSONAL DATA OF CUSTOMER AND PERSONAL DATA OF THIRD PARTIES PROVIDED BY CUSTOMER. ELSYCA DOES NOT ARCHIVE OR BACK-UP ANY PERSONAL DATA PROCESSED USING THE APPLICATION SOFTWARE, NOR DOES ELSYCA ACCEPT ANY LIABILITY RELATED THERETO.</Paragraph></BoxedLi>
          </NestedList>
        </li>
        <li><Heading2>AUDIT</Heading2>
          <NestedList>
            <BoxedLi><Paragraph>THIS LICENSE ALLOWS CUSTOMER TO USE THE APPLICATION SOFTWARE ONLY ON AN INSTANCE AS SPECIFIED BY ELSYCA. CUSTOMER WILL NOT DISABLE, CIRCUMVENT, OR ATTEMPT TO DISABLE OR CIRCUMVENT IN ANY WAY THE LICENSE KEY OR THE USE AND LIMITATIONS SET BY ELSYCA OR THE APPLICATION SOFTWARE.</Paragraph></BoxedLi>
            <li><Paragraph>ELSYCA is entitled to audit the number of instances of the Application Software and to verify compliance with the offer and the Terms. Customer understands that, if needed, ELSYCA may scan any Customer’s software or hardware to check for any compliance issues.</Paragraph></li>
            <li><Paragraph>ELSYCA reserves the right to conduct, without prior notice, such audit or to have an authorized representative conduct the audit either remote or physically on location of Customer. Upon request of ELSYCA Customer will provide all assistance needed to execute the audit.</Paragraph></li>
            <li><Paragraph>Costs of audits conducted by ELSYCA or its authorized representative will be borne by ELSYCA unless the audit reveals that Customer is not in compliance with the Terms, or has committed fraud. In such case Customer will reimburse the total costs of the audit and will immediately pay any additional Software Credits and accrued interests as from the date of use of the Application Software. This leaves unaffected the right for ELSYCA to claim compensation for any damages suffered.</Paragraph></li>
          </NestedList>
        </li>
        <li><Heading2>WARRANTIES AND DISCLAIMERS</Heading2>
          <NestedList>
            <BoxedLi><Paragraph>EXCEPT AS EXPRESSLY PROVIDED IN THESE TERMS AND TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, THE LICENSE AND ANY SERVICES RELATED THERETO ARE PROVIDED “AS IS”.</Paragraph></BoxedLi>
            <li><Paragraph>ELSYCA makes no and hereby disclaims all other warranties, covenants or representations, or conditions, whether written, oral, express or implied including, without limitation, any implied warranties of satisfactory quality, course of dealing, trade usage or practice, merchantability, suitability, availability, title, non-infringement, or fitness for a particular use or purpose, with respect to the use, misuse, or inability to use the Application Software, as the case may be, provided to Customer by ELSYCA. </Paragraph></li>
            <li><Paragraph>ELSYCA does not warrant that the Application Software is error-free. Customer specifically acknowledges and agrees that ELSYCA cannot be held liable for any errors when using the Application Software or as an outcome of using the Application Software. </Paragraph></li>
            <li><Paragraph>Customer acknowledges and agrees that there are risks inherent to transmitting information over the Application Software and ELSYCA is not responsible and cannot be held liable for any losses of Customer’s Content.</Paragraph></li>
            <li><Paragraph>Customer represents and warrants to ELSYCA that it has the authority to contract (i.e. accepting offers and placing orders) and to accept these Terms, and that any information, confidential or not, as well as personal data, provided to ELSYCA by Customer is accurate and truthful.</Paragraph></li>
            <li><Paragraph>Customer will not: (a) infringe any intellectual property rights of third parties when using the Application Software; (b) use any sources containing viruses, worms or other malicious computer programming codes intended to damage or actually damage the Application Software or, ELSYCA’s system or data; or (c) otherwise violate the rights of a third party.</Paragraph></li>
          </NestedList>
        </li>
        <li><Heading2>INDEMNIFICATION</Heading2>
          <NestedList>
            <li><Paragraph>ELSYCA will defend Customer as specified herein against any founded and well-substantiated claims brought by third parties to the extent such claim is based on an infringement of any registered intellectual property rights of such third party by the Application Software and excluding any claims resulting from: (a) the unauthorized use of the Application Software by Customer or by third parties with the consent of Customer; (b) the modification of the Application Software by Customer or by third parties with the consent of Customer; (c) the use of the Application Software in violation of any of the present Terms by Customer or by third parties with the consent of Customer.</Paragraph></li>
            <li><Paragraph>Such indemnity obligation will be conditional upon the following: (a) ELSYCA is given prompt written notice by Customer of any such claim; (b) ELSYCA is granted sole control of the defence and settlement of such a claim; (c) upon ELSYCA’s request, Customer fully cooperates with ELSYCA in the defence and settlement of such a claim; and (d)  Customer makes no admission as to ELSYCA’s liability in respect of such a claim, nor does Customer agree to any settlement in respect of such a claim without ELSYCA’s prior written consent.</Paragraph></li>
            <li><Paragraph>Provided these conditions are met, if such a violation is asserted ELSYCA’s sole responsibility will be at its own expense and option either (i) to procure the right/license to continue using the Application Software ; and/or  (ii) to modify the Application Software to non-infringing; and/or (iii) to take legal action against such third party if ELSYCA is convinced that any asserted intellectual property rights are not valid or the Application Software does not infringe such intellectual property rights. </Paragraph></li>
            <li><Paragraph>The foregoing states the entire liability and obligation of ELSYCA and the sole remedy of Customer with respect to any infringement of any intellectual property rights.</Paragraph></li>
            <li><Paragraph>Customer hereby agrees to indemnify and hold harmless ELSYCA and its current and future affiliates, officers, directors, employees, agents, consultants and representatives from each and every demand, claim, loss, liability, or damage of any kind whatsoever, including reasonable attorney’s fees, whether in tort or in contract, that it or any of them may incur by reason of, or arising out of, any claim which is made by any third party with respect to: (a) any breach or violation by Customer or by any of Customer’s employees, helpers or agents of any provisions of these Terms; (b) any information or business data provided or uploaded by Customer to ELSYCA or in the Application Software that violates any intellectual property rights or any other kind of right of a third party; and (c) fraud, intentional misconduct, or gross negligence committed by Customer.</Paragraph></li>
          </NestedList>
        </li>
        <li><Heading2>LIABILITY</Heading2>
          <NestedList>
            <BoxedLi><Paragraph>TO THE MAXIMUM EXTENT PERMITTED UNDER APPLICABLE LAW, ELSYCA’S LIABILITY ARISING OUT OF OR IN CONNECTION WITH THE LICENSE, APPLICATION SOFTWARE OR RELATED SERVICES UNDER THESE TERMS WHETHER IN CONTRACT, WARRANTY, TORT OR OTHERWISE, WILL BE SUBJECT TO THE RESTRICTIONS SET FORTH IN THESE TERMS, AND IT WILL NOT EXCEED THE VALUE OF THE SOFTWARE CREDITS PAID BY CUSTOMER AND MAXIMUM TEN THOUSAND EURO, WHICHEVER IS MORE.</Paragraph></BoxedLi>
            <li><Paragraph>To the extent legally permitted under applicable law, ELSYCA will not be liable to Customer or any third party, for any special, indirect, exemplary, punitive, incidental or consequential damages of any nature including, but not limited to damages or costs due to loss of profits, loss of data, revenue or income, goodwill, production of use, procurement of substitute services, fatal injuries, including but not limited to any miscalculations, incorrect conversion of data, or the use, misuse, or inability to use the Application Software, regardless of the cause of action or the theory of liability, whether in tort, contract, or otherwise.</Paragraph></li>
            <li><Paragraph>For the avoidance of doubt, ELSYCA will not be liable for any claims resulting from: (a) Customer’s or Customer’s employees’, helpers’ or agents’ unauthorized use of the Application Software; (b) Customer’s or any third party’s unauthorized modification of the Application Software; (c) Customer’s use of the Application Software in combination with any incompatible  hardware or software; (d) Customer’s or Customer’s employees’, helpers’ or agents’ use of the Application Software in violation of any of the Articles of these Terms.</Paragraph></li>
            <li><Paragraph>The exclusions and limitations of liability under these Terms will operate to the benefit of ELSYCA’s affiliates, subcontractors and resellers to the same extent such provisions operate to the benefit of ELSYCA; and all exclusions and limitations of liability enforceable towards Customer are also, to the same extent, enforceable to Customer’s employees, patients, helpers and/or agents.</Paragraph></li>
          </NestedList>
        </li>
        <li><Heading2>MAINTENANCE AND SUPPORT</Heading2>
          <NestedList>
            <li><Paragraph>This License does not cover maintenance and support in relation to the Application Software, unless agreed otherwise. </Paragraph></li>
            <li><Paragraph>The terms and conditions for maintenance and support are covered in separate Support Terms.</Paragraph></li>
            <li><Paragraph>The support terms need to be signed and accepted by the Customer at the latest on the start date of the license in order to benefit from any services provided by ELSYCA.</Paragraph></li>
            <BoxedLi><Paragraph>DURING THE TERM, ELSYCA MAY, IN ITS SOLE DISCRETION, PROVIDE CUSTOMER WITH CERTAIN UPDATES OR UPGRADES OF THE APPLICATION SOFTWARE OR ANY PART THEREOF. FOR THE AVOIDANCE OF DOUBT, ELSYCA IS NOT OBLIGATED TO PROVIDE ANY UPDATES OR UPGRADE.</Paragraph></BoxedLi>
          </NestedList>
        </li>
        <li><Heading2>TERM AND TERMINATION</Heading2>
          <NestedList>
            <li><Paragraph>The License may be offered by ELSYCA for a specific amount of Software Credits or a specific term or a combination thereof. Any offer made to Customer will identify Customer’s right and the start date of the License. If no start date has been explicitly mentioned, the start date is the first date of use of the Application Software.</Paragraph></li>
            <li><Paragraph>Unless agreed otherwise, a License is offered for a duration of maximum one (1) year. This License does not automatically renew, unless agreed otherwise.</Paragraph></li>
            <li><Paragraph>ELSYCA may terminate with immediate effect the License, without any compensation being due to Customer or reimbursement of Software Credits: (a) if ELSYCA believes or has reasonable grounds to suspect that Customer is violating these Terms (for example in case Customer attempts to exceed the use of the Application Software beyond the limits of the License or the license key); or (b) if Customer fails to pay any amount when due. </Paragraph></li>
            <li><Paragraph>Without prejudice to the foregoing, and at ELSYCA’s sole discretion, ELSYCA may decide, in the event of breach, to suspend the License or rendering of related services.</Paragraph></li>
            <li><Paragraph>Upon the termination for any reason whatsoever in accordance with the provisions of these Terms, Customer will discontinue any use of the Application Software. ELSYCA will prevent further access to the Application Software.</Paragraph></li>
            <li><Paragraph>Upon any termination or expiration of this License, Customer understands that any data may no longer be processed using the Application Software. Customer is solely responsible for retrieving its data from the Application Software prior to termination for any reason.</Paragraph></li>
            <li><Paragraph>If the Customer buys Software Credits to conduct In-App Purchases, such Software Credits are valid until the end of the term of the License. Software Credits that have not been used within the term, will be considered expired and cannot be recovered. The Customer is not entitled to reimbursement of the Software Credits in case of termination, early termination or suspension of use of the Application Software. </Paragraph></li>
            <li><Paragraph>Termination will not exempt Customer from paying any pending invoices nor from paying services already rendered (regardless whether or not the corresponding invoice has already been issued).</Paragraph></li>
            <li><Paragraph>Customer understands that ELSYCA’s audit right will survive termination for a period of two (2) years. Likewise, rights and obligations under sections 1.8, 2.5, 2.7, 2.8, 3.2, 5.8, 6.5, 6.7, 7, 9, 10, 11 and 12 will survive any such termination. </Paragraph></li>
          </NestedList>
        </li>
        <li><Heading2>FORCE MAJEURE</Heading2>
          <NestedList>
            <li><Paragraph>ELSYCA will not be liable for any failure or delay if such delay or failure is due to causes beyond ELSYCA’s control, including, but not limited to, war, strikes or labour disputes, embargoes, pandemic conditions, government orders, telecommunications, network, computer, server or internet downtime, unauthorized access to ELSYCA’s information technology systems by third parties or any other cause beyond the reasonable control of ELSYCA (hereinafter, the “Force Majeure Event”).</Paragraph></li>
            <li><Paragraph>ELSYCA will notify Customer, for mere informative purposes and without assuming any liability or obligations in respect of it, of the nature of such Force Majeure Event and the effect on the License and its ability to render services, as well as of ELSYCA’s plan to mitigate the effects of such Force Majeure Event.</Paragraph></li>
          </NestedList>
        </li>
        <li><Heading2>MISCELLANEOUS</Heading2>
          <NestedList>
            <li><Paragraph>These Terms constitute the entire agreement and understanding between Customer and ELSYCA with respect to License and rendering of related services in respect of the Application Software and supersedes all prior oral or written agreements, representations or understandings between Customer and ELSYCA relating to the same subject.</Paragraph></li>
            <li><Paragraph>If any provision of these Terms is, for any reason, held to be invalid or unenforceable, the other provisions of these Terms will remain enforceable and the invalid or unenforceable provision will be deemed modified so that it is valid and enforceable to the maximum extent permitted by law.</Paragraph></li>
            <li><Paragraph>Any failure to enforce any provision of the Terms will not constitute a waiver thereof or of any other provision.</Paragraph></li>
            <li><Paragraph>Customer’s obligations and rights under the present Terms may not be assigned by Customer without the prior written approval of ELSYCA. ELSYCA may assign its obligations and rights to (a) a parent company or affiliated company, (b) an acquirer of all or substantially all of ELSYCA’s assets involved in the operations relevant to these Terms, or (c) a successor by merger or other combination. Any purported assignment in violation of this Article will be void. These Terms may be enforced by and is binding on permitted successors and assigns.</Paragraph></li>
            <li><Paragraph>All notices from ELSYCA intended for receipt by Customer will be deemed delivered and effective when sent to the email address provided by Customer, without prejudice to any other additional mean of communication used for giving such notice. If Customer changes its email address, Customer must inform ELSYCA of that circumstance in written.</Paragraph></li>
            <BoxedLi><Paragraph>THESE TERMS WILL BE EXCLUSIVELY GOVERNED BY AND CONSTRUED IN ACCORDANCE WITH THE LAWS OF BELGIUM, WITHOUT GIVING EFFECT TO ANY OF ITS CONFLICT OF LAW PRINCIPLES OR RULES. THE COURTS AND TRIBUNALS OF LEUVEN (BELGIUM) WILL HAVE SOLE JURISDICTION SHOULD ANY DISPUTE ARISE IN RELATING TO THE TERMS.</Paragraph></BoxedLi>
          </NestedList>
        </li>
      </MainList>
      <BoldDiv>Version June 2021</BoldDiv>
    </Box>
  );
}

export const PrivacyPolicy = () => {
  return (
    <Box pad="large" overflow="auto" flex gap="small">
      <Heading>PRIVACY STATEMENT</Heading>
      <BoxedDiv><Paragraph>THE APPLICATION SOFTWARE DOES ITSELF NOT STORE PERSONAL DATA OF CUSTOMER AND DATA OF THIRD PARTIES PROVIDED BY CUSTOMER (UNLESS EXPLICITLY AGREED OTHERWISE). ELSYCA DOES NOT ARCHIVE OR BACK-UP ANY DATA PROCESSED USING THE APPLICATION SOFTWARE, NOR DOES ELSYCA ACCEPT ANY LIABILITY RELATED THERETO.</Paragraph></BoxedDiv>
      <MainList>
        <li><Heading2>Definitions</Heading2>
          <NestedList>
            <li><Paragraph>“ELSYCA”: ELSYCA NV, registered with the Crossroads Bank of Enterprises under number 0460.049.026 and having its registered office at Vaartdijk 3/603, 3018 Wijgmaal (Belgium).</Paragraph></li>
            <li><Paragraph>“Personal Data”: any information relating to an identified or identifiable natural person; an identifiable natural person is a person who can be identified, directly or indirectly, in particular by reference to an identifier such as a name, an identification number, location data, an online identifier or to one or more factors specific to the physical, physiological, genetic, mental, economic, cultural or social identity of that natural person.</Paragraph></li>
            <li><Paragraph>“Customer”: any existing person or company using the Application Software for its legitimate commercial purposes.</Paragraph></li>
            <li><Paragraph>“Application Software”: ELSYCA CUBE SaaS available via <Link to="https://app.elsycacube.com/login" label="https://app.elsycacube.com/login" />.</Paragraph></li>
            <li><Paragraph>“License”: ELSYCA is the licensor, Customer is the user and licensee under the Terms of Use of the Application Software. </Paragraph></li>
          </NestedList>
        </li>
        <li><Heading2>Personal Data</Heading2>
          <NestedList>
            <li><Paragraph>In order to allow Customer to use the Application Software, ELSYCA processes a limited number of Personal Data received from Customer:</Paragraph>
              <NestedListB>
                <li><Paragraph>Information that Customer provides upon subscription namely, name, function, company name, VAT number (if applicable), address, city, postal code, country, e-mail, telephone number, payment information (card number or account).</Paragraph></li>
                <li><Paragraph>Communication and correspondence with Customer.</Paragraph></li>
              </NestedListB>
            </li>
            <li><Paragraph>If information is provided by Customer via a form, the fields indicated with a (*) strictly are necessary for the purposes for which they are processed. If these are not filled in, the submission of the form will not be executed, the subscription cannot be completed and no access to the Application Software will be granted.</Paragraph></li>
            <li><Paragraph>ELSYCA processes Personal Data to carry out the License.  The Personal Data of Customer are used to:</Paragraph>
              <NestedListB>
                <li><Paragraph>Offer or execute the access to the Application Software;</Paragraph></li>
                <li><Paragraph>Allow Customer to use the Application Software;</Paragraph></li>
                <li><Paragraph>Allow Customer to upload business content in the Application Software;</Paragraph></li>
                <li><Paragraph>Manage incidents notified by Customer when using the Application Software;</Paragraph></li>
                <li><Paragraph>Notify Customer on the updates or upgrades to the Application Software;</Paragraph></li>
                <li><Paragraph>Audit the use of the Application Software;</Paragraph></li>
                <li><Paragraph>Perform account administration;</Paragraph></li>
                <li><Paragraph>Comply with applicable law.</Paragraph></li>
              </NestedListB>
            </li>
            <li><Paragraph>If the processing of Personal Data is based on the Customer’s consent, Customer has the right to withdraw the consent at any time, without this affecting the legality of the processing on the basis of the permission before its withdrawal. </Paragraph></li>
            <li><Paragraph>Customer will ensure that it is entitled to transfer the relevant personal data to ELSYCA, so that ELSYCA may lawfully use and process the Personal Data under the License.</Paragraph></li>
          </NestedList>
        </li>
        <li><Heading2>Disclosure of your information</Heading2>
          <NestedList>
            <li><Paragraph>ELSYCA does not sell or rent the Personal Data to third parties.</Paragraph></li>
            <li><Paragraph>Within ELSYCA Personal Data are only made available to its staff on a need-to-know basis. Customer acknowledges and agrees that the Personal Data will be shared with ELSYCA’s employees, representatives, officers, directors, advisors, subcontractors having a need to know such data for the purposes of executing the License.</Paragraph></li>
            <li><Paragraph>ELSYCA may disclose or share Customer’s Personal Data when required by law, or to enforce or apply the License, to protect ELSYCA’s rights, property and safety. This includes exchanging information with third party companies and organisations for the purposes of fraud protection and credit risk reduction.</Paragraph></li>
            <li><Paragraph>ELSYCA can change its corporate structure by changing its legal form, merger, acquisitions and sales. In such transactions, Personal Data may be transmitted in accordance with this Privacy Statement and the applicable data protection legislation.</Paragraph></li>
          </NestedList>
        </li>
        <li><Heading2>Storage and retention of Personal Data</Heading2>
          <NestedList>
            <li><Paragraph>The Personal Data that ELSYCA collects from Customer are transferred to data-servers hosted in any country of the European Union. Customer acknowledges and agrees that the Personal Data may be transferred or stored in any country of the European Union and that ELSYCA may use subcontractors for hosting services.</Paragraph></li>
            <li><Paragraph>Although ELSYCA undertakes its best effort in protecting Customer’s Personal Data, ELSYCA cannot guarantee the total security of Personal Data.</Paragraph></li>
            <li><Paragraph>ELSYCA takes appropriate technical and organisational measures to prevent unauthorised access to the Personal Data received. Such technical measures include, amongst others: Secure Sockets Layer (SSL), Transport Layer Security (TLS), Login/Password, Firewall, Amazon S3 (back-up, server-side encryption) for content and business data and Nimbu (server-side encryption) for Personal Data.</Paragraph></li>
            <li><Paragraph>Upon instructions of the Customer and termination of the License, ELSYCA will delete all Personal Data from the Application Software. In case the Customer requests termination assistance, ELSYCA will only provide exit services upon the Customer accepting an offer related thereto and advance payment of such amount.</Paragraph></li>
          </NestedList>
        </li>
        <li><Heading2>IP addresses and cookies</Heading2>
          <NestedList>
            <li><Paragraph>ELSYCA may collect information about Customer’s computer, including where available IP address, operating system and browser type, for system administration and to report aggregated information to Customer. This is statistical data about Customer’s browsing actions and patterns and does not identify any Personal Data.</Paragraph></li>
            <li><Paragraph>For the same reason, ELSYCA may obtain information about Customer’s usage of the Application Software by using a cookie file which is stored on the hard drive of Customer’s computer. Cookies contain information that is transferred to Customer’s computer’s hard drive. They help ELSYCA to improve the access and use of the Application Software.</Paragraph></li>
            <li><Paragraph>Customer may refuse to accept cookies. However, if Customer selects this setting Customer may experience reduced ease of use of the Application Software. Unless Customer has adjusted the settings so that it will refuse cookies, the Application Software will issue cookies when Customer logs on to the Application Software.</Paragraph></li>
          </NestedList>
        </li>
        <li><Heading2>Aggregated statistics</Heading2>
          <NestedList>
            <li><Paragraph>ELSYCA may collect statistics about the behaviour of Customers and use of Application Software. For instance, ELSYCA may monitor the popular parts of the Application Software, the applications and functionalities most used, and the number of connections to external datasources.</Paragraph></li>
            <li><Paragraph>ELSYCA may display this information publicly or provide its Customers or third parties with expert reports. However, these business data are generic and anonymized and therefore not considered Personal Data. </Paragraph></li>
          </NestedList>
        </li>
        <li><Heading2>Customer rights</Heading2>
          <NestedList>
            <li><Paragraph>Customers have the right to request to review, rectify or erase Personal Data or to limit the processing of their Personal Data, as well as the right to objection to processing of the Personal Data and the right to request data transferability. </Paragraph></li>
            <li><Paragraph>All these requests should be addressed to info@elsyca.com.</Paragraph></li>
          </NestedList>
        </li>
        <li><Heading2>Changes to the Privacy Statement</Heading2>
          <NestedList>
            <li><Paragraph>ELSYCA has the right to make changes to this Privacy Statement. </Paragraph></li>
            <li><Paragraph>Any changes ELSYCA may make to the Privacy Statement in the future will be notified to Customer by email or through the Application Software.</Paragraph></li>
          </NestedList>
        </li>
      </MainList>
      <BoldDiv>Version June 2021</BoldDiv>
    </Box>
  )
}

// export default TermsAndConditions;