import React, {  Fragment } from 'react';
import styled from 'styled-components';
import { Box } from 'grommet';
import { Button, ButtonProps } from 'components';

export type ActionBarProps = {
  primary?: ButtonProps;
  secondary?: ButtonProps;
  back?: ButtonProps;
  reverse?: boolean;
  arrow?: React.ReactNode;
  msg?: React.ReactNode;
  analyzeDialog?: {
    state: boolean,
    setState: (state: boolean) => void,
    onStart: () => void,
    okLabel: string,
  }
};

const StyledBox = styled(Box)`
  min-width: -webkit-min-content
`;


const ActionBar = ({ primary, secondary, back, reverse, arrow, msg }: ActionBarProps) => {
  const hasBackBtn = back != null && back.label != null;
  const hasSecondaryBtn = secondary != null && secondary.label != null;
  const hasPrimaryBtn = primary != null && primary.label != null;
  // const { windowWidth } = useWindowSize();

  // // workaround for fixing improvement message to center if convenient

  // const [leftWidth, setLeftWidth] = useState(0);

  // const midBox = useRef<HTMLDivElement>(null);
  // const rightBox = useRef<HTMLDivElement>(null);

  // // first we set left width to zero
  // // this will trigger the next uselayouteffect so we can measure the actual width of the other components
  // useLayoutEffect(() => {
  //   if (rightBox.current != null && midBox.current != null) {
  //     setLeftWidth(0);
  //   }
  // }, [windowWidth])

  // // then we see if there is enough width in the bar element to center the msg
  // // if yes: set left element width equal to right element with so the msg is centered
  // // if not: set left element widht equal to the resting width inside the bar
  // useLayoutEffect(() => {
  //   if (leftWidth === 0 && rightBox.current != null && midBox.current != null) {
  //     let actionBarWidth =
  //       windowWidth
  //       - 60 // side nav
  //       - 40 // frame padding
  //       - 20 - 1; // gaps
  //     let rightWidth = rightBox.current.clientWidth;
  //     let midWidth = midBox.current.clientWidth;
  //     // let leftWidth = leftBox.current.clientWidth;
  //     setLeftWidth(Math.min(rightWidth-310, actionBarWidth - midWidth - rightWidth));
  //   }
  // }, [leftWidth]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Box
      direction="row"
      align="center"
      justify="between"
      gap="small"
      pad={{ bottom: 'small' }}
      margin={{ bottom: 'small' }}
      border="bottom"
      width="100%"
      flex={{grow: 0}}
    >

      <StyledBox align="start" flex={{grow: 1, shrink: 2}} basis="0">
        {hasBackBtn && <Button {...back} fontSize={17} />}
      </StyledBox>

      <Box align="center" justify="center" flex={{grow: 0, shrink: 1}}>
        {msg}
      </Box>

      <StyledBox direction="row" align="center" justify="end" flex={{grow: 1, shrink: 0}} basis="0">
        {(hasPrimaryBtn || hasSecondaryBtn) && (
          <Fragment>
            <Box wrap={false} direction="row" align="center">
              {arrow}
              {((reverse && hasPrimaryBtn) || (!reverse && !hasSecondaryBtn)) ?
                <Button primary {...primary} fontSize={17} />
                : <Button secondary {...secondary} fontSize={17}  />}
            </Box>
            {(hasPrimaryBtn && hasSecondaryBtn) ?
              (
                reverse ? <Button secondary {...secondary} margin={{left: "small"}} fontSize={17}/>
                  : <Button primary {...primary} margin={{left: "small"}} fontSize={17} />
              )
              : null}

            {/* {reverse && hasPrimaryBtn && <Button {...{ primary: true, ...primary }} size="large" />}
        {hasSecondaryBtn && <Button {...{ secondary: true, ...secondary }} size="large" />}
        {!reverse && hasPrimaryBtn && <Button {...{ primary: true, ...primary }} size="large" />} */}
          </Fragment>
        )}
      </StyledBox>
    </Box>
  );
};

export default ActionBar;
