import { useStore } from 'store';
import { Setting, Dimension } from 'settings';
import { formatValue } from 'components';
import { ParamsType, ResultsType } from './types';
import { useSettings } from 'store';
import { useSettingTranslations, useTranslations } from 'hooks';
import { useInputSources } from './upload_source_files/hooks';
// import { useSimulationSettings } from './settings/hooks';
import { getSnapshot, Instance } from 'mobx-state-tree';
import { ImageStore } from './store';


export function useDefaultUrls() {
  return {
    frontImage: "https://elsyca-pcbbalance-development.s3.amazonaws.com/74feeda4-ed00-4c6e-bf77-ee766119502a/preview-front.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAQKC5WRIPZ4ZD6J5Q%2F20210111%2Feu-west-3%2Fs3%2Faws4_request&X-Amz-Date=20210111T160011Z&X-Amz-Expires=3600&X-Amz-SignedHeaders=host&X-Amz-Signature=3cb6ce15ba1237a3ebac9a4327ce2ec971eb4a08deab9070c1d10b682723ffe7",
    backImage: "https://elsyca-pcbbalance-development.s3.amazonaws.com/74feeda4-ed00-4c6e-bf77-ee766119502a/preview-back.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAQKC5WRIPZ4ZD6J5Q%2F20210111%2Feu-west-3%2Fs3%2Faws4_request&X-Amz-Date=20210111T160059Z&X-Amz-Expires=3600&X-Amz-SignedHeaders=host&X-Amz-Signature=8db565dd85b54887c58ecf1c84e81ed0cd48a3123bcc31d512801b659f495507",
    analyzedXpl: "https://elsyca-pcbbalance-development.s3.amazonaws.com/74feeda4-ed00-4c6e-bf77-ee766119502a/analysis-result-xpl.xpl?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAQKC5WRIPZ4ZD6J5Q%2F20210111%2Feu-west-3%2Fs3%2Faws4_request&X-Amz-Date=20210111T160252Z&X-Amz-Expires=3600&X-Amz-SignedHeaders=host&X-Amz-Signature=a93b6d808fa09457fc6e0bed9fb89bd66f14574e1cc36e6d3761c7150e115561",
    balancedXpl: "https://elsyca-pcbbalance-development.s3.amazonaws.com/74feeda4-ed00-4c6e-bf77-ee766119502a/balanced-xpl.xpl?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAQKC5WRIPZ4ZD6J5Q%2F20210111%2Feu-west-3%2Fs3%2Faws4_request&X-Amz-Date=20210111T160313Z&X-Amz-Expires=3600&X-Amz-SignedHeaders=host&X-Amz-Signature=0cd141a9b7cbc3319b9f0facb01b085147f70e65b05ecf28dd34a2b41412031d",
    frontCopperAreas: "https://elsyca-pcbbalance-development.s3.amazonaws.com/74feeda4-ed00-4c6e-bf77-ee766119502a/balanced-frontB.txt?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAQKC5WRIPZ4ZD6J5Q%2F20210111%2Feu-west-3%2Fs3%2Faws4_request&X-Amz-Date=20210111T160355Z&X-Amz-Expires=3600&X-Amz-SignedHeaders=host&X-Amz-Signature=7a4dbbb4acd18dc1d203964ea8a9be80163889558b0f8ecc6c40ed64d262f415",
    backCopperAreas: "https://elsyca-pcbbalance-development.s3.amazonaws.com/74feeda4-ed00-4c6e-bf77-ee766119502a/balanced-backB.txt?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAQKC5WRIPZ4ZD6J5Q%2F20210111%2Feu-west-3%2Fs3%2Faws4_request&X-Amz-Date=20210111T160444Z&X-Amz-Expires=3600&X-Amz-SignedHeaders=host&X-Amz-Signature=9b469aadee5b5c9cd93c1aea5184f797637eacc1e24b062c329da9d6934f47d3",
  }
}

export function useSimulationLoading(id: string) {
  const store = useStore();
  let simulation = store.simulations.get(id);
  return simulation == null;
}

export function useSimulation(id: string) {
  const store = useStore();
  let simulation = store.simulations.get(id);
  // We use ! to assert it is there, we will (should?!) only call this when we
  // are sure ...
  return simulation!;
}

export function useCalculateKPI(id: string) {
  return useSimulation(id).settings.calculateKPI;
}

export function useResetBeforeUpload(id: string) {
  const simulation = useSimulation(id);
  return simulation?.resetToNew;
}

export function useResetBeforeAnalyzer(id: string) {
  const simulation = useSimulation(id);

  return simulation?.resetAnalyzerAndBalancer;
}

export function useBackToAnalyzerFromBalancer(id: string) {
  const simulation = useSimulation(id);

  return simulation?.backToAnalyzerFromBalancer;
}

export function useResetBeforeBalancer(id: string) {
  const simulation = useSimulation(id);

  return simulation?.resetBalancer;
}

export function useSimulationParameters(id: string): { [k: string]: Setting<Dimension> } {
  const simulation = useSimulation(id);

  let params = Object.fromEntries(
    simulation.analyzer.settingsUsed
      .getAllSettings()
      .map((setting: Setting<Dimension>): [string, Setting<Dimension>] => [setting.name, setting])
  );

  if (simulation.analyzer.settingsUsed.preferProcessTime) {
    if ('target_thickness' in params) {
      delete params['target_thickness'];
    }
  } else {
    if ('process_time' in params) {
      delete params['process_time'];
    }
    if (simulation.analyzer.result != null && simulation.analyzer.result.timeUpdate != null) {
      params['time_update'] = new Setting('time_update', simulation.analyzer.result.timeUpdate);
    }
  }
  return params;
}

export function useBalancingParameters(id: string) {
  const simulation = useSimulation(id);

  let params = Object.fromEntries(
    simulation.balancer.settingsUsed
      .getAllSettings()
      .map((setting: Setting<Dimension>): [string, Setting<Dimension>] => [setting.name, setting])
  );
  return params;
}

export type kpiValue = {
  key: string;
  text: string;
  front: string;
  back: string;
  frontOk: boolean;
  backOk: boolean;
}

function isValidString(s: string | undefined) {
  return s != null && s !== "";
}

function isValidImagePair(pair: { front: string | undefined, back: string | undefined } | undefined) {
  return pair != null && isValidString(pair.front) && isValidString(pair.back);
}

function isValidKpi(kpi: kpiValue[] | undefined) {
  return kpi != null && kpi.length > 0;
}

export function useSimulationReportData(id: string, balance?: boolean) {
  const simulation = useSimulation(id);
  const globalSettings = useSettings();
  const { labelFor } = useSettingTranslations();
  const t = useTranslations('simulation.upload');
  const sources = useInputSources(id);
  // const settings = useSimulationSettings(id);

  const r = useTranslations('simulation.analyze.results');
  const u = useTranslations('simulation.units');
  const calculateKPI = useCalculateKPI(id);

  const params = useSimulationParameters(id);

  let parameters = (Object.keys(params) as (keyof ParamsType)[]).map((k) => {
    const setting = params[k];
    if (setting.name === 'min' || setting.name === 'max') return null;
    const unit = globalSettings.getDefaultUnitFor(setting.dimension.getName()) || setting.value.unit;
    return { text: labelFor(setting, unit, true), value: formatValue({ value: setting.value, unit, precision: 2 }) }
  }).filter(v => v);
  let kpiparameters = (Object.keys(params) as (keyof ParamsType)[]).map((k) => {
    const setting = params[k];
    if (setting.name !== 'min' && setting.name !== 'max') return null;
    const unit = globalSettings.getDefaultUnitFor(setting.dimension.getName()) || setting.value.unit;
    return { text: labelFor(setting, unit, true), value: formatValue({ value: setting.value, unit, precision: 2 }) }
  }).filter(v => v);

  const bparams = useBalancingParameters(id);
  let bparameters = null;
  if (balance) {
    bparameters = (Object.keys(bparams) as (keyof ParamsType)[]).map((k) => {
      const setting = bparams[k];
      if (setting.name === "max_cu_fraction") return null;
      const unit = globalSettings.getDefaultUnitFor(setting.dimension.getName()) || setting.value.unit;
      return { text: labelFor(setting, unit, true), value: formatValue({ value: setting.value, unit, precision: 2 }) }
    }).filter(v => v);
  }

  function exportKPI(results: any): kpiValue[] | undefined {
    if (results == null) return undefined;
    return (Object.keys(results) as (keyof ResultsType)[]).map((k) => {
      if (k !== 'timeUpdate') {
        const result = results[k];
        const unit = globalSettings.getDefaultUnitFor(result.front.unit.dimension.getName()) || result.front.unit;
        return {
          key: k,
          text: `${r(k)}${unit.name !== "no_unit" ? ` [${u(`${unit.name}.name`)}]` : ''}`,
          front: formatValue({ value: result.front, unit, precision: 2 }),
          back: formatValue({ value: result.back, unit, precision: 2 }),
          frontOk: calculateKPI(k, result.front),
          backOk: calculateKPI(k, result.back),
        }
      } else return null;
    }).filter((k) => { return k != null }) as { key: string, text: string, front: string, back: string, frontOk: boolean, backOk: boolean }[];
  }

  let files = [
    { text: t('front'), value: sources.front?.name || "" },
    { text: t('back'), value: sources.back?.name || "" },
    { text: t('through'), value: sources.through?.name || "" },
  ];

  let fileNames = getSnapshot(simulation.sources.fileNames);

  const images = getSnapshot<Instance<typeof ImageStore>>(simulation.images);
  const akpi = exportKPI(simulation.analyzer.result);
  const bkpi = balance ? exportKPI(simulation.balancer.result) : null;
  const kpi = { analyse: akpi, balance: bkpi };

  const valid = {
    analyse:
      isValidString(images.colorPlotBar) &&
      isValidImagePair(images.analyzed) &&
      isValidKpi(kpi.analyse),
    balance:
      balance && 
      isValidString(images.colorPlotBar) &&
      isValidImagePair(images.balanced) &&
      isValidKpi(kpi.balance!),
    balanceAreas:
      balance &&
      isValidString(images.colorPlotBar) &&
      isValidImagePair(images.balancedWithAreas)
  }

  return {
    valid,
    id,
    name: simulation.name,
    date: (new Date()).toLocaleString(),
    parameters,
    bparameters,
    kpiparameters,
    files,
    images,
    kpi,
    fileNames,
  }
}

export function useStartSimulation() {
  const store = useStore();

  return () => {
    const sim = store.addSimulation();

    return sim.id;
  };
}

export function useExportedSettingsFor(id: string, category: string) {
  const simulation = useSimulation(id);
  // const globalSettings = useSettings();
  const settings = simulation.settings.getAllSettingsFor(category);
  var result: { [key: string]: number } = {};
  for (let i = 0; i < settings.length; ++i) {
    const setting = settings[i];
    // let unit: Unit<Dimension> | undefined = globalSettings.getDefaultUnitFor(setting.dimension.getName());
    // result[setting.name] = (unit == null) ? setting.getValueAsBaseUnit() : setting.getValueAsUnit(unit);
    // we dont want to give color plot renderer the non metric values, because the xpl file contains metric values
    result[setting.name] = setting.getValueAsBaseUnit();
  }
  return result;
}
