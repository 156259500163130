// import { vec2, vec3 } from 'gl-matrix'

export type XplRenderData = {
    points?: Float32Array; //vec3[];
    normals?: Float32Array; //vec3[];
    triangles?: Uint16Array;
    textureCoordinates?: Float32Array;
    scalars?: Float32Array;
    // extremes: Map<number, vec2>;
    min?: Float32Array;
    max?: Float32Array;
    gerber?: string;
    balanceRenderData?: BalanceRenderData;
}


export type BalanceRenderData = {
    cellValues?: Float32Array;
    objects?: {
        points: Float32Array;
        normals: Float32Array;
        triangles: Uint16Array;
        vbo?: WebGLBuffer;
        ibo?: WebGLBuffer;
    }[],
    nbRows?: number;
    nbCols?: number;
}

export class PanelData {
    front: XplRenderData;
    back: XplRenderData;
    // frontB: XplRenderData;
    // backB: XplRenderData;
    // frontBA: BalanceRenderData;
    // backBA: BalanceRenderData;
    // scalarList: string[];
    // nbRows: number;
    // nbCols: number;

    constructor() {
        this.generateTextureCoordinates = this.generateTextureCoordinates.bind(this);

        this.front = {};
        this.back = {};
        // this.frontB = {};
        // this.backB = {};
        // this.frontBA = {};
        // this.backBA = {};
    }

    generateTextureCoordinates = () => {
        this._generateTextureCoordinatesFor(this.front);
        this._generateTextureCoordinatesFor(this.back);
        // this._generateTextureCoordinatesFor(this.frontB);
        // this._generateTextureCoordinatesFor(this.backB);
    }

    _generateTextureCoordinatesFor = (data: XplRenderData) => {
        if (data.points == null || data.max == null || data.min == null) return;

        let width: number = data.max[0] - data.min[0]; // x
        let height: number = data.max[1] - data.min[1]; // y
        let minX: number = data.min[0];

        let minY: number = data.min[1];

        data.textureCoordinates = new Float32Array(data.points.length / 3 * 2);

        for (let i = 0; i < data.points.length; ++i) {
            data.textureCoordinates[2 * i] = (data.points[i * 3] - minX) / width;
            data.textureCoordinates[2 * i + 1] = 1 - ((data.points[i * 3 + 1] - minY) / height);
        }

    }

    _newXplRenderData = (): XplRenderData => {
        return {
            // points: [], 
            // normals: [], 
            // triangles: [],
            // scalars: new Map<number, Float32Array>(), 
            // extremes: new Map<number, vec2>(),
            // min: vec3.create(),
            // max: vec3.create(),
        }
    }

}