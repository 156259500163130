import React from 'react';
import { Routes } from 'routes';
import Archive from './archive';
import Settings from './settings';
import Account from './account';
import { Callback } from './payments';
import FourOhFour from './404';
import Home from './home';
import AuthScreen, { Login, Register, ForgotPassword, ResetPassword, ConfirmEmail } from './login';
import Simulation from './simulation';
import Admin from './admin';

export const screens = {
  [Routes.Home]: <Home />,
  [Routes.Simulation]: <Simulation route={Routes.Simulation} />,
  [Routes.UploadSources]: <Simulation route={Routes.UploadSources} />,
  [Routes.Preview]: <Simulation route={Routes.Preview} />,
  [Routes.Analyze]: <Simulation route={Routes.Analyze} />,
  [Routes.Balance]: <Simulation route={Routes.Balance} />,
  [Routes.Admin]: <Admin />,
  [Routes.Archive]: <Archive />,
  [Routes.Settings]: <Settings />,
  [Routes.SimulationSettings]: <Simulation route={Routes.SimulationSettings} />,
  [Routes.Account]: <Account />,
  [Routes.Callback]: <Callback />,
  [Routes.Login]: (
    <AuthScreen>
      <Login />
    </AuthScreen>
  ),
  [Routes.Register]: (
    <AuthScreen>
      <Register />
    </AuthScreen>
  ),
  [Routes.ForgotPassword]: (
    <AuthScreen>
      <ForgotPassword />
    </AuthScreen>
  ),
  [Routes.ResetPassword]: (
    <AuthScreen>
      <ResetPassword />
    </AuthScreen>
  ),
  [Routes.ConfirmEmail]: (
    <AuthScreen>
      <ConfirmEmail />
    </AuthScreen>
  ),
  [Routes.NotFound]: <FourOhFour />,
};

export default screens;
