import React from 'react';
import { CheckBox, Box, Span } from 'components';

type DoubleToggleCheckBoxProps = {
  checked?: boolean;
  disabled?: boolean;
  reverse?: boolean; // show label before checkbox
  alwaysColoured?: boolean; // show label before checkbox
  labelTrue: string;
  labelFalse: string;
  name?: string;
  onChange?: (value: boolean) => void;
};

const DoubleToggleCheckBox = ({
  reverse,
  checked,
  disabled,
  labelTrue,
  labelFalse,
  name,
  alwaysColoured,
  onChange,
}: DoubleToggleCheckBoxProps) => {
  return (
    <Box direction="row">
      <Span margin={{ right: 'small' }}>{labelFalse}</Span>
      <CheckBox
        checked={checked}
        reverse={reverse}
        disabled={disabled}
        toggle={true}
        name={name}
        label=""
        onChange={onChange}
        alwaysColoured={alwaysColoured}
      ></CheckBox>
      <Span margin={{ left: 'small' }}>{labelTrue}</Span>
    </Box>
  );
};

export default DoubleToggleCheckBox;
