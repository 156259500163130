import 'mobx-react-lite/batchingForReactDom';
import React, { useContext, useEffect } from 'react';
import { types, Instance, SnapshotIn, flow, getSnapshot, applySnapshot } from 'mobx-state-tree';
import { Session } from 'session';
import { Simulation } from 'screens/simulation/store';
import { roles, validate as validateSession } from 'api/session';
import { GlobalSettings, defaultGlobalSettingsFactory } from 'settings/store';
import * as simulationApi from 'api/simulation';
import type { SimulationSessionData } from 'api/simulation';
import { DateTime } from 'luxon';
import { merge, isEmpty } from 'lodash';
import { MAX_LOCKED_MONTHLY_RUNS, MAX_LOCKED_SIMULATIONS } from 'config';
import { SimulationState } from 'screens/simulation/types';

const RootStore = types
  .model('RootStore', {
    session: types.optional(Session, {}),
    loadingSimulations: false,
    simulationsLoaded: false,
    simulations: types.map(Simulation),
    currentSimulation: types.maybeNull(types.string),
    title: types.maybe(types.string),
    pageTitle: types.maybe(types.string),
    settings: types.optional(GlobalSettings, defaultGlobalSettingsFactory),
  })
  .actions((self) => {
    function convertFromApi(value: SimulationSessionData) {
      return simulationApi.convertFromApi(value);
    }

    return {
      reset() {
        // TODO: clear all data
        self.simulations.clear();
        self.loadingSimulations = false;
        self.simulationsLoaded = false;
      },
      setTitle(title: string) {
        self.title = title;
      },
      setPageTitle(title: string) {
        self.pageTitle = title;
      },
      setCurrentSimulation(id: string | null) {
        self.currentSimulation = id;
      },
      addSimulation(id?: string) {
        // Add a simulation
        const sim = Simulation.create({ id });
        // put settings from defaults
        sim.settings.fromObject(self.settings.settings.getAsObject());
        self.simulations.put(sim);
        return sim;
      },
      ensureSimulation: flow(function* (id: string) {
        if (self.simulations.has(id)) {
          return self.simulations.get(id);
        } else {
          const data = yield simulationApi.find(id);
          if (data != null) {
            let sim = self.simulations.put(convertFromApi(data));
            simulationApi.getSimulationData(sim.remoteId!)
              .then(sim.restoreSettings);
            // TODO
            return self.simulations.get(id);
          } else {
            return (self as any).addSimulation(id);
          }
        }
      }),
      loadSimulations: flow(function* () {
        if (!self.simulationsLoaded) {
          self.loadingSimulations = true;
          const data = yield simulationApi.list();
          const publicData = yield simulationApi.publicList();

          data.forEach((value: SimulationSessionData) => {
            let sim = self.simulations.put(convertFromApi(value));
            for (let i = 0; i < publicData.length; ++i) {
              if (publicData[i].session.id === sim.remoteId) {
                sim.restoreSettings(publicData.splice(i, 1)[0]); // remove from array so next loop will be shorter
                break;
              }
            }
          });
          self.loadingSimulations = false;
          self.simulationsLoaded = true;
        }
      }),
      deleteSimulation: flow(function* (id: string) {
        try {
          const simulation = self.simulations.get(id);
          if (simulation != null) {
            if (simulation.remoteId != null) {
              // yield simulationApi.removeSimulationData(simulation.remoteId, simulation.remotePublicDataId);
              yield simulationApi.remove(simulation.id, simulation.remoteId);
            }
            self.simulations.delete(id);
          }
        } catch (error) {
          // TODO: reportError(error);
          console.error(error); //TODO: handle properly
        }
      }),
      refreshSimulation: flow(function* (id: string) {
        const simulation = self.simulations.get(id);
        if (simulation != null) {
          const data = yield simulationApi.getOrFind(simulation.id, simulation.remoteId);
          if (
            data != null &&
            data.last_activity_at != null &&
            DateTime.fromISO(data.last_activity_at) > simulation.lastModified
          ) {
            // merge current state or we loose all settings
            let currentState = JSON.parse(JSON.stringify(getSnapshot(simulation)));
            let newState = convertFromApi(data);

            


            // if there are values in settings, do not update (would cause glitches
            // because params from Nimbu are still different from new as long as job is still in the queue awaiting simulation)
            // we restore it initially from settings saved in the session in Nimbu
            if (
              newState.analyzer != null &&
              currentState.analyzer != null &&
              !isEmpty(currentState.analyzer.settings)
            ) {
              newState.analyzer.settings = undefined;
            }

            if (
              newState.balancer != null &&
              currentState.balancer != null &&
              !isEmpty(currentState.balancer.settings)
            ) {
              newState.balancer.settings = undefined;
            }

            let mergedState = merge(currentState, newState);
            // make sure merged state has balancing removed if server state has balancing null
            if (newState.balancer == null) mergedState.balancer = {};
            applySnapshot(simulation, mergedState);
            return true;
          } else {
            return false;
          }
        } else {
          return false;
        }
      }),
    };
  }).views((self) => ({
    async canCreateNewProject() {
      await self.loadSimulations();
      let locked = 0;
      self.simulations.forEach((v) => {
        if (!v.archived && v.simulationLocked && v.status !== SimulationState.New) ++locked;
      })
      return locked < MAX_LOCKED_SIMULATIONS;
    },
    async canSimulate() {
      await self.loadSimulations();
      let sims = 0;
      const now = new Date().getTime();
      const THIRTY_DAYS = 30*24*60*60*1000;
      self.simulations.forEach((v) => {
        if (v.simulationLocked) {
          v.history.forEach((s) => {
            if (new Date(s).getTime() > (now - THIRTY_DAYS) ) ++sims;
          })
          if ([SimulationState.AnalysisQueuing, SimulationState.AnalysisQueued, SimulationState.Analyzing, 
            SimulationState.BalancingQueuing, SimulationState.BalancingQueued, SimulationState.Balancing].includes(v.status)) {
              ++sims;
          }
        }
      })
      return sims < MAX_LOCKED_MONTHLY_RUNS;
    }
  }));

export interface IRootStore extends Instance<typeof RootStore> { }

export const StoreContext = React.createContext<IRootStore | undefined>(undefined);

export function useStore(): IRootStore;
export function useStore<T>(pick: (store: IRootStore) => T): T;
export function useStore<T>(pick?: (store: IRootStore) => T): T | IRootStore {
  const store = useContext(StoreContext);
  if (store != null) {
    if (pick != null) {
      return pick(store);
    } else {
      return store;
    }
  } else {
    throw new Error(
      'useStore must only be used within a component three where a store has been provided'
    );
  }
}
export function useSession() {
  return useStore((store) => store.session);
}
export function useSettings() {
  return useStore((store) => store.settings);
}

export function useCalculateKPI() {
  return useStore((store) => store.settings.settings.calculateKPI);
}

export function useCredits() {
  const store = useStore();
  const session = store.session;
  const user = session != null ? session.user : undefined;

  useEffect(() => {
    if (user != null && user.availableCredits == null) {
      user.refreshCredits();
    }
  }, [user]);

  return user != null ? user.availableCredits : undefined;
}

export async function initialize(env: any): Promise<IRootStore> {
  let snapshot: SnapshotIn<IRootStore> | undefined;
  let { customer, remember } = await validateSession();
  
  if (customer != null) {
    let r = await roles();
    snapshot = {
      session: {
        user: customer,
        remember,
        roles: r || [],
      },
    };
  } else {
    // Session is not valid anymore -> start with empty store
    snapshot = {};
  }

  const store = RootStore.create(snapshot || {}, env);

  if (customer != null && customer.settings != null) {
    store.settings.restoreSettings(customer.settings);
  }

  if (process.env.NODE_ENV === 'development') {
    (window as any).store = store;
  }

  return store;
}
