import React from 'react';
import { useTranslations } from 'hooks';
import {
  Box,
  Select,
  FileInput,
  SelectOption,
  NumberInput,
  FormField,
  Tooltip,
  Icon,
  Paragraph,
} from 'components';
import { observer } from 'mobx-react';
import { ViaSide } from 'screens/simulation/upload_source_files/store';
import { MAX_VIA_FILES } from 'config';
import { useToasts } from 'react-toast-notifications';

const accept = [".gbr", ".274x"];

type ViaInstance = {
  file?: File;
  depth: number;
  side: ViaSide;
  onChangeDepth: (depth: number) => void;
  onChangeSide: (side: ViaSide) => void;
  onFile: (file: File) => void;
};

type ViaProps = ViaInstance & {
  onRemove: () => void;
};

type ViaInstanceWithId = ViaInstance & {
  id: string;
};

type SourceInputsProps = {
  frontFile?: File;
  backFile?: File;
  throughFile?: File;
  contourFile?: File;
  onFrontFile: (file: File) => void;
  onBackFile: (file: File) => void;
  onThroughFile: (file: File) => void;
  onContourFile: (file: File) => void;
  onResetFrontFile?: () => void;
  onResetBackFile?: () => void;
  onResetThroughFile?: () => void;
  onResetContourFile?: () => void;
  onToggleVias: (value: boolean) => void;
  vias?: ViaInstanceWithId[];
  onAddVia: (file: File) => boolean;
  onRemoveVia: (id: string) => void;
};

const ViaView = ({
  file,
  depth,
  side,
  onChangeDepth,
  onChangeSide,
  onFile,
  onRemove,
}: ViaProps) => {
  const t = useTranslations('simulation.upload');

  const sideOptions: SelectOption<ViaSide>[] = Object.values(ViaSide).map((s) => ({
    label: t(s),
    value: s,
  }));

  return (
    <Box
      border={file ? { style: 'dotted' } : undefined}
      direction="row"
      gap="none"
      pad="small"
      margin={{ horizontal: "20px" }}
      align="start"
      flex={false}
    >
      <Box direction="row" gap="medium" pad="small" align="center">
        <FileInput file={file} name={t('via')} onChange={onFile} onReset={onRemove} accept={accept} />
        {file &&
          <Box direction="column" gap="none">
            <FormField label={`${t('via_depth')} [mm]`}>
              <NumberInput type="float" value={depth} onChange={onChangeDepth} />
            </FormField>
            <FormField label={t('via_side')} border={false}>
              <Select
                value={sideOptions.find((o) => o.value === side)}
                onChange={(o) => {
                  o != null && onChangeSide(o.value);
                }}
                options={sideOptions}
                clearable={false}
              />
            </FormField>
          </Box>}
      </Box>
    </Box>
  );
};

const Via = observer(ViaView);

const SourceInputs = ({
  frontFile,
  backFile,
  throughFile,
  contourFile,
  onFrontFile,
  onBackFile,
  onThroughFile,
  onContourFile,
  onResetFrontFile,
  onResetBackFile,
  onResetThroughFile,
  onResetContourFile,
  onToggleVias,
  vias,
  onAddVia,
  onRemoveVia,
}: SourceInputsProps) => {
  const t = useTranslations('simulation.upload');
  const { addToast } = useToasts();
  return (
    <Box direction="row">
      <Box align="center" gap="small" direction="column" pad={{ horizontal: "large" }} border={(vias != null && vias.length > 0) ? { side: "right", color: "brand" } : undefined}>
        <Box direction="row" justify="around" flex={false}>
          <Box pad={{ horizontal: "20px", vertical: "23.5px" }}>
            <FileInput
              file={frontFile}
              name={t('front')}
              onChange={onFrontFile}
              onReset={onResetFrontFile}
              accept={accept}
            />
          </Box>
          <Box pad={{ horizontal: "20px", vertical: "23.5px" }}>
            <FileInput
              file={backFile}
              name={t('back')}
              onChange={onBackFile}
              onReset={onResetBackFile}
              accept={accept}
            />
          </Box>
          <Box direction="row" pad={{ horizontal: "20px", vertical: "23.5px" }} justify="start">
            <FileInput
              file={contourFile}
              name={t('contour')}
              onChange={onContourFile}
              onReset={onResetContourFile}
              accept={accept}
            />
            <Box>
              <Tooltip tooltip={
                (<Box width={{max: "200px"}}><Paragraph>
                  {t('contour_tooltip')}
                </Paragraph></Box>)
                }>
                <Icon name="question-circle-regular" color="brand" size="large"/>
              </Tooltip>
            </Box>
          </Box>
        </Box>
        <Box direction="row" justify="around" flex={false}>
          <Box pad={{ horizontal: "20px", vertical: "23.5px" }}>
            <FileInput
              file={throughFile}
              name={t('through')}
              onChange={onThroughFile}
              onReset={onResetThroughFile}
              accept={accept}
            />
          </Box>

          <Box pad={{ horizontal: "20px", vertical: "23.5px" }}>
            <FileInput
              name={t('via')}
              onChange={(files: File[]) => {
                files.forEach(file => {
                  if (!onAddVia(file)) addToast(t('via_max_error', {file: file.name, amnt: MAX_VIA_FILES}), { appearance: 'error' });
                })
              }}
              accept={accept}
              passThrough
              multiple
              disabled={vias != null && vias.length >= MAX_VIA_FILES}
            />
          </Box>
        </Box>
      </Box>

      {vias != null && vias.length > 0 && (
        <Box direction="column" align="center" justify="start" gap="small" flex={false} pad={{ horizontal: "large" }}>
          {vias.map(({ id, ...rest }) => (
            <Via key={id} {...rest} onRemove={() => onRemoveVia(id)} />
          ))}
        </Box>
      )}
    </Box >
  );
};

export default observer(SourceInputs);
