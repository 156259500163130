import { XplRenderData } from "./PanelData";
import ColorTexture from './ColorTexture';
import Camera from './Camera';
import frag from "./shaders/colorplot.frag";
import vert from "./shaders/colorplot.vert";
import fragB from "./shaders/basic.frag";
import vertB from "./shaders/basic.vert";

// import { mat4 } from 'gl-matrix';

// import { DisplaySettings } from '../XplPainter'

const UNIFORM_VALUES = [
    "modelMatrix",
    "modelNormalMatrix",
    "mvp",
    "eyePosition",
    "activeSurfaceTexture",
    "colorTable",
    "minScalar",
    "maxScalar",
    "cutOffMinColor",
    "cutOffMaxColor",
    "useCutOff",
    "transparent",
    "useSafeZone",
    "minSafeScalar",
    "maxSafeScalar",
    "safeZoneColor",
]

export default class XplRenderer {
    update: Function | null; // the display is the Image (<img>) element that we will render to.
    updateb?: Function; // the display is the Image (<img>) element that we will render to WITH cu areas
    canvas: HTMLCanvasElement; // the canvas element is the offscreen render surface
    // after drawing we will use the data from the canvas as the source for the display img

    gl: WebGLRenderingContext; // the opengl context obtained from canvas
    renderData?: XplRenderData; // renderdata for this face (front/back)
    program?: WebGLProgram; // opengl program containing shaders

    vbo?: WebGLBuffer; // vertex buffer object
    ibo?: WebGLBuffer; // index buffer object
    colorTex: ColorTexture; // color table texture
    pcbTextureUrl?: string;
    pcbTexture?: WebGLTexture | null; // the texture of the pcb
    pcbTextureImage?: HTMLImageElement | null; // the image the texture is loaded into first
    uniforms: { [key: string]: WebGLUniformLocation | null }; // uniforms used in shaders

    camera?: Camera; // camera object

    settings?: { [key: string]: number };
    showCu?: boolean;

    isBack: boolean;

    programB?: WebGLProgram; // opengl program containing shaders for Balancing
    vboB?: WebGLBuffer;
    iboB?: WebGLBuffer;
    mvpB?: WebGLUniformLocation | null;

    busy: boolean;
    endBusy: Function;



    constructor(update: Function, isBack: boolean, endBusy: Function, updateb?: Function) {

        this.update = update;
        this.updateb = updateb;
        this.canvas = document.createElement('canvas');

        let context = this.canvas.getContext('webgl');
        if (context == null) throw new Error(`XplPainter couldn't retreive webgl context from canvas.`)
        this.gl = context;
        this.busy = false;
        this.endBusy = endBusy;

        this.uniforms = {};

        this.isBack = isBack;

        // init color texture
        this.colorTex = new ColorTexture();

        // bind functions
        // this.setRenderData = this.setRenderData.bind(this);
        this.loadTexture = this.loadTexture.bind(this);
        this._handleContextLost = this._handleContextLost.bind(this);
        this._handleContextRestored = this._handleContextRestored.bind(this);
        this._initPrograms = this._initPrograms.bind(this);
        this.init = this.init.bind(this);

        this.canvas.addEventListener('webglcontextlost', this._handleContextLost, false);
        this.canvas.addEventListener('webglcontextrestored', this._handleContextRestored, false);

        this._initPrograms();
    }

    _initPrograms() {
        this.gl.clearColor(1.0, 1.0, 1.0, 1.0);
        this._createProgram();
        this._createProgramB();
    }

    init(renderData?: XplRenderData) {
        if (renderData != null) this.renderData = renderData;
        if (this.renderData == null) return;


        // init camera
        if (this.renderData.min == null || this.renderData.max == null) throw new Error(`XplPainter received invalid renderData`)
        this.camera = new Camera(this.renderData.min, this.renderData.max);

        this._createVbo();
        this._createIbo();
        this._createBuffersBalancing();
        // this._createVboB();
        // this._createIboB();

        if (this.renderData.gerber != null && this.renderData.gerber !== this.pcbTextureUrl) {
            this.pcbTextureUrl = this.renderData.gerber;
            this.loadTexture(this.pcbTextureUrl);
        } else this.draw();
    }

    initB() {
        this._createBuffersBalancing();
        this.draw();
    }

    // init(display: HTMLImageElement, renderData: XplRenderData,) {
    //     this.display = display;
    //     this.canvas = document.createElement('canvas');

    //     // get context;
    //     let context = this.canvas.getContext('webgl');
    //     if (context == null) throw new Error(`XplPainter couldn't retreive webgl context from canvas.`)
    //     this.gl = context;

    //     this.renderData = renderData;
    //     this._initRender();
    // }

    _handleContextLost(e: any) {
        console.log("handle context lost");
        e.preventDefault();
        this.clearLoadingImage();
    }

    _handleContextRestored() {
        console.log("handle context restored");
        this._initPrograms();
        this.init();
    }

    clearLoadingImage() {
        if (this.pcbTextureImage == null) return;
        this.pcbTextureImage.onload = null;
        this.pcbTextureImage = null;
    }

    _createProgramB() {
        if (this.gl == null) return;

        let vertexShader = this._loadShader(this.gl.VERTEX_SHADER, vertB);
        if (vertexShader == null) return;

        let fragmentShader = this._loadShader(this.gl.FRAGMENT_SHADER, fragB);
        if (fragmentShader == null) return;

        let programObject = this.gl.createProgram();
        if (programObject == null) return;

        this.gl.attachShader(programObject, vertexShader);
        this.gl.attachShader(programObject, fragmentShader);

        this.gl.bindAttribLocation(programObject, 0, "vertexPosition");
        this.gl.bindAttribLocation(programObject, 1, "vertexNormal");

        this.gl.linkProgram(programObject);

        let linked = this.gl.getProgramParameter(programObject, this.gl.LINK_STATUS);

        if (!linked && !this.gl.isContextLost()) {
            let infoLog = this.gl.getProgramInfoLog(programObject);
            alert("Error linking program:\n" + infoLog);
            this.gl.deleteProgram(programObject);
            return;
        }

        this.programB = programObject;

        this.mvpB = this.gl.getUniformLocation(this.programB, "mvp");

        this.checkGLError();
    }

    _createProgram() {
        if (this.gl == null) return;

        let vertexShader = this._loadShader(this.gl.VERTEX_SHADER, vert);
        if (vertexShader == null) return;

        let fragmentShader = this._loadShader(this.gl.FRAGMENT_SHADER, frag);
        if (fragmentShader == null) return;

        let programObject = this.gl.createProgram();
        if (programObject == null) return;

        this.gl.attachShader(programObject, vertexShader);
        this.gl.attachShader(programObject, fragmentShader);

        this.gl.bindAttribLocation(programObject, 0, "vertexPosition");
        this.gl.bindAttribLocation(programObject, 1, "vertexNormal");
        this.gl.bindAttribLocation(programObject, 2, "scalar");
        this.gl.bindAttribLocation(programObject, 3, "vertexCoord");

        this.gl.linkProgram(programObject);

        let linked = this.gl.getProgramParameter(programObject, this.gl.LINK_STATUS);

        if (!linked && !this.gl.isContextLost()) {
            let infoLog = this.gl.getProgramInfoLog(programObject);
            alert("Error linking program:\n" + infoLog);
            this.gl.deleteProgram(programObject);
            return;
        }

        this.program = programObject;

        this.uniforms = {};
        // uniforms
        for (let i = 0; i < UNIFORM_VALUES.length; ++i) {
            this._addUniform(UNIFORM_VALUES[i]);
        }

        this.checkGLError();
    }

    _createVbo() {
        if (this.renderData == null
            || this.renderData.points == null
            || this.renderData.normals == null
            || this.renderData.scalars == null
            || this.renderData.textureCoordinates == null) return;

        let buffer = this.gl.createBuffer();
        if (buffer == null) return;
        this.vbo = buffer;
        this.gl.bindBuffer(this.gl.ARRAY_BUFFER, this.vbo);

        this.gl.bufferData(this.gl.ARRAY_BUFFER,
            this.renderData.points.byteLength
            + this.renderData.normals.byteLength
            + this.renderData.scalars.byteLength
            + this.renderData.textureCoordinates.byteLength,
            this.gl.STATIC_DRAW);

        this.gl.bufferSubData(this.gl.ARRAY_BUFFER,
            0,
            this.renderData.points);

        this.gl.bufferSubData(this.gl.ARRAY_BUFFER,
            this.renderData.points.byteLength,
            this.renderData.normals);

        this.gl.bufferSubData(this.gl.ARRAY_BUFFER,
            this.renderData.points.byteLength + this.renderData.normals.byteLength,
            this.renderData.scalars);

        this.gl.bufferSubData(this.gl.ARRAY_BUFFER,
            this.renderData.points.byteLength + this.renderData.normals.byteLength + this.renderData.scalars.byteLength,
            this.renderData.textureCoordinates);

        this.checkGLError();
    }

    _createIbo() {
        if (this.renderData == null
            || this.renderData.triangles == null) return;

        let buffer = this.gl.createBuffer();
        if (buffer == null) return;
        this.ibo = buffer;
        this.gl.bindBuffer(this.gl.ELEMENT_ARRAY_BUFFER, this.ibo);

        this.gl.bufferData(this.gl.ELEMENT_ARRAY_BUFFER,
            this.renderData.triangles,
            this.gl.STATIC_DRAW)
    }

    _createBuffersBalancing() {
        if (this.renderData == null
            || this.renderData.balanceRenderData == null
            || this.renderData.balanceRenderData.objects == null) return;

        for (let i = 0; i < this.renderData.balanceRenderData.objects.length; ++i) {
            let buffer = this.gl.createBuffer();
            if (buffer == null) return;
            this.renderData.balanceRenderData.objects[i].vbo = buffer;
            this.gl.bindBuffer(this.gl.ARRAY_BUFFER, this.renderData.balanceRenderData.objects[i].vbo!);

            this.gl.bufferData(this.gl.ARRAY_BUFFER,
                this.renderData.balanceRenderData.objects[i].points.byteLength
                + this.renderData.balanceRenderData.objects[i].normals.byteLength,
                this.gl.STATIC_DRAW);

            this.gl.bufferSubData(this.gl.ARRAY_BUFFER,
                0,
                this.renderData.balanceRenderData.objects[i].points);

            this.gl.bufferSubData(this.gl.ARRAY_BUFFER,
                this.renderData.balanceRenderData.objects[i].points.byteLength,
                this.renderData.balanceRenderData.objects[i].normals);

            let buffer2 = this.gl.createBuffer();
            if (buffer2 == null) return;
            this.renderData.balanceRenderData.objects[i].ibo = buffer2;
            this.gl.bindBuffer(this.gl.ELEMENT_ARRAY_BUFFER, this.renderData.balanceRenderData.objects[i].ibo!);

            this.gl.bufferData(this.gl.ELEMENT_ARRAY_BUFFER,
                this.renderData.balanceRenderData.objects[i].triangles,
                this.gl.STATIC_DRAW)

            this.checkGLError();
        }
    }

    // _createIboB() {
    //     if (this.renderData == null
    //         || this.renderData.balanceRenderData == null
    //         || this.renderData.balanceRenderData.triangles == null) return;

    //     let buffer = this.gl.createBuffer();
    //     if (buffer == null) return;
    //     this.iboB = buffer;
    //     this.gl.bindBuffer(this.gl.ELEMENT_ARRAY_BUFFER, this.iboB);

    //     this.gl.bufferData(this.gl.ELEMENT_ARRAY_BUFFER,
    //         this.renderData.balanceRenderData.triangles,
    //         this.gl.STATIC_DRAW)
    // }

    _loadShader(type: number, shaderSrc: string): WebGLShader | null {
        let shader: WebGLShader | null = this.gl.createShader(type);
        if (shader == null) return null;

        this.gl.shaderSource(shader, shaderSrc);
        this.gl.compileShader(shader);

        if (!this.gl.getShaderParameter(shader, this.gl.COMPILE_STATUS)
            && !this.gl.isContextLost()) {
            let infoLog = this.gl.getShaderInfoLog(shader);
            alert("Error compiling shader:\n" + infoLog);
            this.gl.deleteShader(shader);
            return null;
        }
        return shader;
    }


    _addUniform(name: string) {
        if (this.program == null) return;
        let loc = this.gl.getUniformLocation(this.program, name);
        this.uniforms[name] = loc;
    }

    _getUniform(name: string): WebGLUniformLocation | null {
        return this.uniforms[name];
    }

    // setRenderData(renderData: XplRenderData) {
    //     this.renderData = renderData;

    // }

    loadTexture(url?: string) {
        if (url == null) return;

        this.busy = true;

        this.pcbTexture = this.gl.createTexture();
        this.gl.bindTexture(this.gl.TEXTURE_2D, this.pcbTexture);
        this.gl.texParameteri(this.gl.TEXTURE_2D, this.gl.TEXTURE_MIN_FILTER, this.gl.LINEAR);
        this.gl.texParameteri(this.gl.TEXTURE_2D, this.gl.TEXTURE_MAG_FILTER, this.gl.LINEAR);

        // this.image = new Image();
        // // this.image.crossOrigin = "";
        // this.image.onload = this.onImageLoaded;
        // this.image.src = url;

        // Loading image directly from url into Image element causes Cors errors
        // Use XMLHttpRequest into blob first

        // let xhr = new XMLHttpRequest();
        // xhr.responseType = 'blob';

        const myHeaders = new Headers();
        myHeaders.append('Content-Type', "image/png")

        const myRequest = new Request(url, {
            method: 'GET',
            headers: myHeaders,
            mode: 'cors',
            cache: 'default',
        })

        // ffffffffffffffffffffffffff
        // https://serverfault.com/questions/856904/chrome-s3-cloudfront-no-access-control-allow-origin-header-on-initial-xhr-req
        fetch(myRequest) //url, { method: 'GET', mode: 'cors' })
            .then(response => {
                // console.log(response);
                return response.blob()
            })
            .then(blob => {

                // let onchange = () => {
                // if (xhr.readyState !== XMLHttpRequest.DONE || xhr.status !== 200) return;

                this.pcbTextureImage = new Image();
                let onload = () => {
                    // console.log("image loaded");
                    if (this.pcbTexture == null) return;
                    if (this.pcbTextureImage == null) return;

                    this.canvas.width = this.pcbTextureImage.width;
                    this.canvas.height = this.pcbTextureImage.height;
                    this.gl.viewport(0, 0, this.canvas.width, this.canvas.height);

                    this.gl.bindTexture(this.gl.TEXTURE_2D, this.pcbTexture);
                    this.gl.texImage2D(this.gl.TEXTURE_2D, 0, this.gl.RGBA, this.gl.RGBA, this.gl.UNSIGNED_BYTE, this.pcbTextureImage);
                    this.gl.texParameteri(this.gl.TEXTURE_2D, this.gl.TEXTURE_MIN_FILTER, this.gl.NEAREST);
                    this.gl.texParameteri(this.gl.TEXTURE_2D, this.gl.TEXTURE_MAG_FILTER, this.gl.NEAREST);
                    this.gl.texParameteri(this.gl.TEXTURE_2D, this.gl.TEXTURE_WRAP_S, this.gl.CLAMP_TO_EDGE);
                    this.gl.texParameteri(this.gl.TEXTURE_2D, this.gl.TEXTURE_WRAP_T, this.gl.CLAMP_TO_EDGE);
                    this.checkGLError();

                    this.draw();
                }
                this.pcbTextureImage.onload = onload.bind(this);
                // this.pcbTextureImage.crossOrigin= "anonymous";
                // this.pcbTextureImage.src = url;
                let source = URL.createObjectURL(blob);
                // if (this.update != null) this.update(source);
                this.pcbTextureImage.src = source;//xhr.response);
                // console.log(this.pcbTextureImage.src);
                // }
            });
        // xhr.onreadystatechange = onchange.bind(this);

        // xhr.open('GET', url, true);
        // xhr.send();
    }

    checkGLError() {
        if (this.gl == null) return;

        let error = this.gl.getError();
        if (error !== this.gl.NO_ERROR && error !== this.gl.CONTEXT_LOST_WEBGL) {
            var str = "GL Error: " + error;
            console.log(str);
            throw str;
        }
    }

    // setLevel(level: number) {
    //     if (this.gl == null) return;
    //     this.colorTex.setLevel(level, this.gl);
    //     this.draw();
    // }

    draw(settings?: { [key: string]: number }) {
        // draw(min: number = 20, max: number = 30) {
        // let time = (new Date()).getTime();

        if (settings != null) this.settings = settings;

        if (this.settings == null) return;
        if (this.pcbTexture == null) return;
        if (this.program == null) return;
        if (this.camera == null) return;

        if (this.renderData == null
            || this.renderData.points == null
            || this.renderData.normals == null
            || this.renderData.scalars == null
            || this.renderData.textureCoordinates == null
            || this.renderData.triangles == null) return;

        if (this.vbo == null || this.ibo == null) return;
        if (this.pcbTextureImage == null || this.pcbTexture == null) return;

        this.busy = true;

        this.gl.clear(this.gl.COLOR_BUFFER_BIT);
        this.gl.disable(this.gl.DEPTH_TEST);
        this.gl.enable(this.gl.BLEND);
        this.gl.blendEquation(this.gl.FUNC_ADD);
        this.gl.blendFuncSeparate(this.gl.SRC_ALPHA, this.gl.ONE_MINUS_SRC_ALPHA, this.gl.ONE, this.gl.ZERO);
        this.checkGLError();

        this.gl.useProgram(this.program);
        this.checkGLError();

        // no VAO cause WebGL1 doesnt support VAO on all browsers

        // vbo
        this.gl.bindBuffer(this.gl.ARRAY_BUFFER, this.vbo);
        // points
        this.gl.enableVertexAttribArray(0);
        this.gl.vertexAttribPointer(0, 3, this.gl.FLOAT, false, 0, 0);
        // normals
        this.gl.enableVertexAttribArray(1);
        this.gl.vertexAttribPointer(1, 3, this.gl.FLOAT, false, 0, this.renderData.points.byteLength);
        // scalars
        this.gl.enableVertexAttribArray(2);
        this.gl.vertexAttribPointer(2, 1, this.gl.FLOAT, false, 0, this.renderData.points.byteLength + this.renderData.normals.byteLength);
        // tex coos
        this.gl.enableVertexAttribArray(3);
        this.gl.vertexAttribPointer(3, 2, this.gl.FLOAT, false, 0, this.renderData.points.byteLength + this.renderData.normals.byteLength + this.renderData.scalars.byteLength);

        // ibo
        this.gl.bindBuffer(this.gl.ELEMENT_ARRAY_BUFFER, this.ibo);

        this.checkGLError();

        // color texture
        this.colorTex.setLevel(this.settings.level, this.gl);
        this.gl.activeTexture(this.gl.TEXTURE1);
        this.gl.bindTexture(this.gl.TEXTURE_2D, this.colorTex.texture);
        this.checkGLError();

        // textures
        // pcb texture
        this.gl.activeTexture(this.gl.TEXTURE0);
        this.gl.bindTexture(this.gl.TEXTURE_2D, this.pcbTexture);
        this.checkGLError()

        this.gl.uniform1i(this._getUniform("activeSurfaceTexture"), 0);
        this.gl.uniform1i(this._getUniform("colorTable"), 1);

        this.checkGLError();


        // colorPlot Uniforms
        this.gl.uniform1i(this._getUniform("transparent"), 0); // 0 = false, not 0 = true

        this.gl.uniform1f(this._getUniform("minScalar"), this.settings.min_thickness);
        this.gl.uniform1f(this._getUniform("maxScalar"), this.settings.max_thickness);

        // this.gl.uniform4f(this._getUniform("cutOffMaxColor"), 0.6, 0, 0.3, 1.0);
        // this.gl.uniform4f(this._getUniform("cutOffMinColor"), 0.3, 0, 0.6, 1.0);
        this.gl.uniform4f(this._getUniform("cutOffMinColor"), 0.75, 0.75, 0.75, 1.0);
        this.gl.uniform4f(this._getUniform("cutOffMaxColor"), 0.5, 0.5, 0.5, 1.0);

        // cutoff
        this.gl.uniform1i(this._getUniform("useCutOff"), (this.settings.b_cutoff !== 0) ? 1 : 0); // 0 = false, not 0 = true

        // safezone
        // DISABLED!
        this.gl.uniform1i(this._getUniform("useSafeZone"), 0);//(this.settings.b_safezone !== 0) ? 1 : 0) // 0 = false, not 0 = true
        this.gl.uniform1f(this._getUniform("minSafeScalar"), this.settings.min_safezone); //minSafeScalar);
        this.gl.uniform1f(this._getUniform("maxSafeScalar"), this.settings.max_safezone);//maxSafeScalar);
        this.gl.uniform4f(this._getUniform("safeZoneColor"), 0.5, 0.5, 0.5, 1.0);

        this.checkGLError();

        // camera uniforms
        this.camera.adjust(this.isBack && this.settings.b_flip_back !== 0);
        this.gl.uniform3f(this._getUniform("eyePosition"),
            this.camera.eyePosition[0],
            this.camera.eyePosition[1],
            this.camera.eyePosition[2]);
        this.gl.uniformMatrix4fv(this._getUniform("modelMatrix"),
            false,
            this.camera.modelMatrix);
        this.gl.uniformMatrix3fv(this._getUniform("modelNormalMatrix"),
            false,
            this.camera.modelNormalMatrix);
        this.gl.uniformMatrix4fv(this._getUniform("mvp"),
            false,
            this.camera.mvp);

        this.checkGLError();

        // console.log("Initializing render took: " + ((new Date()).getTime() - time) + 'ms');
        // time = (new Date()).getTime();

        // draw elements
        this.gl.drawElements(this.gl.TRIANGLES, this.renderData.triangles.length, this.gl.UNSIGNED_SHORT, 0);

        // console.log("Drawing took: " + ((new Date()).getTime() - time) + 'ms');
        // time = (new Date()).getTime();

        // send to display
        if (this.update) this.update(this.canvas.toDataURL());

        // draw balancing
        if (this.updateb != null) {
            if (this.programB == null) return;
            if (this.renderData.balanceRenderData == null || this.renderData.balanceRenderData.objects == null) return;
            this.gl.useProgram(this.programB);

            for (let i = 0; i < this.renderData.balanceRenderData.objects.length; ++i) {
                const object = this.renderData.balanceRenderData.objects[i];
                if (object.vbo == null || object.ibo == null) return;
                // vbo
                this.gl.bindBuffer(this.gl.ARRAY_BUFFER, object.vbo);
                // points
                this.gl.enableVertexAttribArray(0);
                this.gl.vertexAttribPointer(0, 3, this.gl.FLOAT, false, 0, 0);
                // normals
                this.gl.enableVertexAttribArray(1);
                this.gl.vertexAttribPointer(1, 3, this.gl.FLOAT, false, 0, object.points.byteLength);

                // ibo
                this.gl.bindBuffer(this.gl.ELEMENT_ARRAY_BUFFER, object.ibo);

                this.gl.uniformMatrix4fv(this.mvpB || null,
                    false,
                    this.camera.mvp);

                // console.log("Initializing balance render took: " + ((new Date()).getTime() - time) + 'ms');
                // time = (new Date()).getTime();

                // console.log("loop");
                this.gl.drawElements(this.gl.TRIANGLES, object.triangles.length, this.gl.UNSIGNED_SHORT, 0);

                // console.log("Drawing balancing took: " + ((new Date()).getTime() - time) + 'ms');
                // time = (new Date()).getTime();
            }

            this.updateb(this.canvas.toDataURL());

        }

        this.busy = false;
        this.endBusy();

        // console.log("Sending to display took: " + ((new Date()).getTime() - time) + 'ms');
        // time = (new Date()).getTime();
    }
}