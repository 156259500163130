import React from 'react';
import { CheckBox as GrommetCheckbox } from 'grommet';
import styled from 'styled-components';
import { normalizeColor } from 'grommet/utils';
import { darken } from 'polished';
import {Tooltip, Icon, Box} from 'components';

type CheckBoxProps = {
  checked?: boolean;
  disabled?: boolean;
  toggle?: boolean;
  reverse?: boolean; // show label before checkbox
  label: string;
  hint?: string;
  name?: string;
  alwaysColoured?: boolean;
  onChange?: (value: boolean, e: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
};

const StyledCheckbox = styled(GrommetCheckbox).attrs((props: CheckBoxProps) => ({
  alwaysColoured: props.alwaysColoured,
}))`

  &:focus + span {
    border-color: ${({ theme }) => darken(0.1, normalizeColor('border', theme))};

    & > span {
      background-color: ${({ theme }) => darken(0.1, normalizeColor('border', theme))};
    }
  }

  &:checked + span > span {
    background-color: ${({ theme }) =>
      darken(theme.dark ? 0.1 : 0, normalizeColor('brand', theme))};
  }

  ${({ theme, alwaysColoured }: { alwaysColoured?: boolean; theme: any }) => {
    if (alwaysColoured) {
      return `
      & + span > span, &:focus + span > span {
        background-color: ${darken(theme.dark ? 0.1 : 0, normalizeColor('brand', theme))};
      }
      `;
    } else {
      return '';
    }
  }}
`;

const Checkbox = ({
  reverse,
  checked,
  disabled,
  toggle,
  label,
  hint,
  name,
  alwaysColoured,
  onChange,
  onBlur,
}: CheckBoxProps) => {
  let wrappedOnChange: ((e: React.ChangeEvent<HTMLInputElement>) => void) | undefined;
  if (onChange != null) {
    wrappedOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      onChange(e.target.checked, e);
    };
  }

  let fullLabel : React.ReactNode = label;
  if (hint != null) fullLabel = (
    <Box direction="row">
        {label}{' '}
        <Tooltip tooltip={hint}>
          <Icon name="question-circle-regular" color="text-xweak" />
        </Tooltip>
    </Box>
  );

  return (
    <StyledCheckbox
      checked={checked != null ? checked : false}
      reverse={reverse}
      disabled={disabled}
      toggle={toggle}
      label={fullLabel}
      name={name}
      onChange={wrappedOnChange}
      onBlur={onBlur}
      alwaysColoured={alwaysColoured}
    ></StyledCheckbox>
  );
};

export default Checkbox;
