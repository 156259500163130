import React from 'react';
import { Span, Box, LanguageSwitcher, Layer, TermsAndConditions, PrivacyPolicy } from 'components';
import { useTranslations } from 'hooks';
import styled from 'styled-components';
import { normalizeColor } from 'grommet/utils';
import { transparentize } from 'polished';

export type FooterProps = {
  inverted?: boolean;
  languageSwitcher?: boolean;
};

//  margin-top: auto;

const StyledFooter = styled(Box).attrs((props: FooterProps) => ({
  inverted: props.inverted || false,
}))`
  border-color: ${({ theme, inverted }) =>
    inverted
      ? transparentize(0.8, normalizeColor('white', theme))
      : transparentize(0.8, normalizeColor('text', theme))};
  &,
  & span,
  & a {
    font-weight: normal;
    color: ${({ theme, inverted }) =>
      inverted
        ? transparentize(0.2, normalizeColor('white', theme))
        : transparentize(0.4, normalizeColor('text', theme))};
  }
`;

const Footer = ({ inverted, languageSwitcher }: FooterProps) => {
  const t = useTranslations('footer');
  return (
    <StyledFooter
      inverted={inverted}
      wrap={true}
      direction="row"
      justify="between"
      pad={{ top: 'small', bottom: 'medium', horizontal: 'large' }}
      // margin={{ top: 'auto' }}
      border="top"
    >
      <Box direction="row">
        <Span>{t('app_name')}</Span>
        <Span margin={{ horizontal: 'small' }}>|</Span>
        <Span>
          {t('copyright')} &copy; 2021. {t('all_rights_reserved')}
        </Span>
      </Box>
      {(languageSwitcher == null || languageSwitcher) && (
        <Box direction="row">
          <LanguageSwitcher />
        </Box>
      )}
      <Box direction="row">
        <Layer label={t("privacy_policy")}><PrivacyPolicy/></Layer>
        <Span margin={{ horizontal: 'small' }}>|</Span>
        <Layer label={t('terms_of_use')}><TermsAndConditions/></Layer>
        {/* <Span margin={{ horizontal: 'small' }}>|</Span>
        <Link to="help">{t('help')}</Link> */}
      </Box>
    </StyledFooter>
  );
};

export default Footer;
