import React from 'react';
import { Routes } from 'routes';
import { useTranslations } from 'hooks';
import styled from 'styled-components';
import { useToasts } from 'react-toast-notifications';

// import RoutedButton from './RoutedButton';
import { Box } from 'grommet';
import { IconName } from 'components';

// import { Logo } from 'assets';
import { MenuButton } from './MenuButton';
import { useStore } from 'store';

const StyledSidebar = styled(Box)`
  z-index: 1;
  display: inline-block;
`;

const items: {
  label: string;
  iconName: IconName;
  path?: Routes | string;
}[] = [
    {
      label: 'home',
      iconName: 'home-outline',
      path: Routes.Home,
    },
    {
      label: 'new',
      iconName: 'plus-light',
      path: Routes.NewSimulation,
    },
    {
      label: 'archive',
      iconName: 'archive-light',
      path: Routes.Archive,
    },
  ];

const simulationItems: {
  label: string;
  iconName: IconName;
  path?: Routes | string;
}[] = [
    {
      label: 'simulation',
      iconName: 'flask-solid',
      path: Routes.Simulation,
    },
    {
      label: 'simulation_settings',
      iconName: 'wrench-outline',
      path: Routes.SimulationSettings,
    },
  ];

const Navigation = () => {
  const t = useTranslations('navigation.sidebar.labels');
  const n = useTranslations('navigation');
  const store = useStore();
  const { addToast } = useToasts();
  const [loading, setLoading] = React.useState(true);
  const [canCreate, setCanCreate] = React.useState(true);
  React.useEffect(() => {
    const getCanCreate = async () => {
      setLoading(true);
      setCanCreate(await store.canCreateNewProject());
      setLoading(false);
    }
    getCanCreate();
  }, [store.simulations.size, store]);

  const its = React.useMemo(() => {
    let result = [...items];
    if (store.session.isAdmin) {
      result.push({
        label: 'admin',
        iconName: 'user-regular',
        path: Routes.Admin,
      })
    }
    return result;
  }, [store.session.isAdmin]);
  return (
    <StyledSidebar fill="vertical" width="sidebar" background="background-front" elevation="small">
      <Box flex overflow="auto" >
        {its.map(({ iconName, label, path }) => {
          return <MenuButton
            icon={iconName}
            to={(path === Routes.NewSimulation && (loading || !canCreate)) ? undefined : path}
            label={t(label)}
            key={label}
            onClick={(path === Routes.NewSimulation && !canCreate) ? () => {addToast(n('cant_create_new'), { appearance: 'warning' });} : undefined}
          />;
        })}
        {store.currentSimulation && simulationItems.map(({ iconName, label, path }) => {
          return <MenuButton icon={iconName} params={store.currentSimulation ? { id: store.currentSimulation || "" } : undefined} to={store.currentSimulation ? path : undefined} label={t(label)} key={label} />; // params={{id: store.currentSimulation || ''}}
        })}
      </Box>
    </StyledSidebar >
  );
};

export default Navigation;
