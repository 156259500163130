import React from 'react';
import styled from 'styled-components';
import { Tooltip, Icon } from 'components';
import { Box, Text } from 'grommet';

type FieldSetProps = React.PropsWithChildren<{
  label?: string | JSX.Element;
  hint?: string;
  direction?: 'row' | 'column';
  align?: 'start' | 'center' | 'end' | 'baseline' | 'stretch';
  justify?: 'around' | 'between' | 'center' | 'end' | 'evenly' | 'start' | 'stretch'
}>;

const StyledText = styled(Text)`
  text-transform: uppercase;
`;

const FieldSet = ({
  label,
  hint,
  children,
  direction = 'row',
  align = 'center',
  justify = 'center',
}: FieldSetProps) => {
  function getLabel(label: JSX.Element | string): JSX.Element {
    if (typeof label === 'string') {
      return <StyledText>{label}</StyledText>;
    } else {
      return label;
    }
  }

  return (
    <Box flex gap="small" width="100%">
      <Box
        gap="xsmall"
        pad={{ bottom: '2px' }}
        border={{ color: 'border-light', side: 'bottom' }}
        direction="row"
        align="center"
      >
        {label && getLabel(label)}{hint &&
          <Tooltip tooltip={hint}>
            <Icon name="question-circle-regular" color="text-xweak" />
          </Tooltip>
        }
      </Box>
      <Box flex gap="small" direction={direction} align={align} justify={justify}>
        {children}
      </Box>
    </Box>
  );
};

export default FieldSet;
