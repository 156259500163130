import React, { useEffect } from 'react';
import { Box, Heading, Link, PasswordResetRequestForm } from 'components';
import { useTranslations } from 'hooks';
import { useSession } from 'store';
import { Routes } from 'routes';
import { useAsyncFn } from 'react-use';
import {
  PasswordResetRequestStages,
  PasswordResetRequestFormValues,
} from 'components/PasswordResetRequestForm/PasswordResetRequestForm';
import { observer } from 'mobx-react';

const ForgotPassword = () => {
  const t = useTranslations('login');
  const session = useSession();

  useEffect(() => {
    session.resetPasswordReset();
  }, [session]);

  const [resetPasswordState, resetPassword] = useAsyncFn(
    async (values?: PasswordResetRequestFormValues) => {
      if (values != null) {
        try {
          await session.requestPasswordReset(values.email);
        } catch (error) {
          // TODO: properly handle error
          console.log('Failed password reset request');
        }
      }
    }
  );

  return (
    <React.Fragment>
      <Heading level="3" size="small" margin={{ bottom: 'medium' }}>
        {t('password_reset_title')}
      </Heading>
      <PasswordResetRequestForm
        onRequestReset={resetPassword}
        initialValues={{ email: '' }}
        stage={
          session.resetRequested
            ? PasswordResetRequestStages.REQUEST_SENT
            : PasswordResetRequestStages.INIT
        }
        busy={resetPasswordState.loading}
      />
      <Box margin={{ top: 'large' }}>
        <Link to={Routes.Login}>{t('back_to_login')}</Link>
      </Box>
    </React.Fragment>
  );
};

export default observer(ForgotPassword);
