import React from 'react';
import LazyScreen from 'components/LazyScreen';
import type { SimulationProps } from './Simulation';

const Screen = React.lazy(() => import('./Simulation'));

const Simulation = ({ route }: SimulationProps) => (
  <LazyScreen chrome>
    <Screen route={route} />
  </LazyScreen>
);

export default Simulation;
