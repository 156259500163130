import React, { useState, useRef } from 'react';
import styled, { css } from 'styled-components';
import { Button, Icon, FlexBox as Box, Span } from 'components';
import { normalizeColor } from 'grommet/utils';

import { MapInteractionCSS } from 'react-map-interaction';

type Translation = {
  x: number;
  y: number;
};

export type Transform = {
  scale: number;
  translation: Translation;
};

type CommonImageViewerProps = {
  src: string;
  alt?: string;
  maxScale?: number;
  overlay?: string;
  flipX?: boolean;
  contour?: {
    left: number,
    right: number,
    top: number,
    bottom: number,
  }
};

type UncontrolledImageViewerProps = CommonImageViewerProps & {
  transform?: undefined;
  onChange?: (transform: Transform) => void;
};

type ControlledImageViewerProps = CommonImageViewerProps & {
  transform: Transform;
  onChange: (transform: Transform, width: number) => void;
};

type ImageViewerProps = UncontrolledImageViewerProps | ControlledImageViewerProps;

const Wrapper = styled.div`
  position: relative;
  width: fit-content;
  height: fit-content;
`;

const Tools = styled(Box)`
  width: 83px;
  padding: 2px;
  border-radius: 4px;
`;
// position: absolute;
// bottom: ${(props) => props.theme.global.spacing};
// right: ${(props) => props.theme.global.spacing};

const StyledZoomButton = styled(Button)`
  span {
    color: ${({ theme }) => normalizeColor('text', theme)};
  }
`;

const StripedBox = styled(Box)`
background-image: linear-gradient(45deg, rgba(11,166,0,0.2) 25%, rgba(194,255,199,0.2) 25%, rgba(194,255,199,0.2) 50%, rgba(11,166,0,0.2) 50%, rgba(11,166,0,0.2) 75%, rgba(194,255,199,0.2) 75%, rgba(194,255,199,0.2) 100%);
background-size: 14.14px 14.14px;
`

const Image = styled.img.attrs(({ flipX, contour }: { flipX?: boolean, contour?: boolean }) => ({
  flipX: flipX,
  contour: contour
}))`
  display: block;
  max-width: 100%;
  max-height: 100%;
  margin-bottom: -4px;

  ${({ flipX }) =>
    flipX &&
    css`
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
  `}
  ${({ theme, contour }) =>
    contour && css`
    border: 1px solid ${theme.global.colors.brand.dark};
    margin-bottom: 0px;
`}

  ${({ theme }) =>
    theme.dark &&
    css`
      filter: brightness(0.85);
    `}
`;

const OverlayImage = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: 0;
  width: 100%;
  height: 100%;
`;

function calculateBounds(scale: number, width: number, height: number, contour?: { left: number, right: number, top: number, bottom: number }) {
  // Our viewbox is width x height
  // Our image is (width * scale) x (height * scale)
  // Excess width is (width * scale) - width -> width * (scale - 1)
  // Excess height is (height * scale) - height -> height * (scale - 1)
  // Bounds should be:
  // xMin = -width * (scale - 1)
  // yMin = -height * (scale - 1)
  // xMax = 0
  // yMax = 0
  return {
    xMin: -1 * (width + (contour ? 4 : 0)) * (scale - 1), // adjust for green border of contour
    yMin: -1 * (height + (contour ? 4 : 0)) * (scale - 1),
    xMax: 0,
    yMax: 0,
  };
}

function calculateTranslation(
  previousScale: number,
  scale: number,
  previousTranslation: Translation,
  width: number,
  height: number
): Translation {
  if (previousScale !== 1) {
    const scaleFactor = (scale - 1) / (previousScale - 1);
    return {
      x: previousTranslation.x * scaleFactor,
      y: previousTranslation.y * scaleFactor,
    };
  } else {
    const translation = {
      x: (-1 * (width * (scale - 1))) / 2,
      y: (-1 * (height * (scale - 1))) / 2,
    };
    return translation;
  }
}

function ImageViewer(props: UncontrolledImageViewerProps): JSX.Element;
function ImageViewer(props: ControlledImageViewerProps): JSX.Element;
function ImageViewer({
  src,
  alt,
  overlay,
  maxScale = 8,
  transform: incomingTransform,
  flipX,
  onChange,
  contour,
}: ImageViewerProps): JSX.Element {
  const controlled = incomingTransform != null;
  const [ourTransform, setTransform] = useState({ scale: 1, translation: { x: 0, y: 0 } });
  const ref = useRef<HTMLImageElement>(null!);
  const image = useRef<HTMLImageElement>(null!);
  const container = useRef<HTMLDivElement>(null!);

  const { scale, translation } = controlled ? incomingTransform! : ourTransform;

  const handleChange = (transform: Transform) => {
    if (!controlled) {
      setTransform(transform);
    }
    if (onChange != null) {
      onChange(transform, ref.current?.clientWidth);
    }
  };

  const zoomIn = () => {
    let newScale = scale * 1.2;
    if (newScale > maxScale) {
      newScale = maxScale;
    }
    let newTranslation = translation;
    if (ref.current != null) {
      newTranslation = calculateTranslation(
        scale,
        newScale,
        translation,
        ref.current.clientWidth,
        ref.current.clientHeight
      );
    }
    handleChange({ scale: newScale, translation: newTranslation });
  };

  const reset = () => {
    handleChange({ scale: 1, translation: { x: 0, y: 0 } });
  };

  const zoomOut = () => {
    let newScale = scale / 1.2;
    if (newScale < 1) {
      newScale = 1;
    }
    let newTranslation = translation;
    if (ref.current != null) {
      newTranslation = calculateTranslation(
        scale,
        newScale,
        translation,
        ref.current.clientWidth,
        ref.current.clientHeight
      );
    }
    handleChange({ scale: newScale, translation: newTranslation });
  };

  const [height, setHeight] = React.useState(0);
  const [heightChanged, setHeightChanged] = React.useState(false);
  React.useEffect(() => {
    if (image != null && image.current != null) {
      image.current.onload = () => { setHeightChanged((h) => !h); }
    }
  }, [image])

  
  React.useLayoutEffect(() => {
    if (!contour || !image.current || !container.current) setHeight(0);
    else {
      setHeight(
      container.current.clientWidth * (1 - contour.left - contour.right)
      * image.current.clientHeight / image.current.clientWidth
      / (1 - contour.bottom - contour.top)
    );
  }
  }, [heightChanged, container, contour, image]);

  return (
    <Wrapper>
      <Box border={contour ? undefined : { color: 'brand' }} pad="none" margin="none" ref={container}>
        <MapInteractionCSS
          onChange={handleChange}
          scale={scale}
          translation={translation}
          minScale={1}
          maxScale={maxScale}
          translationBounds={
            ref.current != null
              ? calculateBounds(scale, ref.current.clientWidth, ref.current.clientHeight, contour)
              : undefined
          }
        >
          {contour ?
            <StripedBox ref={ref} border={{ color: 'green', size: '2px' }} pad={{ left: `${(flipX ? contour.right : contour.left) * 100}%`, right: `${(flipX ? contour.left : contour.right) * 100}%`, top: `${contour.top * height}px`, bottom: `${contour.bottom * height}px` }}>
              <Image ref={image} src={src} alt={alt} flipX={flipX} contour />
            </StripedBox>
            : <Image ref={ref} src={src} alt={alt} flipX={flipX} />}

          {overlay != null && <OverlayImage src={overlay} />}
        </MapInteractionCSS>
      </Box>
      <Box direction="column" align="center">
        <Tools direction="row" align="center" background="white" elevation="xsmall">
          <StyledZoomButton
            onClick={zoomIn}
            plain={true}
            disabled={scale === maxScale}
            focusIndicator={false}
          >
            <Icon name="search-plus-regular" />
          </StyledZoomButton>
          <StyledZoomButton
            onClick={reset}
            plain={true}
            disabled={scale === 1}
            focusIndicator={false}
          >
            <Box pad={{ horizontal: 'xsmall' }}>
              <Span color="text" size="xsmall">
                {Math.round(scale * 100)}%
            </Span>
            </Box>
          </StyledZoomButton>
          <StyledZoomButton
            onClick={zoomOut}
            plain={true}
            disabled={scale === 1}
            focusIndicator={false}
          >
            <Icon name="search-minus-regular" />
          </StyledZoomButton>
        </Tools>
      </Box>
    </Wrapper>
  );
}

export default ImageViewer;
