import React from 'react';
import { Modal, Box, Button } from 'components';
import { useTranslations } from 'hooks';
import { PadType, JustifyContentType } from 'components/types';

type DialogProps = React.PropsWithChildren<{
  isOpen?: boolean;
  onOk?: (event: React.MouseEvent | React.KeyboardEvent) => void;
  onCancel?: (event: React.MouseEvent | React.KeyboardEvent, button?: boolean) => void;
  okLabel?: string | React.ReactNode;
  cancelLabel?: string;
  busy?: boolean;
  canSubmit?: boolean;
  pad?: PadType;
  justifyButtons?: JustifyContentType;
}>;

const Dialog = ({
  isOpen = true,
  onOk,
  onCancel,
  okLabel,
  cancelLabel,
  busy = false,
  canSubmit = true,
  pad = 'medium',
  children,
  justifyButtons = "end"
}: DialogProps) => {
  const t = useTranslations('dialog', 'controls');
  return (
    <Modal isOpen={isOpen} onRequestClose={onCancel} pad="none">
      <Box direction="column" gap="none" pad="none" flex={true} width={{ max: '500px' }}>
        <Box flex={true} pad={pad} gap="none">
          {children}
        </Box>
        <Box
          direction="row"
          pad={{horizontal: "medium", vertical: "small"}}
          background={{ color: 'background-contrast' }}
          justify={justifyButtons}
          align="center"
          gap="small"
          width="100%"
        >
          <Button onClick={(e) => { if (onCancel) onCancel(e, true);}} label={cancelLabel || t('cancel')} disabled={busy} />
          <Button
            onClick={onOk}
            label={okLabel || t('ok')}
            primary={true}
            busy={busy}
            disabled={!canSubmit || busy}
          />
        </Box>
      </Box>
    </Modal>
  );
};

export default Dialog;
