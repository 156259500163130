import { types, SnapshotIn, getRoot, flow, getParent } from 'mobx-state-tree';

import {
  Setting,
  Dimension,
  TimeDimension,
  getDimensionByName,
  getUnitByName,
  Unit,
  ValueWithUnit,
  ThicknessUnits,
  LengthUnits,
  AreaUnits,
  CurrentDensityUnits,
  TimeUnits,
  ScalarUnits,
  lengthDimensionInstance,
} from 'settings';
// import { rootCertificates } from 'tls';
// import { NoDimension } from './Dimension';

export const DefaultUnits: { [key: string]: Unit<Dimension> } = {
  min_thickness: ThicknessUnits.umUnit,
  max_thickness: ThicknessUnits.umUnit,
  min_safezone: ThicknessUnits.umUnit,
  max_safezone: ThicknessUnits.umUnit,
  level: ScalarUnits.noUnit,

  b_cutoff: ScalarUnits.noUnit,
  b_safezone: ScalarUnits.noUnit,
  b_flip_back: ScalarUnits.noUnit,


  panel_thickness: LengthUnits.mmUnit,
  process_time: TimeUnits.sUnit,
  min_avg: ThicknessUnits.umUnit,
  max_avg: ThicknessUnits.umUnit,
  min: ThicknessUnits.umUnit,
  max: ThicknessUnits.umUnit,
  max_stdv: ThicknessUnits.umUnit,
  min_cpk: ScalarUnits.noUnit,
  min_frac: ScalarUnits.percentUnit,

  // analyze
  target_thickness: ThicknessUnits.umUnit,
  current_density_front: CurrentDensityUnits.a_m2Unit,
  current_density_back: CurrentDensityUnits.a_m2Unit,

  // balance
  no_go_zone: LengthUnits.mmUnit,
  min_cu_fraction: ScalarUnits.percentUnit,
  max_cu_fraction: ScalarUnits.percentUnit,
  intensity: ScalarUnits.noUnit,
  environmental_power: ScalarUnits.noUnit,
  influence_radius: LengthUnits.mmUnit,
  hatch_coverage: ScalarUnits.percentUnit,
};

export const DefaultSettings: { [key: string]: { [key: string]: number } } = {
  import: {
    panel_thickness: 2,
  },
  analyze: {
    // target thickness
    // current density front
    // current density back

    target_thickness: 25,
    current_density_front: 100,
    current_density_back: 100,
  },
  balance: {
    // min clear on board
    // min cu fraction
    // max cu fraction
    // hash cov
    no_go_zone: 2,
    min_cu_fraction: 70,
    max_cu_fraction: 70,

    hatch_coverage: 0,
    intensity: 4,
    environmental_power: 0.5

    //     export const defaultNoGoZone = new Setting('no_go_zone', new ValueWithUnit(LengthUnits.mmUnit, 2));
    // export const defaultMinCuFraction = new Setting(
    //   'min_cu_fraction',
    //   new ValueWithUnit(ScalarUnits.percentUnit, 25)
    // );
    // export const defaultMaxCuFraction = new Setting(
    //   'max_cu_fraction',
    //   new ValueWithUnit(ScalarUnits.percentUnit, 75)
    // );
    // export const defaultIntensity = new Setting('intensity', new ValueWithUnit(ScalarUnits.noUnit, 2));
    // export const defaultHashCoverage = new Setting(
    //   'hatch_coverage',
    //   new ValueWithUnit(ScalarUnits.percentUnit, 0)
    // );
  },
  display: {
    level: 10,
    b_flip_back: 0, // actually boolean
    min_thickness: 20,
    max_thickness: 30,
    b_cutoff: 0, // actually boolean
    b_safezone: 0, // actually boolean
    min_safezone: 24,
    max_safezone: 26,
  },
  kpi: {
    // min_avg: 22, ?
    // max_avg: 38, ?
    min: 22,
    max: 28,
    max_stdv: 1,
    min_cpk: 1,
    min_frac: 80,
  }
}
// intensity: 2,

// export const DefaultSimulationSettings: { [key: string]: number } = {
//   min_thickness: 20,
//   max_thickness: 30,
//   panel_thickness: 2,
//   process_time: 7225,
//   environmental_power: 0.5,
//   influence_radius: 30,

// };

// export const DefaultKPISettings: { [key: string]: number } = {
//   min_avg: 22,
//   max_avg: 38,
//   min: 20,
//   max: 30,
//   max_stdv: 5,
//   min_cpk: 1,
//   min_frac: 80,
// };

export const SettingCategories = Object.keys(DefaultSettings);

export function SettingNamesFor(category: string): string[] {
  if (DefaultSettings[category] == null) return [];
  return Object.keys(DefaultSettings[category]);
}



export const KPILimits: { [key: string]: { lower?: string; upper?: string } } = {
  avg: {
    lower: 'min_avg',
    upper: 'max_avg',
  },
  min: {
    lower: 'min',
  },
  max: {
    upper: 'max',
  },
  std: {
    upper: 'max_stdv',
  },
  frac: {
    lower: 'min_frac',
  },
};

// export const SimulationSettingsNames = Object.keys(DefaultSimulationSettings);
// export const KPISettingsNames = Object.keys(DefaultKPISettings);

const StoreSetting = types.model('StoreSetting', {
  name: types.identifier,
  dimension: types.string,
  unit: types.string,
  value: types.number,
})
  .views((self) => ({
    get valueWithUnit() {
      const dim: typeof Dimension | undefined = getDimensionByName(self.dimension);
      if (!dim) return undefined;
      const unit: Unit<Dimension> | undefined = getUnitByName(self.unit, dim);
      if (!unit) return undefined;
      return new ValueWithUnit<Dimension>(unit, self.value);
    }
  }));

export { StoreSetting };

const DefaultUnit = types.model('DefaultUnit', {
  dimension: types.identifier,
  unit: types.string,
});

function notUndefined<T>(x: T | undefined): x is T {
  return x !== undefined;
}

export const fillStoreSetting = (setting: Setting<Dimension>) => {
  return {
    name: setting.name,
    dimension: setting.dimension.getName(),
    unit: setting.value.unit.name,
    value: setting.value.getValueAs(setting.value.unit),
  };
};

export const generateDefaultSetting = (name: string, value: number) => {
  if (DefaultUnits[name] != null) {
    return fillStoreSetting(new Setting(name, new ValueWithUnit(DefaultUnits[name], value)));
  } else {
    throw new Error('unknown setting type');
  }
};

export const settingsFor = (defaults: { [key: string]: number }) => {
  let settings: any = {};
  for (let key in defaults) {
    settings[key] = generateDefaultSetting(key, defaults[key]);
  }
  return settings;
};

export const SettingStore = types
  .model('SettingStore', {
    settings: types.map(StoreSetting),
    category: types.optional(types.string, '')
  })
  .views((self) => ({
    getSetting(name: string) {
      const storeSetting = self.settings.get(name);
      if (!storeSetting) return undefined;

      // const dim: typeof Dimension | undefined = getDimensionByName(storeSetting.dimension);
      // if (!dim) return undefined;

      // const unit: Unit<Dimension> | undefined = getUnitByName(storeSetting.unit, dim);
      // if (!unit) return undefined;

      // const val = new ValueWithUnit<Dimension>(unit, storeSetting.value);
      const val = storeSetting.valueWithUnit;
      if (val == null) return undefined;
      return new Setting(storeSetting.name, val);
    },
  }))

  .views((self) => ({
    // will fall back to global settings if settingName is not present in current store
    // meant for Simulation settings
    ensureSetting(name: string): Setting<Dimension> | undefined {
      if (self.getSetting(name) != null) return self.getSetting(name);
      const root = getRoot(self) as any;
      return root.settings.settings.getSettingFor(self.category, name);
    },
    get size() {
      return self.settings.size;
    },
    getAllSettings(): Array<Setting<Dimension>> {
      return Array.from(self.settings.values())
        .map(({ name }) => {
          return self.getSetting(name);
        })
        .filter(notUndefined);
    },
  }))
  .views((self) => ({
    ensureAllSettings(): Array<Setting<Dimension>> {
      const root = getRoot(self) as any;
      return (root.settings.settings.getAllSettingsFor(self.category) as Array<Setting<Dimension>>)
        .map((globalSetting) => {
          let setting = self.getSetting(globalSetting.name);
          if (setting != null) return setting;
          return globalSetting;
        })
        .filter(notUndefined);
    }
  }))
  .actions((self) => ({
    updateSetting(setting: Setting<Dimension>) {
      self.settings.put({
        name: setting.name,
        dimension: setting.dimension.getName(),
        unit: setting.value.unit.name,
        value: setting.value.getValueAs(setting.value.unit),
      });
    },

    reset() {
      const parent = getParent(self, 2) as any;
      self.settings.forEach((s) => {
        parent.resetSetting(self.category, s.name);
      })
    }
  }));

const updateSetting = flow(function* updateSetting(
  self: any,
  settingStore: any,
  setting: Setting<Dimension>,
  dontRemember?: boolean,
) {
  const currentSetting = settingStore.getSetting(setting.name);
  const roundedNewSettingValue = Number(setting.value.getValueAsBaseUnit().toFixed(10)); // compensate for floating point errors in metrics
  if (
    currentSetting == null ||
    currentSetting.value.getValueAsBaseUnit() !== roundedNewSettingValue
  ) {
    settingStore.updateSetting(setting);
    if (!dontRemember) {
      yield self.rememberSettings();
    }

    return true;
  } else {
    return false;
  }
});


export const Settings = types
  .model('Settings', {
    settings: types.map(SettingStore),
  })

  .views((self) => ({
    categories: () => self.settings.keys()
  }))

  .views((self) => ({
    forEachCategory: (f: (category: string) => void) => {
      let it = self.categories();
      let current = it.next();
      while (!current.done) {
        f(current.value);
        current = it.next();
      }
    }
  }))

  .views((self) => ({
    getSettingStoreFor: (category: string): any | undefined => {
      return self.settings.get(category);
    }
  }))

  .views((self) => ({
    getSettingFor: (category: string, name: string): Setting<Dimension> | undefined => {
      return self.getSettingStoreFor(category)?.getSetting(name)
    },
    getAllSettingsFor: (category: string): Array<Setting<Dimension>> => {
      return self.getSettingStoreFor(category)?.getAllSettings();
    },
    ensureAllSettingsFor: (category: string): Array<Setting<Dimension>> => {
      return self.getSettingStoreFor(category)?.ensureAllSettings();
    },
  }))

  .views((self) => ({
    getAsObject: (): { [key: string]: { [key: string]: number } } => {
      let result: { [key: string]: { [key: string]: number } } = {};
      self.forEachCategory((category) => {
        const currentCategory = self.getSettingStoreFor(category);
        if (currentCategory != null) {
          result[category] = {};
          for (let setting of SettingNamesFor(category)) {
            const currentSetting = currentCategory.getSetting(setting);
            if (currentSetting == null) continue;
            result[category][setting] = currentSetting.getValueAsUnit(DefaultUnits[setting]);
          }
        }
      })
      return result;
    },
  }))

  .views((self) => ({
    calculateKPI(name: string, value: ValueWithUnit<Dimension>) {
      const constraint = KPILimits[name];

      let good = true;

      if (constraint != null) {
        if (constraint.lower != null) {
          const lowerConstraint = self.getSettingFor('kpi', constraint.lower);
          if (
            lowerConstraint != null &&
            value.getValueAsBaseUnit() < lowerConstraint.getValueAsBaseUnit()
          ) {
            good = false;
          }
        }

        if (constraint.upper != null) {
          const upperConstraint = self.getSettingFor('kpi', constraint.upper);
          if (
            upperConstraint != null &&
            value.getValueAsBaseUnit() > upperConstraint.getValueAsBaseUnit()
          ) {
            good = false;
          }
        }
      }

      return good;
    },
  }))


  .actions((self) => ({
    updateSettingFor: flow(function* updateSettingFor(category: string, setting: Setting<Dimension>, dontRemember?: boolean) {
      return yield updateSetting(getParent(self), self.settings.get(category), setting, dontRemember);
    }),
  }))

  .actions((self) => ({
    fromObject: (settings: any) => {
      if (settings == null) return;
      self.forEachCategory((category) => {
        if (settings[category] != null) {
          for (let setting of SettingNamesFor(category)) {
            if (settings[category][setting] == null || !DefaultUnits[setting]) continue;
            self.updateSettingFor(category,
              new Setting(
                setting,
                new ValueWithUnit(DefaultUnits[setting], settings[category][setting])
              ), true // dont update nimbu, we just got these settings from nimbu
            );
          }
        }
      })
    },
  }))


export const GlobalSettings = types
  .model('GlobalSettings', {
    settings: Settings,
    defaultUnits: types.map(DefaultUnit),
    help: types.boolean,
  })

  // .views((self) => ({
  //   calculateKPI(name: string, value: ValueWithUnit<Dimension>) {
  //     return self.settings.calculateKPI(name, value);
  //   }
  // }))

  // Default units
  .views((self) => ({
    getDefaultUnitFor(dimension: string) {
      const defaultUnit = self.defaultUnits.get(dimension);
      if (!defaultUnit) return undefined;

      const dim: typeof Dimension | undefined = getDimensionByName(dimension);
      if (!dim) return undefined;

      const unit = getUnitByName(defaultUnit.unit, dim);
      if (!unit) return undefined;

      return unit;
    },
  }))

  .views((self) => ({
    getAllDefaultUnits(): Array<Unit<Dimension>> {
      return Array.from(self.defaultUnits.values())
        .map(({ dimension }) => {
          return self.getDefaultUnitFor(dimension);
        })
        .filter(notUndefined);
    },
    get usesMetricSystem() {
      return self.getDefaultUnitFor(lengthDimensionInstance.getName()) === LengthUnits.mmUnit;
    },
    get timeFormat() {
      return self.getDefaultUnitFor(TimeDimension.dimensionName) || TimeUnits.sUnit;
    },
  }))

  .actions((self) => ({
    updateDefaultUnit(unit: Unit<Dimension>) {
      self.defaultUnits.put({
        dimension: unit.dimension.getName(),
        unit: unit.name,
      });
    },

    rememberSettings: flow(function* rememberSettings() {
      let settings: any = {
        settings: self.settings.getAsObject(),
        metric: self.usesMetricSystem,
        timeFormat: self.timeFormat.name,
        help: self.help,
      };
      const root = getRoot(self) as any;
      yield root.session.updateSettings(settings);
    }),

    resetSetting: flow(function* resetSetting(category: string, name: string, dontRemember?: boolean) {
      let defaultSetting = new Setting(name, new ValueWithUnit(DefaultUnits[name], DefaultSettings[category][name]))
      yield self.settings.updateSettingFor(category, defaultSetting, dontRemember);
    }),
  }))

  .actions((self) => ({
    resetSettings: flow(function* resetSettings(category, dontRemember?: boolean) {
      self.settings.getAllSettingsFor(category).forEach(s => {
        self.resetSetting(category, s.name, true);
      })
      if (!dontRemember) yield self.rememberSettings();
    }),
  }))

  .actions((self) => ({
    resetAllSettings: flow(function* resetAllSettings() {
      self.settings.forEachCategory(async c => {
        self.resetSettings(c, true);
      })
      yield self.rememberSettings();
    }),
  }))

  .actions((self) => ({
    updateDefaultDimensions: (metric: boolean) => {
      if (metric) {
        self.updateDefaultUnit(LengthUnits.mmUnit);
        self.updateDefaultUnit(ThicknessUnits.umUnit);
        self.updateDefaultUnit(AreaUnits.mm2Unit);
        self.updateDefaultUnit(CurrentDensityUnits.a_m2Unit);
      } else {
        self.updateDefaultUnit(LengthUnits.inchUnit);
        self.updateDefaultUnit(ThicknessUnits.milUnit);
        self.updateDefaultUnit(AreaUnits.inch2Unit);
        self.updateDefaultUnit(CurrentDensityUnits.a_ft2Unit);
      }
    },
  }))
  .actions((self) => ({
    updateUsesMetricSystem: flow(function* updateUsesMetricSystem(metric: boolean) {
      self.updateDefaultDimensions(metric);
      yield self.rememberSettings();
    }),
    updateTimeFormat: flow(function* updateTimeFormat(unit: Unit<Dimension>) {
      self.updateDefaultUnit(unit);
      yield self.rememberSettings();
    }),
    updateHelp: flow(function* updateHelp(help: boolean) {
      self.help = help;
      yield self.rememberSettings();
    }),

    restoreSettings: (values: any) => {
      if (values == null) return;
      
      // TODO ?? fix old simulation/default settings
      values.settings['balance']['min_cu_fraction'] = Math.max(Math.min(60, values.settings['balance']['min_cu_fraction']), 10);
      values.settings['balance']['max_cu_fraction'] = Math.max(Math.min(60, values.settings['balance']['max_cu_fraction']), 10);

      self.settings.fromObject(values.settings)

      if (values.timeFormat != null) {
        if (values.timeFormat === 's') {
          self.updateDefaultUnit(TimeUnits.sUnit);
        } else {
          self.updateDefaultUnit(TimeUnits.mmssUnit);
        }
      }

      if (values.metric != null) {
        self.updateDefaultDimensions(values.metric === true);
      }

      self.help = (values.help == null && values.help !== false) ? true : values.help;
    },
  }));

export const defaultGlobalSettingsFactory = () => {
  let defaultUnits: { [key: string]: SnapshotIn<typeof DefaultUnit> } = {};
  for (const unit of [
    LengthUnits.mmUnit,
    ThicknessUnits.umUnit,
    AreaUnits.mm2Unit,
    CurrentDensityUnits.a_m2Unit,
    TimeUnits.mmssUnit,
    ScalarUnits.percentUnit,
  ]) {
    defaultUnits[unit.dimension.getName()] = {
      dimension: unit.dimension.getName(),
      unit: unit.name,
    };
  }

  let s: { [key: string]: SnapshotIn<typeof SettingStore> } = {};
  for (const category of SettingCategories) {
    const settings = settingsFor(DefaultSettings[category]);
    s[category] = { settings, category };
  }

  // let s: { [key: string]: { settings: { [key: string]: number }, category: string} } = {};
  // for (let category of SettingCategories) {
  //   s[category] = { settings: {}, category};
  // }

  const snapshot = {
    settings: { settings: s },
    defaultUnits,
    help: true,
  };

  return snapshot;
};
