import React from 'react';
import styled from 'styled-components';
import { Box, Text } from 'grommet';
import { BoxProps } from 'components/Box';
import { normalizeColor } from 'grommet/utils';
import { lighten } from 'polished';
import { useTranslations } from 'hooks';

const svgForTriangle = (color: string) =>
  `<svg width="22" height="48" preserveAspectRatio="none" viewBox="0 0 22 48" xmlns="http://www.w3.org/2000/svg"><path fill="${color}" d="M2 0l20 24.29L2 48H0V0z" fill-rule="evenodd"/></svg>`
    .replace('<', '%3C')
    .replace('>', '%3E')
    .replace('#', '%23');

type WizardProps = {
  onClick?: ((() => void) | undefined)[];
  titles?: string[];
  step?: number;
};

type WizardBoxProps = BoxProps & {
  arrowType?: 'arrow' | 'divider' | undefined;
};

const StyledBox = styled(Box).attrs((props: WizardBoxProps) => ({
  arrowType: props.arrowType,
}))`
  ${({ theme, background, arrowType }: { arrowType?: string; background?: string; theme: any }) => {
    if (arrowType != null) {
      return `
        position: relative;

        &:after {
          z-index: 1;
          content:'';
          position: absolute;
          top: 0;
          width: 19px;
          bottom: 0;
          left: 100%;
          background: url('data:image/svg+xml;utf8,${svgForTriangle(
            lighten(
              arrowType === 'divider' ? 0.05 : 0,
              normalizeColor(background || 'border-light', theme)
            )
          )}');
          background-size: 100% 100%;
        }
      `;
    } else {
      return '';
    }
  }}

  ${({ theme, background, arrowType }: { arrowType?: string; background?: string; theme: any }) => {
    if (arrowType === 'divider') {
      return `
        &:before {
          z-index: 2;
          content:'';
          position: absolute;
          top: 0;
          width: 19px;
          bottom: 0;
          left: calc(100% - 2px);
          background: url('data:image/svg+xml;utf8,${svgForTriangle(
            normalizeColor(background || 'background-front', theme)
          )}');
          background-size: 100% 100%;
        }
      `;
    } else {
      return '';
    }
  }}
`;

const defaultTitles = ['upload', 'analyze', 'balance'];

const backgroundForStep = (i: number, step: number): string | undefined => {
  return i === step ? 'brand' : i < step ? 'brand-light' : undefined;
};

const Wizard = ({ onClick, titles = defaultTitles, step = 0 }: WizardProps) => {
  const total = titles.length;
  const t = useTranslations('wizard');

  return (
    <Box flex direction="row" background="background-front" overflow="hidden">
      {titles.map((title, i) => {
        const background = backgroundForStep(i, step);
        const arrowType =
          i === step || i === step - 1 ? 'arrow' : i < total - 1 ? 'divider' : undefined;
        const clickHandler = onClick != null && onClick[i] != null ? onClick[i] : undefined;

        return (
          <StyledBox
            flex
            align="center"
            key={title}
            pad="small"
            background={background}
            arrowType={arrowType}
            onClick={clickHandler}
          >
            <Text color={i === step ? 'white' : undefined}>
              {t(title, { defaultValue: title })}
            </Text>
          </StyledBox>
        );
      })}
    </Box>
  );
};

export default Wizard;
