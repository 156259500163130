export default `
precision mediump float;

attribute vec3 vertexPosition;
attribute vec3 vertexNormal;
attribute float scalar;
attribute vec2 vertexTexCoord;

varying vec3 worldPosition;
varying vec3 worldNormal;
varying float scalarF;
varying float containsMagicNumber;
varying vec2 texCoord;

uniform mat4 modelMatrix;
uniform mat3 modelNormalMatrix;
uniform mat4 mvp;

void main()
{
    worldNormal = normalize( modelNormalMatrix * vertexNormal );
    worldPosition = vec3( modelMatrix * vec4( vertexPosition, 1.0 ) );
	scalarF = scalar;
	if(abs(scalarF - 98765.429688) < 0.0001)
	{
		containsMagicNumber = 100000.0;
	}
	else
	{
		containsMagicNumber = 0.0;
	}

	texCoord = vertexTexCoord;

    gl_Position = mvp * vec4( vertexPosition, 1.0 );
}
`