import { types, flow, cast, getParent, getEnv, getRoot } from 'mobx-state-tree';
import { ObjectBase } from 'utils/store';
import * as api from 'api/session';
import * as creditsApi from 'api/credits';
import { PromiseType } from 'utility-types';
import { RouterState, RouterStore } from 'mobx-state-router';
import { Routes } from 'routes';

const User = ObjectBase.named('User')
  .props({
    firstname: types.string,
    lastname: types.string,
    email: types.string,
    company: types.maybe(types.string),
    country: types.maybe(types.string),
    role: types.maybe(types.string),
    availableCredits: types.maybe(types.number),
    phone_number: types.maybe(types.string),
  })
  .actions((self) => ({
    updateSettings: flow(function* updateSettings(values: any) {
      yield api.updateCustomer(self.id, { settings: values });
    }),
    refreshCredits: flow(function* refreshCredits() {
      try {
        let credits = yield creditsApi.get();
        if (credits != null && credits.length > 0) {
          let creditsObject = credits[0];
          self.availableCredits = creditsObject.balance;
        } else {
          self.availableCredits = 0;
        }
      } catch (error) {
        self.availableCredits = 0;
        // TODO: reportError(error)
        console.error(error);
      }
    }),
    updateProfile: flow(function* updateProfile(
      firstName: string,
      lastName: string,
      company: string,
      country: string,
      role: string,
      phone_number: string,
    ) {
      const updates = {
        firstname: firstName.length > 0 ? firstName : self.firstname,
        lastname: lastName.length > 0 ? lastName : self.lastname,
        company: company.length > 0 ? company : self.company,
        country: country.length > 0 ? country : self.country,
        role: role.length > 0 ? role : self.role,
        phone_number: phone_number.length > 0 ? phone_number : self.phone_number,
      };
      const json: PromiseType<ReturnType<typeof api.updateCustomer>> = yield api.updateCustomer(
        self.id,
        updates
      );
      self.firstname = json.firstname;
      self.lastname = json.lastname;
      self.company = json.company;
      self.country = json.country;
      self.role = json.role != null ? json.role : undefined;
      self.phone_number = json.phone_number != null ? json.phone_number : undefined;
      self.updatedAt = json.updatedAt;
    }),
    updateCredentials: flow(function* updateCredentials(
      email: string,
      currentPassword: string,
      password?: string,
      passwordConfirmation?: string
    ) {
      const isPasswordUpdate = password != null && password.length > 0;
      if (email !== self.email || isPasswordUpdate) {
        const updates: Parameters<typeof api.updateCustomer>[1] = {
          current_password: currentPassword,
        };
        if (isPasswordUpdate) {
          updates['password'] = password;
          updates['password_confirmation'] = passwordConfirmation;
        }
        if (email !== self.email) {
          updates['email'] = email;
        }
        const json: PromiseType<ReturnType<typeof api.updateCustomer>> = yield api.updateCustomer(
          self.id,
          updates
        );
        self.updatedAt = json.updatedAt;
        self.email = json.email;
        if (isPasswordUpdate) {
          // We need to login again, since our token is invalidated due to our password change
          const remember = getParent<any>(self).remember;
          // We checked password for != null above
          yield api.login(self.email, password!, remember);
        }
      } // else: no changes
    }),
  }));

export const Session = types
  .model('Session', {
    user: types.maybe(User),
    roles: types.array(types.string),
    remember: false,
  })
  .volatile((self) => ({
    // all these don't need to be persisted in a snapshot
    resetRequested: false,
    busy: false,
    redirectAfterLogin: undefined as RouterState | undefined,
  }))
  .views((self) => ({
    get authenticated() {
      // We don't need initialized/validated because we do that before creating
      // the store
      return self.user != null;
    },
    get isAdmin() {
      return self.roles != null && self.roles.includes('admin');
    },
  }))
  .actions((self) => ({
    setRedirectAfterLogin(to: RouterState) {
      self.redirectAfterLogin = to;
    },
    performRedirectAfterLogin() {
      const router = getEnv(self).getRouter() as RouterStore;
      if (self.redirectAfterLogin != null) {
        router.goToState(self.redirectAfterLogin);
        self.redirectAfterLogin = undefined;
      } else {
        router.goTo(Routes.Home);
      }
    },
    login: flow(function* login(username: string, password: string, remember: boolean = false) {
      self.busy = true;
      try {
        // Apparently TS goes foobar trying to infer the return type of the
        // yield call, so we type it explicitly.
        let user: PromiseType<ReturnType<typeof api.login>> = yield api.login(
          username,
          password,
          remember
        );

        // Now we need to use cast from mobx-state-tree to tell TS that we
        // really can assign a snapshot to an MST node.
        if (user != null) {
          const roles: string[] = yield api.roles();
          if (roles != null) self.roles = cast(roles);
          self.user = cast(user);
          self.remember = remember;
          if (user.settings != null) {
            const root = getRoot(self) as any;
            root.settings.restoreSettings(user.settings);
          }
          (self as any).performRedirectAfterLogin();
          // TODO setBugsnagUser(self.user);
        }
      } catch (error) {
        // TODO: reportError(error)
        console.error(error);
      }
      self.busy = false;
    }),
    logout: flow(function* logout() {
      self.busy = true;
      try {
        yield api.logout();
        self.user = undefined;
        (getParent(self) as any).reset();
      } catch (error) {
        // TODO: reportError(error)
        console.error(error);
      }
      self.busy = false;
    }),
    resetPasswordReset: function resetPasswordReset() {
      self.resetRequested = false;
    },
    requestPasswordReset: flow(function* requestPasswordReset(email: string) {
      self.busy = true;
      self.resetRequested = false;
      yield api.requestPasswordReset(email);
      self.resetRequested = true;
      self.busy = false;
      setTimeout(() => (self as any).resetPasswordReset(), 5000)
    }),
    updateProfile: flow(function* updateProfile(
      firstName: string,
      lastName: string,
      company: string,
      country: string,
      role: string,
      phone_number: string,
    ) {
      if (self.user != null) {
        yield self.user.updateProfile(firstName, lastName, company, country, role, phone_number);
      } else {
        throw new Error('User required');
      }
    }),
    updateSettings: flow(function* updateSettings(values: any) {
      if (self.user != null) {
        yield self.user.updateSettings(values);
      } else {
        throw new Error('User required');
      }
    }),
    updateCredentials: flow(function* updateCredentials(
      email: string,
      currentPassword: string,
      password?: string,
      passwordConfirmation?: string
    ) {
      if (self.user != null) {
        yield self.user.updateCredentials(email, currentPassword, password, passwordConfirmation);
      } else {
        throw new Error('User required');
      }
    }),
  }));
