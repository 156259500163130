import { deepFreeze } from 'grommet/utils';

export const elsyca = {
  name: 'elsyca',
  rounding: 4,
  spacing: 20,
  defaultMode: 'light',
  global: {
    colors: {
      brand: {
        dark: '#0099B8',
        light: '#00b7da',
      },
      'brand-light': {
        dark: '#2e4251',
        light: '#d8ecf4',
      },
      background: {
        dark: '#252631',
        light: '#F8FAFB',
      },
      'background-back': {
        dark: '#252631',
        light: '#F8FAFB',
      },
      'background-front': {
        dark: '#1B1C24',
        light: '#FFFFFF',
      },
      'background-contrast': {
        dark: '#252631',
        light: '#E8ECEF',
      },
      text: {
        dark: '#EEEEEE',
        light: '#4E596A',
      },
      'text-strong': {
        dark: '#FFFFFF',
        light: '#252631',
      },
      'text-weak': {
        dark: '#FFFFFF',
        light: '#778CA2',
      },
      'text-xweak': {
        dark: '#a3a2a3',
        light: '#98A9BC',
      },
      border: {
        dark: '#565964',
        light: '#B7C0C9',
      },
      'border-light': {
        dark: '#5f626e',
        light: '#D1DAE6',
      },
      control: 'brand',
      focus: '#0099B833',
      'control-background': {
        dark: '#1B1C24',
        light: '#FFFFFF',
      },
      red: {
        dark: '#ff4c4c',
        light: '#ff0000',
      },
      green: {
        dark: '#67ff67',
        light: '#008000',
      },
      'active-background': 'background-contrast',
      'active-text': 'text-strong',
      'selected-background': 'brand',
      'selected-text': 'text-strong',
      'status-critical': '#FF4040',
      'status-warning': '#FFAA15',
      'status-ok': '#00C781',
      'status-unknown': '#E8ECEF',
      'status-disabled': '#E8ECEF',
      'modal-background': {
        light: '#00000077',
        dark: '#00000099',
      },
    },
    font: {
      family: '"Titillium Web"',
      size: '16px',
      height: '20px',
      maxWidth: '300px',
    },
    active: {
      background: {
        color: 'active-background',
      },
      color: 'active-text',
    },
    selected: {
      background: 'selected-background',
      color: 'selected-text',
    },
    borderSize: {
      xsmall: '1px',
      small: '2px',
      medium: '3px',
      large: '10px',
      xlarge: '20px',
    },
    breakpoints: {
      small: {
        value: 640,
        borderSize: {
          xsmall: '1px',
          small: '2px',
          medium: '3px',
          large: '5px',
          xlarge: '10px',
        },
        edgeSize: {
          none: '0px',
          hair: '1px',
          xxsmall: '2px',
          xsmall: '3px',
          small: '5px',
          medium: '10px',
          large: '20px',
          xlarge: '40px',
        },
        size: {
          xxsmall: '20px',
          xsmall: '40px',
          small: '80px',
          medium: '160px',
          large: '320px',
          xlarge: '640px',
          full: '100%',
        },
      },
      medium: {
        value: 1280,
      },
      large: {},
    },
    edgeSize: {
      none: '0px',
      hair: '1px',
      xxsmall: '3px',
      xsmall: '5px',
      small: '10px',
      medium: '20px',
      large: '40px',
      xlarge: '80px',
      responsiveBreakpoint: 'small',
    },
    elevation: {
      light: {
        none: 'none',
        xsmall: '0px 1px 2px rgba(0, 0, 0, 0.20)',
        small: '0px 2px 4px rgba(0, 0, 0, 0.20)',
        medium: '0px 4px 8px rgba(0, 0, 0, 0.20)',
        large: '0px 8px 16px rgba(0, 0, 0, 0.20)',
        xlarge: '0px 12px 24px rgba(0, 0, 0, 0.20)',
      },
      dark: {
        none: 'none',
        xsmall: '0px 1px 2px rgba(255, 255, 255, 0.20)',
        small: '0px 2px 4px rgba(255, 255, 255, 0.20)',
        medium: '0px 6px 8px rgba(255, 255, 255, 0.20)',
        large: '0px 8px 16px rgba(255, 255, 255, 0.20)',
        xlarge: '0px 12px 24px rgba(255, 255, 255, 0.20)',
      },
    },
    focus: {
      border: {
        color: 'focus',
      },
    },
    input: {
      padding: '10px',
      weight: 600,
      background: 'control-background',
    },
    hover: {
      background: 'active-background',
      color: 'active-text',
    },
    spacing: '20px',
    size: {
      xxsmall: '40px',
      xsmall: '80px',
      small: '160px',
      medium: '320px',
      large: '640px',
      xlarge: '960px',
      xxlarge: '1280px',
      full: '100%',
      sidebar: '60px',
    },
  },
  chart: {},
  diagram: {
    line: {},
  },
  meter: {},
  layer: {
    background: {
      dark: '#111111',
      light: '#FFFFFF',
    },
  },
  button: {
    border: {
      width: '2px',
      radius: '15px',
    },
    size: {
      small: {
        pad: {
          vertical: '4px',
          horizontal: '12px',
        },
      },
      medium: {
        pad: {
          vertical: '6px',
          horizontal: '18px',
        },
      },
      large: {
        pad: {
          vertical: '8px',
          horizontal: '32px',
        },
      },
    },
    padding: {
      vertical: '8px',
      horizontal: '18px',
    },
  },
  calendar: {
    small: {
      fontSize: '11.666666666666666px',
      lineHeight: 1.375,
      daySize: '22.86px',
    },
    medium: {
      fontSize: '15px',
      lineHeight: 1.45,
      daySize: '45.71px',
    },
    large: {
      fontSize: '25px',
      lineHeight: 1.11,
      daySize: '91.43px',
    },
  },
  checkBox: {
    size: '20px',
    toggle: {
      radius: '20px',
      size: '40px',
    },
    hover: {
      border: {
        color: 'brand',
      },
    },
  },
  clock: {
    analog: {
      hour: {
        width: '7px',
        size: '20px',
      },
      minute: {
        width: '3px',
        size: '10px',
      },
      second: {
        width: '3px',
        size: '8px',
      },
      size: {
        small: '60px',
        medium: '80px',
        large: '120px',
        xlarge: '180px',
        huge: '240px',
      },
    },
    digital: {
      text: {
        xsmall: {
          size: '8.333333333333332px',
          height: 1.5,
        },
        small: {
          size: '11.666666666666666px',
          height: 1.43,
        },
        medium: {
          size: '15px',
          height: 1.375,
        },
        large: {
          size: '18.333333333333332px',
          height: 1.167,
        },
        xlarge: {
          size: '21.666666666666668px',
          height: 1.1875,
        },
        xxlarge: {
          size: '28.333333333333336px',
          height: 1.125,
        },
      },
    },
  },
  heading: {
    level: {
      '1': {
        small: {
          size: '28px',
          height: '33px',
          maxWidth: '567px',
        },
        medium: {
          size: '42px',
          height: '47px',
          maxWidth: '833px',
        },
        large: {
          size: '68px',
          height: '73px',
          maxWidth: '1367px',
        },
        xlarge: {
          size: '95px',
          height: '100px',
          maxWidth: '1900px',
        },
      },
      '2': {
        small: {
          size: '25px',
          height: '30px',
          maxWidth: '500px',
        },
        medium: {
          size: '35px',
          height: '40px',
          maxWidth: '700px',
        },
        large: {
          size: '45px',
          height: '50px',
          maxWidth: '900px',
        },
        xlarge: {
          size: '55px',
          height: '60px',
          maxWidth: '1100px',
        },
      },
      '3': {
        small: {
          size: '22px',
          height: '27px',
          maxWidth: '433px',
        },
        medium: {
          size: '28px',
          height: '33px',
          maxWidth: '567px',
        },
        large: {
          size: '35px',
          height: '40px',
          maxWidth: '700px',
        },
        xlarge: {
          size: '42px',
          height: '47px',
          maxWidth: '833px',
        },
      },
      '4': {
        small: {
          size: '18px',
          height: '23px',
          maxWidth: '367px',
        },
        medium: {
          size: '22px',
          height: '27px',
          maxWidth: '433px',
        },
        large: {
          size: '25px',
          height: '30px',
          maxWidth: '500px',
        },
        xlarge: {
          size: '28px',
          height: '33px',
          maxWidth: '567px',
        },
      },
      '5': {
        small: {
          size: '13px',
          height: '18px',
          maxWidth: '267px',
        },
        medium: {
          size: '13px',
          height: '18px',
          maxWidth: '267px',
        },
        large: {
          size: '13px',
          height: '18px',
          maxWidth: '267px',
        },
        xlarge: {
          size: '13px',
          height: '18px',
          maxWidth: '267px',
        },
      },
      '6': {
        small: {
          size: '12px',
          height: '17px',
          maxWidth: '233px',
        },
        medium: {
          size: '12px',
          height: '17px',
          maxWidth: '233px',
        },
        large: {
          size: '12px',
          height: '17px',
          maxWidth: '233px',
        },
        xlarge: {
          size: '12px',
          height: '17px',
          maxWidth: '233px',
        },
      },
    },
  },
  paragraph: {
    small: {
      size: '13px',
      height: '18px',
      maxWidth: '267px',
    },
    medium: {
      size: '15px',
      height: '20px',
      maxWidth: '300px',
    },
    large: {
      size: '18px',
      height: '23px',
      maxWidth: '367px',
    },
    xlarge: {
      size: '22px',
      height: '27px',
      maxWidth: '433px',
    },
    xxlarge: {
      size: '28px',
      height: '33px',
      maxWidth: '567px',
    },
  },
  radioButton: {
    size: '20px',
  },
  text: {
    xsmall: {
      size: '12px',
      height: '17px',
      maxWidth: '233px',
    },
    small: {
      size: '13px',
      height: '18px',
      maxWidth: '267px',
    },
    medium: {
      size: '15px',
      height: '20px',
      maxWidth: '300px',
    },
    large: {
      size: '18px',
      height: '23px',
      maxWidth: '367px',
    },
    xlarge: {
      size: '22px',
      height: '27px',
      maxWidth: '433px',
    },
    xxlarge: {
      size: '28px',
      height: '33px',
      maxWidth: '567px',
    },
  },
  textInput: {
    container: {
      extend: `
        i {
          opacity: 0.2;
        }
      `,
    },
  },
};

export const darkTheme = deepFreeze(Object.assign({}, elsyca, { defaultMode: 'dark' }));
export default deepFreeze(elsyca);
export const { colors } = elsyca.global;
