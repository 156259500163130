import Dimension from "./Dimension";
import ValueWithUnit from "./ValueWithUnit";
import Unit from "./Unit";

class Setting<D extends Dimension> {
  readonly name: string;
  readonly dimension: D;
  readonly value: ValueWithUnit<D>;

  constructor(name: string, value: ValueWithUnit<D>) {
    this.name = name;
    this.value = value;
    this.dimension = value.unit.dimension;
  }
  getValue() {
    return this.value;
  }

  getValueAsUnit(unit: Unit<D>) {
    return this.value.getValueAs(unit);
  }

  getValueAsBaseUnit() {
    return this.value.getValueAsBaseUnit();
  }

  newWithValue(value: ValueWithUnit<D>) {
    return new Setting(this.name, value);
  }

  newAsUnit(unit: Unit<D>) {
    const newValue = this.getValueAsUnit(unit);
    return this.newWithValue(new ValueWithUnit<D>(unit, newValue));
  }
}

export { Setting as default }
