import { types, Instance, flow, getRoot } from 'mobx-state-tree';
import { Sources } from './upload_source_files/store';
import { Analyzer } from './analyze/store';
import { Balancer } from './balance/store';
import { v4 as uuid } from 'uuid';
import { LockState, UnlockIntents, SimulationState, SimulationStateSequence } from './types';
import { DateTime } from 'luxon';
import * as api from 'api/simulation';
import type { Via } from 'api/simulation';
import { Settings, SettingCategories, StoreSetting, fillStoreSetting } from 'settings/store';
import { ValueWithUnit, Dimension, Setting, LengthUnits, } from 'settings';
import { BalanceState } from './balance/types';
import { AnalyzeState } from './analyze/types';
// import { useSettings } from 'store';
// import { GlobalSettings } from 'settings/store';
// import { useSimulation } from './hooks';
// import { useSettings } from 'store';
// import {
//   Setting,
//   Dimension,
//   ValueWithUnit,
// } from 'settings';


export const SimulationSettingsFactory = () => {
  let settings: { [key: string]: { settings: { [key: string]: number }, category: string } } = {};
  for (let category of SettingCategories) {
    settings[category] = { settings: {}, category };
  }
  const snapshot = {
    settings
  };
  return snapshot;
};




// export const SimulationSettings = types
//   .model('SimulationSettings', {
//     settings: Settings
//   })

//   // .views((self) => ({
//   //   ensureSettingFor(category: string, name: string) {

//   //   }
//   // }))

//   .actions((self) => ({

//     rememberSettings: flow(function* rememberSettings() {
//       let settings: any = self.settings.getAsObject();
//       yield api.setSimulationSettings()
//       // // TODO
//       // // const root = getRoot(self) as any;
//       // // yield root.session.updateSettings(settings);
//     }),

//     restoreSettings: (values: any) => {
//       if (values == null) return;
//       self.settings.fromObject(values.settings)

//       // TODO
//       // if (values.settings != null) {
//       //   for (let category of SettingCategories) {
//       //     if (values.settings[category] == null) continue;
//       //     for (let setting of SettingNamesFor(category)) {
//       //       if (values.settings[category][setting] == null || !DefaultUnits[setting]) continue;
//       //       self.settings.updateSettingFor(category,
//       //         new Setting(
//       //           setting,
//       //           new ValueWithUnit(DefaultUnits[setting], values.settings[category][setting])
//       //         )
//       //       );
//       //     }
//       //   }
//       // }
//     },
//   }));

type ss = {
  name: string;
  dimension: string;
  unit: string;
  value: number;
}

export const BoundingBox = types
  .model('BoundingBox', {
    _minX: StoreSetting,
    _maxX: StoreSetting,
    _minY: StoreSetting,
    _maxY: StoreSetting,
  })
  .views((self) => ({
    get minX() {
      const val = self._minX.valueWithUnit;
      return new Setting('minX', val!);
    },
    get maxX() {
      const val = self._maxX.valueWithUnit;
      return new Setting('maxX', val!);
    },
    get minY() {
      const val = self._minY.valueWithUnit;
      return new Setting('minY', val!);
    },
    get maxY() {
      const val = self._maxY.valueWithUnit;
      return new Setting('maxY', val!);
    },
  }))
  .views((self) => ({
    get values() {
      return {
        minX: self._minX.valueWithUnit?.getValueAsBaseUnit() || 0,
        maxX: self._maxX.valueWithUnit?.getValueAsBaseUnit() || 0,
        minY: self._minY.valueWithUnit?.getValueAsBaseUnit() || 0,
        maxY: self._maxY.valueWithUnit?.getValueAsBaseUnit() || 0,
      }
    }
  }))
  .actions((self) => ({
    setMinX(minX: Setting<Dimension>) {
      self._minX = StoreSetting.create(fillStoreSetting(minX));
    },
    setMaxX(maxX: Setting<Dimension>) {
      self._maxX = StoreSetting.create(fillStoreSetting(maxX));
    },
    setMinY(minY: Setting<Dimension>) {
      self._minY = StoreSetting.create(fillStoreSetting(minY));
    },
    setMaxY(maxY: Setting<Dimension>) {
      self._maxY = StoreSetting.create(fillStoreSetting(maxY));
    },
  }))

export const ImagePair = types
  .model('ImagePair', {
    front: types.string,
    back: types.string,
  })
  .actions((self) => ({
    setFront(front: string) {
      self.front = front;
    },
    setBack(back: string) {
      self.back = back;
    },
  }));

export const ImageStore = types
  .model('ImageStore', {
    analyzed: types.optional(ImagePair, { front: "", back: "" }),
    balanced: types.optional(ImagePair, { front: "", back: "" }),
    balancedWithAreas: types.optional(ImagePair, { front: "", back: "" }),
    colorPlotBar: types.optional(types.string, ""),
  })
  .actions((self) => ({
    setColorPlotBar(data: string) {
      self.colorPlotBar = data;
    }
  }));



export const BasicSimulation = types
  .model('BasicSimulation', {
    id: types.optional(types.identifier, uuid),
    remoteId: types.maybe(types.string),
    status: types.optional(
      types.enumeration(Object.values(SimulationState)),
      () => SimulationState.New
    ),
    simulationLock: types.optional(
      types.enumeration(Object.values(LockState)),
      () => LockState.LOCKED
    ),
    unlockIntent: types.optional(
      types.enumeration(Object.values(UnlockIntents)),
      () => UnlockIntents.EXPORT
    ),
    balancingLock: types.optional(
      types.enumeration(Object.values(LockState)),
      () => LockState.LOCKED
    ),
    expertMode: false,
    revisingSources: false,
    restoredFromSnapshot: false,
    numSimulations: 0,
    numBalancings: 0,
    _lastModified: types.optional(types.string, () => DateTime.local().toISO()),
    settings: types.optional(Settings, SimulationSettingsFactory),
    remotePublicDataId: types.maybe(types.string),
    images: types.optional(ImageStore, {}),
    name: types.optional(types.string, ""),
    front_bbox: types.maybe(BoundingBox),
    back_bbox: types.maybe(BoundingBox),
    contour: types.maybe(BoundingBox),
    archived: types.maybe(types.boolean),
    history: types.optional(types.array(types.string), []),
    // frontImage: types.optional(types.string, ""),
    // backImage: types.optional(types.string, "")
    read_errors: types.maybe(types.map(types.array(types.string)))
  })
  .views((self) => ({
    get lastModified() {
      return DateTime.fromISO(self._lastModified);
    },
    get simulationUnlocked() {
      return self.simulationLock === LockState.UNLOCKED;
    },
    get simulationLocked() {
      return !this.simulationUnlocked;
    },
    get unlockingSimulation() {
      return self.simulationLock !== LockState.LOCKED && self.simulationLock !== LockState.UNLOCKED;
    },
    get balancingUnlocked() {
      return self.balancingLock === LockState.UNLOCKED;
    },
    get balancingLocked() {
      return !this.balancingUnlocked;
    },
    get unlockingBalancing() {
      return self.balancingLock !== LockState.LOCKED && self.balancingLock !== LockState.UNLOCKED;
    },
    get previewsAvailable() {
      return (
        SimulationStateSequence.indexOf(self.status) >=
        SimulationStateSequence.indexOf(SimulationState.PreviewsReady)
      );
    },
    get analysisAvailable() {
      return (
        SimulationStateSequence.indexOf(self.status) >=
        SimulationStateSequence.indexOf(SimulationState.AnalysisReady) ||
        (self as any).analyzer.result != null
      );
    },
    get balancingAvailable() {
      return (
        SimulationStateSequence.indexOf(self.status) >=
        SimulationStateSequence.indexOf(SimulationState.BalancingReady) ||
        (self as any).balancer.result != null
      );
    },
    get removable() {
      return (
        self.status !== SimulationState.AnalysisQueuing &&
        self.status !== SimulationState.AnalysisQueued &&
        self.status !== SimulationState.Analyzing &&
        self.status !== SimulationState.BalancingQueuing &&
        self.status !== SimulationState.BalancingQueued &&
        self.status !== SimulationState.Balancing
      );
    },
  }))
  .actions((self) => ({
    setStatus(value: SimulationState) {
      self.status = value;
    },
    setRevisingSource(value: boolean) {
      self.revisingSources = value;
    },
    setExpertMode(value: boolean) {
      self.expertMode = value;
    },
    askUnlockConfirmation() {
      self.simulationLock = LockState.NEEDS_CONFIRMATION;
    },
    askUnlockOutOfRuns() {
      self.simulationLock = LockState.OUT_OF_RUNS;
    },
    cancelUnlock() {
      if (self.simulationLock === LockState.NEEDS_CONFIRMATION || LockState.OUT_OF_RUNS) {
        self.simulationLock = LockState.LOCKED;
      }
    },
    unlockSimulation: flow(function* unlockSimulation() {
      try {
        self.simulationLock = LockState.PAYING;
        const success = yield api.unlockAnalysis(self.id, self.remoteId);
        if (success) {
          const store = getRoot(self) as any;
          yield store.session.user.refreshCredits();
          self.simulationLock = LockState.UNLOCKED;
        } else {
          self.simulationLock = LockState.LOCKED;
        }
        return success;
      } catch {
        self.simulationLock = LockState.LOCKED;
        return false;
      }
    }),
    askUnlockExportConfirmation() {
      self.unlockIntent = UnlockIntents.EXPORT;
      self.balancingLock = LockState.NEEDS_CONFIRMATION;
    },
    askUnlockCopperAreasConfirmation() {
      self.unlockIntent = UnlockIntents.COPPER_AREAS;
      self.balancingLock = LockState.NEEDS_CONFIRMATION;
    },
    cancelUnlockExport() {
      self.balancingLock = LockState.LOCKED;
    },
    unlockBalancer: flow(function* unlockBalancer() {
      try {
        self.balancingLock = LockState.PAYING;
        const success = yield api.unlockBalancing(self.id, self.remoteId);
        if (success) {
          const store = getRoot(self) as any;
          yield store.session.user.refreshCredits();
          self.balancingLock = LockState.UNLOCKED;
        } else {
          self.balancingLock = LockState.LOCKED;
        }
        return success;
      } catch {
        self.balancingLock = LockState.LOCKED;
        return false;
      }
    }),
    rememberSettings: flow(function* rememberSettings() {
      if (self.remoteId == null) return; // simulation hasnt been saved (ea before uploading)
      let settings: any = self.settings.getAsObject();
      let body = yield api.setSimulationSettings(settings, self.remoteId, self.remotePublicDataId);
      if (self.remotePublicDataId == null) self.remotePublicDataId = body.id;
    }),


    rememberName: flow(function* rememberName() {
      if (self.remoteId == null) return; // simulation hasnt been saved (ea before uploading)
      if (self.name.length > 200) return; // no extremely long names to clutter nimbu storage space
      if (!/^[\w -]*$/.test(self.name)) return; // only allow alphanumeric (+ underscore), spaces and dashes

      let body = yield api.setSimulationName(self.name, self.remoteId, self.remotePublicDataId);
      if (self.remotePublicDataId == null) self.remotePublicDataId = body.id;
    }),

    rememberPublicData: flow(function* rememberPublicData() {
      if (self.remoteId == null) return; // simulation hasnt been saved (ea before uploading)
      if (self.name.length > 200) return; // no extremely long names to clutter nimbu storage space
      if (!/^[\w -]*$/.test(self.name)) return; // only allow alphanumeric (+ underscore), spaces and dashes

      let settings: any = self.settings.getAsObject();
      let body = yield api.setPublicSimulationData({ name: self.name, settings }, self.remoteId, self.remotePublicDataId);
      if (self.remotePublicDataId == null) self.remotePublicDataId = body.id;
    }),




    // setFrontImage: (front: string) => {
    //   if (front !== self.frontImage) {
    //     self.frontImage = front;
    //   }
    // },
    // setBackImage: (back: string) => {
    //   if (back !== self.backImage) {
    //     self.backImage = back;
    //   }
    // },


    resetSetting: flow(function* resetSetting(category: string, name: string, dontRemember?: boolean) {
      const root = getRoot(self) as any;
      let defaultSetting = root.settings.settings.getSettingFor(category, name);
      yield self.settings.updateSettingFor(category, defaultSetting, dontRemember);
    }),

    // input in mm
    createContour(
      minX : number, 
      maxX : number, 
      minY : number,
      maxY : number
      ) {
        self.contour = BoundingBox.create({
          _minX : fillStoreSetting(new Setting('minX', new ValueWithUnit(LengthUnits.mmUnit, minX))),
          _maxX : fillStoreSetting(new Setting('maxX', new ValueWithUnit(LengthUnits.mmUnit, maxX))),
          _minY : fillStoreSetting(new Setting('minY', new ValueWithUnit(LengthUnits.mmUnit, minY))),
          _maxY : fillStoreSetting(new Setting('maxY', new ValueWithUnit(LengthUnits.mmUnit, maxY))),
        });
        return self.contour;
    }
  }))


  .actions((self) => ({
    setName(name: string, remember?: boolean): boolean {
      if (name.length > 200) return false; // no extremely long names to clutter nimbu storage space
      if (!/^[\w -]*$/.test(name)) return false; // only allow alphanumeric (+ underscore), spaces and dashes
      self.name = name;
      if (remember) self.rememberName();
      return true;
    },

    resetSettings: flow(function* resetSettings(category, dontRemember?: boolean) {
      self.settings.getAllSettingsFor(category).forEach(s => {
        self.resetSetting(category, s.name, true);
      })
      if (!dontRemember) yield self.rememberSettings();
    }),
  }))

  .actions((self) => ({
    restoreSettings: (values: any) => {
      if (values == null) return;
      self.settings.fromObject(values.settings);
      if (values.name != null) self.setName(values.name);
    },
    resetAllSettings: flow(function* resetAllSettings() {
      self.settings.forEachCategory(c => {
        self.resetSettings(c, true);
      })
      yield self.rememberSettings();
    }),
  }))

export interface IBasicSimulation extends Instance<typeof BasicSimulation> { }

export const Simulation = BasicSimulation.named('Simulation')
  .props({
    sources: types.optional(Sources, {}),
    analyzer: types.optional(Analyzer, {}),
    balancer: types.optional(Balancer, {}),
  })
  .views((self) => ({
    get improvement() {
      if (
        self.analyzer != null &&
        self.analyzer.result != null &&
        self.balancer != null &&
        self.balancer.result != null
      ) {
        const min_analyze_front = self.analyzer.result.min.front.getValueAsBaseUnit();
        const min_analyze_back = self.analyzer.result.min.back.getValueAsBaseUnit();
        const max_analyze_front = self.analyzer.result.max.front.getValueAsBaseUnit();
        const max_analyze_back = self.analyzer.result.max.back.getValueAsBaseUnit();

        const min_balanced_front = self.balancer.result.min.front.getValueAsBaseUnit();
        const min_balanced_back = self.balancer.result.min.back.getValueAsBaseUnit();
        const max_balanced_front = self.balancer.result.max.front.getValueAsBaseUnit();
        const max_balanced_back = self.balancer.result.max.back.getValueAsBaseUnit();

        // let improvementFront =
        //   (100 *
        //     (max_analyze_front - min_analyze_front - (max_balanced_front - min_balanced_front))) /
        //   (max_analyze_front - min_analyze_front);

        // let improvementBack =
        //   (100 * (max_analyze_back - min_analyze_back - (max_balanced_back - min_balanced_back))) /
        //   (max_analyze_back - min_analyze_back);

        // if (
        //   improvementFront !== Infinity &&
        //   !isNaN(improvementFront) &&
        //   improvementBack !== Infinity &&
        //   !isNaN(improvementBack)
        // ) {
        //   return (improvementFront + improvementBack) / 2;
        // } else if (improvementFront !== Infinity && !isNaN(improvementFront)) {
        //   return improvementFront;
        // } else if (improvementBack !== Infinity && !isNaN(improvementBack)) {
        //   return improvementBack;
        // } else {
        //   return 0;
        // }
        let unit = self.analyzer.result.min.front.unit;
        let spreadA = new ValueWithUnit(unit, Math.max(max_analyze_front, max_analyze_back) - Math.min(min_analyze_front, min_analyze_back));
        let spreadB = new ValueWithUnit(unit, Math.max(max_balanced_front, max_balanced_back) - Math.min(min_balanced_front, min_balanced_back));

        return { spreadA, spreadB }
      } else {
        return {};
      }
    },
  }))
  .volatile((self) => ({
    frontPreview: undefined as string | undefined,
    backPreview: undefined as string | undefined,
  }))
  .actions((self) => ({
    resetToNew() {
      self.status = SimulationState.New;
      // TODO: reset sources?
      self.analyzer.reset();
      self.balancer.reset();
    },
    reset() {
      self.analyzer.reset();
      self.balancer.reset();
    },
    toAnalyzer() {
      if (self.analyzer == null) return;

      if (self.analyzer.state === AnalyzeState.NEW) self.status = SimulationState.PreparingAnalysis;
      else if (self.analyzer.state === AnalyzeState.UNLOCKED || self.analyzer.state === AnalyzeState.READY) self.status = SimulationState.AnalysisReady;
      else if (self.analyzer.state === AnalyzeState.ANALYZING) self.status = SimulationState.AnalysisQueued;
      else return;

      api.acknowledgePreviews(self.id, self.remoteId);
    },
    toBalancer() {
      if (self.balancer == null) return;

      if (self.balancer.state === BalanceState.NEW) self.status = SimulationState.PreparingBalancing;
      else if (self.balancer.state === BalanceState.READY) self.status = SimulationState.BalancingReady;
      else if (self.balancer.state === BalanceState.BALANCING) self.status = SimulationState.BalancingQueued;
      else return;

      api.acknowledgePreviews(self.id, self.remoteId);
    },
    toPreview() {
      // you can only go back to preview
      if (self.previewsAvailable) {
        self.status = SimulationState.PreviewsReady;
        api.acknowledgePreviews(self.id, self.remoteId);
      }
    },
    resetAnalyzerAndBalancer() {
      // Reset state when sources change
      self.status = SimulationState.PreparingAnalysis;
      api.acknowledgePreviews(self.id, self.remoteId);
      self.analyzer.reset();
      self.balancer.reset();
    },
    backToAnalyzerFromBalancer() {
      if (self.analysisAvailable) {
        self.status = SimulationState.AnalysisReady;
        api.acknowledgePreviews(self.id, self.remoteId);
      } else {
        self.status = SimulationState.PreparingAnalysis;
        api.acknowledgePreviews(self.id, self.remoteId);
      }
    },
    resetBalancer() {
      // Reset state after changes to analyzer
      self.status = SimulationState.PreparingBalancing;
      api.prepareBalancing(self.id, self.remoteId);
    },
    uploadContour: flow(function* uploadContour() {
      if (self.contour == null) return;
      try {
        self.status = SimulationState.Uploading;
        yield api.uploadContour(
          self.id,
          self.contour.values,
          self.remoteId
        );
        (self as any).ensurePreviews();
      } catch (error) {
        // TODO: reportError
        self.status = SimulationState.UploadError;
      }
    }),
    upload: flow(function* upload() {
      if (self.sources.front != null && self.sources.back != null) {
        try {
          self.status = SimulationState.Uploading;
          self.revisingSources = false;

          const vias = [] as Via[];
          if (self.sources.hasVias) {
            for (const v of self.sources.vias) {
              if (v.file != null) {
                vias.push(v as Via);
              }
            }
          }

          yield api.upload(
            self.id,
            self.sources.front,
            self.sources.back,
            { name: self.name, settings: self.settings.getAsObject() },
            self.sources.contour,
            self.sources.through,
            vias,
            self.remoteId
          );
          // No yield, we want to transition to the next screen already
          (self as any).ensurePreviews();
        } catch (error) {
          // TODO: reportError
          self.status = SimulationState.UploadError;
        }
      } else {
        // Shouldn't happen ...
        self.status = SimulationState.UploadError;
      }
    }),
    fetchPreviewUrls: flow(function* fetchPreviewUrls() {
      // Fetch the urls from the backend
      const urls = yield api.getPreviewUrls(self.id, self.remoteId);
      self.frontPreview = urls.front;
      self.backPreview = urls.back;
    }),
    ensurePreviews: flow(function* ensurePreviews() {
      if ((self.previewsAvailable || self.status === SimulationState.CreateContour) && (self.frontPreview == null || self.backPreview == null)) {
        yield (self as any).fetchPreviewUrls();
      } else if (
        self.status === SimulationState.Uploading ||
        self.status === SimulationState.PreviewsError ||
        self.status === SimulationState.SizeError ||
        self.status === SimulationState.SizeEqualityError
      ) {
        try {
          self.status = SimulationState.GeneratingPreviews;
          const depth = self.settings.getSettingFor('import', 'panel_thickness')?.getValueAsUnit(LengthUnits.mmUnit) || 0;
          const started = yield api.startPreviewGeneration(depth, self.id, self.remoteId);
          if (started) {
            // Poll the result
            (self as any).pollPreviewResult();
          } else {
            self.status = SimulationState.PreviewsError;
          }
        } catch (error) {
          self.status = SimulationState.PreviewsError;
        }
      }
    }),
    pollPreviewResult: flow(function* pollPreviewResult() {
      const root = getRoot(self) as any;
      const refreshed: boolean = yield root.refreshSimulation(self.id);
      if (
        refreshed &&
        self.status !== SimulationState.GeneratingPreviews &&
        self.status !== SimulationState.Uploading
      ) {
        if (self.status === SimulationState.PreviewsReady || self.status === SimulationState.CreateContour) {

          root.setCurrentSimulation(self.id);

          (self as any).fetchPreviewUrls();
        }
      } else {
        // try again in 5 seconds
        setTimeout((self as any).pollPreviewResult, 5000);
      }
    }),
  }));


export interface ISimulation extends Instance<typeof Simulation> { }

