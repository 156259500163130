import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'core-js';
import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './App';
import * as serviceWorker from './serviceWorker';
import 'i18n';
import { glMatrix } from 'gl-matrix';

// Normal and routing state
import { StoreContext, initialize as initializeStore } from './store';
import { RouterContext, RouterStore } from 'mobx-state-router';
import { initRouter } from 'routes';

import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import { BUGSNAG_API_KEY } from './config.js';

Bugsnag.start({
  apiKey: BUGSNAG_API_KEY,
  plugins: [new BugsnagPluginReact(React)],
  releaseStage: process.env.ELSYCA_ENV,
  appVersion: process.env.__VERSION__,
  enabledReleaseStages: ['production', 'staging'],
});

const ErrorBoundary = Bugsnag.getPlugin('react');

glMatrix.setMatrixArrayType(Float32Array);

let router: RouterStore;

initializeStore({
  getRouter() {
    return router;
  },
}).then((store) => {
  router = initRouter(store);

  ReactDOM.render(
    <React.StrictMode>
      <StoreContext.Provider value={store}>
        <RouterContext.Provider value={router}>
          <ErrorBoundary>
            <App />
          </ErrorBoundary>
        </RouterContext.Provider>
      </StoreContext.Provider>
    </React.StrictMode>,
    document.getElementById('root')
  );
});

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
