import React, { useState, useEffect } from 'react';
import { Box, } from 'components'; // Text
import { Dimension, ValueWithUnit, Setting, getUnitsForDimension, Unit } from 'settings';
import styled from 'styled-components';
import SettingInput from 'components/SettingInput';
import UnitSelect from 'components/UnitSelect';
import FormField from 'components/FormField';
import { useSettingTranslations } from 'hooks';
import { observer } from 'mobx-react';

const DEFAULT_PRECISION = 4;

const StyledFormField = styled(FormField).attrs(
  ({ allowUnitChange = false, grayout = false }: { allowUnitChange: boolean, grayout: boolean }) => ({
    allowUnitChange,
    grayout,
  })
)`
  overflow: visible;
  display: flex;
  width: 100%;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 2px;
  align-items: center;
  max-width: 280px;

  & > label {
    margin: 0px;
    padding: 0px;
    flex-grow: 0;
    width: ${({ allowUnitChange }) => (allowUnitChange ? '50%' : '75%')};
  }

  & > div {
    flex-grow: 0;
    width: ${({ allowUnitChange }) => (allowUnitChange ? '50%' : '25%')}}

    & > div input { 
      ${({ theme, grayout }) => (grayout ? `background-color: ${theme.global.colors['status-disabled']}; 
      color: ${theme.global.colors['text-xweak'].light};` : '')}
      text-align: center;
    }
    
    & + span {
      width: 100%;
      text-align: right;
      margin-bottom: 10px;
    }
  }
  `;

export type SettingFieldProps<D extends Dimension> = {
  setting: Setting<D>;
  onSettingChange: (newSetting: Setting<D>) => void;
  precision?: number;
  fixed?: number;
  allowUnitChange?: boolean;
  disabled?: boolean;
  readOnly?: boolean;
  resetValue?: boolean;
  defaultUnit?: Unit<D>;
  errors?: any;
  grayout?: boolean;
};

const SettingFieldView = <D extends Dimension>({
  setting,
  onSettingChange,
  precision,
  fixed,
  resetValue,
  allowUnitChange,
  defaultUnit,
  disabled,
  readOnly,
  errors,
  grayout,
  ...props
}: SettingFieldProps<D>) => {
  const [hasSwitchedUnit, setHasSwitchedUnit] = useState(false);
  const [displayUnit, setDisplayUnit] = useState(defaultUnit || setting.getValue().unit);
  const { labelFor, hintFor } = useSettingTranslations();
  const actualPrecision = (precision !== undefined) ? precision : DEFAULT_PRECISION;
  const setValue = (newValue: ValueWithUnit<D>) => {
    onSettingChange(new Setting<D>(setting.name, newValue));
    setHasSwitchedUnit(true);
  };

  const error = errors ? errors[setting.name] : undefined;

  const [stringValue, setStringValue] = useState('');
  useEffect(() => {
    let value = setting.getValue().getValueAs(displayUnit);
    setStringValue(((precision === undefined) && (fixed !== undefined)) ? value.toFixed(fixed) : value.toPrecision(actualPrecision));
  }, [setting, precision, actualPrecision, displayUnit, fixed]);

  useEffect(() => {
    // Update the unit if the user hasn't manually changed the value or unit, or if the user is not allowed to change the unit
    if (!hasSwitchedUnit || !allowUnitChange) {
      setDisplayUnit(defaultUnit || setting.getValue().unit);
    }
  }, [setting, defaultUnit, hasSwitchedUnit, allowUnitChange]);
  return (
    <StyledFormField
      border={false}
      label={labelFor(setting, displayUnit, !allowUnitChange)}
      error={error}
      hint={hintFor(setting)}
      allowUnitChange={allowUnitChange}
      grayout={grayout}
      {...props}
    >
      <Box direction="row" gap="4%" flex={false} overflow="visible">
        {/* {!allowUnitChange && (<Box width="58%"></Box>)} */}
        <Box width={allowUnitChange ? "40%" : "100%"} height="100%">
          {!readOnly ?
            <SettingInput
              min={0}
              value={setting.getValue()}
              resetValue={resetValue}
              onChange={setValue}
              unit={displayUnit}
              precision={actualPrecision}
              disabled={disabled}
            /> :
            <Box align="end" justify="center" height="30px" pad={{ right: "3px" }}>{stringValue}</Box>
          }
        </Box>
        {allowUnitChange && (getUnitsForDimension(setting.dimension).length > 0) && (
          <Box width="56%" overflow="visible">
            <UnitSelect
              unit={displayUnit}
              unitOptions={getUnitsForDimension(setting.dimension)}
              onUnitChange={(unit: Unit<D>) => {
                setHasSwitchedUnit(true);
                setDisplayUnit(unit);
              }}
            />
          </Box>
        )}
      </Box>
    </StyledFormField>
  );
};
//height="44px"

export default observer(SettingFieldView);
