import React, { useState, useRef, useEffect } from 'react';
import TextInput, { TextInputProps } from 'components/TextInput';

type Override<T, U> = Pick<T, Exclude<keyof T, keyof U>> & U;

export type NumberInputProps = Override<
  TextInputProps,
  {
    type?: 'float' | 'integer';
    value?: number;
    onChange?: (value: number, stringValue: string, e?: React.FormEvent<HTMLInputElement>) => void;
    min?: number;
    max?: number;
    // TODO: if we want to support step, we need to also add +/- buttons, not sure if it is needed
    // step?: number;
    precision?: number;
  }
>;

function parse(string: string, precision?: number, min?: number, max?: number) {
  let number = parseFloat(string.replace(',', '.'));
  if (Number.isNaN(number)) {
    // If it is not a number: bail out
    return number;
  } else {
    if (min != null && number < min) {
      number = min;
    } else if (max != null && number > max) {
      number = max;
    }
    if (precision != null) {
      // We apply the precision by using toFixed and parsing again
      number = parseFloat(number.toFixed(precision));
    }
    return number;
  }
}

function toMaxPrecision(value: number, precision?: number) {
  if (precision == null) {
    return value.toString();
  }

  const fixedPrecision = value.toFixed(precision);

  // Remove trailing zeros
  return parseFloat(fixedPrecision).toString();
}

const NumberInput = ({
  type = 'float',
  value,
  onChange,
  min,
  max,
  // step,
  precision,
  onBlur,
  ...rest
}: NumberInputProps) => {
  const [textValue, setTextValue] = useState(value != null ? toMaxPrecision(value, precision) : '');
  const numberValue = useRef<number>(value || 0);

  const changeHandler = (stringValue: string, e?: React.FormEvent<HTMLInputElement>) => {
    setTextValue(stringValue);
    const number = parse(stringValue, type === 'integer' ? 0 : precision, min, max);
    if (!Number.isNaN(number)) {
      numberValue.current = number;
    }
    if (onChange != null) {
      onChange(numberValue.current, stringValue, e);
    }
  };

  const onBlurHandler = (e: React.FocusEvent<HTMLInputElement>) => {
    setTextValue(numberValue.current.toString());
    if (onBlur != null) {
      onBlur(e);
    }
  };

  useEffect(() => {
    if (value != null && value !== numberValue.current) {
      const number = parse(value.toString(), type === 'integer' ? 0 : precision, min, max);
      numberValue.current = number;
      setTextValue(number.toString());
    }
  }, [value, type, precision, min, max]);

  return (
    <TextInput
      value={textValue}
      type={type}
      onChange={changeHandler}
      {...rest}
      onBlur={onBlurHandler}
    />
  );
};

export default NumberInput;
