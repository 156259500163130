import React from 'react';
import { Helmet } from 'react-helmet';

type TitleComponentProps = {
  title?: string;
};

const TitleComponent = ({ title }: TitleComponentProps) => {
  var defaultTitle = 'Elsyca CuBE';
  return (
    <Helmet>
      <title>{title ? `${defaultTitle} · ${title}` : defaultTitle}</title>
    </Helmet>
  );
};

export default TitleComponent;
