export enum Routes {
  Home = 'home',
  Simulation = 'simulation',
  NewSimulation = 'new_simulation',
  UploadSources = 'upload_sources',
  Preview = 'preview',
  Analyze = 'analyze',
  Balance = 'balance',
  Archive = 'archive',
  Settings = 'settings',
  SimulationSettings = 'simulation_settings',
  Account = 'account',
  Login = 'login',
  Register = 'register',
  ForgotPassword = 'forgot_password',
  ResetPassword = 'reset_password',
  ConfirmEmail = 'confirm_email',
  Callback = 'payment_callback',
  NotFound = 'not_found',
  Error = 'error',
  Admin = 'admin',
}

export function isRoute(token: any): token is Routes {
  return Object.values(Routes).includes(token);
}
