import React, { useState } from 'react';
import { Heading, RegistrationForm, Box, Paragraph, Link } from 'components';
import { useTranslations, useRegister, useTitle } from 'hooks';
import { Routes } from 'routes';
import { useAsyncFn } from 'react-use';
import { RegistrationFormProps, RegistrationFormFields } from 'components/RegistrationForm';

const Register = () => {
  const t = useTranslations('register');
  useTitle(t('page_title'));

  const { register, ...registerData } = useRegister();
  const [email, setEmail] = useState('');

  const [registerState, onRegister] = useAsyncFn(
    async (values?: RegistrationFormFields) => {
      if (values != null) {
        setEmail(values.email);
        await register(values);
      }
    },
    [register]
  );

  const registerProps: RegistrationFormProps = {
    ...registerData,
    busy: registerState.loading,
    termsConditionsLink: 'terms_and_conditions_link',
    onRegister: onRegister,
  };

  return (
    <React.Fragment>
      <Heading level="3" size="small" margin={{ bottom: 'medium' }}>
        {registerData.done ? t('register_success_title') : t('register_call_to_action')}
      </Heading>
      <Box wrap direction="column" margin="none">
        {registerData.done ? (
          <Paragraph>{t('register_success_hint', { email })}</Paragraph>
        ) : (
          <RegistrationForm {...registerProps} />
        )}
      </Box>
      <Box margin={{ top: 'medium' }}>
        <Paragraph>
          {t('already_registered')} <Link to={Routes.Login}>{t('login_link')}</Link>
        </Paragraph>
      </Box>
    </React.Fragment>
  );
};

export default Register;
