import React, { useState, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import styled from 'styled-components';
import Icon from 'components/Icon';
import IconButton from 'components/Button';
import Box, { BoxProps } from 'components/Box';
import { Span as Text } from 'components/Text';
import { useTranslation } from 'react-i18next';
import { useToasts } from 'react-toast-notifications';
import { MAX_GBR_FILE_SIZE_MB } from 'config';
const Papa = require('papaparse');

type CommonFileInputProps = {
  name?: string;
  icon?: boolean;
  accept?: string | string[];
  disabled?: boolean;
  passThrough?: boolean;
};

type SingleFileChangeHandler = (file: File) => void;
type MultipleFileChangeHandler = (files: File[]) => void;

type SingleFileInputProps = CommonFileInputProps & {
  file?: File;
  files?: undefined;
  multiple?: false;
  onChange?: SingleFileChangeHandler;
  onReset?: () => void;
};

type MultipleFileInputProps = CommonFileInputProps & {
  file?: undefined;
  files?: File[];
  multiple: true;
  onChange?: MultipleFileChangeHandler;
  onReset?: () => void;
};

type FileInputProps = SingleFileInputProps | MultipleFileInputProps;

const FileInputBox = styled(
  ({ disabled, ...rest }: { disabled?: boolean } & React.PropsWithChildren<BoxProps>) => (
    <Box {...rest} />
  )
)`
  position: relative;
  opacity: ${(props) => (props.disabled ? 0.5 : 1)};
`;

const OverlayBox = styled(Box)`
  position: absolute;
  pointer-events: none;
  top: 1px;
  left: 1px;
  bottom: 1px;
  right: 1px;
`;

const LongText = styled(Text)`
  display: block;
  width: 100%;
  font-weight: 600;
`;

const HintText = styled(Text)`
  margin-top: 15px;
  font-size: 13px;
`;

const DeleteButton = styled(IconButton)`
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 1;
`;

function FileInput(props: SingleFileInputProps): JSX.Element;
function FileInput(props: MultipleFileInputProps): JSX.Element;
function FileInput({
  file,
  files,
  name,
  onChange,
  onReset,
  icon = true,
  accept,
  multiple = false,
  disabled = false,
  passThrough = false,
}: FileInputProps) {
  const { t } = useTranslation('inputs');
  const [accepted, setAccepted] = useState<File[]>(
    files != null ? files : file != null ? [file] : []
  );
  const { addToast } = useToasts();
  const [rejected, setRejected] = useState<File[]>([]);
  const onDropAccepted = async (files: File[]) => {
    // check if Gerber
    var error : File[] = [];
    for (const file of files) {
      if (file.size > 1048576 * MAX_GBR_FILE_SIZE_MB){
        error.push(file);
        addToast(t('file.invalid_size', { max_mb: MAX_GBR_FILE_SIZE_MB}), { appearance: 'error' });
        continue;
      }
      await parseGerber(file).catch(() => {
        error.push(file);
        addToast(t('file.invalid_format'), { appearance: 'error' });
      });

    }
    if (error.length > 0) {
      setRejected(error);
      return;
    }

    if (!passThrough && files.length > 0) setAccepted(multiple ? files : [files[0]]);
    if (onChange != null) {
      if (multiple) {
        (onChange as MultipleFileChangeHandler)(files);
      } else if (files.length > 0) {
        (onChange as SingleFileChangeHandler)(files[0]);
      }
    }
  };
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDropAccepted,
    onDropRejected: onDropAccepted,
    accept,
    multiple,
    disabled,
  });

  const stopPropagationAndReset = (e: React.MouseEvent) => {
    e.stopPropagation();
    setAccepted([]);

    if (onReset != null) {
      onReset();
    }
  };

  useEffect(() => {
    let timeout: number | undefined;
    if (rejected.length > 0) {
      timeout = setTimeout(() => setRejected([]), 3000);
    }
    return () => {
      if (timeout != null) {
        clearTimeout(timeout);
      }
    };
  }, [rejected]);

  const color = (accepted.length > 0) ? { color: "green", opacity: "weak" } : undefined;

  return (
    <FileInputBox disabled={disabled} flex={false}>
      <Box>
        <Box
          {...getRootProps({
            border: { color: (accepted.length > 0) ? "green" : 'brand' },
            pad: 'small',
            height: 'small',
            width: '250px',
            align: 'center',
            justify: 'center',
            gap: 'small',
            flex: false,
            background: color,
          })}
        >
          <input {...getInputProps()} />
          {icon && <Icon name="file-import-regular" size="xlarge" />}
          {name && <Text textAlign="center">{name}</Text>}
          {accepted.length > 0 && (
            <LongText textAlign="center" truncate={true}>
              {accepted.map((f) => f.name).join(', ')}
              <DeleteButton
                icon="close"
                plain={true}
                focusIndicator={false}
                onClick={stopPropagationAndReset}
              />
            </LongText>
          )}
          {accepted.length === 0 && (
            <HintText textAlign="center">
              {t(`file.drag_or_click_here_${multiple ? 'multiple' : 'single'}`)}
            </HintText>
          )}
        </Box>
        {isDragActive && (
          <OverlayBox
            background={{ color: 'background-front' }}
            align="center"
            justify="center"
            pad="small"
          >
            <Text>{t(`file.drop_here_${multiple ? 'multiple' : 'single'}`)}</Text>
          </OverlayBox>
        )}
        {rejected.length > 0 && (
          <OverlayBox
            background={{ color: 'status-critical' }}
            align="center"
            justify="center"
            pad="small"
          >
            <LongText textAlign="center" truncate={true}>
              {t(`file.rejected_${multiple ? 'multiple' : 'single'}`)}:{' '}
              {rejected.map((f) => f.name).join(', ')}
            </LongText>
          </OverlayBox>
        )}
      </Box>
    </FileInputBox>
  );
}



function parseGerber(file: File) {
  return new Promise<void>((resolve, reject) => {
    let i = 0;
    let data = "";
    function handleRow(
      row: {
        data: string[] | boolean[] | number[],
        errors: any[],
        meta: Object
      },
      parser: {
        abort: Function,
        aborted: Function,
        parse: Function,
        pause: Function,
        paused: Function,
        resume: Function,
        streamer: {
          _rowCount: number
        }
      }) {
        if (i++ > 10) { parser.abort(); }
        for (const s of row.data) {
          data += s;
        }
    };

    Papa.parse(file, {
      delimiter: ' ',
      step: handleRow,
      comments: "G04",
      complete: () => {
        let found = data.match(/FS[A-Z]{2}X[0-9]{2}Y[0-9]{2}/)
        if (
          (data.includes("MOMM") || data.includes("MOIN")) &&
          found != null && found.length > 0)
          resolve();
        else reject();
      }
    });
  })
}

export default FileInput;
