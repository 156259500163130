import React from 'react';
import styled from 'styled-components';
import { Box, Text } from 'grommet';
import { ElsycaLogo } from 'assets';

type PageTitleProps = React.PropsWithChildren<{
  title: string;
}>;

const TitleText = styled(Text)`
  text-transform: uppercase;
`;

const PageTitle = ({ title, children }: PageTitleProps) => {
  return (
    <Box
      flex={false}
      wrap
      tag="header"
      direction="row"
      align="center"
      justify="between"
      gap="small"
      pad={{ vertical: 'small', horizontal: 'medium' }}
      responsive={false}
      border="bottom"
      background="background-front"
    >
      <Box flex wrap direction="row" align="center" justify="start" gap="small">
        <TitleText size="xlarge">{title}</TitleText>
      </Box>

      {React.Children.count(children) > 0 ? (
        <Box flex wrap direction="row" align="center" justify="end" gap="small">
          {children}
        </Box>
      ) : (
        <Box flex wrap direction="row" align="center" justify="end" gap="small">
          <a href="https://www.elsyca-pcb.com" target="_blank" rel="noopener noreferrer">
            <ElsycaLogo width="150px" />
          </a>
        </Box>
      )}
    </Box>
  );
};

export default PageTitle;
