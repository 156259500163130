import React from 'react';
import { Main as GrommetMain } from 'grommet';

type MainProps = React.PropsWithChildren<{}>;

const Main = ({ children }: MainProps) => {
  return (
    <GrommetMain flex="grow" pad="none">
      {children}
    </GrommetMain>
  );
};

export default Main;
