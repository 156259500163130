export default class ColorTexture {
    texture: WebGLTexture | null;
    level : number;

    constructor() {
        this.texture = null;
        this.level = 0;
    }

    setLevel(level : number, gl : WebGLRenderingContext) {
        if (level === this.level) return;
        if (this.texture != null) gl.deleteTexture(this.texture);
        this.level = level;
        this.texture = gl.createTexture();
        gl.bindTexture(gl.TEXTURE_2D, this.texture);
        gl.texImage2D(gl.TEXTURE_2D, 0, gl.RGB, this.level, 1, 0, gl.RGB, gl.UNSIGNED_BYTE, generateColors(this.level));
        gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MIN_FILTER, gl.NEAREST);
        gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MAG_FILTER, gl.NEAREST);
        gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_S, gl.CLAMP_TO_EDGE);
        gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_T, gl.CLAMP_TO_EDGE);
    }
}

export function generateColors(level : number) : Uint8Array {
    function hue2rgb (p : number, q : number, t : number) : number{
        if(t < 0) t += 1;
        if(t > 1) t -= 1;
        if(t < 1.0/6.0) return p + (q - p) * 6.0 * t;
        if(t < 1.0/2.0) return q;
        if(t < 2.0/3.0) return p + (q - p) * (2.0/3.0 - t) * 6.0;
        return p;
    }

    function getRgb (h : number, s : number, l : number) {
        h /= 360.0;
        s /= 255.0;
        l /= 255.0;

        let r : number, g : number, b : number;
        if(s === 0.0){
            r = g = b = l; // achromatic
        }else{
            var q = l < 0.5 ? l * (1.0 + s) : l + s - l * s;
            var p = 2 * l - q;
            r = hue2rgb(p, q, h + 1.0/3.0);
            g = hue2rgb(p, q, h);
            b = hue2rgb(p, q, h - 1.0/3.0);
        }
        return [Math.round(r * 255.0), Math.round(g * 255.0), Math.round(b * 255.0)];
    }

    let data = new Uint8Array(level * 3);
    for (let i = 0; i < level; ++i) {
        let stepSize = level > 1 ? 240.0/(level-1) : 0;
        let r,g,b;
        [r,g,b] = getRgb((i)*stepSize, 255, 128);
        data[(level-1 - i)*3] = r;
        data[(level-1 - i)*3 + 1] = g;
        data[(level-1 - i)*3 + 2] = b;
    }
    return data;
}