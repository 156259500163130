import React from 'react';
import { Select, SelectOption } from 'components';
import { Dimension, Unit } from 'settings';
import { useTranslations } from 'hooks';

export type UnitSelectProps<D extends Dimension> = {
  unit: Unit<D>;
  onUnitChange: (unit: Unit<D>) => void;
  unitOptions: Array<Unit<D>>;
};

const UnitSelect = <D extends Dimension>({
  unit,
  onUnitChange,
  unitOptions,
}: UnitSelectProps<D>) => {
  const t = useTranslations('simulation.units');

  const labeledUnitOptions: SelectOption<number>[] = unitOptions.map((s: Unit<D>, index) => ({
    label: t(`${s.name}.name`),
    value: index,
  }));

  return (
    <React.Fragment>
      <Select
        options={labeledUnitOptions}
        value={labeledUnitOptions[unitOptions.indexOf(unit)]}
        onChange={(option?: SelectOption<number>) => {
          option && onUnitChange(unitOptions[option.value]);
        }}
        clearable={false}
      />
    </React.Fragment>
  );
};

export default UnitSelect;
