import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { Box, Text } from 'grommet';
// import { parseToHsl, hslToColorString } from 'polished';

// import { DisplaySettings } from '../../webGL/XplPainter';
import { generateColors } from '../../webGL/XplPainter/ColorTexture';

import { useWindowSize } from 'hooks';
import html2canvas from 'html2canvas';
import ReactDOM from 'react-dom'

type PCBScaleProps = {
  settings: { [key: string]: number };
  update: (data: string) => void;
};

const screenshotWidth = 800;

const StyledBox = styled(Box)`
  position: relative;
`;

const StyledTextLeft = styled(Text)`
  position: absolute;
  left: 0;
  top: 0;
  text-align: center;
  transform: translate(-50%, 0);
`;

const StyledTextRight = styled(Text)`
  position: absolute;
  right: 0;
  top: 0;
  text-align: center;
  transform: translate(50%, 0);
`;

// render offscreen
const ScreenshotBox = styled(Box)`
  position: absolute;
  top: -999px;
  left: -999px;
`;

function tween(start: number, stop: number, current: number, steps: number) {
  return start + (current / steps) * (stop - start);
}
// function stepColor(steps: number, current: number, startColor: string, stopColor: string): string {
//   const startColorHSL = parseToHsl(startColor);
//   const stopColorHSL = parseToHsl(stopColor);
//   const color = hslToColorString({
//     hue: tween(startColorHSL.hue, stopColorHSL.hue, current, steps),
//     saturation: tween(startColorHSL.saturation, stopColorHSL.saturation, current, steps),
//     lightness: tween(startColorHSL.lightness, stopColorHSL.lightness, current, steps),
//   });
//   return color;
// }

function rgbToHex(rgb: number) {
  var hex = Number(rgb).toString(16);
  if (hex.length < 2) {
    hex = "0" + hex;
  }
  return hex;
}

function colorHex(r: number, g: number, b: number) {
  return `#${rgbToHex(r)}${rgbToHex(g)}${rgbToHex(b)}`;
}

const Step = ({
  color,
  start,
  end,
  width,
  cssWidth,
}: {
  color: string,
  start?: string,
  end?: string,
  width?: number,
  cssWidth?: string,
}) => {
  return (
    <Box width={cssWidth}>
      <Box background={color} height={{ min: '10px', max: '10px' }} ></Box>
      <StyledBox align="center" justify="center" height={{ min: '15px', max: '15px' }}>
        <StyledTextLeft size="xsmall">{start}</StyledTextLeft>
        {end != null && (
          <StyledTextRight size="xsmall">{end}</StyledTextRight>
        )}
      </StyledBox>
    </Box >
  );
};

// const cutOffMinColor = "#ffff80";
// const cutOffMaxColor = "#804080";
const safeZoneColor = "#808080";
const minPixDistance = 40;
const MAX_LEVEL = 30;

const PCBScale = ({
  settings, update
}: PCBScaleProps) => {

  const { windowWidth } = useWindowSize();
  // cap level
  const [level, setLevel] = useState(Math.min(settings.level, MAX_LEVEL))

  useEffect(() => {
    setLevel(Math.min(settings.level, MAX_LEVEL));
  }, [settings.level])

  settings.b_safezone = 0; // disable safezone


  const [minDistance, setMinDistance] = useState(0);
  const ref = useRef(null);
  useEffect(() => {
    setMinDistance((ref.current && (ref.current! as HTMLDivElement).offsetWidth > 0) ? minPixDistance / ((ref.current! as HTMLDivElement).offsetWidth) : 0);
  }, [windowWidth]);

  const screenshotScale = useRef(null);
  useEffect(() => {
    const element = ReactDOM.findDOMNode(screenshotScale.current) as HTMLElement;
    html2canvas(element, {
      width: screenshotWidth,
      height: 25,
      scrollY: -window.scrollY,
      useCORS: true,
    }).then(canvas => {
      update(canvas.toDataURL());
    })
  }, Object.values(settings)) // eslint-disable-line react-hooks/exhaustive-deps

  function generateScale(minDist: number) {
    let objectResult: {
      color: string,
      width: number,
      start?: string,
      end?: string,
    }[] = [];
    let colors = generateColors(level);
    let safe = { min: Math.max(settings.min_thickness, settings.min_safezone), max: Math.min(settings.max_thickness, settings.max_safezone) }

    function distanceToLastNumber(): number {
      let distance = 0;
      for (let i = objectResult.length - 1; i > -1; --i) {
        if (objectResult[i].end != null) break;
        distance += objectResult[i].width;
        if (objectResult[i].start != null) break;
      }
      if (objectResult.length === 0) return 1;
      return distance / 100;
    }

    if ((settings.b_cutoff !== 0)) {
      objectResult.push(
        {
          // color: 'linear-gradient(to right, rgba(77,0,154,0) 0%, rgba(77,0,154,1) 30%)',
          color: 'linear-gradient(to right, rgba(191,191,191,0) 0%, rgba(191,191,191,1) 30%)',
          width: 100 / level,
          start: "<" + settings.min_thickness.toFixed(2),
        }
      )
    }

    for (let i = 0; i < level; ++i) {
      const value = tween(settings.min_thickness, settings.max_thickness, i, level);
      const nextValue = tween(settings.min_thickness, settings.max_thickness, i + 1, level);
      const prevValue = tween(settings.min_thickness, settings.max_thickness, i - 1, level);
      const color = colorHex(colors[i * 3], colors[i * 3 + 1], colors[i * 3 + 2]);

      if ((settings.b_safezone !== 0)) {

        // if safezone is fully enveloped by a step
        if (value <= safe.min && nextValue >= safe.max) {
          // if start value is smaller than start of safezone: draw starting part of step
          if (value < safe.min) {

            objectResult.push(
              {
                color,
                width: 100 / level * (safe.min - value) / (nextValue - value),
                start: (((safe.min - value) / (settings.max_thickness - settings.min_thickness)) >= minDist && distanceToLastNumber() >= minDist) ? value.toFixed(2).toString() : undefined,
              }
            )
          }

          // draw safezone
          objectResult.push(
            {
              color: safeZoneColor,
              width: 100 / level * (Math.min(safe.max, settings.max_thickness) - safe.min) / (nextValue - value),
              start: distanceToLastNumber() >= minDist ? safe.min.toFixed(2).toString() : undefined,
              // end: (!settings.cutoff && safe.max >= settings.max_thickness) && (distanceToLastNumber() >= minDistance && ) ? settings.max_thickness.toFixed(2).toString() : undefined
            }
          )
          if (!(settings.b_cutoff !== 0) && safe.max >= settings.max_thickness && distanceToLastNumber() >= minDist) {
            objectResult[objectResult.length - 1].end = settings.max_thickness.toFixed(2).toString();
          }
          // if end value is greater than end of safezone draw ending part of step
          if (nextValue > safe.max) {
            objectResult.push(
              {
                color,
                width: 100 / level * (nextValue - safe.max) / (nextValue - value),
                start: distanceToLastNumber() >= minDist ? safe.max.toFixed(2).toString() : undefined,
              }
            )
            if (!(settings.b_cutoff !== 0) && i === level - 1 && distanceToLastNumber() >= minDist) {
              objectResult[objectResult.length - 1].end = settings.max_thickness.toFixed(2).toString();
            }
          }
          continue;
        }

        //  if only the end of this value is enveloped by safezone, draw a smaller block and add the safezone block
        else if (value <= safe.min && nextValue > safe.min) {
          // let blockW = (settings.max_thickness - settings.min_thickness) / steps;
          // let currentBlockW = blockW * (safe.min - value) / (nextValue - value)

          objectResult.push(
            {
              color,
              width: 100.0 / level * (safe.min - value) / (nextValue - value),
              start: (((safe.min - value) / (settings.max_thickness - settings.min_thickness)) > minDist && distanceToLastNumber() >= minDist) ? value.toFixed(2).toString() : undefined,
            }
          )
          // draw safezone
          objectResult.push(
            {
              color: safeZoneColor,
              width: 100 / level * (Math.min(safe.max, settings.max_thickness) - safe.min) / (nextValue - value),
              start: (distanceToLastNumber() >= minDist) ? safe.min.toFixed(2).toString() : undefined,
            }
          )

          if ((!(settings.b_cutoff !== 0) && safe.max >= settings.max_thickness) && distanceToLastNumber() >= minDist) {
            objectResult[objectResult.length - 1].end = settings.max_thickness.toFixed(2).toString();
          }
          continue;
        }

        // otherwise if the start of this value is enveloped by safezone, draw smaller block
        else if (value <= safe.max && nextValue > safe.max) {
          objectResult.push(
            {
              color,
              width: 100 / level * (nextValue - safe.max) / (nextValue - value),
              start: (((safe.max - safe.min) / (settings.max_thickness - settings.min_thickness) > minDist) && distanceToLastNumber() >= minDist) ? safe.max.toFixed(2).toString() : undefined,
            }
          )

          if (!(settings.b_cutoff !== 0) && i === level - 1 && distanceToLastNumber() >= minDist) {
            objectResult[objectResult.length - 1].end = settings.max_thickness.toFixed(2).toString();
          }
          continue;
        }

        else if (prevValue <= safe.max && value >= safe.max) {
          objectResult.push(
            {
              color,
              width: 100 / level,
              start: (((value - safe.max) / (settings.max_thickness - settings.min_thickness) > minDist) && distanceToLastNumber() >= minDist) ? value.toFixed(2).toString() : undefined,
            }
          )

          if (!(settings.b_cutoff !== 0) && i === level - 1 && distanceToLastNumber() >= minDist) {
            objectResult[objectResult.length - 1].end = settings.max_thickness.toFixed(2).toString();
          }
          continue;
        }

        else if (value >= safe.min && nextValue <= safe.max) {
          continue;
        }
      }

      objectResult.push(
        {
          color,
          width: 100 / level,
          start: (distanceToLastNumber() >= minDist) ? value.toFixed(2).toString() : undefined,
        }
      )

      if (!(settings.b_cutoff !== 0) && i === level - 1 && distanceToLastNumber() >= minDist) {
        objectResult[objectResult.length - 1].end = settings.max_thickness.toFixed(2).toString();
      }
    }

    if ((settings.b_cutoff !== 0)) {

      objectResult.push(
        {
          // color: 'linear-gradient(to left, rgba(154,0,77,0) 0%, rgba(154,0,77,1) 30%)',
          color: 'linear-gradient(to left, rgba(127,127,127,0) 0%, rgba(127,127,127,1) 30%)',
          width: 100 / level,
          start: ((((settings.b_safezone !== 0) && settings.max_thickness !== safe.max && ((settings.max_thickness - safe.max) / (settings.max_thickness - settings.min_thickness) * settings.level) < 0.5)) || distanceToLastNumber() < minDist) ? undefined : settings.max_thickness.toFixed(2).toString(),
        }
      )

      if (distanceToLastNumber() >= minDist) {
        objectResult[objectResult.length - 1].end = ">" + settings.max_thickness.toFixed(2);
      }
    }

    return objectResult;
  }

  
  return (
    <React.Fragment>
      <Box gap="1px" direction="row" ref={ref} pad={{ horizontal: 'medium' }} >
        {generateScale(minDistance).map((step, i) => {
          return (
            <Step {...step} cssWidth={step.width + '%'} key={i} />
          )
        })}
      </Box>
      <ScreenshotBox gap="1px" direction="row" ref={screenshotScale} pad={{ horizontal: 'medium' }} width={screenshotWidth + 'px'}>
        {generateScale(minPixDistance / screenshotWidth).map((step, i) => {
          return (
            <Step {...step} cssWidth={step.width + '%'} key={i} />
          )
        })}
      </ScreenshotBox>
    </React.Fragment>
  )
};

export default PCBScale;
