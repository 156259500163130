import React from 'react';
import { Box, Heading, PasswordResetForm, Link } from 'components';
import { useTranslations, useResetPassword } from 'hooks';
import { Routes } from 'routes';
import { useRouterStore } from 'mobx-state-router';

const ResetPassword = () => {
  const {
    routerState: { params },
  } = useRouterStore();
  const t = useTranslations('login');

  const { busy, resetPassword, errors } = useResetPassword(params.token);

  return (
    <React.Fragment>
      <Heading level="3" size="small" margin={{ bottom: 'medium' }}>
        {t('password_reset_title')}
      </Heading>
      <PasswordResetForm
        onResetPassword={resetPassword}
        initialValues={{ password: '', password_repeat: '' }}
        errors={errors}
        busy={busy}
      />
      <Box margin={{ top: 'large' }}>
        <Link to={Routes.Login}>{t('back_to_login')}</Link>
      </Box>
    </React.Fragment>
  );
};

export default ResetPassword;
