import React, { useState, useEffect } from 'react';
import { TextInput } from 'components';
var TimeFormat = require('hh-mm-ss');

export type SplitTimeInputProps = {
  timeInSeconds: number;
  disabled?: boolean;
  resetValue?: boolean;
  onChange: (newTimeInSeconds: number) => void;
};

function secondToTimeString(time: number) {
  return TimeFormat.fromS(time, 'hh:mm:ss');
}

function timeStringToSeconds(timeStr: string) {
  try {
    return TimeFormat.toS(timeStr, 'hh:mm:ss');
  } catch {
    return NaN;
  }
}

const SplitTimeInput = ({ timeInSeconds, onChange, disabled, resetValue }: SplitTimeInputProps) => {
  const [displayTime, setDisplayTime] = useState(secondToTimeString(timeInSeconds));

  const digit = /[0-9]/i;
  const seconds = /[012345]/i;
  const timeMask = [digit, digit, ':', seconds, digit, ':', seconds, digit];

  const onChangeHandler = (value: string) => {
    setDisplayTime(value);
    const time = timeStringToSeconds(value);
    if (!isNaN(time)) {
      onChange(time);
    }
  };

  useEffect(() => {
    if (timeInSeconds) {
      setDisplayTime(secondToTimeString(timeInSeconds));
    }
  }, [timeInSeconds, resetValue]);

  //TODO: add this handler to the masked input, after grommet has been updated to at least 2.13
  // const onBlurHandler = () => {
  //   setDisplayTime(secondToTimeString(timeInSeconds));
  // }

  return (
    <TextInput disabled={disabled} mask={timeMask} value={displayTime} onChange={onChangeHandler} />
  );
};

export default SplitTimeInput;
