import React from 'react';
import { Routes } from 'routes';
import { Box, FormikTextField, FormikCheckboxField, Paragraph, Button, Layer, TermsAndConditions, PrivacyPolicy } from 'components';
import { useTranslations } from 'hooks';
import { Formik, Form } from 'formik';

import * as yup from 'yup';
import ProfileFields, { ProfileFieldsSchemaProto } from 'components/ProfileFields';

export type RegistrationFormFields = {
  email: string;
  password: string;
  password_repeat: string;
  firstName: string;
  lastName: string;
  phone_number: string;
  company: string;
  country: string;
  role: string;
  acceptTerms: boolean;
};

export type RegistrationFormFeedback = Partial<
  { [K in keyof RegistrationFormFields]: string } & { general?: string }
>;
export type RegistrationFormHints = Partial<{ [K in keyof RegistrationFormFields]: string }>;


const passwordLength = 8;
export const passwordReqs = {
  length: passwordLength,
  regex: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*([^\w\s]|[_]))(?=.{8,})/ //eslint-disable-line
}
// regex containing at least <passwordLength> chars with at least one of each: [a-z], [A-Z], [0-9] and [!@#$%^&*]

// TODO: Find a way to use the ${min} interpolation of yup iso hardcoding 'password_too_short'
const schema = yup.object({
  email: yup.string().required('missing_field').email('invalid_email'),
  password: yup.string().required('missing_field').min(passwordReqs?.length, 'password_too_short').matches(passwordReqs?.regex, 'weak_password'),
  password_repeat: yup.string().required('missing_field').oneOf([yup.ref('password')], 'passwords_dont_match'),
  acceptTerms: yup.boolean().required('missing_field').oneOf([true], 'must_be_accepted'),
  ...ProfileFieldsSchemaProto,
});

export type RegistrationFormProps = {
  errors?: RegistrationFormFeedback;
  hints?: RegistrationFormHints;
  onRegister: (values: RegistrationFormFields) => void;
  initialValues: RegistrationFormFields;
  countryOptions: string[];
  roleOptions: string[];
  busy?: boolean;
  termsConditionsLink: Routes | string;
};

const RegistrationForm = ({
  errors,
  hints,
  onRegister,
  initialValues,
  countryOptions,
  roleOptions,
  busy,
  termsConditionsLink,
}: RegistrationFormProps) => {
  const t = useTranslations('login');
  const tr = useTranslations('register');

  return (
    <>
      {errors?.general != null && errors.general.length > 0 && (
        <Paragraph color="status-critical">{errors.general}</Paragraph>
      )}
      <Formik
        initialValues={initialValues}
        onSubmit={onRegister}
        validationSchema={schema}
        validateOnBlur={true}
        validateOnChange={false}
      >
        <Form>
          <ProfileFields
            countryOptions={countryOptions}
            roleOptions={roleOptions}
            hints={hints}
            errors={errors}
            busy={busy}
          />
          <FormikTextField
            type="email"
            name="email"
            label={t('email_label')}
            placeholder={t('email_placeholder')}
            disabled={busy}
            error={errors?.email}
            hint={hints?.email}
            required
          />
          <FormikTextField
            type="password"
            name="password"
            label={t('password_label')}
            placeholder={t('password_placeholder')}
            error={errors?.password}
            hint={hints?.password}
            disabled={busy}
            required
          />
          <FormikTextField
            type="password"
            name="password_repeat"
            label={t('password_repeat_label')}
            placeholder={t('password_placeholder')}
            error={errors?.password_repeat}
            hint={hints?.password_repeat}
            disabled={busy}
            required
          />
          <FormikCheckboxField
            name="acceptTerms"
            label={
              <>
                {t('accept_terms')} <Layer label={t('terms_conditions')}><TermsAndConditions/></Layer> & <Layer label={t('privacy_policy')}><PrivacyPolicy/></Layer>
              </>
            }
            error={errors?.acceptTerms}
            hint={hints?.acceptTerms}
            disabled={busy}
            margin={{ top: 'large' }}
            required
          />
          <Box margin={{ top: 'medium' }}>
            <Button
              type="submit"
              label={busy ? '' : tr('register_button')}
              alignSelf="stretch"
              busy={busy}
              disabled={busy}
              primary
            />
          </Box>
        </Form>
      </Formik>
    </>
  );
};

export default RegistrationForm;
