import React from 'react';
import { useTranslations } from 'hooks';
import { useField, FieldInputProps } from 'formik';
import { FormField, TextInput, Select, Box, CheckBox, Span } from 'components';
import { SelectOption, SelectOptionValue } from 'components/Select';
import { MarginType } from 'components/types';
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import styled from 'styled-components';


const StyledPhoneInput = styled(PhoneInput)`

  > input {
    border: 1px solid #B7C0C9;
    border-radius: 4px;
    display: flex;
    flex: 1 1 auto;
    width: 100%;
    height: 30px;
    font: inherit;
    padding: 2px 8px;

    &:focus {
      border-color: #3fb7ce;
    }
  }
`;

type UseFormikFieldArgs = React.InputHTMLAttributes<HTMLInputElement> & {
  name: string;
  error?: string;
};

function useFormikField<T>({ error: errorProp, ...props }: UseFormikFieldArgs) {
  const [field, meta, helpers] = useField<T>(props);
  const te = useTranslations('errors', 'controls');
  const formikError = meta.touched && meta.error ? te(meta.error, meta.error) : undefined;
  const error = errorProp != null ? te(errorProp, errorProp) : formikError;

  return {
    field,
    helpers,
    meta,
    error,
  };
}

type FormikFieldProps = React.InputHTMLAttributes<HTMLInputElement> & {
  name: string;
  label: string | React.ReactNode;
  disabled?: boolean;
  placeholder?: string;
  error?: string;
  hint?: string;
  required?: boolean;
  margin?: MarginType;
};

type FormikTextFieldProps = FormikFieldProps;

type FormikTextFieldViewProps = Pick<
  FormikTextFieldProps,
  'label' | 'hint' | 'error' | 'disabled' | 'required' | 'type' | 'placeholder' | 'margin'
> &
  Pick<FieldInputProps<string>, 'value' | 'name' | 'onBlur' | 'onChange'>;

const FormikTextFieldView = React.memo(
  ({
    label,
    hint,
    error,
    disabled,
    required,
    type,
    value,
    name,
    placeholder,
    margin,
    onBlur,
    onChange,
  }: FormikTextFieldViewProps) => {
    return (
      <FormField
        label={label}
        hint={hint}
        error={error}
        border={false}
        disabled={disabled}
        required={required}
        margin={margin}
      >
        <TextInput
          type={type}
          value={value}
          name={name}
          onBlur={onBlur}
          disabled={disabled}
          placeholder={placeholder}
          onChange={(v: any, e: any) => onChange(e)}
        />
      </FormField>
    );
  }
);

export const FormikTextField = ({
  label,
  hint,
  disabled,
  placeholder,
  required,
  margin,
  ...props
}: FormikTextFieldProps) => {
  const { field, error } = useFormikField<string>(props);

  return (
    <FormikTextFieldView
      label={label}
      hint={hint}
      disabled={disabled}
      required={required}
      placeholder={placeholder}
      type={props.type}
      error={error}
      value={field.value}
      name={field.name}
      onBlur={field.onBlur}
      onChange={field.onChange}
      margin={margin}
    />
  );
};

type FormikSelectFieldProps<T extends SelectOptionValue> = FormikFieldProps & {
  options: SelectOption<T>[];
};

type FormikSelectFieldViewProps<T extends SelectOptionValue> = Pick<
  FormikSelectFieldProps<T>,
  | 'label'
  | 'hint'
  | 'error'
  | 'disabled'
  | 'required'
  | 'type'
  | 'placeholder'
  | 'margin'
  | 'options'
> &
  Pick<FieldInputProps<T>, 'value' | 'name' | 'onBlur' | 'onChange'>;

const _FormikSelectFieldView = <T extends SelectOptionValue>({
  label,
  error,
  hint,
  disabled,
  required,
  options,
  value,
  placeholder,
  margin,
  onChange,
  onBlur,
  name,
}: FormikSelectFieldViewProps<T>) => {
  const selected = options.find((o) => o.value === value);

  return (
    <FormField
      label={label}
      border={false}
      error={error}
      hint={hint}
      disabled={disabled}
      required={required}
      margin={margin}
    >
      <Box height="30px">
      <Select
        name={name}
        options={options}
        value={selected}
        placeholder={placeholder}
        disabled={disabled}
        onChange={(option?: SelectOption<T>) => {
          // We use formik's onChange, because that one doesn't change when another field is touched.
          // It does expect an 'event' though, so we trick it :-)
          option?.value && onChange({ target: { name, value: option?.value } });
        }}
        clearable={false}
        onBlur={(e) => {
          // We use formik's onBlur, because that one doesn't change when another field is touched.
          // It does expect the name to be set on the event.target though. We trick it :-)
          onBlur({ target: { name } });
        }}
      />
      </Box>
    </FormField>
  );
};

const FormikSelectFieldView = React.memo(_FormikSelectFieldView);

export const FormikSelectField = <T extends SelectOptionValue>({
  label,
  disabled,
  placeholder,
  margin,
  options,
  hint,
  required,
  ...props
}: FormikSelectFieldProps<T>) => {
  const { field, error } = useFormikField<T>(props);
  return (
    <FormikSelectFieldView
      label={label}
      error={error}
      hint={hint}
      disabled={disabled}
      required={required}
      options={options}
      value={field.value}
      placeholder={placeholder}
      onChange={field.onChange}
      onBlur={field.onBlur}
      name={field.name}
      margin={margin}
    />
  );
};

type FormikCheckboxFieldProps = FormikFieldProps;

type FormikCheckboxFieldViewProps = Pick<
  FormikCheckboxFieldProps,
  'label' | 'hint' | 'error' | 'disabled' | 'required' | 'type' | 'margin'
> &
  Pick<FieldInputProps<boolean>, 'value' | 'name' | 'onBlur' | 'onChange'>;

const FormikCheckboxFieldView = React.memo(
  ({
    label,
    error,
    hint,
    disabled,
    required,
    margin,
    name,
    value,
    onChange,
    onBlur,
  }: FormikCheckboxFieldViewProps) => {
    return (
      <FormField
        border={false}
        error={error}
        hint={hint}
        disabled={disabled}
        required={required}
        margin={margin}
      >
        <Box direction="row" gap="small">
          <CheckBox
            name={name}
            disabled={disabled}
            label=""
            checked={value}
            onChange={(value, e) => {
              onChange(e);
            }}
            onBlur={onBlur}
          />
          <Span>{label}</Span>
        </Box>
      </FormField>
    );
  }
);

export const FormikCheckboxField = ({
  label,
  hint,
  disabled,
  required,
  margin,
  ...props
}: FormikCheckboxFieldProps) => {
  const { field, error } = useFormikField<boolean>(props);

  return (
    <FormikCheckboxFieldView
      label={label}
      error={error}
      hint={hint}
      margin={margin}
      disabled={disabled}
      required={required}
      name={field.name}
      value={field.value}
      onChange={field.onChange}
      onBlur={field.onBlur}
    />
  );
};

type FormikPhoneInputFieldProps = FormikFieldProps;

type FormikPhoneInputFieldViewProps = Pick<
  FormikTextFieldProps,
  'label' | 'hint' | 'error' | 'disabled' | 'required' | 'type' | 'placeholder' | 'margin'
> &
  Pick<FieldInputProps<string>, 'value' | 'name' | 'onBlur' | 'onChange'>;

export const FormikPhoneInputFieldView = React.memo(
  ({
    label,
    error,
    hint,
    disabled,
    required,
    margin,
    name,
    placeholder,
    value,
    onChange,
    onBlur,
  }: FormikPhoneInputFieldViewProps) => {
    return (
      <FormField
        label={label}
        border={false}
        error={error}
        hint={hint}
        disabled={disabled}
        required={required}
        margin={margin}
      >
        <Box direction="row" gap="small" height="30px" pad={{horizontal: "8px"}}>
          <StyledPhoneInput
            name={name}
            disabled={disabled}
            value={value}
            onChange={(val? : string) => {
              let v = val || "";
              onChange({ target: {value: v, name}});
            }}
            onBlur={() => { onBlur({ target: { name } })}}
            placeholder={placeholder}
            countrySelectProps={{className: "rs__menu" }}
          />
        </Box>
      </FormField>
    );
  }
);

export const FormikPhoneInputField = ({
  label,
  hint,
  disabled,
  required,
  margin,
  placeholder,
  ...props
}: FormikPhoneInputFieldProps) => {
  const { field, error } = useFormikField<string>(props);

  return (
    <FormikPhoneInputFieldView
      label={label}
      error={error}
      hint={hint}
      margin={margin}
      disabled={disabled}
      required={required}
      name={field.name}
      value={field.value}
      placeholder={placeholder}
      onChange={field.onChange}
      onBlur={field.onBlur}
    />
  );
};