import React from 'react';
import LazyScreen from 'components/LazyScreen';

const Screen = React.lazy(() => import('./Archive'));

const Archive = () => (
  <LazyScreen chrome>
    <Screen />
  </LazyScreen>
);

export default Archive;
