import React from 'react';
import { RangeInput as GrommetRangeInput } from 'grommet';

// forward props from https://github.com/grommet/grommet/tree/master/src/js/components/RangeInput
export type RangeInputProps = {
  min?: number | string;
  max?: number | string;
  name?: string;
  step?: number;
  value?: number | string;
  onChange?: (value: number) => void;
  disabled?: boolean;
};

const RangeInput = ({ onChange, ...props }: RangeInputProps) => {
  const onChangeHandler = (event: React.ChangeEvent<Element>) => {
    if (onChange != null && event != null) {
      let value = (event.target as any).value;
      if (value) {
        onChange(value as number);
      }
    }
  };
  return <GrommetRangeInput onChange={onChangeHandler} {...props} />;
};

export default RangeInput;
