import React from 'react';
import { Box } from 'grommet';
import { Routes } from 'routes';
import { Button, Paragraph, Link, FormikTextField, FormikCheckboxField,  } from 'components'; // passwordReqs
import { useTranslations } from 'hooks';
import { Formik, Form } from 'formik';
import * as yup from 'yup';

export type LoginFormValues = { email: string; password: string; rememberMe: boolean };

type LoginFormFeedback = Partial<
  { general?: string } & {
    [K in keyof LoginFormValues]: string;
  }
>;

type LoginFormHints = Partial<
  {
    [K in keyof LoginFormValues]: string;
  }
>;

export type LoginFormProps = {
  errors?: LoginFormFeedback;
  hints?: LoginFormHints;
  onLogin: (values: LoginFormValues) => void;
  initialValues: LoginFormValues;
  busy?: boolean;
  registerLink: Routes | string;
  forgotPasswordLink: Routes | string;
};

// import doesnt work TODO
const passwordLength = 8;
const passwordReqs = {
  length: passwordLength,
  regex: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*([^\w\s]|[_]))(?=.{8,})/ //eslint-disable-line
}

const schema = yup.object({
  email: yup.string().required('missing_field'),
  password: yup.string().required('missing_field').min(passwordReqs?.length, 'password_too_short'),
  rememberMe: yup.boolean(),
});

const LoginForm = ({
  errors,
  hints,
  onLogin,
  initialValues,
  busy,
  registerLink,
  forgotPasswordLink,
}: LoginFormProps) => {
  const t = useTranslations('login');

  return (
    <Box wrap direction="column">
      {errors?.general != null && errors.general.length > 0 && (
        <Paragraph color="status-critical">{errors.general}</Paragraph>
      )}
      <Formik
        initialValues={initialValues}
        onSubmit={onLogin}
        validationSchema={schema}
        validateOnBlur={true}
        validateOnChange={false}
      >
        <Form>
          <FormikTextField
            name="email"
            type="email"
            label={t('email_label')}
            placeholder={t('email_placeholder')}
            disabled={busy}
            error={errors?.email}
            hint={hints?.email}
            required
          />
          <FormikTextField
            name="password"
            type="password"
            label={t('password_label')}
            placeholder={t('password_placeholder')}
            disabled={busy}
            error={errors?.password}
            hint={hints?.password}
            required
          />
          <FormikCheckboxField
            name="rememberMe"
            label={t('remember_login')}
            disabled={busy}
            error={errors?.rememberMe}
            hint={hints?.rememberMe}
          />
          <Box margin={{ top: 'medium' }}>
            <Button
              type="submit"
              label={busy ? '' : t('login_button')}
              alignSelf="stretch"
              busy={busy}
              disabled={busy}
              primary
            />
          </Box>
        </Form>
      </Formik>
      <Box margin={{ top: 'medium' }}>
        <Paragraph>
          {t('no_account_yet')} <Link to={registerLink}>{t('register_link')}</Link>
        </Paragraph>

        <Paragraph margin={{ top: 'small' }}>
          <Link to={forgotPasswordLink}>{t('forgot_password')}</Link>
        </Paragraph>
      </Box>
    </Box>
  );
};

export default LoginForm;
