import React from 'react';
import RModal from 'react-modal';
import { IconButton, Box } from 'components';
import styled from 'styled-components';
import { normalizeColor } from 'grommet/utils';
import { PadType } from 'components/types';

RModal.setAppElement('#root');

type ModalProps = React.PropsWithChildren<{
  className?: string;
  isOpen: boolean;
  onRequestClose?: (event: React.MouseEvent | React.KeyboardEvent) => void;
  pad?: PadType;
}>;

const CloseButton = styled(IconButton)`
  position: absolute;
  top: 5px;
  right: 5px;
`;

const Modal = ({ isOpen, onRequestClose, children, className, pad = 'medium' }: ModalProps) => {
  return (
    <RModal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      portalClassName={className}
      className="modal-content"
      overlayClassName="modal-overlay"
    >
      <Box pad={pad}>
        <CloseButton icon="close" plain={true} focusIndicator={false} onClick={onRequestClose} />
        {children}
      </Box>
    </RModal>
  );
};

const StyledModal = styled(Modal)`
  .modal-content {
    margin: auto;
    border: 1px solid ${({ theme }) => normalizeColor('border', theme)};
    background-color: ${({ theme }) => normalizeColor('background', theme)};
    color: ${({ theme }) => normalizeColor('text', theme)};
    outline: none;
  }
  .modal-overlay {
    z-index: 99;
    position: fixed;
    display: flex;
    padding: 25px;
    overflow: auto;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: ${({ theme }) => normalizeColor('modal-background', theme)};
  }
`;

export default StyledModal;
