import React from 'react';
import { Box, Button, Paragraph, FormikTextField } from 'components';
import { useTranslations } from 'hooks';
import { Formik, Form } from 'formik';
import * as yup from 'yup';

export enum PasswordResetRequestStages {
  INIT,
  REQUEST_SENT,
}

export type PasswordResetRequestFormValues = { email: string };

export type PasswordResetRequestFormFeedback = { general?: string } & {
  [K in keyof PasswordResetRequestFormValues]?: string;
};

export type PasswordResetRequestFormProps = {
  errors?: PasswordResetRequestFormFeedback;
  onRequestReset: (values: PasswordResetRequestFormValues) => void;
  initialValues: PasswordResetRequestFormValues;
  busy?: boolean;
  stage: PasswordResetRequestStages;
};

const schema = yup.object({
  email: yup.string().required('missing_field').email('invalid_email'),
});

const PasswordResetForm = ({
  errors,
  onRequestReset,
  initialValues,
  busy,
  stage,
}: PasswordResetRequestFormProps) => {
  const t = useTranslations('login');

  switch (stage) {
    case PasswordResetRequestStages.INIT:
      return (
        <Box wrap direction="column" margin="none">
          <Formik
            initialValues={initialValues}
            onSubmit={onRequestReset}
            validationSchema={schema}
            validateOnBlur={true}
            validateOnChange={false}
          >
            <Form>
              <FormikTextField
                name="email"
                type="email"
                label={t('enter_associated_email')}
                placeholder={t('email_placeholder')}
                disabled={busy}
                error={errors?.email}
              />
              <Box margin={{ top: 'small' }}>
                <Button
                  label={busy ? '' : t('reset_password')}
                  type="submit"
                  busy={busy}
                  disabled={busy}
                  primary
                />
              </Box>
            </Form>
          </Formik>
        </Box>
      );
    case PasswordResetRequestStages.REQUEST_SENT:
      return <Paragraph>{t('reset_password_sent')}</Paragraph>;
  }
};

export default PasswordResetForm;
