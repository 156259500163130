import { useState } from 'react';
import { useSession } from 'store';
import { register, confirmEmail, resetPassword } from 'api/session';
import { RegistrationFormFields, RegistrationFormFeedback } from 'components/RegistrationForm';
import { ApiError } from '@nimbu/api';
import { useTranslations } from './i18n';
import { useAsync, useAsyncFn } from 'react-use';
import { LoginFormValues, LoginFormProps } from 'components/LoginForm';
import { PasswordResetFormValues, PasswordResetFormProps } from 'components/PasswordResetForm';
import { ProfileFieldsFields, ProfileFieldsFeedback } from 'components/ProfileFields';
import { SecurityFormFields, SecurityFormFeedback } from 'components/SecurityForm';
import { Routes } from 'routes';
import { useNavigate } from 'utils';

export const useLogin = (rememberMe?: boolean, email = '') => {
  const t = useTranslations('login');
  const initialValues = {
    email,
    password: '',
    rememberMe: false,
  };
  const [errors, setErrors] = useState<LoginFormProps['errors']>({});
  const session = useSession();

  const attemptLogin = async (values: LoginFormValues) => {
    await session.login(values.email, values.password, values.rememberMe);

    if (session.authenticated) {
      return session.authenticated;
    } else {
      setErrors({
        general: t('login_failed'),
      });
      return false;
    }
  };

  return {
    initialValues,
    errors,
    login: attemptLogin,
  };
};

const countryOptions = require('i18n-iso-countries/codes.json').map(
  (values: string[]) => values[0]
);
export const roleOptions = [
  'designer',
  'cam_engineer',
  'process_engineer',
  'commercial',
  'support',
  'other',
];

export const useRegister = () => {
  const initialValues: RegistrationFormFields = {
    email: '',
    password: '',
    password_repeat: '',
    firstName: '',
    lastName: '',
    company: '',
    country: '',
    role: '',
    acceptTerms: false,
    phone_number: '',
  };

  const [done, setDone] = useState(false);
  const [errors, setErrors] = useState<RegistrationFormFeedback>({});
  const t = useTranslations('login');

  const attemptRegister = async (values: RegistrationFormFields) => {
    try {
      await register(
        values.email,
        values.password,
        values.password_repeat,
        values.firstName,
        values.lastName,
        values.country,
        values.company,
        values.role,
        values.acceptTerms,
        values.phone_number
      );
      setDone(true);
    } catch (error) {
      if (error instanceof ApiError && error.status === 422) {
        // Some error registering
        // console.log(error);
        const errors: RegistrationFormFeedback = {};
        error?.errors?.forEach((e) => {
          if (e.field === 'email' && e.code === 'custom') {
            errors['email'] = 'already_exists';
          } else if (e.field === 'password' && (e.code === 'custom')) {
            errors['password'] = 'weak_password';
          } else if (e.field === 'firstname') {
            errors['firstName'] = e.code;
          } else if (e.field === 'lastname') {
            errors['lastName'] = e.code;
          } else if (e.field) {
            // We know e.field will be one of the allowed fields
            errors[e.field as keyof RegistrationFormFeedback] = e.code;
          }
        });
        setErrors(errors);
      } else {
        setErrors({
          general: t('register_general_error'),
        });
      }
    }
  };

  return {
    initialValues,
    done,
    countryOptions: countryOptions,
    roleOptions: roleOptions,
    errors: errors,
    infos: {},
    register: attemptRegister,
  };
};

export const useProfile = () => {
  const session = useSession();
  const initialValues: ProfileFieldsFields = {
    firstName: session.user?.firstname || '',
    lastName: session.user?.lastname || '',
    company: session.user?.company || '',
    country: session.user?.country || '',
    role: session.user?.role || '',
    phone_number: session.user?.phone_number || '',
  };

  const [done, setDone] = useState(false);
  const [errors, setErrors] = useState({} as ProfileFieldsFeedback);
  const t = useTranslations('login');

  const [state, updateProfile] = useAsyncFn(async (values?: ProfileFieldsFields) => {
    if (values != null) {
      try {
        await session.updateProfile(
          values.firstName,
          values.lastName,
          values.company,
          values.country,
          values.role,
          values.phone_number
        );
        setDone(true);
      } catch (error) {
        if (error instanceof ApiError && error.status === 422) {
          // Some error updating
          const errors: ProfileFieldsFeedback = {};
          error?.errors?.forEach((e) => {
            if (e.field === 'firstname') {
              errors['firstName'] = e.code;
            } else if (e.field === 'lastname') {
              errors['lastName'] = e.code;
            } else if (e.field) {
              // We know e.field will be one of the allowed fields
              errors[e.field as keyof ProfileFieldsFeedback] = e.code;
            }
          });
          setErrors(errors);
        } else {
          setErrors({
            general: t('update_profile_error'),
          });
        }
      }
    }
  });

  return {
    initialValues,
    done, // TODO: implement
    busy: state.loading,
    countryOptions,
    roleOptions,
    errors,
    infos: {},
    updateProfile,
  };
};

export const useSecurityInfo = () => {
  const session = useSession();

  const initialValues: SecurityFormFields = {
    email: session.user?.email || '',
    password: '',
    password_repeat: '',
    password_current: '',
  };

  const [done, setDone] = useState(false);
  const [errors, setErrors] = useState({} as SecurityFormFeedback);
  const t = useTranslations('account');

  const [state, updateSecurityInfo] = useAsyncFn(async (values?: SecurityFormFields) => {
    if (values != null) {
      try {
        await session.updateCredentials(
          values.email,
          values.password_current,
          values.password,
          values.password_repeat
        );
        setDone(true);
      } catch (error) {
        if (error instanceof ApiError && error.status === 422) {
          // Some error updating
          const errors: SecurityFormFeedback = {};
          error?.errors?.forEach((e) => {
            if (e.field === 'email') {
              errors['email'] = e.code === 'custom' ? 'already_exists' : e.code;
            } else if (e.field === 'current_password') {
              errors['password_current'] = e.code === 'invalid' ? 'incorrect_password' : e.code;
            } else if (e.field === 'password') {
              errors['password'] = e.code === 'custom' ? 'weak_password' : e.code;
            } else if (e.field === 'password_confirmation') {
              errors['password_repeat'] = e.code;
            }
          });
          setErrors(errors);
        } else {
          setErrors({
            general: t('update_security_info_error'),
          });
        }
      }
    }
  });

  return {
    initialValues,
    done,
    busy: state.loading,
    errors: errors,
    infos: {},
    updateSecurityInfo,
  };
};

export function useConfirmEmail(token: string) {
  const navigate = useNavigate();
  const confirmState = useAsync(async () => {
    const email = await confirmEmail(token);
    if (email != null) {
      navigate(Routes.Login, { queryParams: { confirmed: email } });
    }
    return email;
  }, [token]);

  return {
    busy: confirmState.loading,
    success: confirmState.value != null ? true : false,
  };
}

export function useEmailConfirmed() {
  const params = new URLSearchParams(window.location.search);

  const email = params.get('confirmed');
  return email != null ? decodeURIComponent(email) : undefined;
}

export function useResetPassword(token: string) {
  const navigate = useNavigate();
  const te = useTranslations('errors', 'controls');
  const t = useTranslations('login');
  const [errors, setErrors] = useState<PasswordResetFormProps['errors']>({});

  const [state, doResetPassword] = useAsyncFn(
    async (values?: PasswordResetFormValues) => {
      if (values != null) {
        try {
          const customer = await resetPassword(token, values.password, values.password_repeat!);
          navigate(Routes.Login, { queryParams: { reset: customer.email } });
        } catch (error) {
          if (error instanceof ApiError && error.status === 422) {
            // Some error registering
            const errors: PasswordResetFormProps['errors'] = {};
            error?.errors?.forEach((e) => {
              if (e.field === 'reset_password_token') {
                // something wrong with the password reset token
                errors['general'] = t('reset_password_general_error');
              } else if (e.field === 'password' && e.code === 'custom') {
                errors[e.field] = te('weak_password');
              }
            });
            setErrors(errors);
          } else {
            setErrors({
              general: t('reset_password_general_error'),
            });
          }
        }
      }
    },
    [token]
  );

  return {
    resetPassword: doResetPassword,
    busy: state.loading,
    errors,
  };
}

export function usePasswordResetEmail() {
  const params = new URLSearchParams(window.location.search);

  const email = params.get('reset');
  return email != null ? decodeURIComponent(email) : undefined;
}
