import React from 'react';
import { Text as GrommetText } from 'grommet';
import { Sizes, ColorType, AlignSelfType, MarginType, TextAlignType } from 'components/types';

type TextProps = React.PropsWithChildren<{
  size?: Sizes;
  color?: ColorType;
  alignSelf?: AlignSelfType;
  margin?: MarginType;
  textAlign?: TextAlignType;
  truncate?: boolean;
  wordBreak?: "normal" | "break-all" | "keep-all" | "break-word";
  weight?: "normal" | "bold" | number;
}>;

export const Text = ({
  size = 'medium',
  color = 'text',
  ...rest
}: TextProps & {
  as?: keyof JSX.IntrinsicElements | React.ComponentType<any>;
}) => {
  return <GrommetText size={size} color={color} {...rest} />;
};

export const Span = (props: TextProps) => <Text as="span" {...props} />;

export const Paragraph = (props: TextProps) => <Text as="p" {...props} />;

export default Span;
