import React, { useContext } from 'react';
import styled from 'styled-components';
import { Box, Text, Menu as GrommetMenu } from 'grommet';
import { observer } from 'mobx-react';
import { Icon, Link } from 'components';
import { normalizeColor } from 'grommet/utils';
import { ThemeContext } from 'styled-components';
import { ElsycaLogo, CuBELogoLight } from 'assets';
import { useGetPageTitle } from 'hooks';
// import { useStore } from 'store';
import { useSimulation } from '../../screens/simulation/hooks';
import { useRouterStore } from 'mobx-state-router';
import { useNavigate } from 'utils';
import { Routes } from 'routes';

type MenuItem = {
  label: string;
  onClick: () => void;
};

type TopNavProps = React.PropsWithChildren<{
  userName: string;
  menuItems: MenuItem[];
}>;

const StyledText = styled(Text)`
  cursor: pointer;

  :hover {
    opacity: 0.5;
  }

  :active {
    opacity: 0.8;
  }
`;

const UserNameText = styled(Text)`
  white-space: nowrap;
`;

const UserName = ({ userName }: { userName: string }) => (
  <Box wrap={false} direction="row" gap="xsmall" align="center">
    <Icon name="user-circle-light" color="text-xweak" size="large" />
    <UserNameText>{userName}</UserNameText>
  </Box>
);

const Menu = ({ userName, items }: { userName: string; items: MenuItem[] }) => {
  const label = <UserName userName={userName} />;
  const theme = useContext(ThemeContext);

  return (
    <GrommetMenu
      dropBackground={normalizeColor('background-front', theme)}
      focusIndicator={false}
      label={label}
      items={items}
      dropAlign={{ right: "right", top: "top" }}
    />
  );
};

const TopNavView = ({ userName, menuItems, children }: TopNavProps) => {

  const pageTitle = useGetPageTitle();
  const {
    routerState: { params },
  } = useRouterStore();
  const simulation = useSimulation(params.id || "");
  const navigate = useNavigate();
  const showTitle = params.id && simulation;

  return (
    <Box
      wrap={false}
      tag="header"
      flex
      direction="row"
      gap="small"
      pad={{ horizontal: 'small' }}
      responsive={false}
      border="bottom"
      background="background-front"
      width="100%"
    >
      <Box flex={{ grow: 1 }} wrap direction="row" align="center" gap="small" basis="0">
        <Box margin={{ top: "4px", right: "10px", left: "5px" }} width={{min: "169.2px"}}>
          <Link to="https://www.elsycacube.com">
            <CuBELogoLight width="169.2px" height="36px" />
          </Link>
        </Box>
        {React.Children.count(children) > 0 && (
          <Box wrap={false}>
            {children}
          </Box>
        )}
      </Box>

      {pageTitle != null && (
        <Box
          wrap
          direction="row"
          align="center"
          gap="small"
          justify="center"
          width={{ max: "50%" }}
        >
          <Text size="22px" weight={500}>
            {pageTitle}
          </Text>
          { showTitle && (<Text size="12px">●</Text>)}
          { showTitle && (
            <StyledText textAlign="center" wordBreak="break-word" size="22px" color="brand" onClick={() => { navigate(Routes.Simulation, { params }) }}>
              {simulation.name || "<untitled>"}
            </StyledText>
          )}
        </Box>
      )
      }

      <Box flex={{ grow: 1 }} wrap="reverse" direction="row" align="center" gap="small" justify="end" basis="0">
        <Menu userName={userName} items={menuItems} />
        <Box
          direction="row"
          align="center"
          gap="small"
          width={{ min: '120px' }}
          margin={{ horizontal: 'small' }}
          pad={{ top: '4px' }}
        >
          <a href="https://www.elsyca-pcb.com" target="_blank" rel="noopener noreferrer">
            <ElsycaLogo width="120px" />
          </a>
        </Box>
      </Box>
    </Box >
  );
};

const TopNav = observer(TopNavView);

export default TopNav;
