import React from 'react';
import { Box, Text } from 'grommet';
import { Icon, IconName } from 'components';
import { Routes } from 'routes';

import RoutedButton from './RoutedButton';
import { RouterState } from 'mobx-state-router';

type MenuButtonProps = React.PropsWithChildren<{
  to?: Routes | string;
  params?: RouterState['params'];
  queryParams?: RouterState['queryParams'];
  label: string;
  icon: IconName;
  active?: boolean;
  onClick?: (e: React.MouseEvent) => void;
}>;

export function MenuButton({
  active,
  icon,
  label,
  to: toProp,
  params,
  queryParams,
  onClick,
}: MenuButtonProps) {
  const to = (toProp != null || onClick != null) ? toProp : Routes.NotFound;

  return (
    <RoutedButton
      active={active}
      to={to}
      params={params}
      queryParams={queryParams}
      onClick={onClick}
    >
      <Box pad={{ vertical: 'small' }} gap="xsmall" align="center" justify="center">
        <Icon name={icon} color="text" size="large" />
        <Text size="xsmall" color="text">
          {label}
        </Text>
      </Box>
    </RoutedButton>
  );
}
