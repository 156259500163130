import React, { useEffect, useState, useMemo, useContext } from 'react';
import { Box, Button, Dialog, Heading, Paragraph, FieldSet, RangeInput, Spinner, Tooltip, Icon } from 'components';
import { useTranslations } from 'hooks';
import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js';
import { useSession, useCredits } from 'store';
import countries from 'i18n-iso-countries';
import { Trans } from 'react-i18next'; //useTranslation,
import styled, { ThemeContext } from 'styled-components';
import { useToasts } from 'react-toast-notifications';
import { createPaymentIntent, customer, updateCustomer, Customer } from '../../api/credits';
import { UNLOCK_SIMULATION, UNLOCK_BALANCING, UNIT_PRICE } from '../../config';

import classNames from 'classnames';

import './stripe.scss';

import tax_id_types_json from '../../tax_id_types.json';

interface tax_id_types {
  EU: {
    [key: string]: tax_id_country
  },
  NONEU: {
    [key: string]: tax_id_country
  }
};
interface tax_id_country {
  country: string,
  tax_id_types: {
    [key: string]: tax_id_type
  }
};
interface tax_id_type {
  description: string,
  example: string,
  regex: string
};
const tax_id_types = tax_id_types_json as tax_id_types;

countries.registerLocale(require('i18n-iso-countries/langs/en.json'));
countries.registerLocale(require('i18n-iso-countries/langs/nl.json'));
// countries.registerLocale(require('i18n-iso-countries/langs/de.json'));

const GalleryBox = styled(Box).attrs(
  ({ panel }: { panel: number }) => ({
    panel: panel,
  })
)`
transition: transform 0.75s ease;
${({ panel }) => { return `transform: translateX(${(panel) * -120}%);` }}
`;

// const VATCCBox = styled(Box)`
//   flex: 0;
//   border-style: none;
//   outline: none;

//   font-family: 'Titillium Web';
//   font-size: 16px;
//   font-weight: 400;

//   padding: 0 15px;

//   background: transparent;
//   color: #31325f;

// `

const StyledSpan = styled.span`
  & > span {
    padding: 0px !important;
  }
`

const VATinput = styled.input`
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &[type=number] {
    -moz-appearance: textfield;
  }
`

type BuyDialogProps = {
  isOpen: boolean;
  onCancel?: (event?: React.MouseEvent | React.KeyboardEvent) => void;
};

export const BuyDialog = ({ isOpen, onCancel }: BuyDialogProps) => {
  // const { i18n } = useTranslation();
  const t = useTranslations('buy.dialog');
  const session = useSession();
  const creditsAvailable = useCredits();
  const theme = useContext(ThemeContext);
  const { addToast } = useToasts();

  const email = session.user!.email;
  const phone = session.user!.phone_number;

  const [stripeId, setStripeId] = useState<string | null>(null);
  const [verificationUrl, setVerificationUrl] = useState('');
  const [clientSecret, setClientSecret] = useState('');
  const [nbCredits, setNbCredits] = useState(15);
  const [name, setName] = useState(`${session!.user!.firstname} ${session!.user!.lastname}`);
  const [company, setCompany] = useState(session!.user!.company || "");
  const [VAT, setVAT] = useState('');
  const [VATType, setVATType] = useState<string | null>(null);
  // const [VATError, setVATError] = useState<string | null>(null);
  const [VATFormatError, setVATFormatError] = useState<string | null>(null);
  const [elementComplete, setElementComplete] = useState(false);
  const [busy, setBusy] = useState(false);
  const [addressLine1, setAddressLine1] = useState('test123');
  const [addressLine2, setAddressLine2] = useState('');
  const [city, setCity] = useState('');
  const [zip, setZip] = useState('');
  const [state, setState] = useState('');
  const [countryCode, setCountryCode] = useState(session!.user!.country || 'BE');
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState<{ [key: string]: string }>({});
  const [paymentData, setPaymentData] = useState<any>(null);

  const value = (nbCredits * UNIT_PRICE).toFixed(2);
  const buyLabel = `${t('pay')} ${value}€`;

  const stripe = useStripe();
  const elements = useElements();
  const options: any = {
    style: {
      base: {
        fontSize: '16px',
        color: theme.dark ? '#ffffff' : '#424770',
        letterSpacing: '0.025em',
        fontFamily: 'Source Code Pro, monospace',
        '::placeholder': {
          color: theme.dark ? '#666666' : '#aab7c4',
        },
      },
      invalid: {
        color: theme.dark ? '#ff4c4c' : '#9e2146',
      },
    },
    hidePostalCode: true,
  };

  const countryList = useMemo(() => {
    // countries.registerLocale(require(`i18n-iso-countries/langs/${i18n.language.substr(0,2)}.json`));
    // return countries.getNames(i18n.language.substr(0,2));
    countries.registerLocale(require(`i18n-iso-countries/langs/en.json`));
    return countries.getNames('en');
  }, []);//[i18n.language]);

  // Handle real-time validation errors from the card Element.
  const handleChange = (event: any) => {
    setElementComplete(event.complete);

    if (event.error) {
      setError(event.error.message);
    } else {
      setError(null);
    }
  };

  const finalize = () => {
    let availableCredits = session!.user!.availableCredits;
    let nbAttempts = 0;

    const refresh = async () => {
      nbAttempts++;
      await session!.user!.refreshCredits();
      if (session!.user!.availableCredits === availableCredits && nbAttempts < 10) {
        setTimeout(refresh, 5000);
      } else {
        addToast(t('success', { count: Math.round(nbCredits) }), { appearance: 'success' });
        setBusy(false);
        if (onCancel != null) {
          onCancel();
          setPanel(0);
        }
      }
    };

    refresh();
  };

  const on3DSComplete = () => {
    setVerificationUrl('');

    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }

    // Check the PaymentIntent
    stripe.retrievePaymentIntent(clientSecret).then((result) => {
      if (result.error) {
        addToast(result.error.message, { appearance: 'error' });
        setBusy(false);
      } else {
        if (result?.paymentIntent?.status === 'succeeded') {
          // Show your customer that the payment has succeeded
          finalize();
        } else if (result?.paymentIntent?.status === 'requires_payment_method') {
          // Authentication failed, prompt the customer to enter another payment method
          addToast('error', { appearance: 'error' });
          setBusy(false);
        }
      }
    });
  };

  useEffect(() => {
    function is3DSComplete(event: any) {
      if (event.data === '3DS-authentication-complete') {
        on3DSComplete();
      }
    }
    window.addEventListener('message', is3DSComplete, false);

    return () => window.removeEventListener('message', is3DSComplete);
  });

  const [dirty, setDirty] = useState<boolean>(false);

  const getCustomer = async () => {
    setLoading(true);
    const c = await customer();
    if (c != null) {
      setStripeId(c.id);
      if (c.metadata != null) {
        if (c.metadata.companyName != null) setCompany(c.metadata.companyName);
        if (c.metadata.tax_id != null) setVAT(c.metadata.tax_id);
      }
      if (c.name != null) setName(c.name);
      if (c.address != null) {
        if (c.address.line1 != null) setAddressLine1(c.address.line1);
        if (c.address.line2 != null) setAddressLine2(c.address.line2);
        if (c.address.city != null) setCity(c.address.city);
        if (c.address.postal_code != null) setZip(c.address.postal_code);
        if (c.address.country != null) setCountryCode(c.address.country);
        if (c.address.state != null) setState(c.address.state);
      }
      if (c.tax_id_data != null) {
        setVAT(c.tax_id_data.value);
        setVATType(c.tax_id_data.type);
      }
    }

    setDirty(!dirty);
    setLoading(false);
    setPanel(1);
  }

  const checkVAT = React.useCallback((country: string, taxId: string) => {
    if (Object.keys(tax_id_types.EU).includes(country)) {
      const type = getVATType(country, taxId);
      setVATType(type);
      if (type == null) {
        // const supported = Object.keys(tax_id_types.EU[country].tax_id_types).map((key) => {
        //   return tax_id_types.EU[country].tax_id_types[key].example;
        // }).join(', ');
        return 'format not recognized';
      }
    }
    return null;
  }, []);

  const supportedVAT = (country: string) => {
    return Object.keys(tax_id_types.EU[countryCode].tax_id_types).map((key) => {
      return tax_id_types.EU[country].tax_id_types[key].example;
    }).join(', ');
  };

  const getVATType = (country: string, taxId: string) => {
    let type = null;
    if (tax_id_types.EU[country] != null) {
      const types = Object.keys(tax_id_types.EU[country].tax_id_types);
      for (let i = 0; i < types.length; ++i) {
        let regex = tax_id_types.EU[country].tax_id_types[types[i]].regex;
        let result = RegExp(regex)?.exec(taxId);
        if (result != null && result.length === 1 && result[0] != null && result[0] === taxId) {
          type = types[i];
          break;
        }
      }
    }
    return type;
  }

  const checkErrors = React.useCallback(() => {
    let errors: { [key: string]: string } = {};
    if (name.length === 0) errors.name = 'required';
    if (company.length === 0) errors.company = 'required';
    if (addressLine1.length === 0) errors.addressLine1 = 'required';
    if (city.length === 0) errors.city = 'required';
    if (zip.length === 0) errors.zip = 'required';
    if (countryCode.length === 0) errors.countryCode = 'required';
    if (['AU', 'US'].includes(countryCode) && state.length === 0) errors.state = 'required';

    if (Object.keys(tax_id_types.EU).includes(countryCode)) {
      if (VAT.length === 0) {
        errors.VAT = 'required';
        setVATFormatError(null)
      }
      else setVATFormatError(checkVAT(countryCode, VAT));
    }
    setErrors(errors);
    if (Object.keys(errors).length > 0) {
      return true;
    } else return false;
  }, [VAT, addressLine1.length, checkVAT, city.length, company.length, countryCode, name.length, state.length, zip.length]);

  React.useEffect(() => {
    checkErrors();
  }, [dirty, checkErrors]);

  const setCustomer = async () => {
    if (stripeId == null) return;
    if (checkErrors()) return;

    setLoading(true);
    let customer: Customer = {
      id: stripeId,
      name,
      email,
      phone,
      address: {
        city,
        country: countryCode,
        line1: addressLine1,
        line2: addressLine2,
        postal_code: zip,
        state: state,
      },
      metadata: {
        companyName: company,
      }
    }
    if (tax_id_types.EU[countryCode] != null && VATType != null) {
      customer.tax_id_data = {
        type: VATType,
        value: VAT,
      }
    } else {
      customer.metadata.tax_id = VAT;
    }
    const result = await updateCustomer(customer);
    setLoading(false);
    if (result == null) return addToast('Something went wrong!', { appearance: 'error' });
    if (result.error != null) {
      console.log(result.error);
      // addToast(result.error, { appearance: 'warning' });
    }
    else {
      setPaymentData(result);
      setPanel(2);
    }
  }



  const handleSubmit = async (event: any) => {
    // Block native form submission.
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }

    setBusy(true);

    try {
      const client_secret = await createPaymentIntent(session.user!.id, nbCredits, paymentData);
      setClientSecret(client_secret);
      const result = await stripe.confirmCardPayment(
        client_secret,
        {
          return_url: `${window.location.origin}/payments/callback`,
          payment_method: {
            card: elements.getElement(CardElement)!,
            billing_details: {
              address: {
                city: city,
                country: countryCode,
                line1: addressLine1,
                line2: addressLine2,
                postal_code: zip,
                state: state,
              },
              email: email,
              name: name,
            },
          },
          receipt_email: email,
        },
        { handleActions: false }
      );

      if (result.error) {
        // Show error to your customer (e.g., insufficient funds)
        addToast(result.error.message, { appearance: 'error' });
        setBusy(false);
      } else {
        // The payment has been processed!
        if (result?.paymentIntent?.status === 'succeeded') {
          // Show a success message to your customer
          // There's a risk of the customer closing the window before callback
          // execution. A webhook will listen for the
          // payment_intent.succeeded event server-side to add credits;
          finalize();
        } else if (result?.paymentIntent?.status === 'requires_action') {
          const url = result?.paymentIntent?.next_action?.redirect_to_url?.url;
          if (url != null) {
            setVerificationUrl(url);
          }
        }
      }
    } catch {
      // TODO: report error to Bugsnag, this is not normal!
      addToast('error', { appearance: 'error' });
      setBusy(false);
    }
  };

  const canSubmit = elementComplete && !error && !!stripe && !!elements;
  var [panel, setPanel] = useState(0);

  const tax_id_country: tax_id_country & { placeholder: string } = React.useMemo(() => {
    let country = tax_id_types.EU[countryCode] || tax_id_types.EU.BE;
    let placeholder = Object.keys(country.tax_id_types).map((key) => {
      return country.tax_id_types[key].example;
    }).join(', ');
    return { ...country, placeholder };
  }, [countryCode]);

  return (
    <Dialog
      isOpen={isOpen}
      okLabel={loading ? (<Spinner color="white" size={20} margin={{ horizontal: "5px" }} />) : (panel === 2 ? buyLabel : 'Next')}
      cancelLabel={panel === 0 ? 'Cancel' : 'Back'}
      onCancel={(event, button) => {
        if (panel === 0 || !button) { if (onCancel) onCancel() }
        else setPanel(panel - 1);
      }}
      canSubmit={loading ? false : (panel === 2 ? canSubmit : true)}
      onOk={(e) => { if (panel === 2) handleSubmit(e); else if (panel === 0) getCustomer(); else if (panel === 1) setCustomer(); }}
      busy={busy}
      justifyButtons="between"

    >
      {verificationUrl !== '' && (
        <iframe src={verificationUrl} title="3d-secure-verification" width={390} height={600} />
      )}
      {verificationUrl === '' && (
        <Box overflow="hidden" direction="column">
          <Heading level={4} margin={{ bottom: 'small' }} textAlign="center">
            {t('title')}
          </Heading>
          <GalleryBox direction="row" panel={panel} align="center" gap="20%">
            <Box width="100%">
              <Paragraph textAlign="center">
                {t('explanation', {
                  analyze_amount: Math.round(UNLOCK_SIMULATION),
                  balancer_amount: Math.round(UNLOCK_BALANCING),
                })}
              </Paragraph>
              <Paragraph margin={{ bottom: 'small' }} textAlign="center">
                {t('current_balance', { amount: Math.round(creditsAvailable || 0) })}
              </Paragraph>
              <form
                id="payment"
                className={classNames(theme.dark ? 'dark' : 'light', { busy })}
              >
                <FieldSet label={t('amount.fieldset')}>
                  <Box pad="xsmall" gap="small" width="large" margin={{ bottom: 'small' }}>
                    <Paragraph textAlign="center">{t('amount.label')}</Paragraph>
                    <RangeInput
                      disabled={busy}
                      value={nbCredits}
                      min={1}
                      max={150}
                      step={1}
                      onChange={setNbCredits}
                    />
                    <Paragraph textAlign="center">
                      {/* floats don't go well with i18n plural */}
                      {t('credits_to_value', {count: Math.round(nbCredits), value})}
                    </Paragraph>
                  </Box>
                </FieldSet>
                <FieldSet>
                  <Box pad="xsmall" width="large" margin={{ bottom: 'small' }}>
                    <Paragraph textAlign="center">{t('bulk_question')}</Paragraph>
                    <Paragraph margin={{ bottom: 'small' }} textAlign="center">
                      <Trans t={t} i18nKey="bulk_cta" values={{ email: t('bulk_email') }}>
                        <a href={`mailto:${t('bulk_email')}`}>{t('bulk_email')}</a>
                      </Trans>
                    </Paragraph>
                  </Box>
                </FieldSet>
              </form>
            </Box>
            <Box width="100%">
              <form
                id="payment"
                className={classNames(theme.dark ? 'dark' : 'light', { busy })}
              >
                <FieldSet direction="column" label={t('billing.fieldset')}>
                  <Box pad="xsmall" gap="small" width="large" margin={{ bottom: 'small' }}>
                    <fieldset disabled={busy}>
                      <label>
                        <span>Company</span>
                        <input
                          name="company"
                          className="field"
                          placeholder="Company"
                          value={company}
                          onChange={(event) => {
                            setCompany(event.target.value);
                          }}
                          onBlur={checkErrors}
                          required
                        />
                        <div className={`element-errors${errors.company ? ' visible' : ''}`} role="alert">
                          {errors.company}
                        </div>
                      </label>
                      <label>
                        <span>Name</span>
                        <input
                          name="name"
                          className="field"
                          placeholder="Jenny Rosen"
                          value={name}
                          onChange={(event) => {
                            setName(event.target.value);
                          }}
                          onBlur={checkErrors}
                          required
                        />
                        <div className={`element-errors${errors.name ? ' visible' : ''}`} role="alert">
                          {errors.name}
                        </div>
                      </label>
                      <label>
                        <span>Address</span>
                        <input
                          name="address"
                          className="field"
                          placeholder="Address Line 1"
                          value={addressLine1}
                          onChange={(event) => {
                            setAddressLine1(event.target.value);
                          }}
                          onBlur={checkErrors}
                        />
                        <div className={`element-errors${errors.addressLine1 ? ' visible' : ''}`} role="alert">
                          {errors.addressLine1}
                        </div>
                      </label>
                      <label>
                        <span>Address (line 2)</span>
                        <input
                          name="address"
                          className="field"
                          placeholder="Address Line 2"
                          value={addressLine2}
                          onChange={(event) => {
                            setAddressLine2(event.target.value);
                          }}
                          onBlur={checkErrors}
                        />
                        <div className={`element-errors${errors.addressLine2 ? ' visible' : ''}`} role="alert">
                          {errors.addressLine2}
                        </div>
                      </label>
                      <label className="city">
                        <span>City</span>
                        <input
                          name="city"
                          className="field"
                          placeholder="City"
                          value={city}
                          onChange={(event) => {
                            setCity(event.target.value);
                          }}
                          onBlur={checkErrors}
                        />
                        <div className={`element-errors${errors.city ? ' visible' : ''}`} role="alert">
                          {errors.city}
                        </div>
                      </label>
                      {['AU', 'US'].includes(countryCode) && (
                        <label className="state">
                          <span>State</span>
                          <input
                            name="state"
                            className="field"
                            placeholder="State"
                            value={state}
                            onChange={(event) => {
                              setState(event.target.value);
                            }}
                            onBlur={checkErrors}
                          />
                          <div className={`element-errors${errors.state ? ' visible' : ''}`} role="alert">
                            {errors.state}
                          </div>
                        </label>
                      )}
                      <label className="zip">
                        <span>Postal Code</span>
                        <input
                          name="postal_code"
                          className="field"
                          placeholder="Postal Code"
                          value={zip}
                          onChange={(event) => {
                            setZip(event.target.value);
                          }}
                          onBlur={checkErrors}
                        />
                        <div className={`element-errors${errors.zip ? ' visible' : ''}`} role="alert">
                          {errors.zip}
                        </div>
                      </label>
                      <label className="select">
                        <span>Country</span>
                        <div id="country" className={`field ${countryCode.toLowerCase()}`}>
                          <select
                            name="country"
                            onChange={(event) => {
                              setCountryCode(event.target.value);
                            }}
                            onBlur={checkErrors}
                            value={countryCode}
                          >
                            {/* selected={code === countryCode} */}
                            {Object.keys(countryList).map((code) => (
                              <option key={code} value={code} className={`field ${code.toLowerCase()}`} id="country">
                                {countryList[code]}
                              </option>
                            ))}
                          </select>
                        </div>
                        <div className={`element-errors${errors.countryCode ? ' visible' : ''}`} role="alert">
                          {errors.countryCode}
                        </div>
                      </label>
                      <label>
                        <StyledSpan>{Object.keys(tax_id_types.EU).includes(countryCode) ? (<>VAT
                          <Tooltip tooltip={`Supported VAT formats for ${countries.getName(countryCode, 'en')}: ${supportedVAT(countryCode)}`}>
                            <Icon name="question-circle-regular" color="text-xweak" />
                          </Tooltip></>) : 'TAX ID'}</StyledSpan>

                        <VATinput
                          name="VAT"
                          className="field"
                          placeholder={tax_id_country.placeholder}
                          value={VAT}
                          onChange={(event) => setVAT(event.target.value)}
                          onBlur={checkErrors}
                          required
                        />
                        <div className={`element-errors${errors.VAT ? ' visible' : ''}`} role="alert">
                          {errors.VAT}
                        </div>
                        <div className={`element-warning${VATFormatError ? ' visible' : ''}`} role="alert">
                          {VATFormatError}
                        </div>
                      </label>
                    </fieldset>
                  </Box>
                </FieldSet>
              </form>
            </Box>
            <Box width="100%">
              <form
                id="payment"
                className={classNames(theme.dark ? 'dark' : 'light', { busy })}
              >
                <FieldSet direction="column" align="stretch">
                  <Box pad="small"><Paragraph textAlign="center">{t('invoice_verify')}</Paragraph></Box>
                  <Box margin={{ bottom: "large", horizontal: "medium" }} gap="small">
                    <Box direction="row" border={{ size: "1px", color: "brand-light" }} pad="small" background="background-front">
                      <Box flex>{nbCredits} {t('credits')}</Box>
                      <Box flex align="end">{value}€</Box>
                    </Box>
                    <Box direction="row" flex border={{ size: "1px", color: "brand-light" }} pad="small" background="background-front">
                      <Box>
                        <Paragraph>
                          {name}<br />
                          {company}<br />
                          {VAT}<br />
                        </Paragraph>
                      </Box>
                      <Box flex />
                      <Box>
                        <Paragraph textAlign="end">
                          {addressLine1}<br />
                          {addressLine2 && (<>{addressLine2}<br /></>)}
                          {zip} {city}<br />
                          {state && `${state}, `}{countries.getName(countryCode, 'en')}<br />
                        </Paragraph>
                      </Box>
                    </Box>
                  </Box>
                </FieldSet>
                <FieldSet direction="column" label={t('payment.fieldset')}>
                  <Box pad="xsmall" gap="small" width="large" margin={{ bottom: 'small' }}>
                    <fieldset disabled={busy} className="card-wrapper">
                      <CardElement id="card-element" options={options} onChange={handleChange} />
                      {error != null && (
                        <div className="element-errors" role="alert">
                          {error}
                        </div>
                      )}
                    </fieldset>
                  </Box>
                </FieldSet>
                <FieldSet>
                  <Box pad="xsmall" width="large">
                    <Paragraph textAlign="center">{t('invoice_question')}</Paragraph>
                    <Paragraph textAlign="center">
                      <Trans t={t} i18nKey="bulk_cta" values={{ email: t('invoice_email') }}>
                        <a href={`mailto:${t('invoice_email')}`}>{t('invoice_email')}</a>
                      </Trans>
                    </Paragraph>
                  </Box>
                </FieldSet>
              </form>
            </Box>
          </GalleryBox>
        </Box>
      )}
    </Dialog>
  );
};

export const BuyButton = ({ size }: { size?: 'small' | 'medium' | 'large' }) => {
  const t = useTranslations('buy');
  const [open, setOpen] = useState(false);

  return (
    <>
      <Button primary size={size || 'small'} onClick={() => setOpen(true)}>
        {t('button')}
      </Button>
      <BuyDialog isOpen={open} onCancel={() => setOpen(false)} />
    </>
  );
};

export default BuyButton;
