import React from 'react';
import { Box, Span, Button } from 'components';
import i18n, { languages } from 'i18n';
import styled from 'styled-components';
import { normalizeColor } from 'grommet/utils';

type LanguageProps = {
  code: string;
  name: string;
  separator: boolean;
};

const LanguageSwitcherBox = styled(Box)`
  * {
    white-space: nowrap;
  }
`;

const LanguageButton = styled(Button)`
  box-shadow: none;
  & span {
    color: ${({ theme }) => normalizeColor('text', theme)};
  }
  &:disabled {
    opacity: 1;
    span {
      text-decoration: underline;
    }
  }
`;

const Language = ({ code, name, separator }: LanguageProps) => {
  return (
    <>
      {separator && <Span margin={{ horizontal: 'small' }}>|</Span>}
      <LanguageButton
        plain
        label={name}
        onClick={() => i18n.changeLanguage(code)}
        disabled={i18n.language === code}
      />
    </>
  );
};

const LanguageSwitcher = () => {
  return (
    <LanguageSwitcherBox direction="row">
      {languages.map((lng, i) => (
        <Language key={lng.code} code={lng.code} name={lng.name} separator={i !== 0} />
      ))}
      {process.env.NODE_ENV === 'development' && (
        <Language code="cimode" name="i18n keys" separator />
      )}
    </LanguageSwitcherBox>
  );
};

export default LanguageSwitcher;
