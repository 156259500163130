import React, { useEffect, useState, useMemo } from 'react';
import theme from './theme';
import { Grommet } from 'grommet';
import { Box, TitleComponent } from 'components';
import { observer } from 'mobx-react';
import screens from 'screens';
import { useGetTitle } from 'hooks';
import { RouterView } from 'mobx-state-router';
import { ToastProvider } from 'react-toast-notifications';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe, StripeElementLocale } from '@stripe/stripe-js';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';

type ThemeModes = 'dark' | 'light';

function App() {
  const { i18n } = useTranslation();

  const pageTitle = useGetTitle();
  let defaultMode = 'light';

  if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
    // dark mode
    defaultMode = 'dark';
  }

  const [mode, setMode] = useState<ThemeModes>(defaultMode as ThemeModes);

  // Make sure to call `loadStripe` outside of a component’s render to avoid
  // recreating the `Stripe` object on every render.
  const stripePromise = useMemo(
    () =>
      loadStripe(process.env.STRIPE_API_KEY!, {
        locale: i18n.language as StripeElementLocale,
      }),
    [i18n.language]
  );

  useEffect(() => {
    window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', (e) => {
      setMode(e.matches ? 'dark' : 'light');
    });
  }, []);

  return (
    <Elements stripe={stripePromise}>
      <ToastProvider placement="top-center" autoDismiss={false}>
        <Grommet theme={theme as any} themeMode={mode} full>
          <TitleComponent title={pageTitle} />
          {process.env.ELSYCA_ENV === 'staging' && (
            <Helmet>
              <meta name="robots" content="noindex" />
            </Helmet>
          )}
          <Box fill>
            <RouterView viewMap={screens} />
          </Box>
        </Grommet>
      </ToastProvider>
    </Elements>
  );
}

App.displayName = 'App';

export default observer(App);
